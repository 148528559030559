import React, { useState, useCallback, useEffect } from "react";
import {
  Dimensions,
  Platform,
  View,
  ScrollView,
  RefreshControl,
  TouchableOpacity
} from "react-native";
import AppBar from "../Layout/AppBar";
import {
  primaryColor,
  whiteColor,
} from "../../constant/constantCss";
import AllTripComponent from "./AllTripComponent";
import PrivateTrips from "../Trip/PrivateTrips";
import { ids, styles } from "./TripDashboardCss";
import ContactUs from "../LnadingPage/components/ContactUs";
import MaterialIcons from "react-native-vector-icons/MaterialIcons"
import SectionTrips from "../Trip/SectionTrips";
import { GetFilterTripAPI } from "../../service/api/trip/api";

const TripPage = ({ route }) => {
  const screenHeight = Dimensions.get("window").height;
  const [refreshing, setRefreshing] = useState(false);
  const [upcomingTrip, setUpcomingTrip] = useState([]);
  const [featureTrip, setFeatureTrip] = useState([]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const initialCallingAPI = () => {
    GetFilterTripAPI("upcoming")
      .then((res) => {
        setUpcomingTrip(res.data?.data);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
      GetFilterTripAPI("feature")
      .then((res) => {
        setFeatureTrip(res.data?.data);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };

  useEffect(()=>{
    initialCallingAPI();
  }, [])

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initialCallingAPI();
      setRefreshing(false);
    }, 1000);
  }, []);
  return (
    <>
      <AppBar />
      <View style={{ backgroundColor: whiteColor, minHeight: screenHeight, }}>
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              tintColor={primaryColor}
              colors={[primaryColor]}
            />
          }
          style={{marginBottom: Platform.OS==="ios" ? 110: Platform.OS1!=="web" && 60}}
        >
          <View style={[styles.container, Platform.OS!=="web" && {width: "100%", justifyContent: "center", display: "flex", marginLeft: 0}]} dataSet={{ media: ids.container }}>
            <SectionTrips data={upcomingTrip} section_name={"Upcoming Trips"}/>
            <SectionTrips data={featureTrip} section_name={"Feature Trips"}/>
            <PrivateTrips />
            <AllTripComponent refresh={route.params?.refresh} />
          </View>
      {Platform.OS!=="web" && <ContactUs />}
        </ScrollView>
      </View>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius: 50,
            borderRadius: 66,
            backgroundColor: "#ffb4ac",
          }}
          onPress={handleScrollToTop}
        >
          <MaterialIcons name="keyboard-arrow-up" style={{ fontSize: 42 }} />
        </TouchableOpacity>
        </>
      }
    </>
  );
};
export default TripPage;
