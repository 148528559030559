// Here is the list of validation used in project:

// valid phone number validation
export const MobileNumberValidation = /^[0-9]{7,14}$/;

// atleast 1 lower-case, 1 upper-case,1 number, 1 special char and length 8 to 16
export const PasswordValidation =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

// valid email id validation
export const EmailIdValidation =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// only upper-case and lower-case allowed validation no special char and no numbers
export const OnlyCharValidation = /^[a-zA-Z ]{1,}$/;

export const OnlyNumberValidation = /^[0-9]{1,}$/;

export const CountryCodeValidation = /^\+[1-9]\d{0,3}$/;
