import React, { useEffect, useState,useCallback } from "react";
import { Dimensions, View, ScrollView, Text,Platform,TouchableOpacity,RefreshControl } from "react-native";
import { id, styles } from "./ManageRoleCss";
import AppBar from "../Layout/AppBar";
import { Button,IconButton } from "react-native-paper";
import SearchRole from "./Search Role/SearchRole";
import { GetAllRole } from "../../service/api/role/api";
import RoleTable from "./Role Table/RoleTable";
import CreateRoleModal from "./Permission Modal/CreateRoleModal";
import { useDispatch, useSelector } from "react-redux";
import { setRoleDetails } from "../../store/slices/rolePermissionSlice";
import { showToast } from "../../constant/toastMessage";
import { primaryColor, whiteColor } from "../../constant/constantCss";
import { PERMISSION } from "../../constant/getAllPermission";
import PlaneLoader from "../../components/Loader";
import MaterialIcons from "react-native-vector-icons/MaterialIcons"
import ContactUs from "../LnadingPage/components/ContactUs";

const ManageRole = () => {
  const PAGE_SIZE = 10;
  const { height, width } = Dimensions.get("window");
  const [page, setPage] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const roleList = useSelector((state) => state.rolePermission.roleDetails);
  const { permission } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [refreshing, setRefreshing] = useState(false);
  const [isVisibleCreateRoleModal, setIsVisibleCreateRoleModal] =
    useState(false);
  const initialCallingAPI = () => {
    setLoader(true);
    GetAllRole(page + 1)
      .then((res) => {
        dispatch(setRoleDetails(res?.data?.results));
        setNoOfPage(Math.ceil(res?.data?.count / PAGE_SIZE));
        setLoader(false);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        setLoader(false);
      });
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initialCallingAPI();
      setRefreshing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    initialCallingAPI();
  }, []);
  

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    initialCallingAPI();
  }, []);

  return (
    <View style={[styles.container, { minHeight: height }]}>
      <AppBar />
      <ScrollView showsVerticalScrollIndicator={false} 
        style={{marginBottom:
          Platform.OS === "ios" ? 110 : Platform.OS !== "web" && 60}}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={primaryColor}
            colors={[primaryColor]}
          />
        }
      >
        <View style={styles.subContainer}>
          <View style={styles.flexRow}>
            <Text style={styles.headingText}>Role List</Text>
            {permission && permission?.includes(PERMISSION.CAN_CREATE_ROLE) && (
              Dimensions.get("screen").width >= 480 ? 
                  <Button
                    mode="contained"
                    icon={"plus"}
                    buttonColor={primaryColor}
                    style={{
                      borderRadius: 20,
                      height: 37,
                      paddingVertical: 0,
                      marginRight: 9,
                    }}
                    onPress={() => {
                      setIsVisibleCreateRoleModal(true);
                    }}
                  >
                    Add Role
                  </Button> : 
                  <IconButton
                    icon="plus"
                    containerColor={primaryColor}
                    iconColor={whiteColor}
                    mode="contained"
                    style={{
                      borderRadius: 20,
                      height: 37,
                      paddingVertical: 0,
                      marginRight: 9,
                    }}
                    size={20}
                    onPress={() => {
                      setIsVisibleCreateRoleModal(true);
                    }}
                  />
            )}
          </View>
          {loader ? (
            <PlaneLoader />
          ) : (
            <>
              <SearchRole />
              {roleList?.length > 0 ? (
                <>
                  <RoleTable
                    page={page}
                    setPage={setPage}
                    noOfPage={noOfPage}
                  />
                  {isVisibleCreateRoleModal && (
                    <CreateRoleModal
                      visible={isVisibleCreateRoleModal}
                      handleClose={() => setIsVisibleCreateRoleModal(false)}
                    />
                  )}
                </>
              ) : (
                <Text>No Role found!</Text>
              )}
            </>
          )}
        </View>
        {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
    </View>
  );
};

export default ManageRole;
