import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const TRIP_ENDPOINT = {
  GET_TRIP: BASE_URL + "trip/",
  GET_ANNOUNCEMNET: BASE_URL + "announcement/",
};

export const GetTripApi = async (id) => {
  return axios.get(TRIP_ENDPOINT.GET_TRIP + id + "/");
};
