import React from "react";
import { View, Text, StyleSheet, Image, Platform } from "react-native";
import noTrip from "../../../../assets/icons/noTrip.png";

const NotFoundTripCard = () => {
  const styles = StyleSheet.create({
    notFoundContainer: {
      flex: 1,
      alignItems: "center",
      marginTop: 20,
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      flexDirection: "column",
    },
  });

  return (
    <View style={styles.notFoundContainer}>
      <View style={styles.flexContainer}>
        {Platform.OS == "web" ? (
          <img src={noTrip} style={{ maxHeight: 400, maxWidth: 320 }} />
        ) : (
          <Image source={noTrip} style={{ maxHeight: 350, maxWidth: 300 }} />
        )}
        <Text style={{ fontSize: 22 }}>No Trip Avalible</Text>
        <Text>Tap plus icon to plan your next trip</Text>
      </View>
    </View>
  );
};

export default NotFoundTripCard;
