import React, { useState } from "react";
import {
  Image,
  ImageBackground,
  Linking,
  Text,
  TouchableOpacity,
  View,
  Dimensions
} from "react-native";
import { ids, styles } from "../landingPageCss";
import contactUsBackground from "../../../../assets/landingPage/contact_us/contactUsBackground.webp";
import appstore from "../../../../assets/landingPage/contact_us/appstore.png";
// import playStore from "../../../../assets/landingPage/contact_us/playstore.webp";
import { Divider, TouchableRipple } from "react-native-paper";
import QuickLinkModal from "./QuickLinkModal";
import TermsCondition from "./QuickLinkComponent/TermsCondition";
import PrivacyPolicy from "./QuickLinkComponent/PrivacyPolicy";
import CancellationPolicy from "./QuickLinkComponent/CancellationPolicy";
import BusinessLaw from "./QuickLinkComponent/BusinessLaw";
import PricingPolicy from "./QuickLinkComponent/PricingPolicy";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { primaryColor, primaryOrangeColor, whiteColor } from "../../../constant/constantCss";
import AntDesign from "react-native-vector-icons/AntDesign"
import Ionicons from "react-native-vector-icons/Ionicons"
import MaterialIcons from "react-native-vector-icons/MaterialIcons"
import { useNavigation } from "@react-navigation/native";
import { compose } from "@reduxjs/toolkit";
const ContactUs = () => {
  const [isVisibleQuickLinkModal, setIsVisibleQuickLinkModal] = useState(false);
  const [selectedQuickLink, setSelectedQuickLink] = useState(null);
  const navigation = useNavigation();
  const quickLinkList = [
    {
      name: "Terms & Conditions",
      pathname: "TermsConditions",
    },
    {
      name: "Privacy Policy",
      pathname: "PrivacyPolicy",
    },
    {
      name: "Pricing Policy",
      pathname: "PricingPolicy",
    },
    {
      name: "Cancellation and Refund Policy",
      pathname: "CancellationAndReturnPolicy",
    },
    {
      name: "Business Description & Use case",
      pathname: "BussinessLaw",
    },
  ];
  const socialMediaList = [
    {
      name: "instagram",
      url: "https://www.instagram.com/maroonmigrates/",
      icon: "instagram",
      icon: "instagram",
    },
    {
      name: "facebook",
      url: "https://www.facebook.com/MaroonMigrates/",
      icon: "facebook",
      icon: "facebook",
    },
    {
      name: "whatsapp",
      url: "https://api.whatsapp.com/send?phone=9099445588&text=Hello",
      icon: "whatsapp",
      icon: "whatsapp",
    },
  ];
  const handlePress = (url) => {
    Linking.openURL(url);
  };

  const screenWidth = Dimensions.get('window').width;
  const paddingHorizontal = screenWidth >= 800 ? 120 : 15
  const justcont = screenWidth <= 770 ? "flex-start" : "center";
  return (
    <View style={{backgroundColor:"#171511"}}>
      <View
        style={styles.contact_us_container}
        dataSet={{ media: ids.contact_us_container }}
        id="contact_us"
      >
        <View
          style={styles.contact_us_subContainer}
          dataSet={{ media: ids.contact_us_subContainer }}
        >
          {/* left container */}
          <View
            style={styles.contactLeftContainer}
            dataSet={{ media: ids.contactLeftContainer }}
          >
            <Text style={styles.contact_headerText}>
              <Text>Quick Links</Text>
            </Text>
            {quickLinkList?.map((quickLink, index) => (
              <React.Fragment key={index}>
                <TouchableRipple
                  key={index}
                  onPress={() => {
                    navigation.navigate(quickLink.pathname)
                  }}
                >
                  <Text style={styles.contact_link_text}>{quickLink.name}</Text>
                </TouchableRipple>
              </React.Fragment>
            ))}

            <Text style={styles.contact_link_text}></Text>
          </View>
          {/* Right container */}
          <View
            style={styles.contactRightContainer}
            dataSet={{ media: ids.contactRightContainer }}
          >
            <View
          style={styles.contactUsTextContainer}
          dataSet={{ media: ids.contactUsTextContainer }}
        >
          <Text style={styles.contacUsText}>Contact Us</Text>
        </View>
            <View
              style={styles.socialMediaContainer}
              dataSet={{ media: ids.socialMediaContainer }}
            >
              <View style={styles.iconContainer}>
                <Ionicons name="call-sharp" color={whiteColor} style={{fontSize: 25}}/>
              </View>
              <Text style={styles.socialMediaText}>079 460 406 66</Text>
            </View>
            <View 
              style={styles.socialMediaContainer} 
              dataSet={{ media: ids.socialMediaContainer }} 
            >
              <View style={styles.iconContainer}>
                <MaterialIcons name="mail" color={whiteColor} style={{fontSize: 25}}/>
              </View>
              <TouchableRipple
                    onPress={() => {``
                      Linking.openURL(
                        "mailto:contact@maroonmigrates.com"
                      );
                    }}
                  >
                <Text style={[styles.socialMediaText]}>contact@maroonmigrates.com</Text>
              </TouchableRipple>
            </View>
            <View
              style={[styles.socialMediaContainer, { marginTop: 30 }]}
              dataSet={{ media: ids.socialMediaContainer }}
            >
              <View style={styles.iconContainer}>
                <Text style={[styles.socialMediaText,{fontWeight:600,fontSize:24,marginBottom:10}]}>Follow Us on</Text>
              </View>
            </View>
            <View
              style={[styles.socialMediaContainer]}
              dataSet={{ media: ids.socialMediaContainer }}
            >
              {socialMediaList?.map((socialMedia, index) => (
                <TouchableOpacity
                  key={index}
                  activeOpacity={0.8}
                  onPress={() => handlePress(socialMedia?.url)}
                >
                  <View style={styles.iconContainer}>
                    <FontAwesome name={socialMedia.icon} size={30} color={whiteColor} />
                  </View>
                </TouchableOpacity>
              ))}
            </View>
          </View>
        </View>
      </View>
      <View style={{paddingHorizontal: paddingHorizontal,
          paddingTop: 30,fontWeight:600}}>
          <Divider />
          <Text style={{color:"#e9ecef",display:"flex",justifyContent:justcont,alignItems:"center",height:50}}>
            Copyright © 2024 Maroon Migrates. All rights reserved
          </Text>
      </View>
      </View>
  );
};

export default ContactUs;
