import React from "react";
import { TextInput } from "react-native-paper";
import { StyleSheet } from "react-native";
import {
  blackColor,
  inputTextColor,
  whiteColor,
} from "../../../constant/constantCss";
import { GetAllserAPI } from "../../../service/api/user/getUserApi";
import { showToast } from "../../../constant/toastMessage";

const SearchUser = ({ setUsers }) => {
  const styles = StyleSheet.create({
    textInput: {
      fontSize: 16,
      height: 38,
      borderRadius: 20,
      color: blackColor,
      backgroundColor: whiteColor,
      maxWidth: 350,
      marginBottom: 20,
    },
  });
  const handleSearchUser = (searchVal) => {
    GetAllserAPI({ search: searchVal })
      .then((res) => {
        setUsers(res?.data?.results);
      })
      .catch((e) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  return (
    <TextInput
      style={styles.textInput}
      mode="outlined"
      outlineColor={blackColor}
      activeOutlineColor={inputTextColor}
      placeholder="search user by name or phone no"
      onChangeText={(text) => handleSearchUser(text)}
    />
  );
};

export default SearchUser;
