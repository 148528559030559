import React, { useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  List,
  Switch,
  TextInput,
} from "react-native-paper";
import { ids, styles } from "./CreateNotifyModelCss";
import { View, Text, Modal, ScrollView } from "react-native";
import { GetAllserAPI } from "../../../service/api/user/getUserApi";
import { showToast } from "../../../constant/toastMessage";
import {
  ErrorBoldColor,
  SuccessBoldColor,
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../constant/constantCss";
import {
  CreateNotification,
  GetAllNotifications,
} from "../../../service/api/notification/api";
import PlaneLoader from "../../../components/Loader";

const SelectUsersModal = ({
  visible,
  handleClose,
  closeNotificationModel,
  handleSubmit,
  setValue,
  setNotificationList,
}) => {
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isSendAllUserSwitch, setIsSendAllUserSwitch] = useState(true);
  const [loader, setLoader] = useState(false);
  const [getUserLoader, setGetUserLoader] = useState(false);
  const handleSearchUser = (searchVal) => {
    setGetUserLoader(true);
    GetAllserAPI({ isPagination: "false", search: searchVal })
      .then((res) => {
        setGetUserLoader(false);
        setAllUsers(res.data);
      })
      .catch((err) => {
        setGetUserLoader(false);
        showToast("error", err?.response?.data?.detail);
      });
  };
  const toggleCheckbox = (index) => {
    // Clone the current state of checkedItems
    const newCheckedItems = [...selectedUser];

    // If the item is already checked, uncheck it; otherwise, check it
    if (newCheckedItems.includes(index)) {
      newCheckedItems.splice(newCheckedItems.indexOf(index), 1);
    } else {
      newCheckedItems.push(index);
    }
    setValue("users", newCheckedItems);
    setSelectedUser(newCheckedItems);
  };
  const onSubmitData = (val) => {
    setLoader(true);
    CreateNotification(val)
      .then((res) => {
        GetAllNotifications()
          .then((res) => {
            setLoader(false);
            showToast("success", "Notification created success");
            handleClose();
            closeNotificationModel();
            setNotificationList(res.data);
          })
          .catch((err) => {
            setLoader(false);
            showToast(err?.response?.data?.detail);
          });
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        setLoader(false);
      });
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View
          style={[styles.modalView, { height: 500 }]}
          dataSet={{ media: ids.modalView }}
        >
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Select Users</Text>
            <Text style={styles.closeButton} onPress={handleClose}>
              X
            </Text>
          </View>
          {/* Content */}
          <View
            style={styles.listContainer}
            dataSet={{ media: ids.listContainer }}
          >
            <TextInput
              style={styles.textInput}
              mode="outlined"
              outlineColor={inputTextColor}
              activeOutlineColor={inputTextColor}
              placeholder="search user"
              onChangeText={handleSearchUser}
            />
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: 10,
              }}
            >
              <Text>Are you want to send notify all users ?? </Text>
              <Switch
                color={primaryColor}
                value={isSendAllUserSwitch}
                onValueChange={(val) => {
                  if (val === false) {
                    setGetUserLoader(true);
                    GetAllserAPI({ isPagination: "false" })
                      .then((res) => {
                        setGetUserLoader(false);
                        setAllUsers(res.data);
                      })
                      .catch((err) => {
                        setGetUserLoader(false);
                        showToast("error", err?.response?.data?.detail);
                      });
                  }
                  setIsSendAllUserSwitch(val);
                }}
              ></Switch>
            </View>
            {getUserLoader ? (
              <PlaneLoader />
            ) : (
              !isSendAllUserSwitch && (
                <ScrollView>
                  {allUsers?.length <= 0 ? (
                    <Text>No User found</Text>
                  ) : (
                    allUsers?.map((user, index) => (
                      <List.Item
                        key={index}
                        title={user.first_name}
                        description={
                          user.is_active ? (
                            <Text style={{ color: SuccessBoldColor }}>
                              Active user
                            </Text>
                          ) : (
                            <Text style={{ color: ErrorBoldColor }}>
                              De-Active Account
                            </Text>
                          )
                        }
                        onPress={() => toggleCheckbox(user.id)}
                        left={(props) => (
                          <React.Fragment>
                            <Checkbox.Android
                              color={primaryColor}
                              status={
                                selectedUser.includes(user.id)
                                  ? "checked"
                                  : "unchecked"
                              }
                              onPress={() => toggleCheckbox(user.id)}
                            />
                            <Avatar.Text
                              style={{ backgroundColor: primaryColor }}
                              color={whiteColor}
                              size={40}
                              label={user?.first_name?.charAt(0).toUpperCase()}
                            />
                          </React.Fragment>
                        )}
                      />
                    ))
                  )}
                </ScrollView>
              )
            )}
          </View>
          <Button
            loading={loader}
            icon={"send"}
            buttonColor={primaryColor}
            textColor={whiteColor}
            onPress={handleSubmit(onSubmitData)}
          >
            Send Notification
          </Button>
        </View>
      </View>
    </Modal>
  );
};

export default SelectUsersModal;
