import StyleSheet from "react-native-media-query";
import { blackColor, whiteColor } from "../../../constant/constantCss";
import { Dimensions } from "react-native";

export const { ids, styles } = StyleSheet.create({
  status: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 500,
  },
  listHeader: {
    fontWeight: "bold",
    paddingBottom: 20,
    fontSize: 18,
  },
  tableStyle: {
    width: Dimensions.get("window").width,
    marginTop: 20,
    backgroundColor: whiteColor,
    "@media (max-width: 768px)": {
      width: 1000,
    },
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headingText: {
    marginTop: 10,
    fontSize: 18,
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: blackColor,
  },
});
