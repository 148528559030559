import React, { useState } from "react";
import { Button, DataTable } from "react-native-paper";
import { SuccessBoldColor, primaryColor } from "../../../constant/constantCss";
import UserDetailModal from "../User Modal/UserDetailModal";
import { Dimensions, ScrollView, View } from "react-native";
import StyleSheet from "react-native-media-query";
import { useSelector } from "react-redux";

const UserTable = ({
  users,
  setUsers,
  page,
  setPage,
  noOfPage,
  isVisibleUserModal,
  setIsVisibleUserModal,
}) => {
  const [number, setNumber] = useState(0);
  const { isDrawerVisible } = useSelector((state) => state.general);
  const { ids, styles } = StyleSheet.create({
    tableStyle: {
      width: Dimensions.get("window").width,
      "@media (max-width: 768px)": {
        width: 1000,
      },
    },
  });
  return (
    <>
      <ScrollView horizontal={true}>
        <View style={styles.tableStyle} dataSet={{ media: ids.tableStyle }}>
          <DataTable collapsable={true}>
            <DataTable.Header>
              <DataTable.Title>Name</DataTable.Title>
              <DataTable.Title>Mobile number</DataTable.Title>
              <DataTable.Title>Role</DataTable.Title>
              <DataTable.Title>Action</DataTable.Title>
            </DataTable.Header>
          </DataTable>
          {users?.map((userData, index) => (
            <DataTable.Row key={index}>
              <DataTable.Cell>{userData.first_name}</DataTable.Cell>
              <DataTable.Cell>
                {`+${userData.dialing_code} ${
                  userData.phone_number.slice(0, 5) + "*****"
                }`}
              </DataTable.Cell>
              <DataTable.Cell>{userData.groups?.group}</DataTable.Cell>
              <DataTable.Cell>
                <Button
                  mode="text"
                  textColor={
                    userData.is_active ? SuccessBoldColor : primaryColor
                  }
                  style={styles.buttonStyle}
                  onPress={() => {
                    setIsVisibleUserModal(true);
                    setNumber(userData.id);
                  }}
                >
                  {userData.is_active ? "Verified" : "Verify"}
                </Button>
              </DataTable.Cell>
              {userData.id === number && isVisibleUserModal && (
                <UserDetailModal
                  visible={isVisibleUserModal}
                  handleClose={() => setIsVisibleUserModal(false)}
                  userData={userData}
                  setUsers={setUsers}
                  isActivateButtonVisible={true}
                />
              )}
            </DataTable.Row>
          ))}
        </View>
      </ScrollView>
      <DataTable.Pagination
        page={page}
        numberOfPages={noOfPage}
        onPageChange={(page) => setPage(page)}
        label={`Page ${page + 1} of ${noOfPage}`}
        numberOfItemsPerPage={5}
        showFastPaginationControls
        selectPageDropdownLabel={"Rows per page"}
      />
    </>
  );
};

export default UserTable;
