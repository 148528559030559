import React from "react";
import { View, Text, Image } from "react-native";
import careerImg from "../../../../assets/landingPage/career/career.png";
import { Button } from "react-native-paper";
import { ids, styles } from "../landingPageCss";
import {
  primaryColor,
  primaryOrangeColor,
  whiteColor,
} from "../../../constant/constantCss";
import CareerModal from "./CareerModal";

const Career = ({ isCareerModalVisible, setIsCareerModalVisible }) => {
  return (
    <View
      id="careers"
      style={styles.career_container}
      dataSet={{ media: ids.career_container }}
    >
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Text
          style={styles.career_header_text}
          dataSet={{ media: ids.career_header_text }}
        >
          Careers
        </Text>
        <Text
          style={styles.career_sub_header_text}
          dataSet={{ media: ids.career_sub_header_text }}
        >
          Do you want to
        </Text>
        <Text
          style={styles.career_sub_header_text}
          dataSet={{ media: ids.career_sub_header_text }}
        >
          Join <Text style={{ color: primaryOrangeColor }}>Us?</Text>
        </Text>
        <Button
          textColor={whiteColor}
          buttonColor={primaryColor}
          style={styles.button}
          dataSet={{ media: ids.button }}
          onPress={() => setIsCareerModalVisible(true)}
        >
          Apply
        </Button>
      </View>
      <Image
        resizeMode="contain"
        style={styles.career_img}
        source={careerImg}
        dataSet={{ media: ids.career_img }}
      />
      {isCareerModalVisible ? (
        <CareerModal
          visible={isCareerModalVisible}
          handleClose={() => setIsCareerModalVisible(false)}
        />
      ) : null}
    </View>
  );
};

export default Career;
