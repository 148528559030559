import StyleSheet from "react-native-media-query";
import {
  blackColor,
  blueColor,
  primaryColor,
  greyBackColor,
} from "../../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  formContainer: {
    borderBottomStartRadius: 40,
    borderBottomEndRadius: 40,
    width: "100%",
  },
  header: {
    fontSize: 26,
    color: blackColor,
    fontWeight: "bold",
    textAlign: "center",
  },
  details: {
    marginTop: 10,
    fontWeight: 500,
    fontSize: 16,
    textAlign: "center",
    color: blackColor,
  },
  otpContainer: {
    padding: 20,
    "@media (max-width: 480px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  bottomContainer: {
    color: "#446FEA",
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "flex-end",
    columnGap: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    "@media (max-width: 480px)": {
      paddingHorizontal: 0,
    },
  },
  resendText: {
    marginTop: 10,
    color: primaryColor,
    fontWeight: 600,
  },
  bottomText: {
    fontWeight: 500,
    marginBottom: 10,
    borderColor: primaryColor,
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    columnGap: 10,
  },
  input: {
    width: 50,
    height: 50,
    borderColor: primaryColor,
    borderWidth: 2,
    borderRadius: 5,
    textAlign: "center",
    fontSize: 24,
    "@media (max-width: 768px)": {
      backgroundColor: greyBackColor,
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginTop: 40,
    borderColor: primaryColor,
    backgroundColor: primaryColor,
    width: "100%",
    borderWidth: 1,
    borderColor: primaryColor,
  },
});
