const config = {
  screens: {
    Register: {
      path: "/register",
    },
    Login: {
      path: "/login",
    },
    StudentRegister: {
      path: "/student-register",
    },
    VerifyOtp: {
      path: "/verify-otp",
    },
    Verification: {
      path: "/verify",
    },
    dashboard: {
      path: "/",
    },
    ShowTripInfo: {
      path: "/trip-info/:data",
    },
    Reset_Password: {
      path: "/reset-password",
    },
    PrivacyPolicy: {
      path: "/privacy-policy",
    },
    TermsConditions: {
      path: "/terms-and-condition",
    },
    PricingPolicy : {
      path: "/pricing-policy",
    },
    CancellationAndReturnPolicy : {
      path: "/cancellation-and-return-policy"
    },
    BussinessLaw: {
      path: "/business-description-and-use-case"
    },
    AboutUs: {
      path: "/about-us"
    },
    personalized: {
      path: "/personalized"
    }
  },
};

const linking = {
  prefixes: ["http://localhost:19006"],
  config,
};

export default linking;
