import React from "react";
import { Image, Text, View } from "react-native";
import { ids, styles } from "../landingPageCss";
// explore img
import exploreIcon from "../../../../assets/landingPage/explore_section/icon.png";
import experientialImg from "../../../../assets/landingPage/explore_section/1.webp";
import educationImg from "../../../../assets/landingPage/explore_section/2.webp";
import advantureImg from "../../../../assets/landingPage/explore_section/3.webp";
import globalImg from "../../../../assets/landingPage/explore_section/4.webp";
import {
  containerBlackColor,
  primaryOrangeColor,
} from "../../../constant/constantCss";

const ExploreSection = () => {
  const exploreDescription = [
    {
      title: "1. Experiential Travel:",
      description:
        "Immerse yourself in cultures and communities, fostering meaningful connections and understanding. Our experiential trips not only showcase the beauty of a destination but also contribute to its preservation.",
      is_column_reversed: true,
      img: experientialImg,
    },
    {
      title: "2. Educational Trips:",
      description:
        "Journey with a purposeful educational lens, where learning meets adventure. Our educational trips aim to enrich your understanding of the world while actively contributing to local communities through impactful initiatives.",
      is_column_reversed: false,
      img: educationImg,
    },
    {
      title: "3. Adventure Tours:",
      description:
        "Experience the thrill of adventure with a purpose. Our tours not only push boundaries but also support local economies and conservation efforts, ensuring a sustainable approach to adrenaline-pumping exploration.",
      is_column_reversed: true,
      img: advantureImg,
    },
    {
      title: "4. Global Coverage:",
      description:
        "Traverse the globe with purpose, knowing that each destination is chosen not just for its beauty but for the positive impact your presence can make. From Asia to Europe, explore the world with a purposeful perspective.",
      is_column_reversed: false,
      img: globalImg,
    },
  ];
  return (
    <View style={styles.explore_container}>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={exploreIcon}
          style={styles.icon}
          dataSet={{ media: ids.icon }}
        />
        <Text
          style={[
            styles.preHeaderText,
            {
              textAlign: "center",
            },
          ]}
          dataSet={{ media: ids.preHeaderText }}
        >
          <Text style={{ color: containerBlackColor }}>Explore Our </Text>
          Purposeful
          <Text style={{ color: primaryOrangeColor }}> Offerings</Text>
        </Text>
      </View>
      {exploreDescription?.map((explore_section, index) => (
        <View
          key={index}
          style={
            explore_section?.is_column_reversed
              ? styles.explore_col_reverse_flex
              : styles.explore_col_flex
          }
          dataSet={
            explore_section?.is_column_reversed
              ? { media: ids.explore_col_reverse_flex }
              : { media: ids.explore_col_flex }
          }
        >
          <Image
            style={styles.explore_img}
            source={explore_section?.img}
            dataSet={{ media: ids.explore_img }}
          />
          <View
            style={styles.flexTextContainer}
            dataSet={{ media: ids.flexTextContainer }}
          >
            <View style={styles.subContainer}>
              <View
                style={
                  explore_section?.is_column_reversed
                    ? styles.paddingRightStyle
                    : styles.paddingLeftStyle
                }
                dataSet={
                  explore_section?.is_column_reversed
                    ? { media: ids.paddingRightStyle }
                    : { media: ids.paddingLeftStyle }
                }
              >
                <Text style={styles.explore_headerText}>
                  {explore_section?.title}
                </Text>
                <Text style={styles.explore_subText}>
                  {explore_section?.description}
                </Text>
              </View>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
};

export default ExploreSection;
