import React, { useCallback, useEffect, useState } from "react";
import AppBar from "../Layout/AppBar";
import {
  Dimensions,
  RefreshControl,
  ScrollView,
  View,
  Platform,
  TouchableOpacity,
} from "react-native";
import { ids, styles } from "./CompanyReviewCss.js";
import {
  ErrorBoldColor,
  SuccessBoldColor,
  blackColor,
  primaryColor,
} from "../../constant/constantCss.js";
import { Text } from "react-native";
import { Divider, IconButton, List } from "react-native-paper";
import Ionicons from "react-native-vector-icons/Ionicons";
import {
  GetAllCompanyReview,
  ValidateCompanyReview,
} from "../../service/api/company_review/api.js";
import { showToast } from "../../constant/toastMessage.js";
import PlaneLoader from "../../components/Loader.jsx";
import ContactUs from "../LnadingPage/components/ContactUs.jsx";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

const CompanyReview = () => {
  const [loader, setloader] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const { height } = Dimensions.get("window");
  const [reivewList, setReivewList] = useState([
    {
      name: "abc",
      rating_value: 5,
      message: "Nice website",
    },
    {
      name: "zeel chauhan",
      rating_value: 3,
      message:
        "Game-Changer! This trip management platform is a must-try. Intuitive interface, detailed content, and seamless calendar sync. Enhanced my travel planning.",
    },
  ]);
  const initialCallingAPI = () => {
    setloader(true);
    GetAllCompanyReview()
      .then((res) => {
        setloader(false);
        setReivewList(res?.data);
      })
      .catch((err) => {
        setloader(false);
        showToast("error");
      });
  };
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initialCallingAPI();
      setRefreshing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    initialCallingAPI();
  }, []);

  const handleValidateReview = (reviewId, is_public) => {
    const payload = {
      is_public: !is_public,
    };
    ValidateCompanyReview(payload, reviewId)
      .then((res) => {
        showToast("success", res?.data?.message);
        initialCallingAPI();
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };

  const handleScrollToTop = () => {
    if (Platform.OS === "web") {
      // For web
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      // For React Native
      scrollViewRef.current.scrollTo({ y: 0, animated: true });
    }
  };

  return (
    <View style={[styles.container, { minHeight: height }]}>
      <AppBar />
      <ScrollView
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={primaryColor}
            colors={[primaryColor]}
          />
        }
        style={{
          marginBottom:
            Platform.OS === "ios" ? 110 : Platform.OS !== "web" && 60,
        }}
      >
        <View style={styles.subContainer} dataSet={{ media: ids.subContainer }}>
          <View style={styles.flexRow}>
            <Text style={styles.headingText}>Company Review</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
              paddingTop: 16,
            }}
          >
            <Ionicons color={SuccessBoldColor} name={"lock-open"} size={24} />
            <Text style={{ color: blackColor, fontSize: 16 }}>
              Public : The review is visible on our website
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
              paddingTop: 8,
            }}
          >
            <Ionicons color={ErrorBoldColor} name={"lock-closed"} size={24} />
            <Text style={{ color: blackColor, fontSize: 16 }}>
              Private : The review is not visible on our website
            </Text>
          </View>
          <View style={styles.screenBody}>
            {loader ? (
              <PlaneLoader />
            ) : (
              <View style={styles.listBody}>
                {reivewList.length > 0 ? (
                  <>
                    {reivewList?.map((item, index) => (
                      <React.Fragment key={index}>
                        <List.Item
                          style={styles.listStyle}
                          title={item?.name}
                          descriptionNumberOfLines={10}
                          left={(props) => (
                            <Ionicons
                              props={props}
                              key={index}
                              color={
                                item?.is_public
                                  ? SuccessBoldColor
                                  : ErrorBoldColor
                              }
                              name={
                                item?.is_public ? "lock-open" : "lock-closed"
                              }
                              size={24}
                              style={{ padding: 14 }}
                            />
                          )}
                          description={
                            <View>
                              <Text>{item?.message}</Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 10,
                                  marginTop: 10,
                                }}
                              >
                                {Array.from({ length: 5 }, (_, index) => (
                                  <Ionicons
                                    key={index}
                                    color={
                                      item?.rating_value > index
                                        ? "yellow"
                                        : "grey"
                                    }
                                    name={
                                      item?.rating_value > index
                                        ? "star"
                                        : "star-outline"
                                    }
                                    size={18}
                                  />
                                ))}
                              </View>
                            </View>
                          }
                          right={(props) => (
                            <IconButton
                              mode="contained"
                              {...props}
                              iconColor={primaryColor}
                              icon={item?.is_public ? "eye-off" : "eye"}
                              size={20}
                              onPress={() =>
                                handleValidateReview(item?.id, item?.is_public)
                              }
                            />
                          )}
                        />
                        <Divider horizontalInset={true} />
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 50,
                      gap: 20,
                    }}
                  >
                    <Text style={styles.headerText}>
                      There are no company review available.
                    </Text>
                  </View>
                )}
              </View>
            )}
          </View>
        </View>
        {Platform.OS !== "web" && <ContactUs />}
      </ScrollView>
      {Platform.OS === "web" && <ContactUs />}
      <TouchableOpacity
        style={{
          position: "sticky",
          bottom: 20,
          right: 20,
          marginTop: -44,
          alignSelf: "flex-end",
          borderRadius: 66,
          backgroundColor: "#ffb4ac",
        }}
        onPress={handleScrollToTop}
      >
        <MaterialIcons name="keyboard-arrow-up" style={{ fontSize: 42 }} />
      </TouchableOpacity>
    </View>
  );
};

export default CompanyReview;
