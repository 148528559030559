import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const RATING_ENDPOINT = {
  RATING_URL: BASE_URL + "review/trip/",
  PUBLIC_RATING: BASE_URL + "review/test/"
};

export const GetAllUserRating = async ({ trip_id }) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.get(`${RATING_ENDPOINT.RATING_URL}${trip_id}/`, headers);
  }
};

export const GetUserRating = async ({ trip_id }) => {
    return axios.get(`${RATING_ENDPOINT.PUBLIC_RATING}?trip_id=${trip_id}`);
};

export const CreateUserRating = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(RATING_ENDPOINT.RATING_URL, payload, headers);
  }
};

export const VerifyReview = async (trip_id, payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.patch(
      `${RATING_ENDPOINT.RATING_URL}${trip_id}/`,
      payload,
      headers
    );
  }
};
