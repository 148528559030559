import React from "react";
import { Image, View, Text, Dimensions, BackHandler } from "react-native";
import noInternetIcon from "../../../assets/icons/no_internet.gif";
import {
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../constant/constantCss";
import { Button } from "react-native-paper";
import StyleSheet from "react-native-media-query";

const NoInternetScreen = ({ checkConnectivity }) => {
  const { height, width } = Dimensions.get("window");
  const { id, styles } = StyleSheet.create({
    container: {
      height: height,
      width: width,
      backgroundColor: whiteColor,
      padding: 20,
    },
    subContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
      "@media (min-width: 768px)": {
        gap: 30,
      },
    },
    img: {
      height: 320,
      width: 480,
      "@media (max-width: 480px)": {
        width: "100%",
      },
    },
    text: {
      fontSize: 28,
      fontWeight: 600,
    },
    button: {
      width: "100%",
      maxWidth: 480,
    },
  });
  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Image source={noInternetIcon} style={styles.img} />
        <Text style={styles.text}>Whoops !!</Text>
        <Text
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: subTextColor,
            textAlign: "center",
          }}
        >
          Slow or no internet connections, please check your internet settings
        </Text>
        <Button
          buttonColor={primaryColor}
          textColor={whiteColor}
          style={[styles.button, { marginTop: 28 }]}
          onPress={checkConnectivity}
        >
          Try again
        </Button>
        <Button
          buttonColor={subTextColor}
          textColor={whiteColor}
          style={styles.button}
          onPress={() => {
            BackHandler.exitApp();
          }}
        >
          Exit
        </Button>
      </View>
    </View>
  );
};

export default NoInternetScreen;
