import React from "react";
import { View, Text, ScrollView,TouchableOpacity,Platform,Dimensions } from "react-native";
import { blackColor, subTextColor, whiteColor } from "../../../../constant/constantCss";
import Navbar from "../Navbar";
import ContactUs from "../ContactUs";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

const PricingPolicy = () => {
  const termsList = [
    {
      title: "Dynamic Pricing : ",
      description:
        "Many travel companies, especially airlines, use dynamic pricing strategies. Prices can vary based on factors such as demand, time until departure, competitor pricing, and even the customer's browsing history. This allows companies to optimize revenue by charging higher prices during peak times and offering discounts during off-peak periods.",
    },
    {
      title: "Seasonal Pricing : ",
      description:
        "Prices often vary depending on the season, with higher prices during peak travel times (e.g., summer holidays, Christmas) and lower prices during off-peak times. This helps companies manage demand and maximize revenue throughout the year.",
    },
    {
      title: "Promotional Pricing : ",
      description:
        "Travel companies frequently offer promotions and discounts to attract customers. These can include early booking discounts, last-minute deals, package deals, and loyalty program rewards. Promotions are often used to fill empty seats or rooms and generate additional revenue.",
    },
    {
      title: "Segmented Pricing : ",
      description:
        "Companies may offer different prices to different customer segments based on factors such as age, income, and travel history. For example, airlines often offer discounted fares for children, students, and senior citizens.",
    },
    {
      title: "Bundling : ",
      description:
        "Bundling involves offering multiple products or services together at a discounted price. For example, a hotel might offer a room-and-meal package, or an airline might offer a flight-and-hotel bundle. Bundling can increase revenue by encouraging customers to purchase additional services.",
    },
    {
      title: "Yield Management : ",
      description:
        "Yield management is a pricing strategy that involves adjusting prices based on demand in order to maximize revenue. This is common in industries with perishable inventory, such as airlines and hotels, where prices can change frequently based on factors such as booking pace and capacity.",
    },
  ];

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const screenHeight = Dimensions.get("window").height;

  return (
    <View style={{backgroundColor:whiteColor,minHeight: screenHeight}}>
      <Navbar hideComponents={true}/>
      <ScrollView 
        style={{marginBottom: Platform.OS==="ios" ? 120: Platform.OS!=="web" && 60}}
      >
      <View style={{margin:50,maxWidth:1000,justifyContent:"center",alignSelf:"center"}}>
      <Text style={{ fontSize: 22,
          color: blackColor,
          fontWeight: "900",
          marginTop: 16,
          marginBottom:16}}>
            Our Pricing Policy
        </Text>
      <Text style={{ fontSize: 16, color: subTextColor }}>
        Pricing policies in the travel industry can vary widely depending on the
        sector (e.g., airlines, hotels, tour operators) and market conditions.
        However, there are several common pricing strategies and considerations
        that are often used :
      </Text>
      {termsList?.map((term, index) => (
        <Text
          key={index}
          style={{
            fontSize: 18,
            fontWeight: "800",
            color: blackColor,
            marginTop: 16,
          }}
        >
          {term.title}
          <Text style={{ fontSize:16,fontWeight: "500", color: subTextColor }}>
            {term.description}
          </Text>
        </Text>
      ))}
      <Text style={{ fontSize: 16, color: subTextColor, marginTop: 16 }}>
        Overall, pricing in the travel industry is a complex and dynamic process
        that involves balancing supply and demand, managing costs, and
        maximizing revenue. Companies use a variety of pricing strategies and
        tactics to achieve these goals and stay competitive in the market.
      </Text>
      </View>
      {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius:50,
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
    </View>
  );
};

export default PricingPolicy;
