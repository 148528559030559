import React from "react";
import { Dimensions, Image, Text, View } from "react-native";
import { ids, styles } from "../landingPageCss";
import {
  containerBlackColor,
  primaryColor,
  primaryOrangeColor,
  primaryRedColor,
  whiteColor,
} from "../../../constant/constantCss";
import flightLogo from "../../../../assets/landingPage/flight.png";
import welcomeImg from "../../../../assets/landingPage/welcomeImg.webp";
import { Button } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";

const WelcomeSection = ({
  isVisiblePersonalizedTripModal,
  setIsVisiblePersonalizedTripModal,
}) => {
  const { width } = Dimensions.get("window");
  const navigation = useNavigation();
  return (
    <View style={styles.flexContainer} dataSet={{ media: ids.flexContainer }}>
      <View
        style={styles.flexTextContainer}
        dataSet={{ media: ids.flexTextContainer }}
      >
        <Text
          style={styles.preHeaderText}
          dataSet={{ media: ids.preHeaderText }}
        >
          <Text style={{ color: containerBlackColor }}>
            Welcome to{" "}
            <Image
              style={styles.flightLogo}
              source={flightLogo}
              resizeMode="contain"
              dataSet={{ media: ids.flightLogo }}
            />
            {"\n"}
          </Text>
          <Text style={{ color: primaryRedColor }}>Maroon </Text>
          <Text style={{ color: primaryOrangeColor }}>Migrates</Text>
        </Text>
        <View style={styles.subContainer}>
          <Text style={styles.headerText} dataSet={{ media: ids.headerText }}>
            Tourism with Difference,
            <Text style={{ color: primaryOrangeColor }}> Purpose</Text>
          </Text>
          <Text style={styles.linkText} dataSet={{ media: ids.linkText }}>
            About us
          </Text>
          <Text style={styles.subText} dataSet={{ media: ids.subText }}>
            At Maroon Migrates, we believe in tourism that goes beyond
            sightseeing - it's about making a positive impact on the world. With
            over a decade of unwavering commitment, we've evolved into a travel
            company that blends exploration with purpose. Every journey we
            curate is infused with a deep sense of responsibility and a
            commitment to leaving a lasting, positive footprint.
          </Text>
          {width < 768 && (
            <Button
              mode="contained"
              buttonColor={primaryColor}
              textColor={whiteColor}
              style={{ marginTop: 16 }}
              onPress={() => navigation.navigate("personalized")}
              labelStyle={{ fontSize: 14 }}
            >
              Want a Personalized Trip ?
            </Button>
          )}
        </View>
      </View>
      <Image
        resizeMode="cover"
        style={styles.welcomeImg}
        dataSet={{ media: ids.welcomeImg }}
        source={welcomeImg}
      />
    </View>
  );
};

export default WelcomeSection;
