import React, { useState, useEffect, useCallback } from "react";
import AppBar from "../Layout/AppBar";
import {
  Dimensions,
  View,
  ScrollView,
  Text,
  RefreshControl,
  Platform,
  TouchableOpacity
} from "react-native";
import {  Button, Chip } from "react-native-paper";
import { ids, styles } from "./RevenuePageCss";
import { primaryColor, whiteColor } from "../../constant/constantCss";
import ParticipantTable from "./ParticipantTable/ParticipantTable";
import {
  GetAllPaymentAnalytics,
  GetAllTripAnalytics,
} from "../../service/api/analytics/api";
import AnalyticFilterModal from "./FilterModal/AnalyticFilterModal";
import moment from "moment";
import PieChart from "react-native-pie-chart";
import PlaneLoader from "../../components/Loader";
import ContactUs from "../LnadingPage/components/ContactUs";
import MaterialIcons from "react-native-vector-icons/MaterialIcons"

const RevenuePage = () => {
  const { height, width } = Dimensions.get("window");
  const [loader, setLoader] = useState(false);
  const [tripAnalyticsData, setTripAnalyticsData] = useState({});
  const [paymentAnalyticsData, setPaymentAnalyticsData] = useState({});
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isVisibleNote, setIsVisibleNote] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const filterMessage = () => {
    if (startDate && endDate) {
      return `Note : The records are now filtered to display only within the specified date range (Start Date: ${moment(
        startDate
      ).format("D-M-YYYY")} End Date: ${moment(endDate).format("D-M-YYYY")})`;
    } else if (startDate) {
      return `Note : The records are now filtered to display only within the specified date range (Start Date: ${moment(
        startDate
      ).format("D-M-YY")})`;
    }
  };
  const initalCallingApi = () => {
    GetAllTripAnalytics({})
      .then((res) => {
        setTripAnalyticsData(res.data);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
    GetAllPaymentAnalytics({})
      .then((res) => {
        setPaymentAnalyticsData(res.data);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initalCallingApi();
      setRefreshing(false);
    }, 1000);
  }, []);
  useEffect(() => {
    initalCallingApi();
  }, []);

  const StatusContainer = ({ backColor, title, val }) => {
    return (
      <View
        style={{
          flexDirection: "row",
          gap: 10,
          alignItems: "center",
        }}
      >
        <View
          style={[styles.statusContainer, { backgroundColor: backColor }]}
        />
        <Text>{`${title} (${val})`}</Text>
      </View>
    );
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <View style={[styles.container, { minHeight: height }]}>
      <AppBar />
      <ScrollView
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={primaryColor}
            colors={[primaryColor]}
          />
        }
        style={{marginBottom:
          Platform.OS === "ios" ? 110 : Platform.OS !== "web" && 60}}
      >
        <View style={styles.subContainer}>
          <View style={styles.flexRow}>
            <Text style={styles.headingText}>Overview</Text>
            <Button
              mode="contained"
              buttonColor={primaryColor}
              icon={"filter"}
              onPress={() => setFilterModalVisible(true)}
            >
              Filter
            </Button>
          </View>
          {loader ? (
            <PlaneLoader />
          ) : (
            <>
              {isVisibleNote && (
                <View
                  style={styles.overViewContainer}
                  dataSet={{ media: ids.overViewContainer }}
                >
                  <View style={styles.rightContainer}>
                    <Text style={styles.keyText}>{filterMessage()}</Text>
                  </View>
                </View>
              )}
              <View
                style={[styles.overViewContainer]}
                dataSet={{ media: ids.overViewContainer }}
              >
                <View style={styles.leftContainer}>
                  <Text style={styles.keyText}>Total Trips</Text>
                  <Text style={styles.valText}>
                    {tripAnalyticsData?.trip_count?.all ?? 0}
                  </Text>
                </View>
                <View style={styles.rightContainer}>
                  <Text style={styles.keyText}>Total Revenue</Text>
                  <Text style={styles.valText}>
                    {`Rs ${paymentAnalyticsData?.revenue?.total / 100}` ??
                      "Rs 0"}
                  </Text>
                </View>
              </View>
              <View
                style={styles.graphContainer}
                dataSet={{ media: ids.graphContainer }}
              >
                <View style={[styles.graphLeftContainer]}>
                  <Text style={styles.keyText}>Status of Trip</Text>
                  {tripAnalyticsData?.trip_count?.ongoing +
                    tripAnalyticsData?.trip_count?.upcoming +
                    tripAnalyticsData?.trip_count?.recent +
                    tripAnalyticsData?.trip_count?.completed >
                  0 ? (
                    <React.Fragment>
                      <PieChart
                        widthAndHeight={240}
                        series={[
                          tripAnalyticsData?.trip_count?.ongoing,
                          tripAnalyticsData?.trip_count?.upcoming,
                          tripAnalyticsData?.trip_count?.recent,
                          tripAnalyticsData?.trip_count?.completed,
                        ]}
                        sliceColor={[
                          "#54FFE0",
                          "#FEE500",
                          "#FF9B9B",
                          "#90F410",
                        ]}
                        coverFill={"#FFF"}
                      />
                      <View
                        style={styles.graphFlexRow}
                        dataSet={{ media: ids.graphFlexRow }}
                      >
                        <StatusContainer
                          backColor="#54FFE0"
                          title="In progress"
                          val={tripAnalyticsData?.trip_count?.ongoing}
                        />
                        <StatusContainer
                          backColor="#FEE500"
                          title="Upcoming"
                          val={tripAnalyticsData?.trip_count?.upcoming}
                        />
                        <StatusContainer
                          backColor="#FF9B9B"
                          title="Recent"
                          val={tripAnalyticsData?.trip_count?.recent}
                        />
                        <StatusContainer
                          backColor="#90F410"
                          title="Completed"
                          val={tripAnalyticsData?.trip_count?.completed}
                        />
                      </View>
                    </React.Fragment>
                  ) : (
                    <Text
                      style={{
                        fontSize: 18,
                        padding: 20,
                        textAlign: "justify",
                      }}
                    >
                      The graph might be hard to see because there isn't enough data. To make it easier to understand, we need more data for a better picture.
                    </Text>
                  )}
                </View>
                <View
                  style={styles.graphRightContainer}
                  dataSet={{ media: ids.graphRightContainer }}
                >
                  <Text style={styles.keyText}>Payment Mode Distribution</Text>
                  {paymentAnalyticsData?.payment_count?.online +
                    paymentAnalyticsData?.payment_count?.cash >
                  0 ? (
                    <React.Fragment>
                      <PieChart
                        widthAndHeight={240}
                        series={[
                          paymentAnalyticsData?.payment_count?.online,
                          paymentAnalyticsData?.payment_count?.cash,
                        ]}
                        sliceColor={["#6F8EFF", "#7045EA"]}
                        coverFill={"#FFF"}
                      />
                      <View
                        style={styles.graphFlexRow}
                        dataSet={{ media: ids.graphFlexRow }}
                      >
                        <StatusContainer
                          backColor="#6F8EFF"
                          title="Online Payment"
                          val={paymentAnalyticsData?.payment_count?.online}
                        />
                        <StatusContainer
                          backColor="#7045EA"
                          title="Cash Payment"
                          val={paymentAnalyticsData?.payment_count?.cash}
                        />
                      </View>
                    </React.Fragment>
                  ) : (
                    <Text
                      style={{
                        fontSize: 18,
                        padding: 20,
                        textAlign: "justify",
                      }}
                    >
                      The graph might be hard to see because there isn't enough data. To make it easier to understand, we need more data for a better picture.
                    </Text>
                  )}
                </View>
              </View>
              <View style={styles.participantContainer}>
                <View style={styles.rightContainer}>
                  <Text style={styles.keyText}>
                    Top Trips by Participants(10)
                  </Text>
                </View>
                <ParticipantTable />
              </View>
            </>
          )}
        </View>
      {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
      {filterModalVisible ? (
        <AnalyticFilterModal
          visible={filterModalVisible}
          handleClose={() => setFilterModalVisible(false)}
          type="analytic_overview"
          setTripAnalyticsData={setTripAnalyticsData}
          setPaymentAnalyticsData={setPaymentAnalyticsData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setIsVisibleNote={setIsVisibleNote}
        />
      ) : null}
    </View>
  );
};

export default RevenuePage;
