import React, { useState, useEffect, useCallback } from "react";
import {
  Dimensions,
  ScrollView,
  Text,
  View,
  Image,
  RefreshControl,
  Platform,
  TouchableOpacity
} from "react-native";
import { ids, styles } from "./AllUsersCss";
import AppBar from "../Layout/AppBar";
import { primaryColor } from "../../constant/constantCss";
import { GetAllserAPI } from "../../service/api/user/getUserApi";
import { showToast } from "../../constant/toastMessage";
import UserTable from "./User Table/UserTable";
import SearchUser from "./Filter User/SearchUser";
import FilterUser from "./Filter User/FilterUser";
import noUserImg from "../../../assets/icons/no_user.png";
import PlaneLoader from "../../components/Loader";
import ContactUs from "../LnadingPage/components/ContactUs";
import MaterialIcons from "react-native-vector-icons/MaterialIcons"

const AllUsers = () => {
  const PAGE_SIZE = 10;
  const { height, width } = Dimensions.get("window");
  const [isVisibleUserModal, setIsVisibleUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const initalCallingApi = () => {
    setLoader(true);
    GetAllserAPI({ page: page + 1 })
      .then((res) => {
        setLoader(false);
        setUsers(res?.data?.results);
        setNoOfPage(Math.ceil(res?.data?.count / PAGE_SIZE));
      })
      .catch((err) => {
        setLoader(false);
        showToast("error", err?.response?.data?.detail);
      });
  };
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initalCallingApi();
      setRefreshing(false);
    }, 1000);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    initalCallingApi();
  }, [page]);

  return (
    <View style={[styles.container, { minHeight: height }]}>
      <AppBar />
      <ScrollView
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={primaryColor}
            colors={[primaryColor]}
          />
        }
        style={{marginBottom:
          Platform.OS === "ios" ? 110 : Platform.OS !== "web" && 60}}
      >
        <View style={styles.subContainer}>
          <Text style={styles.headingText}>Verification Requests</Text>
          {loader ? (
            <PlaneLoader />
          ) : (
            <>
              <SearchUser setUsers={setUsers} />
              <FilterUser setUsers={setUsers} />
              {users?.length > 0 ? (
                <UserTable
                  users={users}
                  setUsers={setUsers}
                  page={page}
                  setPage={setPage}
                  noOfPage={noOfPage}
                  isVisibleUserModal={isVisibleUserModal}
                  setIsVisibleUserModal={setIsVisibleUserModal}
                />
              ) : (
                <View
                  style={{
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                    marginTop: 20,
                  }}
                >
                  <Image
                    resizeMode={"contain"}
                    source={noUserImg}
                    style={{ height: 100, width: 100 }}
                  />
                  <Text
                    style={{
                      color: primaryColor,
                      fontSize: 18,
                      fontWeight: 500,
                    }}
                  >
                    No user found !
                  </Text>
                </View>
              )}
            </>
          )}
        </View>
        {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
    </View>
  );
};

export default AllUsers;
