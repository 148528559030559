import StyleSheet from "react-native-media-query";
import {
  blackColor,
  greyBackColor,
  blueColor,
  inputBackColor,
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  albumContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noPhotosText: {
    fontSize: 18,
    fontWeight: 600,
    margin: 20,
  },
  addIcon: {
    color: primaryColor,
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  album: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 200,
    alignItems: "center",
    borderRadius: 10,
    padding: 10,
    backgroundColor: whiteColor,
    "@media (max-width: 768px)": {
      width: "100%",
    },
    position: "relative",
  },
  image: {
    width: "100%",
    height: 150,
    borderRadius: 5,
    "@media (max-width: 768px)": {
      height: 200,
    },
  },
  deleteContainer: {
    position: "absolute",
  },

  //Modal
  closeIcon: {
    display: "flex",
    textAlign: "right",
    justifyContent: "flex-end",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    width: 480,
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 768px)": {
      width: "90%",
    },
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  uploadBox: {
    borderColor: inputTextColor,
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 40,
    paddingHorizontal: 100,
    backgroundColor: greyBackColor,
    marginBottom: 10,
    "@media (max-width: 480px)": {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  modalIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: blueColor,
  },
  boxText: {
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 10,
    color: blackColor,
  },
  textInput: {
    height: 35,
    backgroundColor: inputBackColor,
  },

  //

  container: {
    marginBottom: 30,
    height: "100%",
    minHeight: 0,
    padding: 16,
  },
  innerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 14,
    flexWrap: "wrap",
    "@media (max-width: 480px)": {
      flexDirection: "column",
      width: "100%",
    },
  },
  surfaceContainer: {
    height: 150,
    width: 250,
    backgroundColor: whiteColor,
    borderRadius: 5,
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 16,
    justifyContent: "flex-start",
  },
  imageContainer: {
    height: 150,
    width: 250,
    borderRadius: 20,
  },
});
