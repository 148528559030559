import React, { useState, useRef, useEffect } from "react";
import { View, TextInput, Pressable } from "react-native";
import { Button, Text } from "react-native-paper";
import { ids, styles } from "./OtpInputCss";
import { GetOtpApi, VerifyOtpApi } from "../../../../service/api/user/OtpApi";
import { useNavigation } from "@react-navigation/native";
import { whiteColor } from "../../../../constant/constantCss";
import { showToast } from "../../../../constant/toastMessage";

const OtpInput = ({ otpData, otpType }) => {
  const navigation = useNavigation();
  const [digits, setDigits] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [loader, setLoader] = useState(false);
  const [phoneNo, setPhoneNo] = useState([]);
  const [counter, setCounter] = useState(60);

  const extractDigits = (number) => {
    const firstSixDigits = String(number).substring(0, 6);
    setPhoneNo(firstSixDigits);
  };
  const data = {
    dialing_code: otpData.dialing_code,
    phone_number: otpData.phone_number,
  };
  const handleInputChange = (index, value) => {
    if (value.length > 1) {
      value = value.charAt(0);
    }
    const newDigits = digits.map((digit, i) => (i === index ? value : digit));
    setDigits(newDigits);
    if (value !== "" && index < 3) {
      inputRefs[index + 1].current.focus();
    }
  };
  const handleVerifyOTP = () => {
    setLoader(true);
    const inputOtp = digits.join("");
    const dataWithOTP = {
      ...data,
      otp: inputOtp,
    };
    VerifyOtpApi("verify/", dataWithOTP)
      .then((res) => {
        showToast("success", "OTP validated successfully!");
        if (otpType == "forgot_password") {
          navigation.navigate("Reset_Password", { data: dataWithOTP });
        } else if (otpType == "student_register") {
          navigation.navigate("Login");
        } else {
          navigation.navigate("Verification");
        }
      })
      .catch((err) => {
        if (err.response.data.otp) {
          showToast("error", err.response.data.otp);
          setLoader(false);
        } else {
          setLoader(false);
          showToast("error", err?.response?.data?.message);
          showToast("error", err?.response?.data?.otp[0]);
        }
      });
  };
  const resendOtp = () => {
    setCounter(60);
    GetOtpApi("send/", otpData)
      .then((res) => {
        showToast("error", res.data.data.message);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };

  useEffect(() => {
    extractDigits(otpData.phone_number);
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <React.Fragment>
      <View style={styles.formContainer} dataSet={{ media: ids.formContainer }}>
        <Text style={styles.header}>OTP Verification </Text>
        <Text style={styles.details}>
          Enter OTP sent to +{data.dialing_code} {phoneNo} ****
        </Text>
        <View style={styles.otpContainer} dataSet={{ media: ids.otpContainer }}>
          <View style={styles.container}>
            {digits?.map((digit, index) => (
              <TextInput
                key={index}
                style={styles.input}
                dataSet={{ media: ids.input }}
                maxLength={1}
                keyboardType="numeric"
                value={digit}
                onChangeText={(value) => handleInputChange(index, value)}
                ref={inputRefs[index]}
              />
            ))}
          </View>
        </View>
      </View>
      {counter ? (
        <View
          style={styles.bottomContainer}
          dataSet={{ media: ids.bottomContainer }}
        >
          <Text style={styles.bottomText} dataSet={{ media: ids.bottomText }}>
            00:{counter}
          </Text>
        </View>
      ) : (
        <Pressable onPress={resendOtp}>
          <Text
            style={[styles.resendText, { textAlign: "right" }]}
            dataSet={{ media: ids.bottomText }}
          >
            Resend Otp
          </Text>
        </Pressable>
      )}
      <View style={styles.buttonContainer}>
        <Button
          style={styles.button}
          loading={loader}
          mode="outlined"
          textColor={whiteColor}
          onPress={handleVerifyOTP}
        >
          Verify and Proceed
        </Button>
      </View>
    </React.Fragment>
  );
};

export default OtpInput;
