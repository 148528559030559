import React, { useState } from "react";
import { ids, styles } from "./registerCss.js";
import {
  View,
  Text,
  Image,
  Dimensions,
  ImageBackground,
  ScrollView,
  Platform,
} from "react-native";
import logo from "../../../assets/splashLogo.png";
import {
  blackColor,
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../constant/constantCss.js";
import { Button, TextInput } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { useForm, Controller } from "react-hook-form";
import { UserForgotPassword } from "../../service/api/user/api.js";
import { showToast } from "../../constant/toastMessage.js";
import { GetOtpApi } from "../../service/api/user/OtpApi.js";
import { useSelector } from "react-redux";
import useKeyboardHeight from "../../hooks/KeyboardHeight.jsx";

const ForgotPassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dialingCode: "+91",
    },
  });
  const { height, width } = Dimensions.get("window");
  const { backgroundImage } = useSelector((state) => state.general);
  const roundness = width > 768 ? 40 : 8;
  const navigation = useNavigation();
  const handleSkipButton = () => {
    navigation.goBack();
  };
  const [loader, setLoader] = useState(false);
  const keyboardHeight = useKeyboardHeight();
  const onSubmit = (data) => {
    setLoader(true);
    let otpData = {
      dialing_code: data?.dialingCode?.replace("+", ""),
      phone_number: data?.phone,
    };

    UserForgotPassword(otpData)
      .then((res) => {
        const status = res?.data?.status;
        if (status === "success") {
          GetOtpApi("send/", otpData)
            .then((res) => {
              showToast("success", res.data.data?.message);
              otpData.otp = res.data.data.otp;
              navigation.navigate("VerifyOtp", {
                data: otpData,
                type: "forgot_password",
              });
            })
            .catch((err) => {
              showToast("error", err?.response?.data?.detail);
            });
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        showToast(
          "error",
          err?.response?.data?.message ??
            err?.response?.data?.phone_number?.[0] ??
            err?.response?.data?.dialing_code?.[0]
        );
      });
  };
  return (
    <View style={{ flexDirection: "column", minHeight: height }}>
      <ImageBackground source={backgroundImage} style={styles.imageBackground}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            marginBottom: keyboardHeight,
          }}
        >
          <View style={styles.container} dataSet={{ media: ids.container }}>
            <View style={styles.form} dataSet={{ media: ids.form }}>
              <View style={[styles.flexRow, { justifyContent: "center" }]}>
                <Image
                  style={styles.image}
                  dataSet={{ media: ids.image }}
                  source={logo}
                />
              </View>
              <View
                style={styles.formContainer}
                dataSet={{ media: ids.formContainer }}
              >
                <View style={styles.flexRow}>
                  <Text style={styles.header} dataSet={{ media: ids.header }}>
                    Forgot password
                  </Text>
                  <Button
                    mode="text"
                    textColor={primaryColor}
                    onPress={handleSkipButton}
                  >
                    Back
                  </Button>
                </View>
                <Text style={styles.details} dataSet={{ media: ids.details }}>
                  Please provide your phone number, and we will send you an OTP
                  to that number.
                </Text>
                <View style={styles.inputView}>
                  <Text style={styles.text} dataSet={{ media: ids.text }}>
                    Mobile Number
                  </Text>
                  <View style={[styles.phoneContainer]}>
                    <Controller
                      control={control}
                      rules={{
                        required: "coutry code is required",
                        pattern: {
                          value: /^\+[1-9]\d{0,3}$/,
                          message: "Please enter a valid country code",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          style={[styles.textInput, styles.pin]}
                          onChangeText={onChange}
                          value={value}
                          placeholder="+91"
                          mode="outlined"
                          outlineColor={inputTextColor}
                          activeOutlineColor={inputTextColor}
                          maxLength={4}
                          theme={{
                            roundness: roundness,
                          }}
                          contentStyle={{ color: blackColor }}
                        />
                      )}
                      name="dialingCode"
                    />
                    <Controller
                      control={control}
                      rules={{
                        required: "Mobile No. is required",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Please enter a valid 10-digit phone number",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          style={[
                            styles.textInput,
                            styles.phoneField,
                            { color: "red" },
                          ]}
                          onChangeText={onChange}
                          defaultValue={value}
                          mode="outlined"
                          outlineColor={inputTextColor}
                          placeholder="0123456789"
                          activeOutlineColor={inputTextColor}
                          maxLength={10}
                          contentStyle={{ color: blackColor }}
                          theme={{
                            roundness: roundness,
                          }}
                          // add enter functionality
                          onSubmitEditing={Platform.OS==="web" ? handleSubmit(onsubmit): ()=>{}}

                        />
                      )}
                      name="phone"
                    />
                  </View>
                  {errors.dialingCode && errors.phone ? (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      Mobile Number and Dialing Code is required.
                    </Text>
                  ) : errors.dialingCode ? (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      {errors.dialingCode?.message}
                    </Text>
                  ) : errors.phone ? (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      {errors?.phone?.message}
                    </Text>
                  ) : null}
                </View>
                <View style={styles.buttonContainer}>
                  <Button
                    style={styles.button}
                    dataSet={{ media: ids.button }}
                    loading={loader}
                    mode="outlined"
                    textColor={whiteColor}
                    onPress={handleSubmit(onSubmit)}
                  >
                    <Text>Forgot Password</Text>
                  </Button>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default ForgotPassword;
