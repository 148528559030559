import StyleSheet from "react-native-media-query";
import { primaryColor, whiteColor } from "../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: "#FFF2F2",
    height: "100%",
    padding: 30,
    "@media (max-width: 992px)": {
      padding: 20,
    },
  },
  flexRowHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  pageHeader: {
    marginTop: 10,
    fontSize: 22,
    fontWeight: "800",
    color: primaryColor,
  },
  dateContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
    padding: 2,
    backgroundColor: "white",
    "@media (max-width: 768px)": {
      display: "flex",
    },
  },
  statusCards: {
    backgroundColor: whiteColor,
    padding: 20,
    borderRadius: 10,
    "@media (max-width: 992px)": {
      width: "50%",
    },
  },
  statusIcon: {
    fontWeight: 700,
  },
  contentContainer: {
    justifyContent: "space-between",
    gap: 20,
  },
  cardTitle: {
    color: "#464646",
    fontSize: 18,
    fontWeight: 600,
  },
  flexRow: {
    flexDirection: "row",
    columnGap: 30,
    marginTop: 20,
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardValue: {
    fontSize: 50,
    fontWeight: "bold",
  },
  separator: {
    width: 10, // Adjust the width for spacing as needed
  },
  textInput: {
    fontSize: 16,
    height: 28,
    width: "100%",
    borderWidth: 0,
  },
  boxContainer: {
    backgroundColor: whiteColor,
    padding: 20,
    width: "100%",
    marginBottom: 20,
    borderRadius: 10,
  },
});
