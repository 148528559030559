import React from "react";
import { ids, styles } from "./QrCodeCss";
import { Modal, Text, View } from "react-native";
import { IconButton, Surface } from "react-native-paper";
import { blackColor, subTextColor } from "../../../constant/constantCss";
import QRCode from "react-native-qrcode-svg";
import logo from "../../../../assets/favicon.png";
import { REACT_APP_DEV_FE_URL } from "@env";

const QrCodeModal = ({ visible, handleClose, slug }) => {
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <Surface
          elevation={3}
          style={styles.modalView}
          dataSet={{ media: ids.modalView }}
        >
          {/* header */}
          <View style={styles.headerContainer}>
            <View style={{ flex: 1 }}>
              <Text style={styles.headerText}>QR Code</Text>
              <Text style={{ color: subTextColor, fontSize: 12 }}>
                Scan this QR Code in phone for trip information
              </Text>
            </View>
            <IconButton
              iconColor={blackColor}
              icon={"close"}
              onPress={handleClose}
            />
          </View>
          <View
            style={styles.bodyContainer}
            dataSet={{ media: ids.bodyContainer }}
          >
            <QRCode
              size={200}
              value={`${REACT_APP_DEV_FE_URL}trip-info/${slug}`}
              logo={logo}
              logoSize={30}
              logoBackgroundColor="transparent"
            />
          </View>
        </Surface>
      </View>
    </Modal>
  );
};

export default QrCodeModal;
