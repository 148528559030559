import StyleSheet from "react-native-media-query";
import {
  blackColor,
  subTextColor,
  whiteColor,
} from "../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    width: 500,
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: blackColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    position: "fixed",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 768px)": {
      width: "90%",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    gap: 20,
    marginBottom: 8,
  },
  headerText: {
    fontSize: 22,
    fontWeight: "800",
  },
  // Body
  bodyContainer: {
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: "100%",
    padding: 20,
    "@media (max-width: 992px)": {
      flexDirection: "column",
      gap: 0,
      padding: 10,
    },
  },
  titleText: {
    fontSize: 17,
    color: blackColor,
    width: 130,
    fontWeight: 600,
  },
  subTitleText: {
    fontSize: 15,
    color: subTextColor,
  },
});
