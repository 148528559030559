import StyleSheet from "react-native-media-query";
import {
  blackColor,
  inputBackColor,
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../constant/constantCss";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    height: "85%",
    width: "40%",
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: blackColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 768px)": {
      height: "90%",
      width: "85%",
    },
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: whiteColor,
    fontWeight: "bold",
    textAlign: "center",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  headerText: {
    fontSize: 22,
    fontWeight: "800",
    color: blackColor,
  },
  subText: {
    fontSize: 14,
    fontWeight: "normal",
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "800",
  },
  // Body
  bodyContainer: {
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "row",
    gap: 150,
    height: "90%",
    minWidth: "100%",
    width: "100%",
    "@media (max-width: 992px)": {
      flexDirection: "column",
      gap: 0,
    },
  },
  listContainer: {
    whiteSpace: "nowrap",
    width: "100%",
    maxHeight: "80%",
    overflow: "auto",
    marginTop: 10,
  },
  inlineContainer: {
    width: "100%",
    "@media (min-width: 992px)": {
      display: "inline-block",
      width: "40%",
    },
  },
  inputView: {
    marginTop: 26,
  },
  inputText: {
    fontSize: 16,
  },
  textInput: {
    fontSize: 16,
    height: 38,
    borderRadius: 20,
    borderColor: inputTextColor,
    backgroundColor: inputBackColor,
    paddingLeft: 10,
    paddingRight: 10,
  },
  editTextInput: {
    flex: 1,
    backgroundColor: "white",
    borderWidth: 0,
    padding: 0
  },
  sectionStyles: {
    display: "flex",
    justifyContent: "space-between",
    borderWidth: 1,
    borderBottomColor: "black",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 10,
    paddingLeft: 20,
    marginVertical: 10
  },
  textInputSearch: {
    fontSize: 16,
    height: Platform.OS === "android" ? null : 38,
    borderRadius: 20,
    color: blackColor,
    backgroundColor: whiteColor,
    flexGrow: 1,
    maxHeight: 38,
    maxWidth: 350,
    marginBottom: 20,
    marginTop: 10,
    alignSelf: "flex-start",
    justifyContent:"center"
  },
  editContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  textAreaInput: {
    fontSize: 16,
    height : 120,
    backgroundColor: inputBackColor,
  },
  inputSwitchContainer: {
    marginTop: 10,
    display: "flex",
    gap: 40,
  },
  uploadInput: {
    display: "none",
  },
  rowContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  addContainer:{
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  // Footer
  footerContainer: {
    marginTop: 10,
  },
  inputError: {
    color: primaryColor,
    fontWeight: 600,
    paddingBottom: 0,
  },
  uploadButton: {
    maxWidth: 350,
    color: whiteColor,
  },
  flexRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
