import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  ImageBackground,
  ScrollView,
} from "react-native";
import { TextInput, Button } from "react-native-paper";
import { ids, styles } from "./registerCss.js";
import { useForm, Controller } from "react-hook-form";
import {
  blackColor,
  inputTextColor,
  whiteColor,
  subTextColor,
  primaryColor,
} from "../../constant/constantCss.js";
import logo from "../../../assets/splashLogo.png";
import { useNavigation } from "@react-navigation/native";
import { UserResetPassword } from "../../service/api/user/api.js";
import { showToast } from "../../constant/toastMessage.js";
import { resetState } from "../../store/slices/authSlice.js";
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useKeyboardHeight from "../../hooks/KeyboardHeight.jsx";

const ResetPassword = ({ route }) => {
  let requestParams = route.params.data;

  const { height, width } = Dimensions.get("window");
  const roundness = width > 768 ? 40 : 8;
  const [loader, setLoader] = useState(false);
  const [isVisibleNewPassword, setIsVisibleNewPassword] = useState(false);
  const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] =
    useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({});
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { backgroundImage } = useSelector((state) => state.general);
  const onSubmit = (data) => {
    data.dialing_code = requestParams.dialing_code;
    data.phone_number = requestParams.phone_number;
    setLoader(true);
    UserResetPassword(data)
      .then(async (res) => {
        showToast("success", res?.data?.detail);
        setLoader(false);
        await AsyncStorage.clear();
        dispatch(resetState());
        navigation.navigate("Login");
      })
      .catch((err) => {
        showToast(
          "error",
          err?.response?.data?.message ??
            err?.response?.data?.non_field_errors?.[0] ??
            err?.response?.data?.detail
        );
        setLoader(false);
      });
  };
  const handleSecurityPassword = (key) => {
    if (key == "new_password") {
      setIsVisibleNewPassword((prev) => !prev);
    } else {
      setIsVisibleConfirmPassword((prev) => !prev);
    }
  };
  const keyboardHeight = useKeyboardHeight();
  const handleSkipButton = () => {
    navigation.goBack();
  };
  return (
    <View
      style={{
        flexDirection: "column",
        minHeight: height,
      }}
    >
      <ImageBackground source={backgroundImage} style={styles.imageBackground}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            marginBottom: keyboardHeight,
          }}
        >
          <View style={styles.container} dataSet={{ media: ids.container }}>
            <View style={styles.form} dataSet={{ media: ids.form }}>
              <View style={[styles.flexRow, { justifyContent: "center" }]}>
                <Image
                  style={styles.image}
                  dataSet={{ media: ids.image }}
                  source={logo}
                />
              </View>
              <View
                style={styles.formContainer}
                dataSet={{ media: ids.formContainer }}
              >
                <View style={styles.flexRow}>
                  <Text style={styles.header} dataSet={{ media: ids.header }}>
                    Reset password
                  </Text>
                  <Button
                    mode="text"
                    textColor={primaryColor}
                    onPress={handleSkipButton}
                  >
                    Back
                  </Button>
                </View>

                <Text style={styles.details} dataSet={{ media: ids.details }}>
                  Please provide new and confirm password for reset your
                  password.
                </Text>
                <View style={styles.inputView}>
                  <Text style={styles.text} dataSet={{ media: ids.text }}>
                    New Password
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: "New Password is required",
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/,
                        message:
                          "Password must include at least one uppercase letter, one lowercase letter, and one number",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        right={
                          <TextInput.Icon
                            size={18}
                            icon={!isVisibleNewPassword ? "eye" : "eye-off"}
                            color={subTextColor}
                            onPress={() =>
                              handleSecurityPassword("new_password")
                            }
                          />
                        }
                        style={styles.textInput}
                        onChangeText={onChange}
                        defaultValue={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        secureTextEntry={!isVisibleNewPassword}
                        contentStyle={{ color: blackColor }}
                      />
                    )}
                    name="new_password"
                  />
                  {errors.new_password && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      {errors.new_password.message}
                    </Text>
                  )}
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.text} dataSet={{ media: ids.text }}>
                    Confirm Password
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: "Confirm Password is required",
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/,
                        message:
                          "Password must include at least one uppercase letter, one lowercase letter, and one number",
                      },
                      validate: (value) =>
                        value === getValues("new_password") ||
                        "New and Confirm Password must be matched",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        right={
                          <TextInput.Icon
                            size={18}
                            icon={!isVisibleConfirmPassword ? "eye" : "eye-off"}
                            color={subTextColor}
                            onPress={() =>
                              handleSecurityPassword("confirm_password")
                            }
                          />
                        }
                        style={styles.textInput}
                        onChangeText={onChange}
                        defaultValue={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        secureTextEntry={!isVisibleConfirmPassword}
                        contentStyle={{ color: blackColor }}
                      />
                    )}
                    name="confirm_password"
                  />
                  {errors.confirm_password && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      {errors.confirm_password.message}
                    </Text>
                  )}
                </View>
              </View>
              <View style={styles.buttonContainer}>
                <Button
                  style={styles.button}
                  dataSet={{ media: ids.button }}
                  loading={loader}
                  mode="outlined"
                  textColor={whiteColor}
                  onPress={handleSubmit(onSubmit)}
                >
                  <Text>Reset Password</Text>
                </Button>
              </View>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default ResetPassword;
