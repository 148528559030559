import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const ROLE_ENDPOINT = {
  PUBLIC_ROLE: BASE_URL + "role/public/all",
  GET_ALL_ROLE: BASE_URL + "role/",
  CREATE_ROLE: BASE_URL + "role/",
  EDIT_ROLE: BASE_URL + "role/",
  DELETE_ROLE: BASE_URL + "role/",
};

export const GetPublicRole = async () => {
    let url = ROLE_ENDPOINT.PUBLIC_ROLE;
    return axios.get(url);
};


export const GetAllRole = async (page, searchVal) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    let url = ROLE_ENDPOINT.GET_ALL_ROLE;
    if (page) {
      url += `?page= ${page}`;
    }
    if (searchVal) {
      url += `?search=${searchVal}`;
    }
    return axios.get(url, headers);
  }
};

export const CreateRole = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(ROLE_ENDPOINT.CREATE_ROLE, payload, headers);
  }
};

export const EditRoleDetails = async (roleId, payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.patch(
      `${ROLE_ENDPOINT.EDIT_ROLE} ${roleId}/`,
      payload,
      headers
    );
  }
};

export const DeleteRole = async (roleId) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.delete(`${ROLE_ENDPOINT.DELETE_ROLE} ${roleId}/`, headers);
  }
};
