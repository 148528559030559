import { Dimensions } from "react-native";
import TripItineraryDesktop from "./TripItenaryDesktop.jsx";
import TripItinerary from "./TripItinerary.jsx";

const TripItenaryRedirector = ({ trip_id }) => {
    const { width } = Dimensions.get("screen");
    return (
        <>
            {width < 1000 ? <TripItinerary trip_id={trip_id} /> : <TripItineraryDesktop trip_id={trip_id} />}
        </>
    )

};

export default TripItenaryRedirector;
