import { useRef, useState } from "react";
import { Modal, Text, View, StyleSheet } from "react-native";
import { IconButton, Button } from "react-native-paper";
import {
    styles,
    ids,
} from "../TripDashboard/CreateTripModal/CreateTripModalCss";
import {
    blackColor,
    whiteColor,
    primaryColor,
} from "../../constant/constantCss";
import { DeleteBannerImage } from "../../service/api/trip/api";
import { showToast } from "../../constant/toastMessage";
import { useDispatch, useSelector } from "react-redux";
import { setBannerImageRefreshState } from "../../store/slices/generalSlice";

const DeleteBannerImageModal = ({ visible, handleClose, imageData }) => {
    const dispatch = useDispatch();
    const generalState = useSelector((state) => state.general);
    const [loader, setLoader] = useState(false);
    const handleDelete = () => {
        setLoader(true);
        DeleteBannerImage(imageData.image_id).then(() => {
            handleClose();
            setLoader(false);
            showToast("success", "Image Deleted SuccessFully!");
            dispatch(setBannerImageRefreshState(!generalState.bannerImagesRefreshState));
        })
            .catch(() => {
                setLoader(true);
                showToast("error", "Soemthing went wrong!");
            })
    }
    return (
        <>
            <Modal
                style={styles.modalView}
                animationType="slide"
                transparent={true}
                visible={visible}
                onRequestClose={handleClose}
            >
                <View style={styles.centeredView}>
                    <View
                        style={[styles.modalView, { height: "55%", width: "45%" }]}
                        dataSet={{ media: ids.modalView }}
                    >
                        <View style={styles.headerContainer}>
                            <Text style={styles.headerText}>Delete Banner Image</Text>
                            <IconButton
                                icon={"close"}
                                iconColor={blackColor}
                                onPress={() => { handleClose(); clearAllStates(); }}
                            />
                        </View>
                        <View
                            style={[
                                styles.bodyContainer,
                                {
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    flex: 0,
                                    marginTop: 30,
                                    gap: 20,
                                },
                            ]}
                            dataSet={{ media: ids.bodyContainer }}
                        >
                            <Text style={{ fontWeight: "bold", fontSize: 14 }}>
                                Are you sure you want to delete this banner image?
                            </Text>
                        </View>
                        <View
                            style={[
                                styles.bodyContainer,
                                { justifyContent: "flex-start", marginTop: 40, height: "60%" },
                            ]}
                        >
                            {imageData && imageData.image_url && (
                                <>
                                    <img
                                        src={imageData.image_url}
                                        style={{ height: "100%", width: "100%" }}
                                    />
                                </>
                            )}
                        </View>
                        <View
                            style={[
                                styles.bodyContainer, { height: "auto", marginTop: 20, width: "100%", justifyContent: "center", alignItems: "center" }
                            ]}
                        >
                            <Button
                                mode="contained"
                                style={{ borderRadius: 10 }}
                                buttonColor={primaryColor}
                                textColor={whiteColor}
                                onPress={() => { handleDelete() }}
                                loading={loader}
                            >
                                Yes
                            </Button>
                            <Button
                                mode="outlined"
                                style={{ borderRadius: 10 }}
                                labelStyle={{ fontSize: 16 }}
                                textColor={blackColor}
                                buttonColor={whiteColor}
                                onPress={() => { handleClose() }}
                            >
                                No
                            </Button>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    );
};

export default DeleteBannerImageModal;
