import React, { useState } from "react";
import { View, Text, Modal, ScrollView } from "react-native";
import StyleSheet from "react-native-media-query";
import { Button, IconButton, TextInput } from "react-native-paper";
import {
  blackColor,
  inputBackColor,
  inputTextColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../constant/constantCss";
import { Controller, useForm } from "react-hook-form";
import { UserChangePassword } from "../../service/api/user/api";
import { showToast } from "../../constant/toastMessage";

const ChangePasswordModal = ({ visible, handleClose }) => {
  const [loader, setLoader] = useState(false);
  const [isSecureCurrentPassword, setIsSecureCurrentPassword] = useState(false);
  const [isSecureNewPassword, setIsSecureNewPassword] = useState(false);
  const [isSecureConfirmPassword, setIsSecureConfirmPassword] = useState(false);

  const { ids, styles } = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      width: 500,
      backgroundColor: whiteColor,
      borderRadius: 20,
      padding: 20,
      alignItems: "center",
      shadowColor: blackColor,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      position: "fixed",
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      "@media (max-width: 768px)": {
        width: "90%",
      },
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    uploadInput: {
      display: "none",
    },
    uploadButton: {
      maxWidth: 350,
      color: whiteColor,
    },
    headerText: {
      fontSize: 22,
      fontWeight: "800",
    },
    closeButton: {
      fontSize: 22,
      fontWeight: "800",
    },

    // Body
    bodyContainer: {
      whiteSpace: "nowrap",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minWidth: "100%",
      overflow: "auto",
      "@media (max-width: 992px)": {
        flexDirection: "column",
        gap: 0,
      },
    },
    inputView: {
      marginTop: 26,
    },
    inputText: {
      fontSize: 14,
      fontWeight: "600",
      marginBottom: 4,
    },
    textInput: {
      fontSize: 16,
      height: 38,
      width: "100%",
      borderRadius: 10,
      backgroundColor: inputBackColor,
      color: blackColor,
    },
    inputError: {
      color: "#c72810",
      fontWeight: 600,
      paddingBottom: 0,
    },
  });
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({});
  const onSubmit = (data) => {
    setLoader(true);
    UserChangePassword(data)
      .then((res) => {
        showToast("success", res?.data?.message);
        setLoader(false);
        handleClose();
      })
      .catch((err) => {
        showToast(
          "error",
          err?.response?.data?.message ??
            err?.response?.data?.non_field_errors?.[0] ??
            err?.response?.data?.detail
        );
        setLoader(false);
      });
  };
  const handleSecurityPassword = (type) => {
    if (type === "old_password") {
      setIsSecureCurrentPassword((prev) => !prev);
    } else if (type === "new_password") {
      setIsSecureNewPassword((prev) => !prev);
    } else {
      setIsSecureConfirmPassword((prev) => !prev);
    }
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Change Password</Text>
            <IconButton
              iconColor={blackColor}
              icon={"close"}
              onPress={handleClose}
            />
          </View>
          {/* content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View style={styles.inputView}>
                <Text style={styles.inputText}> Current Password</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Current Password is required",
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/,
                      message:
                        "Password must include at least one uppercase letter, one lowercase letter, and one number",
                    },
                  }}
                  name="old_password"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      onChangeText={onChange}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      secureTextEntry={!isSecureCurrentPassword}
                      contentStyle={{ color: blackColor }}
                      right={
                        <TextInput.Icon
                          size={18}
                          icon={!isSecureCurrentPassword ? "eye" : "eye-off"}
                          color={subTextColor}
                          onPress={() => handleSecurityPassword("old_password")}
                        />
                      }
                    />
                  )}
                />
                {errors.old_password && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.old_password.message}
                  </Text>
                )}
              </View>

              <View style={styles.inputView}>
                <Text style={styles.inputText}> New Password</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "New Password is required",
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/,
                      message:
                        "Password must include at least one uppercase letter, one lowercase letter, and one number",
                    },
                  }}
                  name="new_password"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      onChangeText={onChange}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      secureTextEntry={!isSecureNewPassword}
                      contentStyle={{ color: blackColor }}
                      right={
                        <TextInput.Icon
                          size={18}
                          icon={!isSecureNewPassword ? "eye" : "eye-off"}
                          color={subTextColor}
                          onPress={() => handleSecurityPassword("new_password")}
                        />
                      }
                    />
                  )}
                />
                {errors.new_password && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.new_password.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}> Confirm Password</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Current Password is required",
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/,
                      message:
                        "Password must include at least one uppercase letter, one lowercase letter, and one number",
                    },
                    validate: (value) =>
                      value === getValues("new_password") ||
                      "New and Confirm Password must be matched",
                  }}
                  name="confirm_password"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      onChangeText={onChange}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      secureTextEntry={!isSecureConfirmPassword}
                      contentStyle={{ color: blackColor }}
                      right={
                        <TextInput.Icon
                          size={18}
                          icon={!isSecureConfirmPassword ? "eye" : "eye-off"}
                          color={subTextColor}
                          onPress={() =>
                            handleSecurityPassword("confirm_password")
                          }
                        />
                      }
                    />
                  )}
                />
                {errors.confirm_password && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.confirm_password.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Button
                  mode="contained"
                  buttonColor={primaryColor}
                  textColor={whiteColor}
                  loading={loader}
                  onPress={handleSubmit(onSubmit)}
                >
                  Change password
                </Button>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default ChangePasswordModal;
