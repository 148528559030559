import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const TRIP_ENDPOINT = {
  TRIP_FILTER_URL: BASE_URL + "trip/filter?type=",
  CREATE_TRIP_URL: BASE_URL + "trip/",
  ENROLL_TRIP: BASE_URL + "trip/",
  PARTICIPANTS_LIST: BASE_URL + "trip/participant/",
  UPDATE_TRIP_URL: BASE_URL + "trip/",
  DELETE_TRIP_URL: BASE_URL + "trip/",
  TRIP_CHECK: BASE_URL + "trip/title-check",
  UPLOAD_BANNER_IMAGE: BASE_URL + "trip/banner-image",
  SECTIONS: BASE_URL + "trip/section",
  SELECTED_SECTIONS: BASE_URL + "trip/selected-sections",
  SECTION_TRIPS: BASE_URL + "trip/section-trip",
};

export const GetFilterTripAPI = async (
  type,
  search,
  isPublic,
  minPrice,
  maxPrice,
  month,
  minDuration,
  maxDuration,
  themes,
  age
) => {
  let url = `${TRIP_ENDPOINT.TRIP_FILTER_URL}${type}`;
  if (search) {
    url += `&search=${search}`;
  }
  if (isPublic) {
    url += `&isPublic=${isPublic}`;
  }
  if (minPrice) {
    url += `&minPrice=${minPrice}`;
  }
  if (maxPrice) {
    url += `&maxPrice=${maxPrice}`;
  }
  if (month) {
    url += `&tripMonth=${month}`;
  }
  if (minDuration) {
    url += `&minDuration=${minDuration}`;
  }
  if (maxDuration) {
    url += `&maxDuration=${maxDuration}`;
  }
  if (themes) {
    url += `&themes=${themes}`;
  }
  if (age) {
    url += `&age=${age}`;
  }
  const headers = await getAuthorizationHeader();
  return axios.get(url, headers);
};

export const CreateTripAPI = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    const mergedHeaders = {
      ...headers.headers,
      'Content-Type': 'multipart/form-data',
    };
    return axios.post(TRIP_ENDPOINT.CREATE_TRIP_URL, payload, {headers:mergedHeaders});
  }
};
export const UpdateTripAPI = async (trip_id, payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    const mergedHeaders = {
      ...headers.headers,
      'Content-Type': 'multipart/form-data',
    };
    return axios.patch(
      TRIP_ENDPOINT.UPDATE_TRIP_URL + trip_id + "/",
      payload,
      {headers:mergedHeaders}
    );
  }
};

export const EnrollTrip = async (id, payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(
      `${TRIP_ENDPOINT.ENROLL_TRIP}${id}/enroll/`,
      payload,
      headers
    );
  }
};

export const EnrollTripWithoutLogin = async (id, payload) => {
  return axios.post(
    `${TRIP_ENDPOINT.ENROLL_TRIP}${id}/enroll/public/`,
    payload
  );
};

export const GetTripParticipants = async (id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.get(`${TRIP_ENDPOINT.PARTICIPANTS_LIST}${id}/`, headers);
  }
};

export const DeleteTripAPI = async (id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.delete(`${TRIP_ENDPOINT.DELETE_TRIP_URL}${id}/`, headers);
  }
};

export const TripTitleCheck = async (trip_title) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.get(
      `${TRIP_ENDPOINT.TRIP_CHECK}?title=${trip_title}`,
      headers
    );
  }
};

export const UploadBannerImage = async (image) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(TRIP_ENDPOINT.UPLOAD_BANNER_IMAGE + "/", image, headers);
  }
};

export const GetBannerImages = async () => {
  return axios.get(TRIP_ENDPOINT.UPLOAD_BANNER_IMAGE + "/");
};

export const DeleteBannerImage = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.delete(TRIP_ENDPOINT.UPLOAD_BANNER_IMAGE + "/", {
      data: { image_id: payload },
      headers: headers.headers,
    });
  }
};

export const GetSections = async () => {
  return axios.get(TRIP_ENDPOINT.SECTIONS + "/");
};

export const GetSelectedSections = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.get(
      TRIP_ENDPOINT.SELECTED_SECTIONS + "/?trip_id=" + payload,
      headers
    );
  }
};

export const UpdateSection = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.patch(TRIP_ENDPOINT.SECTIONS + "/", payload, headers);
  }
};

export const DeleteSection = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.delete(TRIP_ENDPOINT.SECTIONS + "/", {
      data: payload,
      headers: headers.headers,
    });
  }
};

export const AddSection = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(TRIP_ENDPOINT.SECTIONS + "/", payload, headers);
  }
};

export const GetSectionTrips = async () => {
  return axios.get(TRIP_ENDPOINT.SECTION_TRIPS + "/");
};

export const CreateSectionTrips = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(TRIP_ENDPOINT.SECTION_TRIPS + "/", payload, headers);
  }
};

export const DeleteSectionTrips = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.delete(TRIP_ENDPOINT.SECTION_TRIPS + "/", {
      data: payload,
      headers: headers.headers,
    });
  }
};
