import StyleSheet from "react-native-media-query";
import { appBarColor, subTextColor } from "../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  // school card

  primaryText: {
    marginTop: 20,
    fontSize: 20,
  },
  secondaryText: {
    color: subTextColor,
    fontSize: 13,
  },
  schoolCard: {
    maxWidth: 280,
    minWidth: 280,
    cursor: "pointer",
    backgroundColor: appBarColor,
    "@media (max-width: 480px)": {
      minWidth: "100%",
      maxWidth: "100%",
    },
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
});
