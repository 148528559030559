import React from 'react'
import { Platform,Dimensions } from 'react-native'
import TripInfo from './TripInfo';
import TripInfoM from './TripInfoM';

const TripInfoRedirector = ({tripDetails, trip_id, setRefreshAPI}) => {
    const { width } = Dimensions.get("screen");
    return (
        <>{
            Platform.OS === "web" && width > 1000 ?
        <TripInfo tripDetails={tripDetails}
            trip_id={trip_id}
            setRefreshAPI={setRefreshAPI}
        /> :
            <TripInfoM tripDetails={tripDetails}
                trip_id={trip_id}
                setRefreshAPI={setRefreshAPI}
            />}
        </>
    )
}

export default TripInfoRedirector