import StyleSheet from "react-native-media-query";
import {
  blackColor,
  inputBackColor,
  whiteColor,
} from "../../constant/constantCss";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    width: 600,
    maxHeight: 700,
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 16,
    alignItems: "center",
    shadowColor: blackColor,
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 768px)": {
      width: "90%",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  uploadInput: {
    display: "none",
  },
  uploadButton: {
    maxWidth: 350,
    color: whiteColor,
  },
  headerText: {
    fontSize: 22,
    fontWeight: "800",
    width: "80%"
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "800",
  },

  // Body
  bodyContainer: {
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: "100%",
    overflow: "auto",
    "@media (max-width: 992px)": {
      flexDirection: "column",
      gap: 0,
    },
  },
  inputView: {
    marginTop: 16,
  },
  inputText: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 4,
  },
  textInput: {
    fontSize: 16,
    height: Platform.OS === "android" ? null : 40,
    width: "100%",
    borderRadius: 10,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  inputError: {
    color: "#c72810",
    fontWeight: 600,
    paddingBottom: 0,
  },
  uploadContainer: {
    backgroundColor: "#F0F0F0",
    height: 80,
    borderWidth: 1,
    borderColor: blackColor,
    borderStyle: "dashed",
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },
  link_text: {
    fontSize: 18,
    color: "blue",
    cursor: "pointer",
    textDecorationLine: "underline",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
});
