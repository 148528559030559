import React, { useState, useRef, useEffect } from "react";
import {
  Text,
  Button,
  TextInput,
  RadioButton,
  IconButton,
} from "react-native-paper";
import { ids, styles } from "./EditInfoCss";
import { Controller, useForm } from "react-hook-form";
import {
  Dimensions,
  Platform,
  Pressable,
  ScrollView,
  View,
} from "react-native";
import { EditUserApi } from "../../../service/api/user/editUserApi";
import ImagePickerComponent from "../../../components/ImagePicker/ImagePicker";
import AntDesign from "react-native-vector-icons/AntDesign";
import {
  blackColor,
  inputTextColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../constant/constantCss";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { showToast } from "../../../constant/toastMessage";
const EditInfomation = ({
  visible,
  setVisible,
  first_name,
  enrollment_no,
  school_name,
  phone_number,
  gender,
  date_of_birth,
  profile_url,
  role,
}) => {
  const fileInputRef = useRef(null);
  const [checked, setChecked] = useState(gender);
  const [imageUrl, setImageUrl] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: first_name,
      enrollment_no: enrollment_no,
      school_name: school_name,
      phone_number: phone_number,
      gender: gender,
      date_of_birth: date_of_birth,
    },
  });
  const handleUploadButtonClick = () => {
    // Trigger a click event on the file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const extractFilename = (url) => {
    if (url) {
      const parts = url?.split("/");
      setImageUrl(parts[parts.length - 1]);
    }
  };

  const onSubmit = (data) => {
    if (dateOfBirth) data.date_of_birth = dateOfBirth;
    EditUserApi(data)
      .then((res) => {
        setVisible(false);
        showToast("success", "Information Successfully Edited");
      })
      .catch((error) => {
        showToast("error", error.res.data.error[0]);
      });
  };
  const Input = [
    {
      value: first_name,
      input: "first_name",
      label: "Full Name",
      readOnly: false,
      display: true,
    },
    {
      value: enrollment_no,
      input: "enrollment_no",
      label: "Enrollment No",
      readOnly: false,
      display: role === "is_parent",
    },
    {
      value: school_name,
      input: "school_name",
      label: "School Name",
      readOnly: true,
      display: role === "is_parent",
    },
    {
      value: phone_number,
      input: "phone_number",
      label: "Phone Number",
      readOnly: true,
      display: true,
    },
    {
      value: gender,
      input: "gender",
      label: "Gender",
      readOnly: false,
      display: true,
    },
    {
      value: date_of_birth,
      input: "date_of_birth",
      label: "Date of Birth",
      readOnly: false,
      display: true,
    },
  ];
  const genderValue = [
    {
      value: "Male",
      text: "Male",
    },
    {
      value: "Female",
      text: "Female",
    },
  ];
  const getInputString = () => {
    if (date_of_birth) {
      return date_of_birth;
    } else {
      return "yyyy-mm-dd";
    }
  };

  useEffect(() => {
    extractFilename(profile_url);
  }, [imageUrl]);

  return (
    <View style={styles.centeredView}>
      <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
        <View style={styles.headerContainer}>
          <Text
            style={{
              fontWeight: 700,
              fontSize: 20,
              marginBottom: 20,
              color: blackColor,
            }}
          >
            Edit Profile
          </Text>
          <Pressable onPress={() => setVisible(false)}>
            <AntDesign style={styles.closeIcon} name="close" size={20} />
          </Pressable>
        </View>
        <View>
          <ScrollView style={{ width: "100%" }}>
            <View style={[styles.container, { marginBottom: 40 }]}>
              <View style={styles.gridInput} dataSet={{ media: ids.gridInput }}>
                {Input.map((item, index) => {
                  const fieldName = item.input;
                  const isFieldError =
                    errors[fieldName] && errors[fieldName].type === "required";
                  return (
                    item.display && (
                      <View
                        style={styles.input}
                        key={index}
                        dataSet={{ media: ids.input }}
                      >
                        <Text style={styles.inputLabel}>{item.label}</Text>
                        <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <>
                              {fieldName === "date_of_birth" ? (
                                <>
                                  {Platform.OS === "web" ? (
                                    <View
                                      style={[
                                        styles.inputText,
                                        { width: "100%", padding: 0 },
                                      ]}
                                    >
                                      <input
                                        type="date"
                                        style={styles.inputText}
                                        onChange={onChange}
                                        value={value}
                                      />
                                    </View>
                                  ) : (
                                    <DatePicker
                                      style={[
                                        styles.inputText,
                                        { width: "100%",color:blackColor
                                         },
                                      ]}
                                      inputString={getInputString()}
                                      mode="date"
                                      setDateTime={(date) => {
                                        setDateOfBirth(date);
                                        setValue("date_of_birth", date);
                                      }}
                                    />
                                  )}
                                </>
                              ) : fieldName === "gender" ? (
                                <>
                                  <View
                                    style={[
                                      styles.genderInput,
                                      { width: "100%" },
                                    ]}
                                    dataSet={{ media: ids.genderInput }}
                                  >
                                    {genderValue.map((item, index) => (
                                      <View key={index}>
                                        <RadioButton.Android
                                          style={styles.genderInput}
                                          value={item.value}
                                          status={
                                            checked === item.value
                                              ? "checked"
                                              : "unchecked"
                                          }
                                          onPress={() => {
                                            setChecked(item.value),
                                              setValue("gender", item.value);
                                          }}
                                        />
                                        <Text style={styles.gender}>
                                          {item.text}
                                        </Text>
                                      </View>
                                    ))}
                                  </View>
                                </>
                              ) : (
                                <>
                                  <TextInput
                                    style={[
                                      styles.textInput,
                                      { width: "100%" },
                                    ]}
                                    onChangeText={onChange}
                                    readOnly={item.readOnly}
                                    value={value}
                                    mode="outlined"
                                    outlineColor={inputTextColor}
                                    activeOutlineColor={inputTextColor}
                                    contentStyle={{ color: "black" }}
                                  />
                                </>
                              )}
                            </>
                          )}
                          name={item.input}
                        />
                        {isFieldError && (
                          <>
                            <Text style={styles.inputError}>
                              This is required
                            </Text>
                          </>
                        )}
                      </View>
                    )
                  );
                })}
              </View>
              <Button
                style={styles.button}
                dataSet={{ media: ids.button }}
                mode="outlined"
                backgroundColor={primaryColor}
                textColor={whiteColor}
                onPress={handleSubmit(onSubmit)}
              >
                Save Changes
              </Button>
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
};

export default EditInfomation;
