import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const NOTIFICATION_ENDPOINT = {
  NOTIFICATION_BASE_URL: BASE_URL + "notification/",
};

export const CreateNotification = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(
      NOTIFICATION_ENDPOINT.NOTIFICATION_BASE_URL,
      payload,
      headers
    );
  }
};

export const GetAllNotifications = async (search, todayDate) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    let url = NOTIFICATION_ENDPOINT.NOTIFICATION_BASE_URL;
    if (search) {
      url += `?search=${search}`;
    }
    if (todayDate) {
      url += `?scheduled_at=${todayDate}`;
    }
    return axios.get(url, headers);
  }
};

export const DeleteNotification = async (id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.delete(
      `${NOTIFICATION_ENDPOINT.NOTIFICATION_BASE_URL}${id}/`,
      headers
    );
  }
};
