import React, { useEffect, useState } from "react";
import { View, Text, Platform, ScrollView, Modal } from "react-native";
import { ids, styles } from "./modalCss";
import { Button, IconButton } from "react-native-paper";
import {
  blackColor,
  primaryColor,
  whiteColor,
} from "../../../../../constant/constantCss";
import { DeleteTripAPI } from "../../../../../service/api/trip/api";
import { showToast } from "../../../../../constant/toastMessage";
import { useNavigation } from "@react-navigation/native";

const DeleteTripModal = ({ visible, handleClose, trip_id }) => {
  const navigation = useNavigation();
  const handleCancel = () => {
    handleClose();
  };
  const handleTripDelete = () => {
    DeleteTripAPI(trip_id)
      .then((res) => {
        showToast("success", res?.data?.message);
        navigation.navigate("dashboard", { refresh: "True" });
        handleClose();
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View
          style={[styles.modalView, { maxHeight: 180 }]}
          dataSet={{ media: ids.modalView }}
        >
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText} dataSet={{ media: ids.headerText }}>
              Delete Trip ??
            </Text>
            <IconButton
              icon={"close"}
              iconColor={blackColor}
              onPress={handleClose}
            />
          </View>
          <View
            style={styles.bodyContainer}
            dataSet={{ media: ids.bodyContainer }}
          >
            <Text style={styles.inputText}>
              Are you sure you want to delete this Trip?
            </Text>
            <View style={[styles.flexRow, { marginTop: 20 }]}>
              <Button
                buttonColor={whiteColor}
                textColor={primaryColor}
                onPress={handleCancel}
              >
                Close
              </Button>
              <Button
                buttonColor={primaryColor}
                textColor={whiteColor}
                style={{ borderRadius: 10 }}
                onPress={handleTripDelete}
              >
                Yes
              </Button>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default DeleteTripModal;
