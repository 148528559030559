import StyleSheet from "react-native-media-query";
import {
  blackColor,
  inputBackColor,
  inputTextColor,
  primaryColor,
  whiteColor,
  lightBlueColor,
} from "../../constant/constantCss.js";

export const { ids, styles } = StyleSheet.create({
  modal: {
    height: "100%",
    margin: "auto",
  },
  profileContainer: {
    flexDirection: "row",
    marginBottom: 50,
  },
  logoContainer: {
    flexDirection: "row",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  logoView: {
    flex: 0.2,
  },
  image: {
    resizeMode: "contain",
    width: 120,
    height: 70,
    margin: "auto",
  },
  sideBar: {
    flex: 0.2,
    marginHorizontal: 10,
    width: 200,
    height: "100%",
    "@media (max-width: 992px)": {
      display: "none",
    },
  },
  linkContainer: {
    flexDirection: "row",
    marginVertical: 10,
  },
  linkIcon: {
    marginRight: 10,
  },
  sideLinks: {
    fontWeight: 600,
  },
  logoutButton: {
    margin: 0,
    fontWeight: 600,
  },

  userProfile: {
    flex: 1,
  },
  loginInfo: {
    fontWeight: 500,
    fontSize: 18,
    color: "grey",
  },
  userForm: {
    marginHorizontal: 20,
    marginTop: 60,
    gap:10
  },
  item: {
    paddingTop: 20,
  },
  title: {
    fontWeight: 500,
    fontSize: 15,
  },
  formBackground: {
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    height: 150,
    marginBottom: 40,
  },
  profilePictureContainer: {
    position: "absolute",
    top: 85,
    left: 45,
    padding: 10,
    marginBottom: 30,
    borderRadius: 15,
    flexDirection: "column",
    "@media (max-width: 768px)": {
      position: "relative",
      left: 0,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  lastLoginFlex: {
    flexDirection: "row",
    columnGap: 10,
    flexGrow: 0,
    "@media (max-width: 768px)": {
      flexDirection: "column",
      rowGap: 20,
    },
  },
  imageContainer: {
    backgroundColor: whiteColor,
    borderRadius: 15,
    "@media (max-width: 768px)": {
      height: 150,
      width: 150,
    },
  },
  profilePicture: {
    height: 150,
    width: 135,
    borderRadius: 10,
  },
  iconContainer: {
    position: "absolute",
    top: 120,
    right: -16,
    backgroundColor: whiteColor,
    borderRadius: 6,
  },
  profileIcon: {
    alignItems: "flex-end",
  },
  belowProfile: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    paddingBottom: 30,
    paddingTop: 10,
    "@media (max-width: 480px)": {
      flexDirection: "column",
      paddingBottom: 5,
    },
    "@media (max-width: 768px)": {
      paddingTop: 40,
    },
  },

  formHeader: {
    fontWeight: 800,
    fontSize:20,
    marginVertical:13,
    marginTop: 45,
    color: blackColor,
  },
  superAdminView: {
    backgroundColor: lightBlueColor,
    borderRadius: 9,
    "@media (max-width: 480px)": {
      backgroundColor: whiteColor,
    },
  },
  superAdminInnerView: {
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    "@media (min-width: 480px)": {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
  },
  editContainer: {
    "@media (max-width: 576px)": {
      flexDirection:"column",
    },
  },
  editIcon: {
    paddingRight: 10,
    "@media (max-width: 768px)": {
      paddingRight: 20,
    },
    "@media (max-width: 480px)": {
      fontWeight: 800,
    },
  },
  editText: {
    color: whiteColor,
    "@media (max-width: 480px)": {
      color: blackColor,
    },
  },
  inputIcon: {
    flex: 0.4,
    color: blackColor,
    "@media (max-width: 1200px)": {
      flex: 0.4,
    },
    "@media (max-width: 480px)": {
      flex: 1.1,
    },
  },
  inputText: {
    flex: 15,
    fontWeight: "bold",
    color: blackColor,
    "@media (max-width: 1200px)": {
      fontSize: 14,
      flex: 2.5,
    },
    "@media (max-width: 480px)": {
      fontSize: 14,
      flex: 5.5,
    },
  },
  textInput: {
    flex: 6,
    borderWidth: 1,
    borderColor: inputTextColor,
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    "@media (max-width: 1200px)": {
      fontSize: 14,
      flex: 8,
    },
    "@media (max-width: 480px)": {
      flex: 12,
      height: 12,
      fontSize: 14,
    },
  },
  utilityIcon: {
    flex: 0.2,
    "@media (max-width: 1200px)": {
      flex: 0.09,
    },
    "@media (max-width: 480px)": {
      flex: 0.4,
    },
  },
  utilities: {
    flex: 10,
    fontWeight: "bold",
    color: blackColor,
    "@media (max-width: 1200px)": {
      flex: 8,
    },
    "@media (max-width: 480px)": {
      fontSize: 14,
      flex: 6,
    },
  },
  formInput: {
    borderColor: inputTextColor,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "center",
    gap:10,
    alignItems: "center",
    width:"100%"
    
  },
  img: {
    display: "none",
  },
});
