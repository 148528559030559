import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const SCHOOL_ENDPOINT = {
    GET_ALL_SCHOOL : BASE_URL+"school/",
    SEARCH_SCHOOL : BASE_URL+'school/search?name=',
    CREATE_SCHOOL : BASE_URL+"school/",
    PUBLIC_SCHOOLS : BASE_URL+"school/public/all",
    CREATE_USER_LIST : BASE_URL+"school/upload/user_list/"
}

export const GetAllSchool = async () => {
    let headers = await getAuthorizationHeader(); 
    if (headers){
        return axios.get(SCHOOL_ENDPOINT.GET_ALL_SCHOOL,
            headers
        )
    }
}

export const SearchSchoolDetails = async(name) =>{
    let headers = await getAuthorizationHeader(); 
    if(headers){
        return axios.get(SCHOOL_ENDPOINT.SEARCH_SCHOOL+name,
            headers
        )
    }
}

export const CreateSchool = async(payload) => {
    let headers = await getAuthorizationHeader(); 
    if(headers){
        const mergedHeaders = {
            ...headers.headers,
            'Content-Type': 'multipart/form-data',
          };
        return axios.post(SCHOOL_ENDPOINT.CREATE_SCHOOL,payload,
            {headers:mergedHeaders}
        )
    }
}

export const GetSchoolsPublic = async() => {
        return axios.get(SCHOOL_ENDPOINT.PUBLIC_SCHOOLS,
        )
}

export const GetPerticularSchool = async(id)=>{
    let headers = await getAuthorizationHeader(); 
    if(headers){
        return axios.get(`${SCHOOL_ENDPOINT.GET_ALL_SCHOOL}${id}/`,
            headers
        )
    }
}

export const CreateUserList = async(payload) => {
    let headers = await getAuthorizationHeader(); 
    if(headers){
        return axios.post(SCHOOL_ENDPOINT.CREATE_USER_LIST,payload,
            headers
        )
    }
}