import React from "react";
import {
  blackColor,
  inputTextColor,
  whiteColor,
} from "../../../constant/constantCss";
import { StyleSheet } from "react-native";
import { TextInput } from "react-native-paper";
import { GetUserPaymentHistory } from "../../../service/api/payment/api";

const SearchPayment = ({ setPaymentList }) => {
  const styles = StyleSheet.create({
    textInput: {
      fontSize: 16,
      height: 38,
      flex: 1,
      borderRadius: 20,
      color: blackColor,
      backgroundColor: whiteColor,
      maxWidth: 350,
      marginBottom: 20,
      marginTop: 20,
    },
  });
  const handlePaymentSearch = (searchVal) => {
    GetUserPaymentHistory({ search: searchVal })
      .then((res) => {
        setPaymentList(res.data.results);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  return (
    <TextInput
      style={styles.textInput}
      mode="outlined"
      outlineColor={blackColor}
      activeOutlineColor={inputTextColor}
      placeholder="search payments"
      onChangeText={(text) => handlePaymentSearch(text)}
    />
  );
};

export default SearchPayment;
