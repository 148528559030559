import React, { useState } from "react";
import { View, Text, Modal, ScrollView } from "react-native";
import StyleSheet from "react-native-media-query";
import { Button, IconButton, TouchableRipple } from "react-native-paper";
import {
  ErrorBoldColor,
  blackColor,
  inputBackColor,
  primaryColor,
  sidebarColor,
  whiteColor,
} from "../../constant/constantCss";
import { DeleteUserAccount } from "../../service/api/user/api";
import { showToast } from "../../constant/toastMessage";
import { resetState } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";

const DeleteAccountModal = ({ visible, handleClose }) => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { ids, styles } = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      width: 500,
      backgroundColor: whiteColor,
      borderRadius: 20,
      padding: 20,
      alignItems: "center",
      shadowColor: blackColor,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      position: "fixed",
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      "@media (max-width: 768px)": {
        width: "90%",
      },
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    uploadInput: {
      display: "none",
    },
    uploadButton: {
      maxWidth: 350,
      color: whiteColor,
    },
    headerText: {
      fontSize: 22,
      fontWeight: "800",
    },
    closeButton: {
      fontSize: 22,
      fontWeight: "800",
    },

    // Body
    bodyContainer: {
      whiteSpace: "nowrap",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minWidth: "100%",
      overflow: "auto",
      gap: 10,
      "@media (max-width: 992px)": {
        flexDirection: "column",
      },
    },
    inputView: {
      marginTop: 26,
    },
    inputText: {
      fontSize: 16,
      marginBottom: 4,
    },
    textInput: {
      fontSize: 16,
      height: 38,
      width: "100%",
      borderRadius: 10,
      backgroundColor: inputBackColor,
      color: blackColor,
    },
    inputError: {
      color: "#c72810",
      fontSize: 16,
    },
  });
  const onSubmitDelete = (str) => {
    if (str === "yes") {
      setLoader(true);
      DeleteUserAccount()
        .then(async (res) => {
          showToast("success", res?.data?.message);
          handleClose();
          await AsyncStorage.clear();
          dispatch(resetState({}));
          navigation.navigate("Login");
          setLoader(false);
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.detail);
          setLoader(false);
        });
    } else {
      handleClose();
    }
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          <View
            style={[styles.headerContainer, { justifyContent: "flex-end" }]}
          >
            <IconButton
              iconColor={blackColor}
              icon={"close"}
              onPress={handleClose}
            />
          </View>
          {/* content */}
          <ScrollView
            style={{
              width: "100%",
            }}
          >
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <IconButton
                iconColor={ErrorBoldColor}
                style={{ backgroundColor: sidebarColor }}
                icon={"delete"}
                size={40}
              />
              <Text style={styles.headerText}>Delete Account ??</Text>

              <View style={{ marginTop: 10 }}>
                <Text style={styles.inputError}>
                  Notes :{" "}
                  <Text style={{ color: blackColor }}>
                    The account deletion process is expected to be completed
                    within 15-20 days.{"\n\n"}Should you wish to expedite the
                    account deletion immediately, please contact us directly at{" "}
                    <TouchableRipple
                      onPress={() => {
                        Linking.openURL(
                          "https://mail.google.com/mail/u/0/#inbox"
                        );
                      }}
                    >
                      <Text
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecorationLine: "underline",
                        }}
                      >
                        contact@maroonmigrates.com
                      </Text>
                    </TouchableRipple>
                  </Text>
                </Text>
              </View>

              <Button
                mode="contained"
                buttonColor={primaryColor}
                textColor={whiteColor}
                loading={loader}
                onPress={() => onSubmitDelete("yes")}
                style={{ borderRadius: 10, width: "100%" }}
              >
                Delete Account
              </Button>
              <Button
                mode="contained"
                buttonColor={whiteColor}
                textColor={primaryColor}
                style={{ borderRadius: 10, width: "100%" }}
                onPress={() => onSubmitDelete("no")}
              >
                Cancel
              </Button>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default DeleteAccountModal;
