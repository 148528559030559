import React from "react";
import { Button, Surface } from "react-native-paper";
import logo from "../../../../assets/splashLogo.png";
import phonelogo from "../../../../assets/appIcon/logo512.png";
import {
  View,
  Image,
  TouchableOpacity,
  Platform,
  Dimensions,
} from "react-native";
import { primaryColor, whiteColor } from "../../../constant/constantCss";
import { ids, styles } from "../landingPageCss";
import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { ActiveItem } from "../../../store/slices/generalSlice";

const Navbar = ({
  scrollViewRef,
  hideComponents
}) => {
  const navigation = useNavigation();
  const onScrollTop = () => {
    if (Platform.OS === "web") {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTo({ y: 0, animated: true });
      }
    }
  };
  const onScrollCareers = () => {
    if (Platform.OS === "web") {
      const scrollHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      const scrollPosition = scrollHeight - windowHeight - 400;
      window.scrollTo({
        top: scrollPosition,
        left: 0,
        behavior: "smooth",
      });
    } else {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }
    }
  };
  const onScrollBottom = () => {
    if (Platform.OS === "web") {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    } else {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }
    }
  };
  const redirectAboutUs = () => {
    navigation.navigate("AboutUs")
  }
  const { width } = Dimensions.get("window");
  const dispatch = useDispatch();
  return (
    <Surface
      style={styles.navBarContainer}
      dataSet={{ media: ids.navBarContainer }}
      elevation={1}
    >
      <View style={styles.flexRow} dataSet={{ media: ids.flexRow }}>
        <TouchableOpacity
          elevation={1}
          // add condition to upon login status navigate 
          onPress={() => {

            navigation.navigate("dashboard");
            dispatch(ActiveItem("dashboard"));
          }}
        >
          {Platform.OS === "web" ? 
          <Image
          style={styles.image}
          source={logo}
          dataSet={{ media: ids.image }}
        />
          : 
          <Image
          style={styles.image}
          source={phonelogo}
          dataSet={{ media: ids.image }}
          />
          }
          
        </TouchableOpacity>
        <View style={styles.flexRow} dataSet={{ media: ids.flexRow }}>
          <Button
            mode="text"
            textColor={primaryColor}
            onPress={hideComponents? redirectAboutUs: onScrollTop}
            labelStyle={{ fontSize: 16 }}
          >
            About Us
          </Button>
          {!hideComponents &&
            <>
              {width >= 576 && (
                <Button
                  mode="text"
                  textColor={primaryColor}
                  onPress={onScrollCareers}
                  labelStyle={{ fontSize: 16 }}
                >
                  Careers
                </Button>
              )}
              <Button
                mode="text"
                textColor={primaryColor}
                onPress={onScrollBottom}
                labelStyle={{ fontSize: 16 }}
              >
                Contact Us
              </Button>
              {width >= 768 && (
                <Button
                  mode="contained"
                  buttonColor={primaryColor}
                  textColor={whiteColor}
                  onPress={() => {
                    navigation.navigate("personalized");
                  }}
                  labelStyle={{ fontSize: 18 }}
                >
                  Want a Personalized Trip ?
                </Button>
              )}
            </>}
        </View>
      </View>
    </Surface>
  );
};

export default Navbar;
