const config = {
    screens: {
      Register: {
        path: "/register",
      },
      Login: {
        path: "/login",
      },
      StudentRegister: {
        path: "/student-register",
      },
      VerifyOtp: {
        path: "/verify-otp",
      },
      Verification: {
        path: "/verify",
      },
      dashboard: {
        path: "/dashboard",
      },
      Trip: {
        path: "/trips",
      },
      profile: {
        path: "/profile",
      },
      ShowTripInfo: {
        path: "/trip-info/:data",
      },
      Reset_Password: {
        path: "/reset-password",
      },
      Company_Review: {
        path: "/company-review",
      },
      Notification: {
        path: "/notifications"
      },
      PrivacyPolicy: {
        path: "/privacy-policy",
      },
      TermsConditions: {
        path: "/terms-and-condition",
      },
      PricingPolicy : {
        path: "/pricing-policy",
      },
      CancellationAndReturnPolicy : {
        path: "/cancellation-and-return-policy"
      },
      BussinessLaw: {
        path: "/business-description-and-use-case"
      },
      AboutUs: {
        path: "/about-us"
      },
      Manage_school_detail: {
        path: "/manage-school-details"
      },
      Role_Management: {
        path: "/role-management"
      },
      All_User: {
        path: "/all-user"
      },
      Payment_History: {
        path: "/payment-history"
      },
      analytics: {
        path: "/analytics"
      },
      Analytics_Trip: {
        path: "/analytics-trip"
      },
      personalized: {
        path: "/personalized"
      }
    },
  };
  
  const linkingUser = {
    prefixes: ["http://localhost:19006"],
    config,
  };
  
  export default linkingUser;
  