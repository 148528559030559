import React, { useState } from "react";
import { View, Text, Image, Platform, Clipboard, Share } from "react-native";
import { ids, styles } from "./TripCardCss.js";
import { Button, IconButton } from "react-native-paper";
import tripCardImg from "../../../../assets/DefaultImages/defaultTrip.jpeg";
import moment from "moment";
import Feather from "react-native-vector-icons/Feather";
import {
  blackColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../constant/constantCss.js";
import { useDispatch, useSelector } from "react-redux";
import { UpdateTripAPI } from "../../../service/api/trip/api.js";
import { showToast } from "../../../constant/toastMessage.js";
import { useNavigation } from "@react-navigation/native";
import * as DocumentPicker from "expo-document-picker";
import { PERMISSION } from "../../../constant/getAllPermission.js";
import { REACT_APP_DEV_FE_URL } from "@env";
import QrCodeModal from "../QrCodeModal/QrCodeModal.jsx";
import BookNowModal from "../Book Now/BookNowModal.jsx";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { setFromBackButton } from "../../../store/slices/generalSlice.js";

const TripCard = ({ tripDetails, setRefreshAPI }) => {
  const [isVisibleBookNowModal, setIsVisibleBookNowModal] = useState(false);
  const [isFeaturedTrip, setIsFeaturedTrip] = useState(
    tripDetails?.is_feature_trip
  );
  const { permission } = useSelector((state) => state.auth);
  const [editImageloader, setEditImageLoader] = useState(false);
  const [isVisibleQrCode, setIsVisibleQrCode] = useState(false);
  const navigator = useNavigation();
  const handleCopyText = async () => {
    if (Platform.OS === "web") {
      const copyText = `${REACT_APP_DEV_FE_URL}trip-info/${tripDetails?.slug}`;
      Clipboard.setString(copyText);
      showToast("success", "Trip link copied!");
    } else {
      try {
        const result = await Share.share({
          title: "Maroon Migrates",
          message:
            "Please Click on this link to open the trip description : " +
            `${REACT_APP_DEV_FE_URL}trip-info/${tripDetails?.slug}`,
          url: `${REACT_APP_DEV_FE_URL}trip-info/${tripDetails?.slug}`,
        });
      } catch (error) {
        alert(error.message);
      }
    }
  };
  const dispatch = useDispatch();

  const handleEnrollTrip = () => {
    setIsVisibleBookNowModal(true);
  };
  const handleFeaturedTrip = () => {
    const payload = {
      is_feature_trip: !isFeaturedTrip,
    };
    UpdateTripAPI(tripDetails?.id, payload)
      .then((res) => {
        setIsFeaturedTrip((prev) => !prev);
        if (res?.data?.data?.is_feature_trip) {
          showToast("success", "Marked as Featured Trip");
        } else {
          showToast("error", "Marked as Normal Trip");
        }
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  const handleEditTripImage = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: ["image/png", "image/webp", "image/jpg", "image/jpeg"],
      });
      let formData = new FormData();
      if (!result?.canceled) {
        setEditImageLoader(true);
        if (Platform.OS === "web") {
          const img = new window.Image();
          img.src = URL.createObjectURL(result?.assets[0]?.file);
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            if (
              aspectRatio >= 1.6 &&
              aspectRatio <= 2 &&
              img.width >= 700 &&
              img.height >= 400 &&
              img.width <= 4000 &&
              img.height <= 2200
            ) {
              formData.append("image_url", result?.assets[0]?.file);
              UpdateTripAPI(tripDetails?.id, formData)
                .then((res) => {
                  showToast("success", res?.data?.message);
                  setEditImageLoader(false);
                  setRefreshAPI((prev) => !prev);
                })
                .catch((err) => {
                  showToast("error", err?.response?.data?.detail);
                  setEditImageLoader(false);
                });
            } else {
              showToast(
                "error",
                "Image does not meet the required aspect ratio and size."
              );
              setEditImageLoader(false);
            }
            URL.revokeObjectURL(img.src);
          };
        } else {
          // random name generate
          const currentDate = moment();
          const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
          const imageName = `location_image_${formattedDate}.jpg`;

          const fileObj = {
            uri: result?.assets?.[0]?.uri,
            type: "image/jpg",
            name: imageName,
          };
          formData.append("image_url", fileObj);
        }
      }
    } catch (error) {
      console.error("Error while picking an image:", error);
    }
  };
  return (
    <View style={styles.CardContainer}>
      <View style={styles.flexContainer} dataSet={{ media: ids.flexContainer }}>
        <View style={{ flex: 5, position: "relative" }}>
          <Image
            resizeMode="cover"
            style={styles.cardImage}
            dataSet={{ media: ids.cardImage }}
            source={
              tripDetails?.image_url
                ? { uri: tripDetails?.image_url }
                : tripCardImg
            }
          />
          {Platform.OS !== "web" && (
            <IconButton
              icon={"arrow-left"}
              iconColor={primaryColor}
              style={{ position: "absolute" }}
              onPress={() => {
                navigator.goBack();
                dispatch(setFromBackButton(true));
              }}
            />
          )}
          {tripDetails.badge_text && (
            <View
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: 200,
                height: 350,
                overflow: "hidden",
                zIndex: 10,
              }}
            >
              <View
                style={{
                  position: "absolute",
                  top: 27,
                  left: -28,
                  transform: [{ rotate: "-45deg" }],
                  width: 170,
                  backgroundColor: "#b01d32",
                  paddingVertical: 8,
                  marginLeft: "-5%",
                  shadowColor: "black",
                  shadowOffset: { width: -2, height: 2 },
                  shadowOpacity: 0.5,
                  shadowRadius: 3,
                }}
              >
                <Text
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 14,
                    textAlign: "center",
                  }}
                >
                  {tripDetails.badge_text}
                </Text>
              </View>
            </View>
          )}
          {permission && permission.includes(PERMISSION.CAN_EDIT_TRIP) && (
            <Button
              icon={"pencil"}
              loading={editImageloader}
              size={20}
              buttonColor={primaryColor}
              textColor={whiteColor}
              style={{
                position: "absolute",
                bottom: 16,
                left: "42%",
                maxHeight: 100,
              }}
              onPress={handleEditTripImage}
            >
              Edit
            </Button>
          )}
        </View>
        <View
          style={styles.infoContainer}
          dataSet={{ media: ids.infoContainer }}
        >
          <View style={styles.cardleftFooter}>
            <View style={styles.textFlex}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: 480,
                }}
              >
                {/* left container */}
                <View style={{ flex: 1 }}>
                  <Text
                    style={styles.cardPrimaryText}
                    dataSet={{ media: ids.cardPrimaryText }}
                  >
                    {tripDetails?.title ?? "-"}
                  </Text>

                  <Text style={styles.cardSecondaryText}>
                    {tripDetails?.discount && (
                      <Text
                        style={{
                          color: subTextColor,
                          textDecorationLine: "line-through",
                          marginRight: "2%",
                        }}
                      >
                        &#8377;{`${tripDetails?.amount}`}
                      </Text>
                    )}
                    &#8377;{" "}
                    {tripDetails?.discount
                      ? `${
                          parseFloat(tripDetails?.amount) -
                          (
                            parseFloat(tripDetails?.amount) *
                            (parseFloat(tripDetails?.discount) / 100)
                          ).toFixed(2)
                        }/- `
                      : `${parseFloat(tripDetails?.amount)}/- `}
                    <Text
                      style={{
                        fontSize: 12,
                        color: subTextColor,
                      }}
                    >
                      Per Person
                    </Text>
                  </Text>
                </View>
                {permission &&
                  permission.includes(PERMISSION.CAN_MAKE_FEATURE_TRIP) && (
                    <MaterialCommunityIcons
                      name={isFeaturedTrip ? "heart" : "heart-outline"}
                      size={30}
                      color={isFeaturedTrip ? primaryColor : subTextColor}
                      style={{ backgroundColor: whiteColor }}
                      onPress={handleFeaturedTrip}
                    />
                  )}
                {/* right container */}
                {
                  <View style={{ flexDirection: "column" }}>
                    <IconButton
                      icon={"share-variant"}
                      size={20}
                      iconColor={whiteColor}
                      style={{
                        backgroundColor: primaryColor,
                      }}
                      onPress={handleCopyText}
                    />
                    {permission &&
                      permission.includes(PERMISSION.CAN_MAKE_FEATURE_TRIP) && (
                        <IconButton
                          size={20}
                          icon={"qrcode"}
                          iconColor={whiteColor}
                          style={{ backgroundColor: primaryColor }}
                          onPress={() => setIsVisibleQrCode(true)}
                        />
                      )}
                  </View>
                }
              </View>
              <View style={[styles.BottomContainer, { marginTop: 16 }]}>
                <Feather name="sun" size={22} color={primaryColor} />
                <Text style={styles.BottomText}>
                  Duration :
                  {` ${tripDetails?.no_of_days ?? 0} Day${
                    tripDetails?.no_of_days > 1 ? "s" : ""
                  }`}
                  {tripDetails?.no_of_days - 1 > 0 &&
                    ` / ${tripDetails?.no_of_days - 1 ?? 0} Night${
                      tripDetails?.no_of_days - 1 > 1 ? "s" : ""
                    }`}
                  {!tripDetails?.is_public && (
                    <Text style={[styles.privateTripText]}> | Private </Text>
                  )}
                </Text>
              </View>
              <View style={styles.BottomContainer}>
                <Feather name="users" size={22} color={primaryColor} />
                <Text style={styles.BottomText}>
                  Minimum Age Group :
                  {tripDetails?.min_age_limit !== null
                    ? ` ${tripDetails?.min_age_limit} Years or Above`
                    : " No limit"}
                </Text>
              </View>
              {tripDetails.departure_date?.length !== 0 && (
                <View style={styles.BottomContainer}>
                  <Feather name="calendar" size={22} color={primaryColor} />
                  <Text style={styles.BottomText}>
                    Departure Date :{" "}
                    {tripDetails.departure_date?.map((res, index) => (
                      <React.Fragment key={index}>
                        {moment(res).format("Do MMM YYYY")}
                        {index === tripDetails?.departure_date.length - 1
                          ? ""
                          : ", "}
                      </React.Fragment>
                    ))}
                  </Text>
                </View>
              )}
              {isVisibleQrCode && (
                <QrCodeModal
                  visible={isVisibleQrCode}
                  handleClose={() => setIsVisibleQrCode(false)}
                  slug={tripDetails?.slug}
                />
              )}
              <Button
                style={styles.cardButton}
                textColor={whiteColor}
                onPress={handleEnrollTrip}
              >
                Book Now
              </Button>
            </View>
          </View>

          <View
            style={{
              marginTop: 20,
              width: "100%",
            }}
          ></View>
        </View>
      </View>
      {isVisibleBookNowModal ? (
        <BookNowModal
          visible={isVisibleBookNowModal}
          handleClose={() => setIsVisibleBookNowModal(false)}
          ageRating={tripDetails?.min_age_limit}
          trip_id={tripDetails?.id}
          tripTitle={tripDetails?.title}
          tripDepartuureDate={tripDetails?.departure_date}
          noOfDays={tripDetails?.no_of_days}
        />
      ) : null}
    </View>
  );
};

export default TripCard;
