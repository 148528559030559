import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const TRIP_ENDPOINT = {
  GET_ITINERARY: BASE_URL + "itinerary/",
  UPLOAD_ITINERARY: BASE_URL + "itinerary/upload/",
};

export const GetItineraryApi = async (id) => {
  return axios.get(TRIP_ENDPOINT.GET_ITINERARY + id + "/");
};
export const CreateItineraryAPI = async (data) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    const mergedHeaders = {
      ...headers.headers,
      'Content-Type': 'multipart/form-data',
    };
    return axios.post(TRIP_ENDPOINT.GET_ITINERARY, data, {headers:mergedHeaders});
  }
};

export const DeleteItineraryApi = async (id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.delete(TRIP_ENDPOINT.GET_ITINERARY + id + "/", headers);
  }
};

export const UploadItinerary = async (tripId, payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    const mergedHeaders = {
      ...headers.headers,
      'Content-Type': 'multipart/form-data',
    };
    return axios.patch(
      TRIP_ENDPOINT.UPLOAD_ITINERARY + tripId + "/",
      payload,
      {headers:mergedHeaders}
    );
  }
};
