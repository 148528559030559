import React, { useState, useEffect } from "react";
import { View, Text, Modal, ScrollView } from "react-native";
import { ids, styles } from "../CreateTripModal/CreateTripModalCss";
import { Button, Checkbox, List, Avatar, TextInput } from "react-native-paper";
import {
  GetAllSchool,
  SearchSchoolDetails,
} from "../../../service/api/school/api";
import { inputTextColor, primaryColor } from "../../../constant/constantCss";
import { showToast } from "../../../constant/toastMessage";

const SchoolSelectionModal = ({
  modalVisible,
  handleCloseCreateModal,
  handleCloseModal,
  setValue,
  ItenariesVisible,
  // fetching school
}) => {
  useEffect(() => {
    GetAllSchool().then((res) => {
      setSchool(res.data);
    });
  }, []);

  const [school, setSchool] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const handleSearchSchool = (e) => {
    const searchVal = e.target.value;
    SearchSchoolDetails(searchVal)
      .then((res) => {
        setSchool(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const toggleCheckbox = (index) => {
    // Clone the current state of checkedItems
    const newCheckedItems = [...selectedSchool];

    // If the item is already checked, uncheck it; otherwise, check it
    if (newCheckedItems.includes(index)) {
      newCheckedItems.splice(newCheckedItems.indexOf(index), 1);
    } else {
      newCheckedItems.push(index);
    }

    setSelectedSchool(newCheckedItems);
  };
  const address = (area, city) =>
    area && city ? `${area}, ${city}` : area ? area : city ? city : "";
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={handleCloseModal}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>
              <Button icon="arrow-left" onPress={handleCloseModal}></Button>
              Select School
            </Text>
            <Text style={styles.closeButton} onPress={handleCloseModal}>
              X
            </Text>
          </View>

          <View
            style={styles.listContainer}
            dataSet={{ media: ids.listContainer }}
          >
            <TextInput
              style={styles.textInput}
              mode="outlined"
              outlineColor={inputTextColor}
              activeOutlineColor={inputTextColor}
              placeholder="search your school"
              onChange={handleSearchSchool}
            />
            <ScrollView>
              {school?.length <= 0 ? (
                <Text>No school found</Text>
              ) : (
                school?.map((schoolDetail, index) => (
                  <List.Item
                    key={index}
                    title={schoolDetail.name}
                    description={address(schoolDetail.area, schoolDetail.city)}
                    left={(props) => (
                      <>
                        <Checkbox.Android
                          color={primaryColor}
                          status={
                            selectedSchool.includes(schoolDetail.id)
                              ? "checked"
                              : "unchecked"
                          }
                          onPress={() => toggleCheckbox(schoolDetail.id)}
                        />
                        <Avatar.Text
                          size={40}
                          style={{ backgroundColor: primaryColor }}
                          label={schoolDetail?.name?.charAt(0)?.toUpperCase()}
                        />
                      </>
                    )}
                    onPress={() => toggleCheckbox(schoolDetail.id)}
                  />
                ))
              )}
            </ScrollView>
          </View>
          <Button
            style={{ minWidth: 250, marginTop: 10 }}
            buttonColor={primaryColor}
            mode="contained"
            onPress={() => {
              if (selectedSchool.length > 0) {
                setValue("school", selectedSchool);
                handleCloseModal();
                ItenariesVisible();
              } else {
                showToast(
                  "error",
                  "Select any single school for private trip or go with public trip"
                );
              }
            }}
          >
            Next
          </Button>
        </View>
      </View>
    </Modal>
  );
};

export default SchoolSelectionModal;
