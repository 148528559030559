import React from "react";
import { Pressable, View, Image, Modal } from "react-native";
import { Button } from "react-native-paper";
import { ids, styles } from "./VerifyAlbumsModalCss";
import AntDesign from "react-native-vector-icons/AntDesign";
import { primaryColor } from "../../../../../constant/constantCss";
import { showToast } from "../../../../../constant/toastMessage";
import {
  DeleteAlbumsApi,
  GetAlbumsApi,
  VerifyAlbumsApi,
} from "../../../../../service/api/trip/albums";
import { ScrollView } from "react-native";
import { PERMISSION } from "../../../../../constant/getAllPermission";
import { useSelector } from "react-redux";

const VerifyAlbumsModal = ({
  imageId,
  imageUrl,
  handleClose,
  is_public,
  setAlbum,
  trip_id,
}) => {
  const { permission } = useSelector((state) => state.auth);
  const handleVerify = () => {
    handleClose();
    const data = {
      is_public: true,
    };
    VerifyAlbumsApi(data, imageId).then((res) => {
      showToast("success", res?.data?.message);
    });
  };
  const handleDelete = () => {
    DeleteAlbumsApi(imageId)
      .then((res) => {
        handleClose();
        showToast("success", "album deleted successfully");
        // fetch the album
        GetAlbumsApi(trip_id)
          .then((res) => {
            setAlbum(res.data.data);
          })
          .catch((err) => {
            showToast("error", err?.response?.data?.detail);
          });
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  return (
    <Modal visible={true} animationType="slide" transparent={true}>
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          <ScrollView>
            <View style={styles.headerContainer}>
              <Pressable onPress={() => handleClose()}>
                <AntDesign style={styles.closeIcon} name="close" size={20} />
              </Pressable>
            </View>
            <View style={{ width: "100%", marginTop: 20 }}>
              <Image
                style={{ height: 400 }}
                source={{ uri: imageUrl }}
                resizeMode="contain"
              />
            </View>
            <View style={styles.flexButton}>
              {!is_public &&
              permission &&
              permission.includes(PERMISSION.CAN_DELETE_ALBUMS) ? (
                <Button
                  mode="contained"
                  buttonColor="#2c8f47"
                  onPress={handleVerify}
                >
                  Verify
                </Button>
              ) : null}

              {permission &&
                permission.includes(PERMISSION.CAN_DELETE_ALBUMS) && (
                  <Button
                    mode="contained"
                    buttonColor={primaryColor}
                    onPress={handleDelete}
                  >
                    Delete
                  </Button>
                )}
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default VerifyAlbumsModal;
