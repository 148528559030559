import React, { useState } from "react";
import { ids, styles } from "./TripFilterModalCss";
import { Modal, View, Text, ScrollView, Platform } from "react-native";
import { Button, Divider, IconButton } from "react-native-paper";
import {
  inputBackColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../constant/constantCss";
import { GetFilterTripAPI } from "../../../service/api/trip/api";
import { Dropdown } from "react-native-element-dropdown";
import { RadioButton } from "react-native-paper";
import { useSelector } from "react-redux";

const TripFilterModal = ({
  visible,
  handleClose,
  setTripDetails,
  filterValue,
}) => {
  const tripMonthPicker = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];
  const [month, setMonth] = useState();
  const [isSetDate, setIsSetDate] = useState(false);
  const [type, setType] = useState();
  const [budget, setBudget] = useState();
  const [themes, setThemes] = useState();
  const [duration, setDuration] = useState();
  const [age, setAge] = useState();

  const fetchData = () => {
    handleClose();
    let minValue = null;
    let maxValue = null;
    if (budget) {
      if (budget === "under-five") {
        maxValue = 5000;
      } else if (budget === "five-fifteen") {
        minValue = 5000;
        maxValue = 15000;
      } else if (budget === "fifteen-thirty") {
        minValue = 15000;
        maxValue = 30000;
      } else if (budget === "thirty-fifty") {
        minValue = 30000;
        maxValue = 50000;
      } else {
        minValue = 50000;
      }
    }
    let minDuration = null;
    let maxDuration = null;
    if (duration) {
      if (duration === "upto-three") {
        maxDuration = 3;
      } else if (duration === "four-six") {
        minDuration = 4;
        maxDuration = 6;
      } else if (duration === "seven-ten") {
        minDuration = 7;
        maxDuration = 10;
      } else if (duration === "eleven-fifteen") {
        minDuration = 11;
        maxDuration = 15;
      } else {
        minDuration = 16;
      }
    }
    let ageValue = null;
    if (age) {
      if (age === "upto-18") {
        ageValue = "lower";
      } else {
        ageValue = "higher";
      }
    }
    GetFilterTripAPI(
      filterValue,
      null,
      type,
      minValue,
      maxValue,
      month,
      minDuration,
      maxDuration,
      themes,
      ageValue
    )
      .then((res) => {
        setTripDetails(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleReset = () => {
    setBudget();
    setAge();
    setDuration();
    setThemes();
    setMonth();
    setIsSetDate(false);
    setType();
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Filters</Text>
            <IconButton icon={"close"} onPress={handleClose} />
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View style={styles.listContainer}>
                <View>
                  <Text style={styles.listTitle}>Trip Month</Text>
                  <Text style={styles.listDesc}>Filter by month</Text>
                </View>
                <Dropdown
                  data={tripMonthPicker}
                  labelField="label"
                  valueField="value"
                  search
                  mode={"auto"}
                  value={month}
                  style={{
                    minWidth: 150,
                    paddingHorizontal: 10,
                    borderWidth: 1,
                    borderColor: subTextColor,
                    borderRadius: 5,
                    backgroundColor: inputBackColor,
                  }}
                  placeholderStyle={{ color: subTextColor }}
                  maxHeight={300}
                  inputSearchStyle={{
                    minWidth: 100,
                    maxHeight: 30,
                  }}
                  placeholder="Select Month"
                  searchPlaceholder="Search..."
                  onChange={(item) => {
                    setMonth(item.value);
                  }}
                />
              </View>
              <Divider horizontalInset={true} />
              <View style={styles.listContainer_radio}>
                <View>
                  <Text style={styles.listTitle}>Budget</Text>
                </View>
                <RadioButton.Group
                  onValueChange={(newValue) => setBudget(newValue)}
                  value={budget}
                >
                  <View style={styles.radio}>
                    <RadioButton.Android value="under-five" />
                    <Text>{"Under ₹5,000"}</Text>
                  </View>
                  <View style={styles.radio}>
                    <RadioButton.Android value="five-fifteen" />
                    <Text>{"₹5,000 - ₹15,000"}</Text>
                  </View>
                  <View style={styles.radio}>
                    <RadioButton.Android value="fifteen-thirty" />
                    <Text>{"₹15,000 - ₹30,000"}</Text>
                  </View>
                  <View style={styles.radio}>
                    <RadioButton.Android value="thirty-fifty" />
                    <Text>{"₹30,000 - ₹50,000"}</Text>
                  </View>
                  <View style={styles.radio}>
                    <RadioButton.Android value="over-fifty" />
                    <Text>{"Over ₹50,000"}</Text>
                  </View>
                </RadioButton.Group>
              </View>
              <Divider horizontalInset={true} />
              <View style={styles.listContainer_radio}>
                <View>
                  <Text style={styles.listTitle}>Duration</Text>
                </View>
                <RadioButton.Group
                  onValueChange={(newValue) => setDuration(newValue)}
                  value={duration}
                >
                  <View style={styles.radio}>
                    <RadioButton.Android value="upto-three" />
                    <Text>{"Up to 3 nights"}</Text>
                  </View>
                  <View style={styles.radio}>
                    <RadioButton.Android value="four-six" />
                    <Text>{"4 nights - 6 nights"}</Text>
                  </View>
                  <View style={styles.radio}>
                    <RadioButton.Android value="seven-ten" />
                    <Text>{"7 nights - 10 nights"}</Text>
                  </View>
                  <View style={styles.radio}>
                    <RadioButton.Android value="eleven-fifteen" />
                    <Text>{"11 nights - 15 nights"}</Text>
                  </View>
                  <View style={styles.radio}>
                    <RadioButton.Android value="above-sixteen" />
                    <Text>{"Above 16 nights"}</Text>
                  </View>
                </RadioButton.Group>
              </View>
              <Divider horizontalInset={true} />
              <View style={styles.listContainer_radio}>
                <View>
                  <Text style={styles.listTitle}>Interest</Text>
                </View>
                <RadioButton.Group
                  onValueChange={(newValue) => setThemes(newValue)}
                  value={themes}
                >
                  <View style={styles.radio}>
                    <RadioButton.Android value="adventure" />
                    <Text>{"Adventure"}</Text>
                  </View>
                  <View style={styles.radio}>
                    <RadioButton.Android value="discovery" />
                    <Text>{"Discovery"}</Text>
                  </View>
                </RadioButton.Group>
              </View>
              <Divider horizontalInset={true} />
              <View style={styles.listContainer_radio}>
                <View>
                  <Text style={styles.listTitle}>Age Limit</Text>
                </View>
                <RadioButton.Group
                  onValueChange={(newValue) => setAge(newValue)}
                  value={age}
                >
                  <View style={styles.radio}>
                    <RadioButton.Android value="upto-18" />
                    <Text>{"Below 18 years"}</Text>
                  </View>
                  <View style={styles.radio}>
                    <RadioButton.Android value="18-plus" />
                    <Text>{"Above 18 years"}</Text>
                  </View>
                </RadioButton.Group>
              </View>
              <Divider horizontalInset={true} />
              <View style={styles.listContainer}>
                <Button
                  mode="outlined"
                  textColor={primaryColor}
                  onPress={() => handleReset()}
                >
                  Reset
                </Button>
                <Button
                  buttonColor={primaryColor}
                  textColor={whiteColor}
                  style={{ minWidth: 120 }}
                  onPress={() => fetchData()}
                >
                  Apply
                </Button>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default TripFilterModal;
