import React, { useState } from "react";
import { ids, styles } from "./PaymentFilterCss";
import { Modal, View, Text, ScrollView, Platform } from "react-native";
import { Divider, List, TextInput } from "react-native-paper";
import { GetUserPaymentHistory } from "../../../service/api/payment/api";
import {
  blackColor,
  inputBackColor,
  inputTextColor,
  subTextColor,
} from "../../../constant/constantCss";
import { Picker } from "@react-native-picker/picker";
import { Dropdown } from "react-native-element-dropdown";

const PaymentFilterModal = ({ visible, handleClose, setPaymentList }) => {
  const statusPicker = [
    { label: "pending", value: "pending" },
    { label: "success", value: "success" },
    { label: "failed", value: "failed" },
    { label: "refunded", value: "refunded" },
    { label: "all", value: "all" },
  ];
  const [selectedValue, setSelectedValue] = useState("pending");
  const [selectedPrice, setSelectedPrice] = useState("");
  const FilterByStatus = (itemValue) => {
    setSelectedValue(itemValue);
    const statusChangeText = {
      success: "captured",
      pending: "success",
      failed: "failed",
      refunded: "refunded",
    };

    GetUserPaymentHistory(
      itemValue === "all" ? {} : { status: statusChangeText[itemValue] }
    )
      .then((res) => {
        setPaymentList(res.data?.results);
        handleClose();
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  const FilterByPrice = (priceVal) => {
    GetUserPaymentHistory({ price: priceVal })
      .then((res) => {
        setPaymentList(res.data?.results);
        handleClose();
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Payment Filter</Text>
            <Text style={styles.closeButton} onPress={handleClose}>
              X
            </Text>
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View style={styles.listContainer}>
                <View>
                  <Text style={styles.listTitle}>Status</Text>
                  <Text style={styles.listDesc}>Filter by Payment Status</Text>
                </View>
                {Platform.OS === "android" || Platform.OS === "ios" ? (
                  <Dropdown
                    data={statusPicker}
                    labelField="label"
                    valueField="value"
                    search
                    mode={"auto"}
                    style={{
                      minWidth: 150,
                      paddingHorizontal: 10,
                      borderWidth: 1,
                      borderColor: subTextColor,
                      borderRadius: 5,
                      backgroundColor: inputBackColor,
                    }}
                    placeholderStyle={{ color: subTextColor }}
                    maxHeight={300}
                    inputSearchStyle={{
                      minWidth: 100,
                      maxHeight: 30,
                    }}
                    placeholder="Select Type"
                    searchPlaceholder="Search..."
                    onChange={(item) => {
                      FilterByStatus(item.value);
                    }}
                  />
                ) : (
                  <Picker
                    selectedValue={selectedValue}
                    onValueChange={(itemValue) => FilterByStatus(itemValue)}
                    style={styles.picker}
                  >
                    {statusPicker?.map((item, index) => (
                      <Picker.Item
                        key={index}
                        label={item.label}
                        value={item.value}
                      />
                    ))}
                  </Picker>
                )}
              </View>
              <Divider horizontalInset={true} />
              <View style={styles.listContainer}>
                <View>
                  <Text style={styles.listTitle}>Price</Text>
                  <Text style={styles.listDesc}>Filter by less Price</Text>
                </View>
                <TextInput
                  style={styles.textInput}
                  mode="outlined"
                  outlineColor={blackColor}
                  activeOutlineColor={inputTextColor}
                  placeholder="00"
                  onChangeText={(text) => setSelectedPrice(text)}
                  right={
                    <TextInput.Icon
                      size={18}
                      icon={"check"}
                      color={subTextColor}
                      onPress={() => FilterByPrice(selectedPrice)}
                    />
                  }
                ></TextInput>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default PaymentFilterModal;
