import StyleSheet from "react-native-media-query";
import {
  blackColor,
  greyBackColor,
  errorTextColor,
  inputTextColor,
  whiteColor,
  subTextColor,
  primaryColor,
} from "../../../../../constant/constantCss";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  locationContainer: {
    borderWidth: 1,
    borderColor: inputTextColor,
    borderRadius: 10,
    padding: 10,
    marginTop: 10,
  },
  stopMainContainer: {
    borderWidth: 1,
    borderColor: inputTextColor,
    borderRadius: 10,
    padding: 10,
    marginTop: 20,
    width: "100%",
    overflow: "auto",
    maxHeight: 300,
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  stopContainer: {
    borderWidth: 1,
    borderColor: inputTextColor,
    padding: 10,
    borderRadius: 10,
    marginRight: 10,
  },
  modalView: {
    margin: 20,
    width: 500,
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: blackColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 480px)": {
      width: "90%",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 22,
    fontWeight: "800",
    "@media (max-width: 768px)": {
      fontSize: 18,
    },
  },
  subText: {
    fontSize: 14,
    fontWeight: "normal",
    color: subTextColor,
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "800",
  },
  // Body
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    width: "100%",
    minWidth: "100%",
    overflow: "auto",
    marginTop: 10,
    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: 0,
    },
  },
  inputView: {
    marginTop: 16,
  },
  inputText: {
    fontSize: 16,
  },
  textInput: {
    fontSize: 16,
    height: Platform.OS === "android" ? null : 38,
    borderRadius: 10,
    borderColor: inputTextColor,
    backgroundColor: greyBackColor,
  },
  textAreaInput: {
    fontSize: 16,
    height: 100,
    backgroundColor: greyBackColor,
  },
  buttonView: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginTop: 10,
  },
  addmoreButton: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  inputError: {
    color: errorTextColor,
    fontWeight: 600,
    paddingBottom: 0,
  },
  // upload
  upload_container: {
    minHeight: 100,
    borderWidth: 1,
    borderStyle: "dotted",
    width: "100%",
    marginBottom: 20,
  },
  upload_sub_container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 100,
    gap: 10,
  },
  upload_text: {
    fontSize: 16,
    color: primaryColor,
    fontWeight: "500",
  },
});
