import RazorpayCheckout from "react-native-razorpay";
import { showToast } from "../../constant/toastMessage";

export const RazorpayAndroidAndIos = async (options) => {
  try{
    const data = await RazorpayCheckout.open(options);
    if (data) {
    showToast("success", "Payment successfully received");
    }
    else {
      showToast("error","Invalid data!")
    }
  }
  catch (error) {
    showToast("error", `Failed payment`);
    throw error; 
  }
};
