import React, { useState } from "react";
import { ids, styles } from "./FilterModalCss";
import { Modal, Platform, ScrollView, Text, View } from "react-native";
import { Button, Divider, List, TextInput } from "react-native-paper";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { primaryColor, whiteColor } from "../../../constant/constantCss";
import {
  GetAllPaymentAnalytics,
  GetAllTripAnalytics,
} from "../../../service/api/analytics/api";
import { showToast } from "../../../constant/toastMessage";
import moment from "moment";

const AnalyticFilterModal = ({
  visible,
  handleClose,
  type,
  setTripAnalyticsData,
  setPaymentAnalyticsData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setIsVisibleNote,
}) => {
  const onSubmit = () => {
    if (startDate) {
      if (moment(startDate) < moment(endDate)) {
        GetAllTripAnalytics({ startDate: startDate, endDate: endDate })
          .then((res) => {
            setTripAnalyticsData(res.data);
          })
          .catch((err) => {
            showToast("error", err?.response?.data?.detail);
          });
        if (type === "analytic_overview") {
          GetAllPaymentAnalytics({ startDate: startDate, endDate: endDate })
            .then((res) => {
              setPaymentAnalyticsData(res.data);
            })
            .catch((err) => {
              showToast("error", err?.response?.data?.detail);
            });
        }
        handleClose();
        setIsVisibleNote(true);
      } else {
        showToast("error", "End date must be greater than Start Date");
      }
    }
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Analytics Filter</Text>
            <Text style={styles.closeButton} onPress={handleClose}>
              X
            </Text>
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View style={styles.listContainer}>
                <View>
                  <Text style={styles.listTitle}>Start Date</Text>
                  <Text style={styles.listDesc}>Filter by Date</Text>
                </View>
                {Platform.OS === "web" ? (
                  <input
                    type="date"
                    style={styles.textInput}
                    onChange={(event) => setStartDate(event.target.value)}
                  />
                ) : (
                  <View style={styles.textInput}>
                    <DatePicker
                      inputString="dd-mm-yyyy"
                      mode="date"
                      dateTime=""
                      setDateTime={setStartDate}
                    />
                  </View>
                )}
              </View>
              <Divider horizontalInset={true} />
              <View style={styles.listContainer}>
                <View>
                  <Text style={styles.listTitle}>End Date</Text>
                  <Text style={styles.listDesc}>Filter by Date</Text>
                </View>
                {Platform.OS === "web" ? (
                  <input
                    disabled={startDate ? false : true}
                    type="date"
                    style={styles.textInput}
                    onChange={(event) => setEndDate(event.target.value)}
                  />
                ) : (
                  <View style={styles.textInput}>
                    <DatePicker
                      inputString="dd-mm-yyyy"
                      mode="date"
                      dateTime=""
                      setDateTime={setEndDate}
                    />
                  </View>
                )}
              </View>
              <Button
                icon={"send"}
                buttonColor={primaryColor}
                textColor={whiteColor}
                style={{ marginTop: 20 }}
                onPress={onSubmit}
              >
                Send
              </Button>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default AnalyticFilterModal;
