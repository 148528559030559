import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roleDetails: [],
};

export const rolePermissionSlice = createSlice({
  name: "rolePermission",
  initialState,
  reducers: {
    setRoleDetails: (state, action) => {
      state.roleDetails = action.payload;
    },
  },
});

export const { setRoleDetails } = rolePermissionSlice.actions;
export default rolePermissionSlice.reducer;
