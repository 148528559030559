import StyleSheet from "react-native-media-query";
import {
  blackColor,
  blueColor,
  primaryColor,
  subTextColor,
  tripBackgrColor,
  whiteColor,
} from "../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  upcomingCardContainer: {
    height: 340,
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
  surfaceContainer: {
    height: "100%",
    borderRadius: 10,
    backgroundColor: tripBackgrColor,
  },
  cardImage: {
    height: 200,
    width: "100%",
    marginBottom: 10,
    borderRadius: 10,
  },
  cardFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 1,
    paddingBottom: 20
  },
  cardleftFooter: {
    display: "flex",
    flexDirection: "row",
    gap: 14,
    paddingLeft: 14,
    width: "100%",
  },
  timeContainer: {
    height: 90,
    minWidth: 70,
    backgroundColor: primaryColor,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
  },
  timeText: {
    fontSize: 18,
    fontWeight: "700",
    color: whiteColor,
    textAlign: "center",
  },
  textFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: 90,
    width: "100%",
    flex: 1,
    marginRight: 10,
    marginTop: 10,
    gap: 6,
  },
  cardPrimaryText: {
    fontSize: 18,
    fontWeight: "600",
    width: "100%",
    color: blackColor,
  },
  cardSecondaryText: {
    fontSize: 18,
    color: blackColor,
  },
  chipText: {
    fontSize: 14,
    color: subTextColor,
  },
  privateTripText: {
    width: 80,
    color: blueColor,
  },
});
