import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const USER_VERIFY_ENDPOINT = {
  USER_VERIFY_URL: BASE_URL + "auth/verify_user/",
};

export const VerifyUserAPI = async (user_id, payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.patch(
      USER_VERIFY_ENDPOINT.USER_VERIFY_URL + user_id,
      payload,
      headers
    );
  }
};
