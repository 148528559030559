import { RAZOR_PAY_API_KEY } from "@env";

export const RazorpayApiKey = RAZOR_PAY_API_KEY;

export const RazorpayConfig = {
  display: {
    blocks: {
      banks: {
        name: "Most used methods",
        instruments: [
          {
            method: "upi",
          },
          {
            method: "card",
          },
        ],
      },
    },
    hide: [
      {
        method: "emi",
      },
      {
        method: "paylater",
      },
    ],
    sequence: ["block.banks"],
    preferences: {
      show_default_blocks: true,
    },
  },
};
