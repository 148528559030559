import React, { useState, useEffect, useCallback } from "react";
import AppBar from "../Layout/AppBar";
import { View, Text, Dimensions, ScrollView, Image,TouchableOpacity,Platform } from "react-native";
import { ids, styles } from "./ManageSchoolCss";
import { Button,IconButton } from "react-native-paper";
import SchoolCard from "./SchoolCard/SchoolCard";
import { GetAllSchool } from "../../service/api/school/api";
import AddSchoolModal from "./AddSchoolModal/AddSchoolModal";
import SearchSchool from "./Search school/SearchSchool";
import { primaryColor, subTextColor, whiteColor } from "../../constant/constantCss";
import { useSelector } from "react-redux";
import { PERMISSION } from "../../constant/getAllPermission";
import noInstituteImg from "../../../assets/icons/no_institute.png";
import { RefreshControl } from "react-native";
import PlaneLoader from "../../components/Loader";
import ContactUs from "../LnadingPage/components/ContactUs";
import MaterialIcons from "react-native-vector-icons/MaterialIcons"

const ManageSchool = () => {
  const { permission } = useSelector((state) => state.auth);
  const [schoolList, setschoolList] = useState([]);
  const [loader, setloader] = useState(false);
  const screenWidth = Dimensions.get("window").width;
  const screenHeight = Dimensions.get("window").height;
  const [isVisibleAddSchoolModal, setIsVisibleAddSchoolModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const initalCallingApi = () => {
    setloader(true);
    GetAllSchool()
      .then((res) => {
        setschoolList(res.data);
        setloader(false);
      })
      .catch((e) => {
        showToast("error", e?.response?.data?.detail);
        setloader(false);
      });
  };

  useEffect(()=>{
    initalCallingApi();
  }, [])

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initalCallingApi();
      setRefreshing(false);
    }, 1000);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    initalCallingApi();
  }, []);

  return (
    <>
      <View style={styles.container}>
        <AppBar />
        <ScrollView
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              tintColor={primaryColor}
              colors={[primaryColor]}
            />
          }
          style={{marginBottom:
            Platform.OS === "ios" ? 110 : Platform.OS !== "web" && 60}}
        >
          <View style={styles.subContainer}>
            <View style={styles.flexRow}>
              <Text style={styles.headingText}>Organization Details</Text>
              {permission &&
                permission?.includes(PERMISSION.CAN_CREATE_ORGANIZATION) && (
                  Dimensions.get("screen").width >= 480 ? 
                  <Button
                    mode="contained"
                    icon={"plus"}
                    buttonColor={primaryColor}
                    style={{
                      borderRadius: 20,
                      height: 37,
                      paddingVertical: 0,
                      marginRight: 9,
                    }}
                    onPress={() => {
                      setIsVisibleAddSchoolModal(true);
                    }}
                  >
                    Add Organization
                  </Button> : 
                  <IconButton
                    icon="plus"
                    containerColor={primaryColor}
                    iconColor={whiteColor}
                    mode="contained"
                    style={{
                      borderRadius: 20,
                      height: 37,
                      paddingVertical: 0,
                      marginRight: 9,
                    }}
                    size={20}
                    onPress={() => {
                      setIsVisibleAddSchoolModal(true);
                    }}
                  />
                )}
            </View>
            <SearchSchool setschoolList={setschoolList} />
            <View style={styles.cardViewContainer}>
              {loader ? (
                <PlaneLoader />
              ) : schoolList.length > 0 ? (
                schoolList?.map((school, index) => (
                  <SchoolCard
                    key={index}
                    id={school.id}
                    name={school.name}
                    area={school.area}
                    city={school.city}
                    address={school.address}
                    image={school.image}
                    phone_num={school.phone_number}
                    school_admin={school.school_admin}
                  />
                ))
              ) : (
                <View
                  style={{
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                    marginTop: 20,
                  }}
                >
                  <Image
                    resizeMode={"contain"}
                    source={noInstituteImg}
                    style={{ height: 150, width: 150 }}
                  />
                  <Text
                    style={{
                      color: subTextColor,
                      fontSize: 18,
                      fontWeight: 500,
                    }}
                  >
                    No institute found !
                  </Text>
                </View>
              )}
            </View>
          </View>
          {/* Visible or Invisible of Add School Modal */}
          <AddSchoolModal
            visible={isVisibleAddSchoolModal}
            setschoolList={setschoolList}
            handleClose={() => setIsVisibleAddSchoolModal(false)}
          />
          {Platform.OS!=="web" && <ContactUs />}
        </ScrollView>
        {Platform.OS==="web" && 
          <>
          <ContactUs />
          <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
      </View>
    </>
  );
};

export default ManageSchool;
