import React, { useState, useRef,useEffect } from "react";
import { View, Dimensions, Image, ScrollView, TouchableOpacity, Text, Modal, TouchableWithoutFeedback, Animated } from "react-native";
import { Drawer, IconButton } from "react-native-paper";
import StyleSheet from "react-native-media-query";
import splashLogo from "../../../assets/splashLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { ActiveItem, setIsDrawerVisible } from "../../store/slices/generalSlice";
import { PERMISSION } from "../../constant/getAllPermission";
import LogoutModal from "../Logout/LogoutModal";
import { useRoute } from "@react-navigation/native";
import {
  appBarColor,
  primaryColor,
  sidebarColor,
  whiteColor,
} from "../../constant/constantCss";
import { GetUserAPI } from "../../service/api/user/getUserApi";
import { setUserData } from "../../store/slices/authSlice";

const AppDrawer = ({ activeItem, navigateToScreen }) => {
  const { isDrawerVisible } = useSelector((state) => state.general);
  const [isVisibleLogoutModal, setIsVisibleLogoutModal] = useState(false);
  const { permission } = useSelector((state) => state.auth);
  const screenHeight = Dimensions.get("window").height;
  const screenWidth = Dimensions.get("window").width;
  const dispatch = useDispatch();
  const drawerAnimation = useRef(new Animated.Value(-0.75 * screenWidth)).current;

  useEffect(() => {
    toggleDrawer();
  }, [isDrawerVisible]);

  const toggleDrawer = () => {
    const openPosition = -0.75 * screenWidth; 
    const toValue = isDrawerVisible ? 0 : openPosition;
    Animated.timing(drawerAnimation, {
      toValue,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const closeDrawer = () => {
    dispatch(setIsDrawerVisible(false));
  };

  const { ids, styles } = StyleSheet.create({
    img: {
      marginLeft: 50,
      height: 80,
      width: 150,
      marginBottom: 20,
      "@media (min-width: 768px)": {
        marginTop: 20,
      },
    },
    drawerContainer: {
      flex: 1,
      zIndex: 1,
      height: "100%",
      minWidth: 250,
      display: isDrawerVisible ? "block" : "none",
      position: "fixed",
      backgroundColor: whiteColor,
      borderRightWidth: 1,
      borderRightColor: "#D8D8D8",
      overflow: "auto",

      "@media (max-width: 768px)": {
        borderTopRightRadius: 40,
        borderBottomRightRadius: 40,
      },
    },
    itemContainer: {
      borderRadius: 0,
    },
    activeItemContainer: {
      borderRadius: 0,
      backgroundColor: sidebarColor,
      marginLeft: 3,
      paddingLeft: 15,
      borderRightWidth: 2,
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2
    },
    mainContainer: {
      width: 250,
      minHeight: screenHeight,
    },
    backMenuContainer: {
      display: "flex",
      height: 64,
      alignItems: "flex-end",
      "@media (min-width: 768 )": {
        display: "none",
      },
    },
  })

  toggleDrawer();

  const route = useRoute();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await GetUserAPI();
        dispatch(setUserData(response.data));
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    fetchUserData();
    dispatch(ActiveItem(route.name));
  }, [dispatch, route.name]);

  return (
    <Modal
      animationType="none"
      transparent={true}
      visible={isDrawerVisible}
      onRequestClose={() => {
        dispatch(setIsDrawerVisible(false));
      }}
    >
      <TouchableWithoutFeedback onPress={() => dispatch(setIsDrawerVisible(false))}>
        <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <Animated.View style={[styles.drawerContainer, { transform: [{ translateX: drawerAnimation }] }]}>
            <ScrollView style={{ height: screenHeight }}>
              <Drawer.Section showDivider={false}>
                <View style={styles.backMenuContainer}>
                  <IconButton
                    icon={"menu-open"}
                    style={{
                      borderRadius: 10,
                      borderWidth: 1,
                      marginTop: 40,
                      backgroundColor: sidebarColor,
                      borderColor: primaryColor,
                    }}
                    onPress={() => {
                      dispatch(setIsDrawerVisible(false));
                    }}
                    animated={true}
                  />
                </View>
                <TouchableOpacity onPress={() => {
                  navigateToScreen("dashboard");
                  closeDrawer();
                }}>
                  <Image
                    source={splashLogo}
                    style={styles.img}
                    dataSet={{ media: ids.img }}
                  />
                </TouchableOpacity>
                <Drawer.Item
                  label="Dashboard"
                  icon="home"
                  style={
                    activeItem === "dashboard"
                      ? styles.activeItemContainer
                      : styles.itemContainer
                  }
                  active={activeItem === "dashboard"}
                  onPress={() => {
                    navigateToScreen("dashboard");
                    closeDrawer();}
                  }
                />
                <Drawer.Item
                  label="Trips"
                  icon="camera-image"
                  style={
                    activeItem === "Trip"
                      ? styles.activeItemContainer
                      : styles.itemContainer
                  }
                  active={activeItem === "Trip"}
                  onPress={() => {
                    navigateToScreen("Trip");
                    closeDrawer();
                  }}
                />
                {permission &&
                  permission?.includes(
                    PERMISSION.CAN_SHOW_ORGANIZATION_DETAILS_TAB
                  ) && (
                    <React.Fragment>
                      <Drawer.Item
                        label="Organization Details"
                        icon="bank"
                        style={[
                          activeItem === "Manage_school_detail"
                            ? styles.activeItemContainer
                            : styles.itemContainer]
                        }
                        active={activeItem === "Manage_school_detail"}
                        onPress={() => {
                          navigateToScreen("Manage_school_detail");
                          closeDrawer();
                        }
                        }
                      />
                    </React.Fragment>
                  )}

                {permission &&
                  permission?.includes(
                    PERMISSION.CAN_SHOW_ROLE_MANAGEMENT_TAB
                  ) && (
                    <>
                      <Drawer.Item
                        label="Role Management"
                        icon="shield-account"
                        style={
                          activeItem === "Role_Management"
                            ? styles.activeItemContainer
                            : styles.itemContainer
                        }
                        active={activeItem === "Role_Management"}
                        onPress={() => {
                          navigateToScreen("Role_Management");
                          closeDrawer();
                        }}
                      />
                    </>
                  )}
                {permission &&
                  permission?.includes(PERMISSION.CAN_SHOW_ALL_USERS_TAB) && (
                    <>
                      <Drawer.Item
                        label="All Users"
                        icon="account-group"
                        style={
                          activeItem === "All_User"
                            ? styles.activeItemContainer
                            : styles.itemContainer
                        }
                        active={activeItem === "All_User"}
                        onPress={() => {
                          navigateToScreen("All_User");
                          closeDrawer();
                        }}
                      />
                    </>
                  )}
                <Drawer.Item
                  label="Profile"
                  icon="account"
                  style={
                    activeItem === "profile"
                      ? styles.activeItemContainer
                      : styles.itemContainer
                  }
                  active={activeItem === "profile"}
                  onPress={() => {
                    navigateToScreen("profile");
                    closeDrawer();
                  }}
                />
                <Drawer.Item
                  label="Payment History"
                  icon="credit-card"
                  style={
                    activeItem === "Payment_History"
                      ? styles.activeItemContainer
                      : styles.itemContainer
                  }
                  active={activeItem === "Payment_History"}
                  onPress={() => {
                    navigateToScreen("Payment_History");
                    closeDrawer();
                  }}
                />
                {permission &&
                  permission?.includes(
                    PERMISSION.CAN_SHOW_ANALYTICS_OVERVIEW_TAB
                  ) && (
                    <>
                      <Drawer.Item
                        label="Analytics Overview"
                        icon="chart-line"
                        style={
                          activeItem === "analytics"
                            ? styles.activeItemContainer
                            : styles.itemContainer
                        }
                        active={activeItem === "analytics"}
                        onPress={() => {
                          navigateToScreen("analytics");
                          closeDrawer();
                        }}
                      />
                    </>
                  )}
                {permission &&
                  permission?.includes(
                    PERMISSION.CAN_SHOW_ANALYTICS_TRIP_TAB
                  ) && (
                    <>
                      <Drawer.Item
                        label="Analytics Trip"
                        icon="chart-line"
                        style={
                          activeItem === "Analytics_Trip"
                            ? styles.activeItemContainer
                            : styles.itemContainer
                        }
                        active={activeItem === "Analytics_Trip"}
                        onPress={() => {
                          navigateToScreen("Analytics_Trip");
                          closeDrawer();
                        }}
                      />
                    </>
                  )}
                {permission &&
                  permission?.includes(
                    PERMISSION.CAN_SHOW_COMPANY_REVIEW_TAB
                  ) && (
                    <>
                      <Drawer.Item
                        label="Company Reviews"
                        icon="star"
                        style={
                          activeItem === "Company_Review"
                            ? styles.activeItemContainer
                            : styles.itemContainer
                        }
                        active={activeItem === "Company_Review"}
                        onPress={() => {
                          navigateToScreen("Company_Review");
                          closeDrawer();
                        }}
                      />
                    </>
                  )}
                <Drawer.Item
                  label="Logout"
                  icon="logout"
                  style={
                    [activeItem === "Logout"
                      ? styles.activeItemContainer
                      : styles.itemContainer
                    ]}
                  active={activeItem === "Logout"}
                  onPress={() => {
                    setIsVisibleLogoutModal(true);
                  }}
                />
              </Drawer.Section>
            </ScrollView>
          </Animated.View>
        </View>
      </TouchableWithoutFeedback>
            {isVisibleLogoutModal && (
              <LogoutModal
                visible={isVisibleLogoutModal}
                handleClose={() => setIsVisibleLogoutModal(false)}
              />
            )}
    </Modal>
  );
};

export default AppDrawer;