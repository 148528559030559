import React, { useState } from "react";
import {
  View,
  Text,
  Platform,
  ScrollView,
  Modal,
  Dimensions,
} from "react-native";
import { ids, styles } from "../EditItinerary/EditItenariesModalCss";
import { useForm, Controller } from "react-hook-form";
import * as DocumentPicker from "expo-document-picker";
import moment from "moment";
import { TextInput, Button, IconButton } from "react-native-paper";
import {
  blackColor,
  inputTextColor,
  primaryColor,
} from "../../../../../constant/constantCss";
import { showToast } from "../../../../../constant/toastMessage";
import {
  CreateItineraryAPI,
  GetItineraryApi,
} from "../../../../../service/api/trip/itineraryApi";
import DatePicker from "../../../../../components/DatePicker/DatePicker";

const AddItinerary = ({ trip_id, visible, handleClose, setRefreshAPI }) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    unregister,
    formState: { errors },
  } = useForm({});
  const screenWidth = Dimensions.get("window").width;
  const [loader, setLoader] = useState(false);
  const roundness = screenWidth > 768 ? 20 : 8;
  const [locationItems, setLocationItems] = useState([{ stopItems: [0] }]);
  const [stopTime, setStopTime] = useState([{ stopItems: [0] }]);

  const handleAddStop = (index) => {
    const location = locationItems[index];
    location.stopItems.push(location.stopItems.length + 1);

    setLocationItems([...locationItems]);
  };

  function handleDeleteStop(index, stopIndex) {
    if (stopIndex > 0) {
      locationItems[index].stopItems.splice(stopIndex, 1);
      setLocationItems([...locationItems]);
      unregister(`stopTime_${index}_${stopIndex}`);
      unregister(`stopDescription_${index}_${stopIndex}`);
    } else {
      if (Platform.OS == "web") {
        alert("error : you can't delete");
      } else {
        showToast("error", "you can't delete");
      }
    }
  }

  const pickImage = async (index) => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: ["image/png", "image/webp", "image/jpg", "image/jpeg"],
      });

      if (!result?.canceled) {
        if (Platform.OS === "web") {
          setValue(`image_location_${index}`, result?.assets[0]?.file);
        } else {
          // random name generate
          const currentDate = moment();
          const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
          const imageName = `location_image_${formattedDate}.jpeg`;

          const fileObj = {
            uri: result?.assets?.[0]?.uri,
            type: "image/jpeg",
            name: imageName,
          };
          setValue(`image_location_${index}`, fileObj);
        }
      }
    } catch (error) {
      console.error("Error picking an image:", error);
    }
  };
  const onSubmitData = (data) => {
    const lastLocation = `location_${locationItems.length - 1}`;
    if (data[lastLocation] === "") {
      showToast("error", "Location can't null");
    } else {
      setLoader(true);
      const newArray = [];
      // Iterate through the input object keys and group them into a new structure
      for (const key in data) {
        const [prefix, index, subIndex] = key.split("_");

        // Check if the key is a location
        if (prefix === "location") {
          const locationIndex = parseInt(index);
          const newObject = {
            location_name: data[key],
            stop: [],
          };
          delete data[key]; // delete from data key location
          // Find associated stop descriptions and stop times
          for (let stopIndex = 0; ; stopIndex++) {
            const stopTimeKey = `stopTime_${locationIndex}_${stopIndex}`;
            const stopDescKey = `stopDescription_${locationIndex}_${stopIndex}`;

            if (data[stopDescKey] && data[stopTimeKey]) {
              newObject.stop.push({
                stop_time: data[stopTimeKey],
                description: data[stopDescKey],
              });
              delete data[stopTimeKey];
              delete data[stopDescKey];
            } else if (data[stopDescKey]) {
              newObject.stop.push({
                description: data[stopDescKey],
              });
              delete data[stopTimeKey];
              delete data[stopDescKey];
            } else {
              break; // Stop when there are no more associated stops
            }
          }
          newArray.push(newObject);
        }
      }
      Object.assign(data, { itinerary: JSON.stringify(newArray) });
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key] !== undefined) {
          formData.append(key, data[key]);
        }
      });
      formData.append("trip_id", trip_id);

      CreateItineraryAPI(formData)
        .then((res) => {
          setRefreshAPI((prev) => !prev);
          showToast("success", res?.data?.message);
          setLoader(false);
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          showToast("error", err?.response?.data?.detail);
        });
    }
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Add new itinerary</Text>
            <IconButton
              icon={"close"}
              iconColor={blackColor}
              onPress={handleClose}
            />
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View>
                {locationItems?.map((locationItem, index) => (
                  <View style={styles.locationContainer} key={index}>
                    <View>
                      <Text style={styles.inputText}>Location </Text>
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            style={styles.textInput}
                            dataSet={{ media: ids.textInput }}
                            onChangeText={onChange}
                            value={value}
                            theme={{
                              roundness: roundness,
                            }}
                            mode="outlined"
                            outlineColor={inputTextColor}
                            activeOutlineColor={inputTextColor}
                            placeholder="Enter Location"
                          />
                        )}
                        name={`location_${index}`}
                      />
                      {`errors.location_${index}` && (
                        <Text
                          style={styles.inputError}
                          dataSet={{ media: ids.inputError }}
                        >
                          This is required.
                        </Text>
                      )}
                      <Text style={styles.inputText}>Location Image</Text>
                      <React.Fragment>
                        <IconButton
                          icon="file-upload"
                          iconColor={primaryColor}
                          mode={"contained"}
                          onPress={() => pickImage(index)}
                        ></IconButton>
                        <Text>{watch(`image_location_${index}`)?.name}</Text>
                      </React.Fragment>
                    </View>

                    <View style={styles.stopMainContainer}>
                      <ScrollView horizontal={true}>
                        {locationItem?.stopItems?.map((res, stopIndex) => (
                          <View key={index + stopIndex}>
                            <View style={styles.stopContainer}>
                              <Text style={styles.inputText}>
                                Stop Time {stopIndex + 1}
                              </Text>
                              <Controller
                                control={control}
                                rules={{
                                  required: false,
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    {Platform.OS === "web" ? (
                                      <input
                                        type="time"
                                        style={styles.textInput}
                                        dataSet={{ media: ids.textInput }}
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Enter Stop time in HH:MM"
                                      />
                                    ) : (
                                      <>
                                        <DatePicker
                                          inputString="---Select Stop Time---"
                                          dateTime={stopTime}
                                          mode="time"
                                          setDateTime={(date) => {
                                            setValue(
                                              `stopTime_${index}_${stopIndex}`,
                                              date
                                            );
                                          }}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                                name={`stopTime_${index}_${stopIndex}`}
                              />
                              <View style={styles.inputView}>
                                <Text style={styles.inputText}>
                                  Description of stop {stopIndex + 1}*
                                </Text>
                                <Controller
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  render={({ field: { onChange, value } }) => (
                                    <TextInput
                                      style={styles.textAreaInput}
                                      onChangeText={onChange}
                                      multiline={true}
                                      numberOfLines={4}
                                      value={value}
                                      theme={{
                                        roundness: roundness,
                                      }}
                                      mode="outlined"
                                      outlineColor={inputTextColor}
                                      activeOutlineColor={inputTextColor}
                                      placeholder="Enter Description of Trip"
                                    />
                                  )}
                                  name={`stopDescription_${index}_${stopIndex}`}
                                />
                                {`error.locationDescription_${index}_${stopIndex}` && (
                                  <Text
                                    style={styles.inputError}
                                    dataSet={{ media: ids.inputError }}
                                  >
                                    This is required.
                                  </Text>
                                )}
                              </View>
                              <Button
                                mode="text"
                                icon={"minus"}
                                style={styles.addmoreButton}
                                labelStyle={{ fontWeight: "900" }}
                                onPress={() =>
                                  handleDeleteStop(index, stopIndex)
                                }
                              >
                                Delete Stop
                              </Button>
                            </View>
                          </View>
                        ))}
                      </ScrollView>
                    </View>
                    <Button
                      mode="text"
                      icon={"plus"}
                      style={styles.addmoreButton}
                      labelStyle={{ fontWeight: "900" }}
                      onPress={() => handleAddStop(index)}
                    >
                      Add More Stop
                    </Button>
                  </View>
                ))}
              </View>
              <View style={styles.footerContainer}>
                <Button
                  loading={loader}
                  mode="contained"
                  style={{ marginTop: 10 }}
                  buttonColor={primaryColor}
                  onPress={handleSubmit(onSubmitData)}
                >
                  Save
                </Button>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default AddItinerary;
