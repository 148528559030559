import React from "react";
import { View, Text, Platform, ScrollView, Modal } from "react-native";
import { ids, styles } from "./EditItenariesModalCss";
import { useForm, Controller } from "react-hook-form";
import * as DocumentPicker from "expo-document-picker";
import moment from "moment";
import { TextInput, Button, IconButton } from "react-native-paper";
import {
  blackColor,
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../../../constant/constantCss";
import { EditLocationAPI } from "../../../../../service/api/itinerary/api";
import { showToast } from "../../../../../constant/toastMessage";
import { GetItineraryApi } from "../../../../../service/api/trip/itineraryApi";

const EditLocation = ({
  visible,
  handleClose,
  location_id,
  location_name,
  setRefreshAPI,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      location_name: location_name,
    },
  });
  const pickImage = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: ["image/png", "image/webp", "image/jpg", "image/jpeg"],
      });

      if (!result?.canceled) {
        if (Platform.OS === "web") {
          setValue("location_image", result?.assets[0]?.file);
        } else {
          // random name generate
          const currentDate = moment();
          const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
          const imageName = `location_image_${formattedDate}.jpeg`;

          const fileObj = {
            uri: result?.assets?.[0]?.uri,
            type: "image/jpeg",
            name: imageName,
          };
          setValue("location_image", fileObj);
        }
      }
    } catch (error) {
      console.error("Error picking an image:", error);
    }
  };
  const onSubmitLocationData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) {
        formData.append(key, data[key]);
      }
    });
    EditLocationAPI(formData, location_id)
      .then((res) => {
        showToast("success", res.data?.message);
        handleClose();
        setRefreshAPI((prev) => !prev);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        handleClose();
      });
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText} dataSet={{ media: ids.headerText }}>
              Edit Itinerary Location
            </Text>
            <IconButton
              icon={"close"}
              iconColor={blackColor}
              onPress={handleClose}
            />
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Location name</Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      onChangeText={onChange}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter location name"
                    />
                  )}
                  name="location_name"
                />
                {errors.location_name && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors?.location_name?.message}
                  </Text>
                )}
              </View>

              <View style={styles.inputView}>
                <Text style={styles.inputText}>Location Image</Text>
                <IconButton
                  icon="file-upload"
                  iconColor={primaryColor}
                  mode={"contained"}
                  onPress={pickImage}
                />
                <Text style={{ fontSize: 16, color: blackColor }}>
                  {watch("location_image")?.name}
                </Text>
              </View>
              <View style={styles.inputView}>
                <Button
                  mode="contained"
                  buttonColor={primaryColor}
                  textColor={whiteColor}
                  onPress={handleSubmit(onSubmitLocationData)}
                >
                  Save
                </Button>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default EditLocation;
