import React from "react";
import { TextInput } from "react-native-paper";
import { StyleSheet } from "react-native";
import {
  blackColor,
  inputTextColor,
  whiteColor,
} from "../../../../constant/constantCss";

const SearchPermission = ({
  permissionList,
  setpermissionList,
  allPermission,
}) => {
  const styles = StyleSheet.create({
    textInput: {
      marginTop: 20,
      fontSize: 16,
      height: 38,
      borderRadius: 20,
      color: blackColor,
      backgroundColor: whiteColor,
    },
  });
  const handleSearchPermission = (searchVal) => {
    if (searchVal === "") {
      setpermissionList(allPermission);
    } else {
      setpermissionList(
        permissionList?.filter((permission) =>
          permission.name.includes(searchVal)
        )
      );
    }
  };
  return (
    <TextInput
      style={styles.textInput}
      mode="outlined"
      outlineColor={blackColor}
      activeOutlineColor={inputTextColor}
      placeholder="search permission "
      onChangeText={(text) => handleSearchPermission(text)}
    />
  );
};

export default SearchPermission;
