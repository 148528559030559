import React, { useEffect, useMemo, useState } from "react";
import { ids, styles } from "../landingPageCss";
import { Image, ScrollView, Text, View } from "react-native";
import companyReviewImg from "../../../../assets/landingPage/company_review/companyreview.webp";
import commaImg from "../../../../assets/landingPage/company_review/comma.png";
import {
  containerBlackColor,
  primaryColor,
  primaryOrangeColor,
  subTextColor,
  whiteColor,
} from "../../../constant/constantCss";
import { Avatar, Button } from "react-native-paper";
import Ionicons from "react-native-vector-icons/Ionicons";
import CompanyReivewModal from "./CompanyReivewModal";
import { GetCompanyReview } from "../../../service/api/company_review/api";
import { showToast } from "../../../constant/toastMessage";

const CompanyReview = ({ isVisibleAddReview, setIsVisibleAddReview }) => {
  const [companyReview, setCompanyReview] = useState([]);
  useEffect(() => {
    GetCompanyReview()
      .then((res) => {
        setCompanyReview(res?.data);
      })
      .catch((err) => {
        showToast("error", err);
      });
  }, []);
  return (
    <View style={styles.company_review_container}>
      {/* header container */}
      <View style={styles.company_review_header}>
        <Image
          source={companyReviewImg}
          style={styles.icon}
          dataSet={{ media: ids.icon }}
        />
        <Text
          style={[
            styles.preHeaderText,
            {
              textAlign: "center",
            },
          ]}
          dataSet={{ media: ids.preHeaderText }}
        >
          <Text style={{ color: containerBlackColor }}>Client </Text>
          <Text style={{ color: primaryOrangeColor }}>Testimonials</Text>
        </Text>
        <Text style={{ marginBottom: 30, color: subTextColor, fontSize: 16 }}>
          See what our clients are saying about us
        </Text>
      </View>
      {/* Button container */}
      <View style={styles.addReviewBtnContainer}>
        <Button
          icon={"plus"}
          style={styles.addReviewBtn}
          buttonColor={whiteColor}
          textColor={primaryColor}
          onPress={() => setIsVisibleAddReview(true)}
        >
          Write Review
        </Button>
      </View>
      <View>
        <ReviewCard companyReview={companyReview} />
      </View>
      {isVisibleAddReview ? (
        <CompanyReivewModal
          visible={isVisibleAddReview}
          handleClose={() => setIsVisibleAddReview(false)}
        />
      ) : null}
    </View>
  );
};

export const ReviewCard = ({ companyReview }) => {
  const getInitials = (name) => {
    const nameArray = name.split(" ");
    const initials = nameArray
      .map((word) => word[0])
      .join("")
      .toUpperCase();
    return initials;
  };
  const getRandomColor = (text) =>
    useMemo(() => {
      const letters = "0123456789ABCDEF";
      let color = "#";

      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    }, [text]);
  return (
    <ScrollView horizontal={true} style={styles.addReviewscrollBar}>
      {companyReview?.map((item, _index) => (
        <View
          key={_index}
          style={styles.reviewItemContainer}
          dataSet={{ media: ids.reviewItemContainer }}
        >
          <View style={styles.reviewItembackground} />
          <Avatar.Text
            size={46}
            labelStyle={{ fontSize: 18 }}
            label={getInitials(item?.name)}
            style={[
              styles.nameAvtar,
              { backgroundColor: getRandomColor(item?.name) },
            ]}
          />
          <Text
            style={styles.reviewNameText}
            dataSet={{ media: ids.reviewNameText }}
          >
            {item?.name}
          </Text>
          <View style={styles.iconAvtar}>
            {Array.from({ length: 5 }, (_, index) => (
              <Ionicons
                key={index}
                name={item?.rating_value > index ? "star" : "star-outline"}
                color={item?.rating_value > index ? "#ffe234" : "#7e7863"}
                size={18}
              />
            ))}
          </View>
          <Text style={styles.descText} dataSet={{ media: ids.descText }}>
            {item?.message}
          </Text>
          <View style={styles.commaImgContainer}>
            <Image
              source={commaImg}
              resizeMode="contain"
              style={{ height: 16, width: 16 }}
            />
          </View>
        </View>
      ))}
    </ScrollView>
  );
};
export default CompanyReview;
