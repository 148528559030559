import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const CAREER_ENDPOINT = {
  USER_CAREER: BASE_URL + "career/user/",
};

export const UserCareer = async (payload) => {
  return axios.post(CAREER_ENDPOINT.USER_CAREER, payload);
};
