import React, { useRef, useEffect, useState } from "react";
import { View, ScrollView, StyleSheet, Text } from "react-native";
import FeaturedTripCard from "../TripDashboard/TripCard/FeaturedTripCard";

const SectionTrips = ({ data, section_name }) => {
  const scrollViewRef = useRef(null);
  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    if (data && data.length > 0) {
      const intervalId = setInterval(() => {
        if (scrollViewRef.current) {
          scrollViewRef.current.scrollTo({
            x: scrollOffset,
            animated: true,
          });
          const newOffset = scrollOffset + 320;
          if (newOffset > data.length * 320) {
            setScrollOffset(0);
          } else {
            setScrollOffset(newOffset);
          }
        }
      }, 3200);

      return () => clearInterval(intervalId);
    }
  }, [data, scrollOffset]);

  return (
    <View>
      {data && data.length > 0 && (
        <View>
          <Text style={styles.headingText}>
            {section_name}
          </Text>
          <ScrollView
            ref={scrollViewRef}
            horizontal
            showsHorizontalScrollIndicator={false}
            style={styles.container}
          >
            {data?.map((item, index) => (
              <View key={index} style={styles.card}>
                <FeaturedTripCard
                  key={index}
                  id={item.id}
                  title={item.title}
                  address={item.address}
                  is_public={item.is_public}
                  amount={item.amount}
                  image={item.image_url}
                  departure_date={item.departure_date}
                  no_of_days={item.no_of_days}
                  slug_url={item.slug}
                  badge_text={item.badge_text}
                  discount={item.discount}
                />
              </View>
            ))}
          </ScrollView>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  card: {
    width: 300,
    marginRight: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 24,
  },
  headingText: {
    marginTop: 10,
    fontSize: 20,
    fontWeight: "800",
  },
});

export default SectionTrips;
