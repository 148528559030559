import React, { useRef, useEffect, useState } from "react";
import { View, ScrollView, StyleSheet, Text } from "react-native";
import FeaturedTripCard from "../TripDashboard/TripCard/FeaturedTripCard";
import { showToast } from "../../constant/toastMessage";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { GetFilterTripAPI } from "../../service/api/trip/api";
import PlaneLoader from "../../components/Loader";

const PrivateTrips = ({}) => {
  const scrollViewRef = useRef(null);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    GetFilterTripAPI("private")
      .then((res) => {
        setLoader(false);
        AsyncStorage.setItem("offset_private", "0");
        setData(res.data?.data);
      })
      .catch((err) => {
        setLoader(false);
        AsyncStorage.setItem("offset_private", "0");
        showToast("error", err?.response?.data?.detail);
      });
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const intervalId = setInterval(() => {
        AsyncStorage.getItem("offset_private").then((offset) => {
          if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({
              x: parseInt(offset),
              animated: true,
            });
            if (parseInt(offset) + 320 > data.length * 320) {
              AsyncStorage.setItem("offset_private", "0");
            } else {
              AsyncStorage.setItem("offset_private", (parseInt(offset) + 320).toString());
            }
          }
        });
      }, 3200);

      return () => clearInterval(intervalId);
    }
  }, [data]);

  return (
    <View>
      {data.length > 0 && (
        <View>
          <Text style={styles.headingText}>
            Private Trips
          </Text>
          {loader ? (
            <PlaneLoader />
          ) : (
            <ScrollView
              ref={scrollViewRef}
              horizontal
              showsHorizontalScrollIndicator={false}
              style={styles.container}
            >
              {data?.map((item, index) => (
                <View key={index} style={styles.card}>
                  <FeaturedTripCard
                    key={index}
                    id={item.id}
                    title={item.title}
                    address={item.address}
                    is_public={item.is_public}
                    amount={item.amount}
                    image={item.image_url}
                    departure_date={item.departure_date}
                    no_of_days={item.no_of_days}
                    slug_url={item.slug}
                    badge_text={item.badge_text}
                    discount={item.discount}
                  />
                </View>
              ))}
            </ScrollView>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  card: {
    width: 300,
    marginRight: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 24,
  },
  headingText: {
    marginTop: 10,
    fontSize: 20,
    fontWeight: "800",
  },
});

export default PrivateTrips;
