import StyleSheet from "react-native-media-query";
import {
  blackColor,
  inputBackColor,
  inputTextColor,
  whiteColor,
} from "../../constant/constantCss";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    width: 800,
    height: "80%",
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 16,
    alignItems: "center",
    shadowColor: blackColor,
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
    "@media (max-width: 768px)": {
      width: "90%",
      height: "90%",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 16,
  },
  uploadInput: {
    display: "none",
  },
  uploadButton: {
    maxWidth: 350,
    color: whiteColor,
  },
  headerText: {
    fontSize: 22,
    fontWeight: "800",
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "800",
  },

  // Body
  bodyMainContainer: {
    paddingHorizontal: 20,
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "row",
    minWidth: "100%",
    overflow: "auto",
    gap: 24,
    "@media (max-width: 576px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: 0,
      width: "100%",
    },
  },
  leftBodyContainer: {
    flex: 1,
    width: "100%",
  },
  rightBodyContainer: {
    width: "100%",
    "@media (min-width: 577px)": {
      flex: 1,
    },
  },
  inputView: {
    marginTop: 24,
    "@media (max-width: 576px)": {
      marginTop: 16,
      width: "100%",
    },
  },
  inputText: {
    fontSize: 16,
    marginBottom: 4,
    color: "#191919",
  },
  textInput: {
    fontSize: 16,
    height: Platform.OS === "android" ? null : 38,
    width: "100%",
    borderRadius: 10,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  dateTimeInput: {
    fontSize: 16,
    height: 38,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: inputTextColor,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  textAreaInput: {
    fontSize: 16,
    width: "100%",
    borderRadius: 10,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  inputError: {
    color: "#c72810",
    fontWeight: 600,
    paddingBottom: 0,
  },
  flexRowContainer: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    "@media (max-width: 576px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
});
