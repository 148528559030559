import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  ImageBackground,
  ScrollView,
  Platform,
} from "react-native";
import { TextInput, Button, Surface } from "react-native-paper";
import { ids, styles } from "./registerCss.js";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { UserAuthAPI } from "../../service/api/user/UserAuthApi.js";
import {
  blackColor,
  inputTextColor,
  whiteColor,
  primaryColor,
  subTextColor,
} from "../../constant/constantCss.js";
import splashLogo from "../../../assets/splashLogo.png";
import { showToast } from "../../constant/toastMessage.js";
import { useDispatch, useSelector } from "react-redux";
import { setLoginData, setUserData } from "../../store/slices/authSlice.js";
import { GetOtpApi } from "../../service/api/user/OtpApi.js";
import { OneSignal } from "react-native-onesignal";
import useKeyboardHeight from "../../hooks/KeyboardHeight.jsx";
import { GetUserAPI } from "../../service/api/user/getUserApi.js";
import { ActiveItem } from "../../store/slices/generalSlice.js";

const LoginScreen = () => {
  const screenWidth = Dimensions.get("window").width;
  const roundness = screenWidth > 768 ? 40 : 8;
  const [loader, setLoader] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const keyboardHeight = useKeyboardHeight();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: "",
      password: "",
      dialingCode: "+91",
    },
  });
  const handleExternalId = (userId) => {
    try {
      OneSignal.User.addAlias("external_id", userId.toString());
      OneSignal.login(userId.toString());
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = (data) => {
    setLoader(true);
    const formData = {
      phone_number: data.phone,
      password: data.password,
      dialing_code: data.dialingCode.replace(/\+/g, ""),
    };
    UserAuthAPI("login/", formData)
      .then(async (res) => {
        await AsyncStorage.setItem("AuthToken", res?.data?.access);
        handleExternalId(res.data.id);
        dispatch(setLoginData(res?.data));
        const userData = await GetUserAPI();
        dispatch(setUserData(userData.data));
        dispatch(ActiveItem("dashboard"))
        navigation.navigate("dashboard"); 
        showToast("success", "Login successfully");
        setLoader(false);
      })
      .catch((error) => {
        showToast("error", error?.response?.data?.message);

        // After OTP if not verified phone then send SMS
        const isPhoneVerified = error?.response?.data?.is_phone_verified;
        if (isPhoneVerified === "False") {
          const otpData = {
            dialing_code: data.dialingCode.replace(/\+/g, ""),
            phone_number: data.phone,
          };
          GetOtpApi("send/", otpData)
            .then((res) => {
              showToast("success", res.data.data.message);
              otpData.otp = res.data.data.otp;
              navigation.navigate("VerifyOtp", { data: otpData });
            })
            .catch((err) => {
              showToast("error", err?.response?.data?.detail);
            });
        }

        setLoader(false);
      });
  };
  const handleSkipButton = () => {
    navigation.navigate("dashboard");
  };
  const handleSecurityPassword = () => {
    setIsVisiblePassword((prev) => !prev);
  };
  const { backgroundImage } = useSelector((state) => state.general);
  return (
    <React.Fragment>
      <View style={[styles.imageContainer, { marginBottom: keyboardHeight }]}>
        <ImageBackground
          source={backgroundImage}
          style={styles.imageBackground}
        >
          {/* Nab bar */}
          <Surface
            style={styles.navBarContainer}
            dataSet={{ media: ids.navBarContainer }}
          >
            <View style={styles.navbarFlexRow} elevation={1}>
              <Link
                to={{
                  screen: "AboutUs",
                }}
              >
                <Text style={{ fontSize: 18 }}>About Us</Text>
              </Link>
            </View>
          </Surface>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
          >
            <View style={styles.container} dataSet={{ media: ids.container }}>
              <View style={styles.form} dataSet={{ media: ids.form }}>
                <View style={[styles.flexRow, { justifyContent: "center" }]}>
                  <Image
                    style={styles.image}
                    dataSet={{ media: ids.image }}
                    source={splashLogo}
                  />
                </View>
                <View
                  style={styles.formContainer}
                  dataSet={{ media: ids.formContainer }}
                >
                  <View style={styles.flexRow}>
                    <Text style={styles.header} dataSet={{ media: ids.header }}>
                      Login
                    </Text>
                    <Button
                      mode="text"
                      textColor={primaryColor}
                      onPress={handleSkipButton}
                    >
                      Skip
                    </Button>
                  </View>

                  <Text style={styles.details} dataSet={{ media: ids.details }}>
                    Give us your Mobile Number & we will send you one time
                    Password
                  </Text>
                  <View style={styles.inputView}>
                    <Text style={styles.text} dataSet={{ media: ids.text }}>
                      Mobile Number
                    </Text>
                    <View style={[styles.phoneContainer]}>
                      <Controller
                        control={control}
                        rules={{
                          required: "coutry code is required",
                          pattern: {
                            value: /^\+[1-9]\d{0,3}$/,
                            message: "Please enter a valid country code",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            style={[styles.textInput, styles.pin]}
                            onChangeText={onChange}
                            value={value}
                            mode="outlined"
                            outlineColor={inputTextColor}
                            activeOutlineColor={inputTextColor}
                            maxLength={4}
                            theme={{
                              roundness: roundness,
                            }}
                            contentStyle={{ color: blackColor }}
                          />
                        )}
                        name="dialingCode"
                      />
                      <Controller
                        control={control}
                        rules={{
                          required: "Mobile No. is required",
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message:
                              "Please enter a valid 10-digit phone number",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            style={[
                              styles.textInput,
                              styles.phoneField,
                              { color: "red" },
                            ]}
                            onChangeText={onChange}
                            defaultValue={value}
                            mode="outlined"
                            outlineColor={inputTextColor}
                            activeOutlineColor={inputTextColor}
                            maxLength={10}
                            contentStyle={{ color: blackColor }}
                            theme={{
                              roundness: roundness,
                            }}
                          />
                        )}
                        name="phone"
                      />
                    </View>
                    {errors.dialingCode && errors.phone ? (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        Mobile Number and Dialing Code is required.
                      </Text>
                    ) : errors.dialingCode ? (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        {errors.dialingCode?.message}
                      </Text>
                    ) : errors.phone ? (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        {errors?.phone?.message}
                      </Text>
                    ) : null}
                  </View>
                  <View style={styles.inputView}>
                    <Text style={styles.text} dataSet={{ media: ids.text }}>
                      Password
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: "Password is required",
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/,
                          message:
                            "Password must include at least one uppercase letter, one lowercase letter, and one number",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          right={
                            <TextInput.Icon
                              size={18}
                              icon={!isVisiblePassword ? "eye" : "eye-off"}
                              color={subTextColor}
                              onPress={handleSecurityPassword}
                            />
                          }
                          style={styles.textInput}
                          onChangeText={onChange}
                          defaultValue={value}
                          theme={{
                            roundness: roundness,
                          }}
                          mode="outlined"
                          outlineColor={inputTextColor}
                          activeOutlineColor={inputTextColor}
                          secureTextEntry={!isVisiblePassword}
                          contentStyle={{ color: blackColor }}
                          // added to enter to login 
                          onSubmitEditing={handleSubmit(onSubmit)}
                        />
                      )}
                      name="password"
                    />
                    {errors.password && (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        {errors.password.message}
                      </Text>
                    )}
                  </View>
                  <View style={[styles.bottomContainer, { marginTop: 20 }]}>
                    <Text
                      style={styles.bottomText}
                      dataSet={{ media: ids.bottomText }}
                    >
                      Don't have an account?
                    </Text>
                    <View style={styles.linkContainer}>
                      <Link
                        to={{ screen: "Register" }}
                        style={styles.bottomLinks}
                        dataSet={{ media: ids.bottomLinks }}
                      >
                        Register
                      </Link>
                      <Link
                        to={{ screen: "ForgotPassword" }}
                        style={styles.bottomLinks}
                        dataSet={{ media: ids.bottomLinks }}
                      >
                        Forgot password
                      </Link>
                    </View>
                  </View>
                </View>
                <View style={styles.buttonContainer}>
                  <Button
                    style={styles.button}
                    dataSet={{ media: ids.button }}
                    loading={loader}
                    mode="outlined"
                    textColor={whiteColor}
                    onPress={handleSubmit(onSubmit)}
                  >
                    <Text>Login</Text>
                  </Button>
                </View>
              </View>
            </View>
          </ScrollView>
        </ImageBackground>
      </View>
    </React.Fragment>
  );
};

export default LoginScreen;
