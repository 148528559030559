// color
export const primaryColor = "#B01D32";
export const sidebarColor = "#F4C0C0";
export const appBarColor = "#F1F1F1";
export const blackColor = "#000000";
export const whiteColor = "#FFFFFF";
export const subTextColor = "#555555";
export const iconColor = "#F98306";

export const privateTripColor = "#D7E6FC";
export const publicTripColor = "#D7FCE1";
export const tripBackgrColor = "#f8f9fa";
export const inputTextColor = "#D3D3D3";
export const inputBackColor = "#F3F3F3";
export const greyBackColor = "#F6F6F6";
export const lightBlueColor = "#D8ECFF";
export const blueColor = "#1890FF";
export const errorTextColor = "#db281a";

// message color
export const SuccessBoldColor = "#37c37a";
export const SuccessColor = "#c6f7dd";
export const WarningBoldColor = "#e9a024";
export const WarningColor = "#ffe9c4";
export const InfoBoldColor = "#3a85e6";
export const InfoColor = "#cee3ff";
export const ErrorBoldColor = "#ea594b";
export const ErrorColor = "#ffd0cc";

// trip details background color
export const TripDetailBackColor = "#FDF0F0";
export const TripItineraryColor = "#FFDFCB";
export const TripItineraryButtonColor = "#FF7E2F";

// landing page coloe :
export const primaryRedColor = "#B01D32";
export const primaryOrangeColor = "#FF7E2F";
export const containerBackColor = "#F9F9F9";
export const containerTextColor = "#535353";
export const containerBlackColor = "#191919";

// font size
export const headerText = 22;
export const subText = 18;
export const smallText = 16;

export const font20 = 20;
export const font24 = 24;
