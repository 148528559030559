import React from "react";
import { View, Text, Modal } from "react-native";
import { ids, styles } from "./AddSchoolModalCss";
import { Avatar, Button, IconButton } from "react-native-paper";
import { primaryColor, whiteColor } from "../../../constant/constantCss";
import schoolImg from "../../../../assets/DefaultImages/Defaultschool.webp";
import { useNavigation } from "@react-navigation/native";

const SchoolDetailsModal = ({
  visible,
  handleClose,
  id,
  name,
  address,
  city,
  area,
  image,
  phone_num,
  school_admin,
}) => {
  const navigation = useNavigation();
  const handleCheckList = (organizationID) => {
    navigation.navigate("Organization_Profile", { data: organizationID });
    handleClose();
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.detailsHeaderContainer}>
            <IconButton
              icon="close"
              iconColor={whiteColor}
              style={{ position: "absolute", right: 0 }}
              onPress={handleClose}
            />
            <Avatar.Image
              size={80}
              source={image ? { uri: image } : schoolImg}
              style={styles.img}
            />
          </View>
          {/* body view */}
          <View style={[styles.bodyContainer, { marginTop: 40 }]}>
            <View style={styles.flexRow}>
              <Text style={styles.titleText}>Name : </Text>
              <Text style={styles.inputText}>{name}</Text>
            </View>
            <View style={styles.flexRow}>
              <Text style={styles.titleText}>Address : </Text>
              <Text style={styles.inputText}>
                {area ?? ""}
                {area && ", "}
                {city ?? "-"}
              </Text>
            </View>
            <View style={styles.flexRow}>
              <Text style={styles.titleText}>Phone no : </Text>
              <Text style={styles.inputText}>{phone_num}</Text>
            </View>
            <View style={styles.flexRow}>
              <Text style={styles.titleText}>Admin : </Text>
              <Text style={styles.inputText}>
                {school_admin?.first_name ?? "N/A"}
              </Text>
            </View>
            <View style={styles.footerContainer}>
              <View style={[styles.flexRow, { justifyContent: "center" }]}>
                <Button
                  icon={"eye"}
                  mode={"outline"}
                  textColor={whiteColor}
                  buttonColor={primaryColor}
                  onPress={() => handleCheckList(id)}
                >
                  View Profile
                </Button>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default SchoolDetailsModal;
