import React, { useEffect, useState, useCallback } from "react";
import {
  View,
  Text,
  Dimensions,
} from "react-native";
import { ids, styles } from "./TripDashboardCss";
import { Button,IconButton } from "react-native-paper";
import TripCard from "./TripCard/TripCard";
import { GetFilterTripAPI } from "../../service/api/trip/api";
import { CreateTripModal } from "./CreateTripModal/CreateTripModal";
import NotFoundTripCard from "./TripCard/NotFoundTripCard";
import SearchTrip from "./SearchTrip/SearchTrip";
import {
  primaryColor,
  subTextColor,
} from "../../constant/constantCss";
import { showToast } from "../../constant/toastMessage";
import { useSelector } from "react-redux";
import {
  PERMISSION,
} from "../../constant/getAllPermission";
import TripFilterModal from "./TripFilterModal/TripFilterModal";
import PlaneLoader from "../../components/Loader";
import { ManageSectionModal } from "./ManageSectionModal/ManageSectionModal";
import { styles as AllTripStyles } from "../Trip/AllTripCss";

const AllTripComponent = ({ refresh }) => {
  const [upComingTrip, setUpComingTrip] = useState([]);
  const [loader, setloader] = useState(false);
  const [isVisibleCreateModal, setisVisibleCreateModal] = useState(false);
  const [isVisibleManageSection, setIsVisibleManageSection] = useState(false);
  const [isVisibleFilterModal, setIsVisibleFilterModal] = useState(false);
  const { permission } = useSelector((state) => state.auth);
  const initialCallingAPI = () => {
    setloader(true);
    GetFilterTripAPI("all")
      .then((res) => {
        setUpComingTrip(res.data?.data);
        setloader(false);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        setloader(false);
      });
  };
  useEffect(() => {
    initialCallingAPI();
  }, [refresh]);

  const handleInvisibleModal = () => {
    setisVisibleCreateModal(false);
    GetFilterTripAPI("all")
      .then((res) => {
        setUpComingTrip(res.data?.data || []);
        setloader(false);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        setloader(false);
      });
  };

  const handleInvisibleModalSection = () => {
    setIsVisibleManageSection(false);
    GetFilterTripAPI("all")
      .then((res) => {
        setUpComingTrip(res.data?.data || []);
        setloader(false);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        setloader(false);
      });
  };

  return (
    <>
        <Text style={AllTripStyles.headingText}>All Trips</Text>
          <View
            style={styles.containerFlex}
            dataSet={{ media: ids.containerFlex }}
          >
            <View style={styles.flexRow}>
              <SearchTrip
                setTripDetails={setUpComingTrip}
                filterValue={"all"}
              />
              {Dimensions.get("screen").width >= 480 ? 
            <Button
              mode="outlined"
              icon={"filter"}
              textColor={subTextColor}
              style={{
                borderRadius: 10,
                border: "1px solid",
                borderRadius: 5,
                height: 37,
                paddingVertical: 0,
                marginRight: 9,
              }}
              onPress={() => {
                setIsVisibleFilterModal(true);
              }}
            >
              Filter
            </Button> : 
            <IconButton
              icon="filter"
              textColor={subTextColor}
              mode="outlined"
              style={{
                borderRadius: 10,
                border: "1px solid",
                borderRadius: 5,
                height: 37,
                paddingVertical: 0,
                marginRight: 9,
              }}
              size={20}
              onPress={() => {
                setIsVisibleFilterModal(true);
              }}
            />}
            </View>
            {permission && permission?.includes(PERMISSION.CAN_CREATE_TRIP) && (
              <Button
                mode="contained"
                buttonColor={primaryColor}
                icon={"plus"}
                onPress={() => setisVisibleCreateModal(!isVisibleCreateModal)}
              >
                Create Trip
              </Button>
            )}
          </View>
          <View
            style={styles.CardFlexContainer}
            dataSet={{ media: ids.CardFlexContainer }}
          >
            {loader ? (
              <PlaneLoader />
            ) : upComingTrip?.length > 0 ? (
              upComingTrip?.map((item, index) => (
                <TripCard
                  key={index}
                  id={item.id}
                  title={item.title}
                  address={item.address}
                  is_public={item.is_public}
                  amount={item.amount}
                  image={item.image_url}
                  departure_date={item.departure_date}
                  no_of_days={item.no_of_days}
                  slug_url={item.slug}
                  badge_text={item.badge_text}
                  discount={item.discount}
                />
              ))
            ) : (
              <NotFoundTripCard />
            )}
          </View>
      {isVisibleCreateModal && (
        <CreateTripModal
          visible={isVisibleCreateModal}
          handleClose={handleInvisibleModal}
        />
      )}
      {isVisibleManageSection && (
        <ManageSectionModal
          visible={isVisibleManageSection}
          handleClose={handleInvisibleModalSection}
        />
      )}
      {isVisibleFilterModal && (
        <TripFilterModal
          visible={isVisibleFilterModal}
          setTripDetails={setUpComingTrip}
          filterValue={"all"}
          handleClose={() => setIsVisibleFilterModal(false)}
        />
      )} 
    </>
  );
};
export default AllTripComponent;
