import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const TRIP_INQUIRY_ENDPOINT = {
  INQUIRY_BASE_URL: BASE_URL + "inquiry/",
};

export const CreateTripInquiryFormAPI = async (payload) => {
  return axios.post(
    TRIP_INQUIRY_ENDPOINT.INQUIRY_BASE_URL + "personalized_trip/",
    payload
  );
};
