import React, { useEffect } from "react";
import { Platform,TouchableOpacity } from "react-native";
import AppDrawer from "./Drawer";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { ActiveItem, setIsDrawerVisible } from "../../store/slices/generalSlice";
import MaterialIcons from "react-native-vector-icons/MaterialIcons"
import { useRoute } from "@react-navigation/native";

const Layout = () => {
  const navigation = useNavigation();
  const activeItem = useSelector(state => state.general.ActiveItem)
  const dispatch = useDispatch();
  const navigateToScreen = (screen) => {
    dispatch(ActiveItem(screen));
    navigation.navigate(screen);
    if (Platform.OS === "android" || Platform.OS === "ios") {
      dispatch(setIsDrawerVisible(false));
    }
  };
  
  return ( 
    <AppDrawer activeItem={activeItem} navigateToScreen={navigateToScreen} />
  );
};

export default Layout;
