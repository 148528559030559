import React, { useState } from "react";
import { Dimensions, Image, Platform, View } from "react-native";
import {
  appBarColor,
  primaryColor,
  whiteColor,
} from "../../constant/constantCss";
import iconLogo from "../../../assets/splashLogo.png";
import phonelogo from "../../../assets/appIcon/logo512.png";
import { useNavigation } from "@react-navigation/native";
import { Appbar, Button, TouchableRipple } from "react-native-paper";

const PublicAppBar = () => {
  const navigation = useNavigation();
  const { width } = Dimensions.get("window");
  const handleOnLoginButton = () => {
    navigation.navigate("Login");
  };
  return (
    <View
      style={{
        width: "100%",
        borderBottomWidth: 1,
        borderBottomColor: "#D8D8D8",
        position: "sticky",
        top: 0,
        zIndex: 10,
      }}
    >
      <Appbar.Header style={{ backgroundColor: whiteColor }}>
        <Appbar.Content
          title={
            <TouchableRipple
            style={width >= 480 ?{ height: 50, width: 150 } :{ height: 50, width: 100,marginRight:80}  }
              onPress={() => navigation.navigate("dashboard")}
              rippleColor={"transparent"}
            >
              {Platform.OS === "web" ? 
                <Image 
                resizeMode="contain"
                source={iconLogo}
                alt="Maroon Migrate"
                style={width >= 768 ?{ height: 50, width: 150 } :{ height: 50, width: 100 }  }
              /> : 
              <Image 
              resizeMode="contain"
              source={phonelogo}
              alt="Maroon Migrate"
              style={[width >= 768 ?{ height: 50, width: 150 } :{ height: 50, width: 100 } ,{marginLeft:0} ]}
            />
              }
              
            </TouchableRipple>
          }
        />
        {width >= 768 && (
          <Button
            mode="text"
            textColor={whiteColor}
            buttonColor={primaryColor}
            onPress={() => navigation.navigate("personalized")}
            labelStyle={{ fontSize: 16 }}
            style={{ marginRight: 16 }}
          >
            Want a Personalized Trip?
          </Button>
        )}
        <Button
          mode="text"
          textColor={primaryColor}
          labelStyle={{ fontSize: 16 }}
          style={{ marginRight: 16 }}
          onPress={() => {
            navigation.navigate("AboutUs");
          }}
        >
          About Us
        </Button>
        <Button
          mode="text"
          icon={"login"}
          onPress={handleOnLoginButton}
          iconColor={primaryColor}
          textColor={primaryColor}
        >
          Login
        </Button>
      </Appbar.Header>
    </View>
  );
};

export default PublicAppBar;
