import React, { useState } from "react";
import {
  Text,
  Pressable,
  View,
  Platform,
  ScrollView,
  Modal,
} from "react-native";
import { Controller, useForm } from "react-hook-form";
import { useRef } from "react";
import { Button, IconButton, TextInput } from "react-native-paper";
import AntDesign from "react-native-vector-icons/AntDesign";
import { ids, styles } from "./AlbumsCss.js";
import {
  AddAlbumsApi,
  GetAlbumsApi,
} from "../../../../../service/api/trip/albums.js";
import {
  blackColor,
  inputTextColor,
  errorTextColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../../../constant/constantCss.js";
import * as ImagePicker from "expo-image-picker";
import moment from "moment";
import { showToast } from "../../../../../constant/toastMessage.js";

const AddAlbum = ({ tripId, visible, setRefreshAPI, setVisible }) => {
  const fileInputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      location: "",
      trip: tripId,
    },
  });
  const pickImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      console.error("Permission to access photo library denied");
      return;
    }
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result?.canceled) {
        // random name generate
        const currentDate = moment();
        const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
        const imageName = `album_${formattedDate}.jpeg`;

        const fileObj = {
          uri: result?.assets?.[0]?.uri,
          type: "image/jpeg",
          name: imageName,
        };
        setValue("album_url", fileObj);
      }
    } catch (error) {
      console.error("Error picking an image:", error);
    }
  };
  const onSubmit = (data) => {
    setLoader(true);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    AddAlbumsApi(formData)
      .then((res) => {
        setLoader(false);
        showToast("success", "album added successfully");
        setVisible(false);
        // Again fetch the album details
        setRefreshAPI((prev) => !prev);
      })
      .catch((error) => {
        setLoader(false);
        showToast("error", "Something went wrong!");
      });
  };
  return (
    <Modal
      visible={visible}
      onDismiss={() => setVisible(false)}
      animationType="slide"
      transparent={true}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          <View style={styles.headerContainer}>
            <Text
              style={{
                fontWeight: 700,
                fontSize: 20,
                marginBottom: 20,
                color: blackColor,
              }}
            >
              Upload Album
              {"\n"}
              <Text
                style={{ fontSize: 12, fontWeight: 600, color: subTextColor }}
              >
                Upload photo of location for better Identification
              </Text>
            </Text>
            <Pressable onPress={() => setVisible(false)}>
              <AntDesign style={styles.closeIcon} name="close" size={20} />
            </Pressable>
          </View>
          <View>
            <ScrollView centerContent={true}>
              <View style={{ marginBottom: 10 }}>
                <Text style={{ color: "black" }}>Location*</Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textInput}
                      onChangeText={onChange}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                    />
                  )}
                  name="location"
                />
                {errors.location && (
                  <Text style={{ color: errorTextColor }}>
                    This is required.
                  </Text>
                )}
              </View>
              <View style={{ marginBottom: 10, flexDirection: "row" }}>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ color: "black" }}>Select Album*</Text>
                </View>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      {
                        <>
                          {Platform.OS === "web" ? (
                            <>
                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{
                                  color: inputTextColor,
                                  display: "none",
                                }}
                                onChange={(e) =>
                                  setValue("album_url", e.target.files[0])
                                }
                              />
                              <IconButton
                                icon="file-upload"
                                iconColor={primaryColor}
                                mode={"contained"}
                                style={styles.uploadButton}
                                onPress={() => {
                                  if (fileInputRef.current) {
                                    fileInputRef.current.click();
                                  }
                                }}
                              ></IconButton>
                              <Text
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {getValues("album_url")?.name}
                              </Text>
                            </>
                          ) : (
                            <IconButton
                              icon="file-upload"
                              iconColor={primaryColor}
                              mode={"contained"}
                              style={{ maxWidth: 350, color: whiteColor }}
                              onPress={pickImage}
                            ></IconButton>
                          )}
                        </>
                      }
                    </>
                  )}
                  name="album_url"
                />
                {errors.album_url && (
                  <Text style={{ color: errorTextColor }}>
                    This is required.
                  </Text>
                )}
              </View>

              <Button
                mode="contained"
                style={{ marginTop: 40 }}
                buttonColor={primaryColor}
                loading={loader}
                textColor="white"
                onPress={handleSubmit(onSubmit)}
              >
                Upload
              </Button>
            </ScrollView>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default AddAlbum;
