import React from "react";
import TripCard from "../../../TripDashboard/TripCard/TripCard";
import { Text, View } from "react-native";
import { ids, styles } from "../ProfileCss";

const OrganizationTrip = ({ tripList }) => {
  return (
    <View
      style={styles.CardFlexContainer}
      dataSet={{ media: ids.CardFlexContainer }}
    >
      {tripList?.length > 0 ? (
        tripList?.map((item, index) => (
          <TripCard
            key={index}
            id={item.id}
            title={item.title}
            address={item.address}
            is_public={item.is_public}
            amount={item.amount}
            image={item.image_url}
            departure_date={item.departure_date}
            no_of_days={item.no_of_days}
            slug_url={item.slug}
            badge_text={item.badge_text}
            discount={item.discount}
          />
        ))
      ) : (
        <Text style={{ fontSize: 20 }}>No Trip Found!</Text>
      )}
    </View>
  );
};

export default OrganizationTrip;
