import Toast, { BaseToast, ErrorToast } from "react-native-toast-message";
import {
  ErrorBoldColor,
  ErrorColor,
  SuccessBoldColor,
  SuccessColor,
  blackColor,
  subTextColor,
} from "./constantCss";

export const showToast = (type, str1, str2) => {
  Toast.show({
    type: type,
    text1: str1 ?? "Something went wrong !!",
    text2: str2,
    position: "top", // Adjust the position as needed
  });
};

export const toastConfig = {
  success: (props) => (
    <BaseToast
      {...props}
      style={{
        borderLeftColor: SuccessBoldColor,
        backgroundColor: SuccessColor,
      }}
      contentContainerStyle={{ paddingHorizontal: 12 }}
      text1Style={{
        fontSize: 14,
        fontWeight: "600",
        color: blackColor,
      }}
      text2Style={{
        fontSize: 12,
        fontWeight: "400",
        color: subTextColor,
      }}
    />
  ),
  error: (props) => (
    <ErrorToast
      {...props}
      style={{
        borderLeftColor: ErrorBoldColor,
        backgroundColor: ErrorColor,
      }}
      text1Style={{
        fontSize: 14,
        fontWeight: "600",
        color: blackColor,
      }}
      contentContainerStyle={{ paddingHorizontal: 12 }}
      text2Style={{
        fontSize: 12,
        fontWeight: "400",
        color: subTextColor,
      }}
    />
  ),
};
