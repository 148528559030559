import React, { useState } from "react";
import { View, Text, Modal, ScrollView, Dimensions } from "react-native";
import { Button, TextInput } from "react-native-paper";
import { ids, styles } from "./PermissionCss";
import SelectPermissionModal from "./SelectPermissionModal";
import { showToast } from "../../../constant/toastMessage";
import { primaryColor } from "../../../constant/constantCss";

const CreateRoleModal = ({ visible, handleClose }) => {
  const [isVisiblePermissionModal, setIsVisiblePermissionModal] =
    useState(false);
  const screenWidth = Dimensions.get("window").width;
  const roundness = screenWidth > 768 ? 20 : 8;
  const [formData, setFormData] = useState({
    name: null,
    description: null,
    permission: [],
  });
  const handleSubmit = () => {
    if (formData.name !== null) {
      setIsVisiblePermissionModal(true);
    } else {
      showToast("error", "Role name is required field!");
    }
  };
  return (
    <>
      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={handleClose}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
            {/* Header */}
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>
                <Button icon="arrow-left" onPress={handleClose}></Button>
                Create a new role
              </Text>
              <Text style={styles.closeButton} onPress={handleClose}>
                X
              </Text>
            </View>
            {/* Content */}
            <ScrollView style={{ width: "100%" }}>
              <View
                style={styles.bodyContainer}
                dataSet={{ media: ids.bodyContainer }}
              >
                <View style={styles.inputView}>
                  <Text style={styles.inputText}> Role Name*</Text>
                  <TextInput
                    style={styles.textInput}
                    dataSet={{ media: ids.textInput }}
                    onChangeText={(text) => {
                      setFormData({ ...formData, name: text });
                    }}
                    theme={{
                      roundness: roundness,
                    }}
                    mode="outlined"
                    outlineColor="#D3D3D3"
                    activeOutlineColor="#D3D3D3"
                    placeholder="Enter role name"
                  />
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Role Description</Text>
                  <TextInput
                    style={styles.textInput}
                    dataSet={{ media: ids.textInput }}
                    onChangeText={(text) => {
                      setFormData({ ...formData, description: text });
                    }}
                    theme={{
                      roundness: roundness,
                    }}
                    mode="outlined"
                    outlineColor="#D3D3D3"
                    activeOutlineColor="#D3D3D3"
                    placeholder="Enter description of role"
                  />
                  <View style={styles.inputView}></View>
                  <Button
                    mode="contained"
                    buttonColor={primaryColor}
                    style={{ maxWidth: 250 }}
                    onPress={handleSubmit}
                  >
                    Proceed
                  </Button>
                </View>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
      {isVisiblePermissionModal && (
        <SelectPermissionModal
          formData={formData}
          setFormData={setFormData}
          visible={isVisiblePermissionModal}
          handleClose={() => setIsVisiblePermissionModal(false)}
          closeCreateRoleModal={handleClose}
        />
      )}
    </>
  );
};

export default CreateRoleModal;
