import React from "react";
import { Text, View, Platform } from "react-native";
import { Button, Dialog } from "react-native-paper";
import { TripDetailBackColor, primaryColor } from "../../constant/constantCss";

const DescriptionDialog = ({ visibleDialog, setVisibleDialog }) => {
  return (
    <Dialog
      style={{
        backgroundColor: TripDetailBackColor,
        borderRadius: 10,
        ...(Platform.OS === "web" && {
          width: 320,
          top: "-20%",
          left: "50%",
          transform: [{ translateX: "-50%" }, { translateY: "-50%" }],
        }),
      }}
      visible={visibleDialog.visible}
      onDismiss={() => setVisibleDialog({ ...visibleDialog, visible: false })}
    >
      <Dialog.Content>
        <Text style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>
          Description
        </Text>
        <View style={{ width: 280, overflow: "visible" }}>
          <Text style={{ fontSize: 16, fontWeight: 700, marginBottom: 10 }}>
            {visibleDialog.permission?.replaceAll("_", " ")}
          </Text>
        </View>
        <View style={{ width: 280, overflow: "visible" }}>
          <Text style={{ fontSize: 16 }}>{visibleDialog.description}</Text>
        </View>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          textColor={primaryColor}
          onPress={() => {
            setVisibleDialog({ ...visibleDialog, visible: false });
          }}
        >
          Close
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default DescriptionDialog;
