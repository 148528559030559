import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  ImageBackground,
  Platform,
  ScrollView,
} from "react-native";
import { Button } from "react-native-paper";
import { ids, styles } from "./VerificationCss.js";
import { useNavigation } from "@react-navigation/native";
import { whiteColor } from "../../../../constant/constantCss.js";
import logo from "../../../../../assets/logo.png";
import { useSelector } from "react-redux";

const Verification = () => {
  const { backgroundImage } = useSelector((state) => state.general);
  const [loader, setLoader] = useState(false);
  const navigation = useNavigation();
  const navigateToLogin = () => {
    setLoader(true);
    navigation.navigate("Login");
  };

  return (
    <React.Fragment>
      <View style={styles.imageContainer}>
        <ImageBackground source={backgroundImage} style={styles.imageBackground}>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
          >
            <View style={styles.container} dataSet={{ media: ids.container }}>
              <Image style={styles.image} source={logo} />
              <View style={styles.form} dataSet={{ media: ids.form }}>
                <View
                  style={styles.formContainer}
                  dataSet={{ media: ids.formContainer }}
                >
                  <Text style={styles.header}>Verification</Text>
                  <Text style={styles.details} dataSet={{ media: ids.details }}>
                    Your Account has been sent for verification request. You
                    will be Notified once your account is approved
                  </Text>
                </View>
                <View style={styles.buttonContainer}>
                  <Button
                    style={styles.button}
                    dataSet={{ media: ids.button }}
                    loading={loader}
                    mode="outlined"
                    textColor={whiteColor}
                    onPress={navigateToLogin}
                  >
                    <Text>Login</Text>
                  </Button>
                </View>
              </View>
            </View>
          </ScrollView>
        </ImageBackground>
      </View>
    </React.Fragment>
  );
};

export default Verification;
