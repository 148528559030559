import StyleSheet from "react-native-media-query";
import {
  blackColor,
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  imageContainer: {
    flex: 1,
    flexDirection: "column",
  },
  imageBackground: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    rowGap: 65,
    margin: 20,
    marginVertical: 60,
    "@media (max-width: 480px)": {
      margin: "0",
    },
  },
  image: {
    resizeMode: "contain",
    width: 180,
    height: 100,
  },
  form: {
    width: "100%",
    margin: "auto",
    backgroundColor: whiteColor,
    width: "30%",
    borderWidth: 1,
    borderColor: inputTextColor,
    borderRadius: 40,
    padding: 35,
    "@media (max-width: 1068px)": {
      width: "50%",
    },
    "@media (max-width: 768px)": {
      borderWidth: 0,
      marginLeft: 20,
      marginRight: 20,
      backgroundColor: "transparent",
      width: "80%",
    },
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  formContainer: {
    borderBottomStartRadius: 40,
    borderBottomEndRadius: 40,
    "@media (max-width: 768px)": {
      paddingTop: 64,
      paddingBottom: 64,
      paddingLeft: 40,
      paddingRight: 40,
    },
    "@media (max-width: 480px) ": {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  header: {
    fontSize: 26,
    color: blackColor,
    fontWeight: "bold",
    textAlign: "center",
  },
  details: {
    marginTop: 10,
    fontWeight: 500,
    fontSize: 14,
    color: blackColor,
    textAlign: "center",
    lineHeight: 23,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginTop: 40,
    borderColor: primaryColor,
    backgroundColor: whiteColor,
    width: "100%",
    borderWidth: 1,
    backgroundColor: primaryColor,
    "@media (max-width: 768px)": {
      width: "45%",
    },
  },
});
