import React, { useEffect, useState } from "react";
import { Dimensions, Image, TouchableOpacity,Platform } from "react-native";
import { Appbar, Avatar, Button, IconButton } from "react-native-paper";
import iconLogo from "../../../assets/splashLogo.png";
import phonelogo from "../../../assets/appIcon/logo512.png";
import { useDispatch, useSelector } from "react-redux";
import { ActiveItem, setIsDrawerVisible } from "../../store/slices/generalSlice";
import {  View } from "react-native";
import {
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../constant/constantCss";
import { useNavigation } from "@react-navigation/core";
import PersonalizedTripModal from "../Personalized Trip/PersonalizedTripModal";
import Layout from "./Layout";
const AppBar = () => {
  const dispatch = useDispatch();
  const { width, height } = Dimensions.get("window");
  const adjustwidth = width - 1;
  const { isDrawerVisible } = useSelector((state) => state.general);
  const { first_name, profile_url } = useSelector((state) => state.auth);
  const navigation = useNavigation();
  
  return (
    <View
      style={{
        width: adjustwidth,
        borderBottomWidth: 1,
        borderBottomColor: "#D8D8D8",
        position: "sticky",
        top: 0,
        zIndex: 10,
        marginLeft: 1,
      }}
    >
      <Appbar.Header style={{ backgroundColor: whiteColor,
          gap:  width > 400 ? 12 : 0
        }}>
        <Appbar.Action
          iconColor={subTextColor}
          icon={isDrawerVisible ? "menu-open" : "menu"}
          onPress={() => dispatch(setIsDrawerVisible(!isDrawerVisible))}
        />
        <Appbar.Content
          title={
            !isDrawerVisible ? (
              // changing the navigation
              <TouchableOpacity onPress={() => {
                navigation.navigate("dashboard");
                dispatch(ActiveItem("dashboard"))
              }}
              style={{width: "auto"}}
              >
                {Platform.OS === "web" ? 
                  <Image
                    resizeMode="contain"
                    source={iconLogo}
                    alt="Maroon Migrate"
                    style={{ height: 50, width: 100 }}
                  />
                  : 
                  <Image
                    resizeMode="contain"
                    source={phonelogo}
                    alt="Maroon Migrate"
                    style={{ height: 50, width: 100,marginLeft:-30 }}
                  />
                }
              </TouchableOpacity>
            ) : null
          }
        />
        {width >= 768 && (
          <Button
            mode="text"
            textColor={whiteColor}
            buttonColor={primaryColor}
            onPress={() => {
              navigation.navigate("personalized");
            }}
            labelStyle={{ fontSize: 16 }}
          >
            Want a Personalized Trip?
          </Button>
        )}
        <Button
          mode="text"
          textColor={primaryColor}
          labelStyle={{ fontSize: 16 }}
          onPress={() => {
            navigation.navigate("AboutUs");
          }}
        >
          About Us
        </Button>
        <IconButton
          icon={"bell"}
          onPress={() => navigation.navigate("Notification")}
          animated={true}
          iconColor={subTextColor}
        />
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("profile");
            dispatch(ActiveItem("profile"));
          }}
        >
          {profile_url ? (
            <Avatar.Image
              size={40}
              source={{ uri: profile_url }}
              style={{ backgroundColor: primaryColor }}
            />
          ) : (
            <Avatar.Text
              size={40}
              label={first_name?.split(" ")[0][0]}
              style={{ backgroundColor: primaryColor }}
            />
          )}
        </TouchableOpacity>
      </Appbar.Header>
      <Layout />
    </View>
  );
};
export default AppBar;
