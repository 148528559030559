import React, { useState } from "react";
import { Pressable, View } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { TextInput } from "react-native-paper";
import AntDesign from "react-native-vector-icons/AntDesign";
import { ids, styles } from "./DatePickerCss";
import { inputTextColor } from "../../constant/constantCss";
import moment from "moment";

const DatePicker = ({ inputString, mode, dateTime, setDateTime }) => {
  const [date, setDate] = useState(inputString);
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const handleConfirm = (date) => {
    if (mode == "time") {
      setDate(date?.toLocaleTimeString());
      setDateTime(moment(date).format("HH:mm:ss"));
    } else if (mode == "date") {
      hideDatePicker();
      const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with leading zero if needed
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (+1 because months are zero-indexed) and pad with leading zero if needed
      const year = date.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      setDate(formattedDate);
      setDateTime(formattedDate)
    } else {
      const dateStr = date?.toString();
      const dateObj = new Date(dateStr);

      const year = dateObj?.getFullYear();
      const month = ("0" + (dateObj?.getMonth() + 1)).slice(-2);
      const day = ("0" + dateObj?.getDate()).slice(-2);

      const hours = ("0" + dateObj?.getHours()).slice(-2);
      const minutes = ("0" + dateObj?.getMinutes()).slice(-2);
      const seconds = ("0" + dateObj?.getSeconds()).slice(-2);
      const isoDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

      setDate(date?.toString());
      setDateTime(isoDate);
      hideDatePicker();
    }
  };

  return (
    <View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TextInput
          style={styles.textAreaInput}
          value={date}
          theme={{
            roundness: 8,
          }}
          mode="outlined"
          outlineColor={inputTextColor}
          activeOutlineColor={inputTextColor}
          placeholder="Enter Description of Trip"
        />
        <Pressable onPress={showDatePicker}>
          <AntDesign style={styles.dateIcon} name="calendar" size={30} />
        </Pressable>
      </View>
      <DateTimePickerModal
        isVisible={isDatePickerVisible}
        mode={mode}
        onConfirm={handleConfirm}
        onCancel={hideDatePicker}
      />
    </View>
  );
};

export default DatePicker;
