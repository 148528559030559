import StyleSheet from "react-native-media-query";
import { whiteColor } from "../../constant/constantCss";
import { Dimensions } from "react-native";

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: whiteColor,
  },
  subContainer: {
    padding: 24,
    minHeight: Dimensions.get("screen").height,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  headingText: {
    marginTop: 10,
    fontSize: 22,
    fontWeight: "800",
  },
  cardViewContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: 20,
    flexWrap: "wrap",
    marginBottom: 100,
  },
});
