import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  Platform,
  ScrollView,
  Text,
  View,
} from "react-native";
import {
  Avatar,
  Button,
  Chip,
  DataTable,
  Divider,
  List,
} from "react-native-paper";
import {
  GetAllUserRating,
  VerifyReview,
} from "../../../../service/api/rating/api";
import { ids, styles } from "../../TripInfoCss.js";
import PlaneLoader from "../../../../components/Loader";
import {
  blackColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../../constant/constantCss";
import { showToast } from "../../../../constant/toastMessage.js";
import { useSelector } from "react-redux";
import { GetTripParticipants } from "../../../../service/api/trip/api.js";
import { PERMISSION } from "../../../../constant/getAllPermission.js";
import moment from "moment";
import CashPaymentModal from "./Cash Payment Modal/CashPaymentModal.jsx";
import {
  statusBackColor,
  statusText,
} from "../../../../constant/statusColor.js";

const TripParticipant = ({ tripId, tripDetails }) => {
  const isCompletedTrip = moment(tripDetails?.start_time) > moment();
  const { permission } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(true);
  const [participantList, setParticipantList] = useState([]);
  const [isVisibleCashModal, setisVisibleCashModal] = useState(false);

  useEffect(() => {
    GetTripParticipants(tripId)
      .then((res) => {
        setLoader(false);
        setParticipantList(res?.data);
      })
      .catch((err) => {
        setLoader(false);
        showToast("error", err?.response?.data?.detail);
      });
  }, []);

  return (
    <View style={{ flexDirection: "column" }} id="participants">
      {/* header */}
      <View>
        <View style={styles.rowContainer}>
          <Text style={styles.headerKeyText}>Trip Participants </Text>
          {!isCompletedTrip &&
            permission &&
            permission.includes(PERMISSION.CAN_ADD_CASH_PAYMENT) && (
              <View style={{ flexDirection: "row", gap: 10 }}>
                <Button
                  icon={"plus"}
                  buttonColor={primaryColor}
                  textColor={whiteColor}
                  onPress={() => setisVisibleCashModal(true)}
                >
                  Add Cash Payment
                </Button>
              </View>
            )}
        </View>
        <Divider style={{ maxWidth: 500, padding: 0, marginBottom: 16 }} />
      </View>
      {/* loader */}
      {loader && <PlaneLoader />}
      {/* Trip participants */}
      {participantList?.length > 0 ? (
        <ScrollView horizontal={true}>
          <View style={styles.tableStyle} dataSet={{ media: ids.tableStyle }}>
            <DataTable collapsable={true}>
              <DataTable.Header>
                <DataTable.Title>Name</DataTable.Title>
                <DataTable.Title>Phone number</DataTable.Title>
                <DataTable.Title>School Name</DataTable.Title>
                <DataTable.Title>Order Id</DataTable.Title>
                <DataTable.Title>Paid Mode</DataTable.Title>
                <DataTable.Title>Amount Paid(₹)</DataTable.Title>
                <DataTable.Title>Payment Status</DataTable.Title>
                <DataTable.Title>Paid on</DataTable.Title>
              </DataTable.Header>
              {participantList?.map((participant, index) => (
                <DataTable.Row key={index}>
                  <DataTable.Cell>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <Avatar.Text
                        size={34}
                        style={{
                          backgroundColor:
                            statusBackColor[participant?.payment?.status],
                        }}
                        label={participant?.user?.first_name
                          .charAt(0)
                          .toUpperCase()}
                      />
                      <Text>{participant?.user?.first_name}</Text>
                    </View>
                  </DataTable.Cell>

                  <DataTable.Cell>{`+${participant?.user?.dialing_code} ${participant?.user?.phone_number}`}</DataTable.Cell>
                  <DataTable.Cell>
                    {participant?.user?.school_name ?? "N/A"}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    {participant?.payment?.order_id ?? "N/A"}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    {participant?.payment?.method + "  "}
                    {participant?.payment?.bank ??
                      participant?.payment?.wallet ??
                      participant?.payment?.vpa}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    {`${participant?.payment?.amount / 100} ${
                      participant?.payment?.currency
                    }`}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <Chip
                      mode="flat"
                      style={{
                        backgroundColor:
                          statusBackColor[participant?.payment?.status],
                      }}
                      textStyle={{
                        color: blackColor,
                      }}
                    >
                      {statusText[participant?.payment?.status]}
                    </Chip>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    {moment(participant?.payment?.created_at).format(
                      "MMM D, YYYY [at] hh:mm A"
                    )}
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
            </DataTable>
          </View>
        </ScrollView>
      ) : (
        <View style={{ backgroundColor: whiteColor }}>
          <Text
            style={{
              fontSize: 16,
              marginVertical: 20,
              textAlign: "center",
              color: subTextColor,
            }}
          >
            No participants have enrolled for this journey at this time!
          </Text>
        </View>
      )}
      {isVisibleCashModal && (
        <CashPaymentModal
          visible={isVisibleCashModal}
          handleClose={() => setisVisibleCashModal(false)}
          setParticipantList={setParticipantList}
          trip_id={tripId}
          title={tripDetails?.title}
          amount={tripDetails?.amount}
        />
      )}
    </View>
  );
};

export default TripParticipant;
