import React, { useState } from "react";
import { Card } from "react-native-paper";
import { Text } from "react-native";
import { ids, styles } from "./SchoolCardCss.js";
import schoolImg from "../../../../assets/DefaultImages/Defaultschool.webp";
import SchoolDetailsModal from "../AddSchoolModal/SchoolDetailsModal.jsx";

const SchoolCard = ({
  id,
  name,
  address,
  city,
  area,
  image,
  phone_num,
  school_admin,
}) => {
  const [isVisibleDetailsModal, setIsVisibleDetailsModal] = useState(false);
  return (
    <React.Fragment>
      <Card
        elevation={2}
        style={styles.schoolCard}
        key={id}
        dataSet={{ media: ids.schoolCard }}
        onPress={() => setIsVisibleDetailsModal(true)}
      >
        <Card.Cover source={image ? { uri: image } : schoolImg} />
        <Card.Content style={styles.flexRow}>
          <Text style={styles.primaryText}>
            {name.length > 25 ? name.slice(0, 25) + ".." : name}
            {"\n"}
            <Text style={styles.secondaryText}>
              {area ?? ""}
              {area && ", "}
              {city ?? ""}
            </Text>
          </Text>
        </Card.Content>
      </Card>
      {isVisibleDetailsModal && (
        <SchoolDetailsModal
          visible={isVisibleDetailsModal}
          handleClose={() => setIsVisibleDetailsModal(false)}
          id={id}
          name={name}
          address={address}
          area={area}
          city={city}
          image={image}
          phone_num={phone_num}
          school_admin={school_admin}
        />
      )}
    </React.Fragment>
  );
};

export default SchoolCard;
