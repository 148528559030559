import StyleSheet from "react-native-media-query";
import {
  blackColor,
  primaryColor,
  whiteColor,
} from "../../constant/constantCss";
import { Dimensions } from "react-native";

export const { ids, styles } = StyleSheet.create({
  container: {
    padding: 20,
    display:"flex",
    "@media (min-width: 960px)": {
      maxWidth: 960,
      alignSelf:"center",
      width:"100%"
    },
    "@media (min-width: 1400px)": {
      maxWidth: 1282,
    },
    "@media (min-width: 1900px)": {
      maxWidth: 1586,
    },
    "@media (max-width: 480px)": {
      padding: 12,
      marginHorizontal: 96
    },
    "@media (min-width: 768px)": {
      padding: 24,
      marginHorizontal: 96
    }
      ,
    minHeight: Dimensions.get("screen").height - 150,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
  },
  containerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginTop: 10,
    "@media (max-width: 1200px)": {
      flexDirection: "column",
      gap:10
    },
  },
  tabView: {
    width: "100%",
    backgroundColor: whiteColor,
    marginBottom: 10,
  },
  headerTextWhite: {
    color: blackColor,
    fontSize: 20,
    fontWeight: 700,
  },
  headerText: {
    color: blackColor,
    fontSize: 20,
    fontWeight: 700,
  },
  linkText: {
    color: primaryColor,
    fontSize: 18,
  },
  CardFlexContainer: {
    display: "flex",
    flexDirection:"row",
    flexWrap: "wrap",
    gap:20,
    width: "100%",
    "@media (max-width: 768px)": {
      marginBottom: 100,
      justifyContent:"center",
    },

  },
  recentTripContainer: {
    padding: 20,
    borderBottomLeftRadius: 40,
    borderBottomRightRadius: 40,
    "@media (max-width: 480px)": {
      padding: 12,
    },
    "@media (min-width: 768px)": {
      padding: 24,
    },
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    gap: 20,
    flex: 1,
    marginBottom:10
  },
});
