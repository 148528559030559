import StyleSheet from "react-native-media-query";
import {
  blackColor,
  subTextColor,
  whiteColor,
} from "../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    width: 450,
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: blackColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    position: "fixed",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 768px)": {
      width: "90%",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    paddingHorizontal: 20,
  },
  headerText: {
    fontSize: 20,
    fontWeight: "600",
  },
  descriptionText: {
    fontSize: 16,
    color: subTextColor,
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "800",
  },
  bodyContainer: {
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: "100%",
    overflow: "auto",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    "@media (max-width: 992px)": {
      flexDirection: "column",
      gap: 0,
    },
    padding: 20,
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    gap: 10,
  },
});
