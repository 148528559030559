import StyleSheet from "react-native-media-query";
import {
  blackColor,
  containerBackColor,
  containerBlackColor,
  containerTextColor,
  primaryColor,
  primaryOrangeColor,
  primaryRedColor,
  whiteColor,
} from "../../constant/constantCss";
import { Dimensions } from "react-native";

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: whiteColor,
  },
  icon: {
    height: 67,
    width: 67,
    marginTop: 100,
    "@media (max-width: 768px)": {
      height: 60,
      paddingHorizontal: 20,
    },
    "@media (max-width: 576px)": {
      paddingHorizontal: 12,
    },
  },
  navBarContainer: {
    position: "sticky",
    top: 0,
    zIndex: 100,
    height: 80,
    paddingLeft: 60,
    paddingRight: 60,
    backgroundColor: whiteColor,
    "@media (max-width: 768px)": {
      height: 60,
      paddingLeft: 20,
      paddingRight: 20,
    },
    "@media (max-width: 576px)": {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  flexRow: {
    height: "100%",
    flexDirection: "row",
    gap: 10,
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 576px)": {
      justifyContent: "space-between",
      gap: 0,
    },
  },
  image: {
    resizeMode: "contain",
    height: 60,
    width: 112,
    "@media (max-width: 576px)": {
      height: 43,
      width: 80,
    },
    "@media (max-width: 425px)": {
      height: 40,
      width: 70,
    },
  },
  flightLogo: {
    height: 75,
    width: 150,
    "@media (max-width: 992px)": {
      height: 45,
      width: 100,
    },
    "@media (max-width: 768px)": {
      height: 45,
      width: 100,
    },
    "@media (max-width: 576px)": {
      height: 45,
      width: 150,
    },
  },

  mainContainer: {
    padding: 150,
    "@media (max-width: 1440px)": {
      padding: 120,
    },
    "@media (max-width: 1200px)": {
      padding: 60,
    },
    "@media (max-width: 992px)": {
      padding: 50,
    },
    "@media (max-width: 768px)": {
      padding: 20,
    },
    "@media (max-width: 576px)": {
      padding: 16,
    },
  },
  flexContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:1200px)": {
      flexDirection: "column-reverse",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  flexTextContainer: {
    backgroundColor: containerBackColor,
    borderRadius: 10,
    flex: 1,
    "@media (max-width: 576px)": {
      width: "100%",
    },
  },
  subContainer: {
    backgroundColor: containerBackColor,
    padding: 20,
    borderRadius: 10,
    width: "100%",
  },
  welcomeImg: {
    height: 700,
    width: Dimensions.get("screen").width / 2 - 200,
    borderRadius: 20,
    "@media (max-width: 1440px)": {
      width: 540,
    },
    "@media (max-width: 1200px)": {
      width: Dimensions.get("screen").width / 2 - 60,
    },
    "@media (max-width: 992px)": {
      width: Dimensions.get("screen").width / 2 - 50,
    },
    "@media (max-width: 768px)": {
      width: Dimensions.get("screen").width / 2 - 20,
    },
    "@media (max-width: 576px)": {
      height: 500,
      width: Dimensions.get("screen").width - 16,
    },
    "@media (max-width: 480px)": {
      height: 450,
      width: Dimensions.get("screen").width - 16,
    },
  },
  preHeaderText: {
    fontSize: 32,
    fontWeight: "600",
    color: primaryRedColor,
    marginLeft: 20,
    "@media (max-width: 768px)": {
      fontSize: 22,
      marginTop: 30,
    },
    "@media (max-width: 576px)": {
      fontSize: 24,
      marginTop: 30,
    },
  },
  headerText: {
    fontSize: 40,
    fontWeight: "600",
    color: primaryRedColor,
    "@media (max-width: 768px)": {
      fontSize: 24,
    },
  },
  linkText: {
    fontSize: 16,
    color: primaryOrangeColor,
    marginVertical: 10,
    lineHeight: 1.6 * 16,
    "@media (max-width: 768px)": {
      fontSize: 14,
      lineHeight: 1.6 * 14,
    },
  },
  subText: {
    fontSize: 16,
    fontWeight: "500",
    color: containerTextColor,
    lineHeight: 1.6 * 16,
    "@media (max-width: 768px)": {
      fontSize: 14,
      lineHeight: 1.6 * 14,
    },
  },

  //   explore container :
  explore_container: {
    flexDirection: "column",
    gap: 30,
  },
  explore_headerText: {
    fontSize: 22,
    fontWeight: "600",
    color: primaryRedColor,
    marginBottom: 10,
    lineHeight: 1.6 * 22,
  },
  explore_subText: {
    fontSize: 18,
    fontWeight: "500",
    color: containerTextColor,
    lineHeight: 1.6 * 16,
  },
  explore_img: {
    height: 300,
    width: Dimensions.get("screen").width / 2 - 150,
    maxWidth: 500,
    borderRadius: 10,
    "@media (max-width: 1440px)": {
      height: 250,
      width: Dimensions.get("screen").width / 2 - 180,
    },
    "@media (max-width: 992px)": {
      height: 250,
      width: Dimensions.get("screen").width / 2 - 50,
    },
    "@media (max-width: 768px)": {
      height: 200,
      width: Dimensions.get("screen").width / 2 - 20,
    },
    "@media (max-width: 576px)": {
      height: 230,
      width: Dimensions.get("screen").width - 16,
    },
  },
  explore_col_reverse_flex: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "flex-end",
    "@media (max-width: 576px)": {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 10,
    },
  },
  explore_col_flex: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 10,
    },
  },
  paddingLeftStyle: {
    paddingLeft: 150,
    "@media (max-width: 1440px)": {
      paddingLeft: 120,
    },
    "@media (max-width: 1200px)": {
      paddingLeft: 60,
    },
    "@media (max-width: 992px)": {
      paddingLeft: 50,
    },
    "@media (max-width: 768px)": {
      paddingLeft: 20,
    },
    "@media (max-width: 576px)": {
      paddingLeft: 16,
    },
  },
  paddingRightStyle: {
    paddingRight: 150,
    "@media (max-width: 1440px)": {
      paddingRight: 120,
    },
    "@media (max-width: 1200px)": {
      paddingRight: 60,
    },
    "@media (max-width: 992px)": {
      paddingRight: 50,
    },
    "@media (max-width: 768px)": {
      paddingRight: 20,
    },
    "@media (max-width: 576px)": {
      paddingRight: 16,
    },
  },

  //   why choose maroon migrates container :
  why_choose_container: {
    flexDirection: "column",
    gap: 30,
    paddingHorizontal: 0 ,
    "@media (max-width: 1200px)": {
      paddingHorizontal: 0,
    },
  },

  //   contact us container :
  contact_us_container: {
    paddingHorizontal: 120,
    paddingVertical: 30,
    "@media (max-width: 1200px)": {
      paddingHorizontal: 60,
      paddingVertical: 20,
    },
    "@media (max-width: 992px)": {
      padding: 50,
    },
    "@media (max-width: 768px)": {
      padding: 20,
    },
    "@media (max-width: 576px)": {
      padding: 16,
    },
  },
  contact_headerText: {
    fontSize: 24,
    fontWeight: "600",
    color: whiteColor,
    marginBottom: 10,
    lineHeight: 1.6 * 18,
  },
  personalizedTripText: {
    fontSize: 18,
    fontWeight: "600",
    color: whiteColor,
  },
  contact_link_text: {
    fontSize: 16,
    // cursor: "pointer",
    color: "#e9ecef",
    lineHeight: 1.6 * 16,
  },
  contact_subText: {
    fontSize: 18,
    fontWeight: "500",
    color: whiteColor,
    lineHeight: 1.6 * 16,
  },
  contact_img: {
    height: 400,
    width: 550,
    borderRadius: 10,
    "@media (max-width: 992px)": {
      height: 250,
      width: Dimensions.get("screen").width / 2 - 50,
    },
    "@media (max-width: 768px)": {
      height: 280,
      width: Dimensions.get("screen").width / 2 - 20,
    },
    "@media (max-width: 576px)": {
      height: 230,
      width: Dimensions.get("screen").width - 16,
    },
  },
  contactUsTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    marginBottom: 19,
    lineHeight: 1.6 * 16,
    marginLeft: 13,
    "@media (max-width: 768px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  contactUsTextMobileContainer: {
    marginBottom: 10,
    display: "none",
    "@media (max-width: 576px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  contact_us_subContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: 24,
    },
  },
  contacUsText: {
    fontSize: 24,
    fontWeight: "600",
    color: whiteColor,
  },
  ContactUSIcon: {
    height: 67,
    width: 67,
  },
  socialMediaContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconContainer: {
    backgroundColor: 'transparent',
    alignSelf: "center",
    borderRadius: 50,
    padding: 10,
    marginHorizontal: 5,
    fontSize: 20,
  },
  socialMediaText: {
    fontSize: 20,
    fontStyle: "normal",
    alignSelf: "center",
    color: "#e9ecef",
    marginTop: -3,
  },
  socialMediaIcon: {
    height: 40,
    width: 40,
  },
  contactLeftContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 16,
    width: "40%",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  contactRightContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    width: "40%",
    "@media (max-width: 768px)": {
      width: "100%",
      marginLeft: -12
    },

  },
  // carrer
  career_container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 100,
    paddingHorizontal: 50,
    "@media (max-width: 768px)": {
      flexDirection: "column-reverse",
      justifyContent: "center",
      alignItems: "center",
      padding: 20,
      marginTop: 0,
    },
  },
  career_img: {
    height: 500,
    width: Dimensions.get("screen").width / 2,
    "@media (max-width: 768px)": {
      height: 400,
      width: "100%",
    },
  },
  career_header_text: {
    fontSize: 32,
    color: primaryOrangeColor,
    "@media (max-width: 768px)": {
      fontSize: 24,
    },
    "@media (max-width: 480px)": {
      fontSize: 28,
    },
  },
  career_sub_header_text: {
    fontSize: 44,
    color: blackColor,
    fontWeight: "600",
    "@media (max-width: 1200px)": {
      fontSize: 40,
    },
    "@media (max-width: 768px)": {
      fontSize: 30,
    },
    "@media (max-width: 480px)": {
      fontSize: 28,
    },
  },
  button: {
    width: 140,
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  // footer
  footerContainer: {
    height: 34,
    backgroundColor: blackColor,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  // comopany review
  addReviewBtn: {
    borderWidth: 1,
    borderColor: primaryColor,
    maxWidth: 170,
    textAlign: "right",
  },
  company_review_container: {
    flexDirection: "column",
    gap: 30,
  },
  company_review_header: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  addReviewBtnContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  addReviewscrollBar: {
    width: "100%",
    height: 280,
  },
  reviewItemContainer: {
    backgroundColor: "#F9F9F9",
    width: 400,
    height: 280,
    borderRadius: 10,
    position: "relative",
    marginRight: 30,
    "@media (max-width: 576px)": {
      width: Dimensions.get("screen").width - 30,
    },
  },
  reviewItembackground: {
    position: "absolute",
    left: 0,
    bottom: 0,
    top: 0,
    width: 60,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: "#EBEBEB",
  },
  nameAvtar: {
    position: "absolute",
    left: 30,
    top: 24,
    zIndex: 2,
  },
  reviewNameText: {
    position: "absolute",
    left: 98,
    top: 25,
    fontSize: 20,
    "@media (max-width: 576px)": {
      fontSize: 16,
    },
  },
  iconAvtar: {
    position: "absolute",
    left: 98,
    top: 45,
    flexDirection: "row",
    gap: 5,
  },
  descText: {
    position: "absolute",
    left: 98,
    top: 80,
    right: 14,
    fontSize: 16,
    color: "#535353",
    "@media (max-width: 576px)": {
      fontSize: 14,
    },
  },
  commaImgContainer: {
    position: "absolute",
    right: 10,
    top: 16,
    backgroundColor: "#EBEBEB",
    height: 34,
    width: 34,
    borderRadius: 40,
    padding: 8,
  },
});