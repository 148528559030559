import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const USER_ENDPOINT = {
  USER_FILTER_URL: BASE_URL + "auth/user/",
  GET_ALL_USER_URL: BASE_URL + "auth/user/all/",
};

export const GetUserAPI = async () => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.get(USER_ENDPOINT.USER_FILTER_URL, headers);
  }
};

export const GetAllserAPI = async ({
  page,
  search,
  isVerified,
  isPagination,
}) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    let url = USER_ENDPOINT.GET_ALL_USER_URL;
    if (page) {
      url += `?page=${page}`;
    } else if (isPagination && search) {
      url += `?is_paginate=${isPagination}&search=${search}`;
    } else if (isPagination) {
      url += `?is_paginate=${isPagination}`;
    } else if (search) {
      url += `?search=${search}`;
    } else if (isVerified) {
      url += `?is_active=${isVerified}`;
    }
    return axios.get(url, headers);
  }
};
