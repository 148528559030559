import StyleSheet from "react-native-media-query";
import { whiteColor } from "../../constant/constantCss";

export const {ids, styles} = StyleSheet.create({
    container:{
        flex:1,
        alignItems:"center",
        justifyContent:"center",
        '@media (max-width: 1200px)': {
            flexDirection:"row"
        }
    },
    uploadButton:{
        maxWidth:350,
        color: whiteColor
    }
});
