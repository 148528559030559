import React, { useState } from "react";
import { ids, styles } from "./ReviewModalCss";
import { Dimensions, Modal, Text, View } from "react-native";
import { Button, IconButton, TextInput } from "react-native-paper";
import { Controller, useForm } from "react-hook-form";
import {
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../../../constant/constantCss";
import {
  CreateUserRating,
  GetAllUserRating,
} from "../../../../../service/api/rating/api";
import { showToast } from "../../../../../constant/toastMessage";

const ReviewModal = ({ trip_id, visible, handleClose, initialCallingApi }) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "Traveling Through Time",
    },
  });
  const screenWidth = Dimensions.get("window").width;
  const roundness = screenWidth > 768 ? 20 : 8;
  const [loader, setloader] = useState(false);
  const [rateValue, setRateValue] = useState(-1);
  const submitForm = (data) => {
    data["rating_value"] = rateValue + 1;
    data["trip"] = trip_id;
    setloader(true);
    CreateUserRating(data)
      .then((res) => {
        showToast(
          "success",
          "Thank you for giving Rating!",
          "Your review will be visible once admin approved!"
        );
        setloader(false);
        initialCallingApi();
      })
      .catch((err) => {
        setloader(false);
        showToast(
          "error",
          err?.response?.data?.message ?? err?.response?.data?.details
        );
      });
    handleClose();
  };
  const validationMessages = {
    title: {
      required: "This field is required.",
      maxLength: "This field must not exceed maximum characters.",
    },
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Rate Your Trip</Text>
            <IconButton icon={"close"} onPress={handleClose} />
          </View>

          {/* Content */}
          <View
            style={styles.bodyContainer}
            dataSet={{ media: ids.bodyContainer }}
          >
            <View style={styles.inputView}>
              <View
                style={[styles.flexRow, { justifyContent: "space-between" }]}
              >
                <Text style={styles.inputText}>Title*</Text>
                <Text style={styles.inputText}>
                  {watch("title")?.length ?? 0}/30
                </Text>
              </View>
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    style={styles.textInput}
                    dataSet={{ media: ids.textInput }}
                    onChangeText={onChange}
                    value={value}
                    theme={{
                      roundness: roundness,
                    }}
                    mode="outlined"
                    outlineColor={inputTextColor}
                    activeOutlineColor={inputTextColor}
                    placeholder="Enter title eq. Nice trip"
                  />
                )}
                name="title"
              />
              {errors.title && (
                <Text
                  style={styles.inputError}
                  dataSet={{ media: ids.inputError }}
                >
                  {validationMessages.title[errors.title.type]}
                </Text>
              )}
            </View>
            <View style={styles.inputView}>
              <View
                style={[styles.flexRow, { justifyContent: "space-between" }]}
              >
                <Text style={styles.inputText}>Message*</Text>
                <Text style={styles.inputText}>
                  {watch("message")?.length ?? 0}/150
                </Text>
              </View>
              <Controller
                control={control}
                rules={{
                  required: true,
                  maxLength: 150,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    multiline={true}
                    numberOfLines={4}
                    style={[styles.textInput, { height: 100 }]}
                    dataSet={{ media: ids.textInput }}
                    onChangeText={onChange}
                    value={value}
                    theme={{
                      roundness: roundness,
                    }}
                    mode="outlined"
                    outlineColor={inputTextColor}
                    activeOutlineColor={inputTextColor}
                    placeholder="Write here"
                  />
                )}
                name="message"
              />
              {errors.message && (
                <Text
                  style={styles.inputError}
                  dataSet={{ media: ids.inputError }}
                >
                  {validationMessages.title[errors.message.type]}
                </Text>
              )}
            </View>
            <View style={styles.flexRow}>
              {Array.from({ length: 5 }, (_, index) => (
                <IconButton
                  key={index + 1}
                  size={30}
                  onPress={() => setRateValue(index)}
                  icon={rateValue >= index ? "star" : "star-outline"}
                  iconColor={rateValue >= index ? "#ffe234" : "#7e7863"}
                />
              ))}
            </View>
            <View style={styles.inputView}>
              <Button
                loading={loader}
                textColor={whiteColor}
                buttonColor={primaryColor}
                onPress={handleSubmit(submitForm)}
              >
                Submit
              </Button>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ReviewModal;
