import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Text, Pressable, View, Modal } from "react-native";
import { Button, TextInput } from "react-native-paper";
import { ids, styles } from "./UpdatesCss";
import { CreateAnnouncementApi } from "../../../../../service/api/trip/AnnouncementApi";
import AntDesign from "react-native-vector-icons/AntDesign";
import {
  primaryColor,
  inputTextColor,
  errorTextColor,
  blackColor,
} from "../../../../../constant/constantCss";

import { showToast } from "../../../../../constant/toastMessage";

const AnnouncementModal = ({
  tripId,
  announcementModalVisible,
  setAnnouncementModalVisible,
  setRefreshAPI,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      details: "",
      trip: tripId,
    },
  });

  const onSubmit = (data) => {
    data = {
      description: data.details,
      trip: tripId,
    };
    CreateAnnouncementApi(tripId, data)
      .then((res) => {
        showToast("success", "Announcement created successfully");
        setAnnouncementModalVisible(false);
        setRefreshAPI((prev) => !prev);
      })
      .catch((error) => {
        showToast("error", "Something went wrong!");
      });
  };
  return (
    <Modal
      visible={announcementModalVisible}
      animationType="slide"
      transparent={true}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          <View style={styles.headerContainer}>
            <Text
              style={{
                fontWeight: 700,
                fontSize: 20,
                marginBottom: 20,
                color: blackColor,
              }}
            >
              Add Updates
            </Text>
            <Pressable onPress={() => setAnnouncementModalVisible(false)}>
              <AntDesign style={styles.closeIcon} name="close" size={20} />
            </Pressable>
          </View>
          <View>
            <View style={{ paddingVertical: 10 }}>
              <Text style={styles.updateText}>Update Details*</Text>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    style={styles.textInput}
                    onChangeText={onChange}
                    value={value}
                    placeholder="Enter update details here.. "
                    mode="outlined"
                    outlineColor={inputTextColor}
                    activeOutlineColor={inputTextColor}
                    multiline={true}
                    numberOfLines={5}
                  />
                )}
                name="details"
              />
              {errors.details && (
                <Text style={{ color: errorTextColor }}>This is required.</Text>
              )}
            </View>
          </View>
          <Button
            mode="contained"
            buttonColor={primaryColor}
            onPress={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </View>
      </View>
    </Modal>
  );
};

export default AnnouncementModal;
