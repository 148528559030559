import React, { useEffect, useState } from "react";
import { Dimensions, Image, Platform, Text, View } from "react-native";
import { ids, styles } from "./Tripitenary.css.js";
import {
  TripItineraryButtonColor,
  TripItineraryColor,
  primaryColor,
  whiteColor,
} from "../../../../constant/constantCss";
import {
  Button,
  Divider,
  IconButton,
  TouchableRipple,
} from "react-native-paper";
import Octicons from "react-native-vector-icons/Octicons";
import AntDesign from "react-native-vector-icons/AntDesign";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { showToast } from "../../../../constant/toastMessage.js";
import { GetItineraryApi } from "../../../../service/api/trip/itineraryApi.js";
import * as DocumentPicker from "expo-document-picker";
import { UpdateTripAPI } from "../../../../service/api/trip/api.js";
import { useSelector } from "react-redux";
import { PERMISSION } from "../../../../constant/getAllPermission.js";
import AddItinerary from "./AddItinerary/AddItinerary.jsx";
import EditLocation from "./EditItinerary/EditLocation.jsx";
import { DeleteLocationAPI } from "../../../../service/api/itinerary/api.js";
import EditStop from "./EditItinerary/EditStop.jsx";
import PlaneLoader from "../../../../components/Loader.jsx";

const TripItineraryDesktop = ({ trip_id }) => {
  const { width } = Dimensions.get("screen");
  const [loader, setLoader] = useState(true);
  const [visibleAddLocation, setVisibleAddLocation] = useState(false);
  const [uploadLoader, setuploadLoader] = useState(false);
  const [refreshAPI, setRefreshAPI] = useState(false);
  const { permission } = useSelector((state) => state.auth);
  const [itineraryList, setItineraryList] = useState([]);
  const [isCallapsedLocation, setIsCallapsedLocation] = useState([]);
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeStop, setActiveStop] = useState(null);

  const pickItinerariesFile = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: ["application/pdf"],
      });
      let formData = new FormData();
      if (!result?.canceled) {
        setuploadLoader(true);
        if (Platform.OS === "web") {
          formData.append("itinerary_url", result?.assets[0]?.file);
        } else {
          // random name generate
          const currentDate = momentt();
          const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
          const imageName = `location_image_${formattedDate}.pdf`;

          const fileObj = {
            uri: result?.assets?.[0]?.uri,
            type: "application/pdf",
            name: imageName,
          };
          formData.append("itinerary_url", fileObj);
        }
        UpdateTripAPI(trip_id, formData)
          .then((res) => {
            setuploadLoader(false);
            showToast("success", res?.data?.message);
          })
          .catch((err) => {
            setuploadLoader(false);
            showToast("error", err?.response?.data?.message);
          });
      }
    } catch (error) {
      console.error("Error picking an image:", error);
    }
  };
  const toggleCollapse = (stopIndex) => {
    setIsCallapsedLocation((prevCollapsedStops) => {
      const isCollapsed = prevCollapsedStops.includes(stopIndex);
      if (isCollapsed) {
        return prevCollapsedStops.filter((index) => index !== stopIndex);
      } else {
        return [...prevCollapsedStops, stopIndex];
      }
    });
  };
  const handleLocationDelete = (location_id, id) => {
    DeleteLocationAPI(location_id)
      .then((res) => {
        showToast("success", res?.data?.message);
        setRefreshAPI((prev) => !prev);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  useEffect(() => {
    GetItineraryApi(trip_id)
      .then((res) => {
        setItineraryList(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        showToast("error", err?.response?.data?.detail);
      });
  }, [trip_id, refreshAPI]);
  return (
    <View style={{ flexDirection: "column" }} id="itinerary">
      <View>
        <View style={styles.rowContainerHeader}>
          <Text style={styles.headerKeyText}>Itinerary</Text>
          {permission &&
            permission.includes(PERMISSION.CAN_EDIT_ITERNARIES) && (
              <View style={{ flexDirection: "row", gap: 10 }}>
                {width >= 576 ? (
                  <Button
                    loading={uploadLoader}
                    icon={"upload"}
                    buttonColor={primaryColor}
                    textColor={whiteColor}
                    onPress={pickItinerariesFile}
                  >
                    Upload Itinerary
                  </Button>
                ) : (
                  <IconButton
                    size={20}
                    icon={"upload"}
                    mode="contained"
                    style={{ backgroundColor: primaryColor }}
                    iconColor={whiteColor}
                    onPress={pickItinerariesFile}
                  />
                )}
                {width >= 576 ? (
                  <Button
                    icon={"plus"}
                    buttonColor={primaryColor}
                    textColor={whiteColor}
                    onPress={() => setVisibleAddLocation(true)}
                  >
                    {width >= 576 ? "Add Itinerary" : ""}
                  </Button>
                ) : (
                  <IconButton
                    size={20}
                    icon={"plus"}
                    mode="contained"
                    style={{ backgroundColor: primaryColor }}
                    iconColor={whiteColor}
                    onPress={() => setVisibleAddLocation(true)}
                  />
                )}
              </View>
            )}
        </View>
        <Divider style={{ maxWidth: 500, padding: 0, marginBottom: 16 }} />
      </View>
      {loader && <PlaneLoader />}
      {itineraryList?.map((location, locationIndex) => (
        <View key={locationIndex}>
          <TouchableRipple onPress={() => toggleCollapse(locationIndex)}>
            <View style={styles.rowContainer}>
              <Text style={styles.dayText} dataSet={{ media: ids.dayText }}>
                <Octicons
                  name="dot-fill"
                  size={22}
                  style={{ marginRight: 10 }}
                />
                {"  "}
                {`${location?.location_name?.split(":")[0]}`}
              </Text>
              <AntDesign
                name={
                  isCallapsedLocation.includes(locationIndex) ? "up" : "down"
                }
                size={16}
              />
            </View>
          </TouchableRipple>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <View
              style={{
                width: "49.5%",
                borderRightWidth: 2,
                borderColor: TripItineraryColor,
              }}
            >
              {locationIndex % 2 ? (
                <>
                  <View
                    style={[
                      styles.rowContainer,
                      {
                        marginHorizontal: "5%",
                        marginVertical: `${isCallapsedLocation.includes(locationIndex) ? "5%" : "0%"
                          }`,
                      },
                    ]}
                  >
                    {permission &&
                      permission.includes(PERMISSION.CAN_EDIT_ITERNARIES) && (
                        <View style={{ flexDirection: "row", gap: 16 }}>
                          <MaterialIcons
                            name={"edit"}
                            color={TripItineraryButtonColor}
                            size={22}
                            onPress={() => setActiveLocation(location.id)}
                          />
                          <MaterialIcons
                            name={"delete"}
                            color={TripItineraryButtonColor}
                            size={22}
                            onPress={() => handleLocationDelete(location.id)}
                          />
                        </View>
                      )}
                  </View>
                  {!isCallapsedLocation.includes(locationIndex) && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: `${!location.location_image
                            ? ""
                            : !(locationIndex % 2)
                              ? "column"
                              : ""
                          }`,
                        marginHorizontal: "5%",
                        marginVertical: "5%",
                        alignItems: "center",
                      }}
                    >
                      {location.location_image && (
                        <Image
                          source={{ uri: location?.location_image }}
                          style={[styles.locationImage, { zIndex: 10 }]}
                          dataSet={{ media: ids.locationImage }}
                        />
                      )}
                      {/* stop details */}
                    </View>
                  )}
                </>
              ) : (
                <View style={{ marginTop: 35 }}>

                  {!isCallapsedLocation.includes(locationIndex) && (
                    <>
                      {location?.stop_set?.map((stop, stopIndex) => (
                        <View
                          key={stopIndex}
                          style={[
                            styles.rowContainer,
                            {
                              justifyContent: "center",
                              marginTop: "2%",
                            },
                          ]}
                        >
                          <View
                            style={{
                              width: (width >= 1400) ? 450 : 350,
                              marginLeft: `${permission &&
                                  permission.includes(
                                    PERMISSION.CAN_EDIT_ITERNARIES
                                  )
                                  ? "8%"
                                  : "3%"
                                }`,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "column",
                                gap: 16,
                              }}
                            >
                              <Text style={styles.locationText}>
                                {location?.location_name}
                              </Text>
                              <Text style={styles.stopText}>
                                {stop?.description}
                              </Text>
                            </View>
                            <Text style={styles.tinyText}>
                              {stop?.stop_time}
                            </Text>
                          </View>
                          {permission &&
                            permission.includes(
                              PERMISSION.CAN_EDIT_ITERNARIES
                            ) && (
                              <MaterialIcons
                                name={"edit"}
                                color={TripItineraryButtonColor}
                                size={22}
                                onPress={() => setActiveStop(stop?.id)}
                              />
                            )}
                          {activeStop === stop?.id ? (
                            <EditStop
                              visible={true}
                              handleClose={() => setActiveStop(null)}
                              stop_id={stop?.id}
                              stop_time={stop?.stop_time}
                              stop_desc={stop?.description}
                              setRefreshAPI={setRefreshAPI}
                            />
                          ) : null}
                        </View>
                      ))}
                    </>
                  )}
                </View>
              )}
            </View>
            <View style={{ width: "50%" }}>
              {!(locationIndex % 2) ? (
                <>
                  <View
                    style={[
                      styles.rowContainer,
                      {
                        marginHorizontal: "5%",
                        marginVertical: `${isCallapsedLocation.includes(locationIndex) ? "5%" : "0%"}`,
                      },
                    ]}
                  >
                    {permission &&
                      permission.includes(PERMISSION.CAN_EDIT_ITERNARIES) && (
                        <View style={{ flexDirection: "row", gap: 16 }}>
                          <MaterialIcons
                            name={"edit"}
                            color={TripItineraryButtonColor}
                            size={22}
                            onPress={() => setActiveLocation(location.id)}
                          />
                          <MaterialIcons
                            name={"delete"}
                            color={TripItineraryButtonColor}
                            size={22}
                            onPress={() => handleLocationDelete(location.id)}
                          />
                        </View>
                      )}
                  </View>
                  {!isCallapsedLocation.includes(locationIndex) && (
                    <>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: `${!location.location_image
                            ? ""
                            : !(locationIndex % 2)
                              ? "column"
                              : ""
                            }`,
                          marginHorizontal: "5%",
                          marginVertical: "5%",
                          alignItems: "center",
                        }}
                      >
                        {location.location_image && (
                          <Image
                            source={{ uri: location?.location_image }}
                            style={[styles.locationImage, { zIndex: 10 }]}
                            dataSet={{ media: ids.locationImage }}
                          />
                        )}
                        {/* stop details */}
                      </View>
                    </>
                  )}
                </>
              ) : (
                <View style={{ margin: 35 }}>

                  {!isCallapsedLocation.includes(locationIndex) &&
                    location?.stop_set?.map((stop, stopIndex) => (
                      <View
                        key={stopIndex}
                        style={[
                          styles.rowContainer,
                          {
                            justifyContent: "center",
                            marginTop: "2%",
                          },
                        ]}
                      >
                        <View
                          style={{
                            maxWidth: width >= 1400 ? 450 : 350 ,
                            marginLeft: `${permission &&
                                permission.includes(
                                  PERMISSION.CAN_EDIT_ITERNARIES
                                )
                                ? "8%"
                                : "3%"
                              }`,
                          }}
                        >


                          <View
                            style={{
                              flexDirection: "column",
                              gap: 16,
                            }}
                          >
                            <Text style={styles.locationText}>
                              {location?.location_name}
                            </Text>
                            <Text style={[styles.stopText, { justifyContent: "flex-start" }]}>
                              {stop?.description}
                            </Text>
                          </View>
                          <Text style={styles.tinyText}>
                            {stop?.stop_time}
                          </Text>
                        </View>
                        {permission &&
                          permission.includes(
                            PERMISSION.CAN_EDIT_ITERNARIES
                          ) && (
                            <MaterialIcons
                              name={"edit"}
                              color={TripItineraryButtonColor}
                              size={22}
                              onPress={() => setActiveStop(stop?.id)}
                            />
                          )}
                        {activeStop === stop?.id ? (
                          <EditStop
                            visible={true}
                            handleClose={() => setActiveStop(null)}
                            stop_id={stop?.id}
                            stop_time={stop?.stop_time}
                            stop_desc={stop?.description}
                            setRefreshAPI={setRefreshAPI}
                          />
                        ) : null}
                      </View>
                    ))}
                </View>
              )}
            </View>
          </View>
          {activeLocation === location.id ? (
            <EditLocation
              visible={true}
              handleClose={() => setActiveLocation(null)}
              location_id={location.id}
              location_name={location.location_name}
              setRefreshAPI={setRefreshAPI}
            />
          ) : null}
        </View>
      ))}
      {visibleAddLocation && (
        <AddItinerary
          trip_id={trip_id}
          visible={visibleAddLocation}
          handleClose={() => {
            setVisibleAddLocation(false);
          }}
          setRefreshAPI={setRefreshAPI}
        />
      )}
    </View>
  );
};

export default TripItineraryDesktop;
