import React, { useEffect, useState, useCallback } from "react";
import {
  ScrollView,
  View,
  Text,
  Dimensions,
  RefreshControl,
} from "react-native";
import { ids, styles } from "./ProfileCss";
import AppBar from "../../Layout/AppBar";
import { PERMISSION } from "../../../constant/getAllPermission";
import {
  blackColor,
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../constant/constantCss";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  TextInput,
} from "react-native-paper";
import { useSelector } from "react-redux";
import { GetPerticularSchool } from "../../../service/api/school/api";
import { showToast } from "../../../constant/toastMessage";
import { useNavigation } from "@react-navigation/native";
import schoolImg from "../../../../assets/DefaultImages/Defaultschool.webp";
import Ionicons from "react-native-vector-icons/Ionicons";
import UserListTable from "./Tabs/UserListTable";
import OrganizationTrip from "./Tabs/OrganizationTrip";
import CreateStudentListModal from "./Add StudentList Modal/CreateStudentListModal";
import PlaneLoader from "../../../components/Loader";

const OrganizationProfile = ({ route }) => {
  const id = route.params.data;
  const { permission } = useSelector((state) => state.auth);
  const [tabValue, setTabValue] = useState("user-list");
  const { height, width } = Dimensions.get("window");
  const [loader, setLoader] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [isVisibleAddStudentModal, setIsVisibleAddStudentModal] =
    useState(false);
  const [organization, setOrganization] = useState([]);
  const [userList, setUserList] = useState([]);
  const [tripList, setTripList] = useState([]);
  const navigation = useNavigation();

  const currentButtonStyle = (val) => ({
    borderWidth: 0,
    borderBottomWidth: 2,
    borderRadius: 2,
    backgroundColor: whiteColor,
    borderColor: tabValue === val ? primaryColor : whiteColor,
  });
  const tabButton = [
    {
      value: "user-list",
      label: "User List",
      style: currentButtonStyle("user-list"),
    },
    {
      value: "trip",
      label: "Your Trips",
      style: currentButtonStyle("trip"),
    },
  ];
  const initalCallingApi = () => {
    setLoader(true);
    GetPerticularSchool(id)
      .then((res) => {
        setLoader(false);
        setOrganization(res.data);
        setUserList(res.data?.user_list);
        setTripList(res.data?.trip);
      })
      .catch((err) => {
        setLoader(false);
        showToast("error", err?.response?.data?.detail);
      });
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initalCallingApi();
      setRefreshing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    initalCallingApi();
  }, []);

  const handleCloseModal = () => {
    setIsVisibleAddStudentModal(false);
    setLoader(true);
    GetPerticularSchool(id)
      .then((res) => {
        setLoader(false);
        setOrganization(res.data);
      })
      .catch((err) => {
        setLoader(false);
        showToast("error", err?.response?.data?.detail);
      });
  };
  const handleSearchUser = (searchVal) => {
    const user_list = organization.user_list;
    const newArray = user_list.filter((val) =>
      val.first_name.toLowerCase().includes(searchVal.toLowerCase())
    );
    if (searchVal === "") {
      setOrganization({ ...organization, user_list: userList });
    } else {
      setOrganization({ ...organization, user_list: newArray });
    }
  };
  const handleSearchTrip = (searchVal) => {
    const trip_list = organization.trip;
    const newArray = trip_list.filter((val) =>
      val.title.toLowerCase().includes(searchVal.toLowerCase())
    );
    if (searchVal === "") {
      setOrganization({ ...organization, trip: tripList });
    } else {
      setOrganization({ ...organization, trip: newArray });
    }
  };
  return (
    <View style={{ minHeight: height }}>
      <AppBar />
      {loader ? (
        <PlaneLoader />
      ) : (
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ backgroundColor: whiteColor }}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              tintColor={primaryColor}
              colors={[primaryColor]}
            />
          }
        >
          <View style={styles.detailsHeaderContainer}>
            <IconButton
              icon="arrow-left"
              iconColor={whiteColor}
              style={{ position: "absolute", left: 0 }}
              onPress={() => {
                navigation.pop();
              }}
            />
            <Avatar.Image
              size={120}
              source={
                organization.image ? { uri: organization.image } : schoolImg
              }
              style={styles.img}
              dataSet={{ media: ids.img }}
            />
          </View>
          <View style={styles.subContainer}>
            <View style={styles.flexRow}>
              <View>
                <Text style={styles.headingText}>{organization.name}</Text>

                <Text style={styles.subText}>
                  <Ionicons name="location-outline" size={20} />
                  {organization.area ?? ""}
                  {organization.area && ", "}
                  {organization.city ?? "-"}
                </Text>
              </View>
              {permission &&
                permission?.includes(PERMISSION.CAN_CREATE_STUDENT_LIST) && (
                  <Button
                    icon={"plus"}
                    mode="contained"
                    buttonColor={primaryColor}
                    onPress={() => setIsVisibleAddStudentModal(true)}
                  >
                    {width >= 480 && "Add User List"}
                  </Button>
                )}
            </View>
            <Divider style={styles.divider} />
            {/* <SegmentedButtons
              value={tabValue}
              onValueChange={setTabValue}
              buttons={tabButton}
            /> */}
            <Divider style={{ marginBottom: 20 }} />

            {tabValue === "user-list" && (
              <View>
                <TextInput
                  style={styles.textInput}
                  mode="outlined"
                  outlineColor={blackColor}
                  activeOutlineColor={inputTextColor}
                  placeholder="search users"
                  onChangeText={(val) => handleSearchUser(val)}
                />
                <UserListTable userList={organization?.user_list} />
              </View>
            )}
            {tabValue === "trip" && (
              <View>
                <TextInput
                  style={styles.textInput}
                  mode="outlined"
                  outlineColor={blackColor}
                  activeOutlineColor={inputTextColor}
                  placeholder="search trips"
                  onChangeText={(val) => handleSearchTrip(val)}
                />
                <OrganizationTrip tripList={organization.trip} />
              </View>
            )}
          </View>
          {isVisibleAddStudentModal && (
            <CreateStudentListModal
              visible={isVisibleAddStudentModal}
              handleClose={handleCloseModal}
              schoolName={organization?.name}
              school_id={organization?.id}
            />
          )}
        </ScrollView>
      )}
    </View>
  );
};

export default OrganizationProfile;
