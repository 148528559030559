import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const ITINERARY_ENDPOINT = {
  CREATE_LOCATION_URL: BASE_URL + "itinerary/location/add/",
  EDIT_LOCATION_URL: BASE_URL + "itinerary/location/",
  DELETE_LOCATION_URL: BASE_URL + "itinerary/",

  CREATE_STOP_URL: BASE_URL + "itinerary/stop/add/",
  EDIT_STOP_URL: BASE_URL + "itinerary/stop/",
};

export const CreateLocationAPI = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(ITINERARY_ENDPOINT.CREATE_LOCATION_URL, payload, headers);
  }
};
export const EditLocationAPI = async (payload, location_id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.patch(
      `${ITINERARY_ENDPOINT.EDIT_LOCATION_URL}${location_id}/`,
      payload,
      headers
    );
  }
};
export const DeleteLocationAPI = async (location_id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.delete(
      `${ITINERARY_ENDPOINT.DELETE_LOCATION_URL}${location_id}/`,
      headers
    );
  }
};

export const CreateStopAPI = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(ITINERARY_ENDPOINT.CREATE_STOP_URL, payload, headers);
  }
};
export const EditStopAPI = async (payload, stop_id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.patch(
      `${ITINERARY_ENDPOINT.EDIT_STOP_URL}${stop_id}/`,
      payload,
      headers
    );
  }
};
