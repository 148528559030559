import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  subContainer: {
    padding: 24,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    gap: 20,
  },
  headingText: {
    marginTop: 10,
    fontSize: 22,
    fontWeight: "800",
  },
  cardViewContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: 20,
    flexWrap: "wrap",
  },
});
