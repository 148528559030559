import React, { useEffect, useState } from "react";
import { Dimensions, Image, Platform, Text, View } from "react-native";
import {
  Button,
  Divider,
  IconButton,
  List,
  Surface,
  TouchableRipple,
} from "react-native-paper";
import { PERMISSION } from "../../../../constant/getAllPermission";
import {
  ErrorBoldColor,
  WarningBoldColor,
  primaryColor,
  whiteColor,
} from "../../../../constant/constantCss";
import { ids, styles } from "../../TripInfoCss.js";
import { showToast } from "../../../../constant/toastMessage.js";
import { useSelector } from "react-redux";
import {
  DeleteAnnouncementApi,
  GetAnnouncementApi,
} from "../../../../service/api/trip/AnnouncementApi.js";
import PlaneLoader from "../../../../components/Loader.jsx";
import noUpdates from "../../../../../assets/icons/noupdates.png";
import Octicons from "react-native-vector-icons/Octicons";
import moment from "moment";
import AnnouncementModal from "./Manage Updates/AddUpdates.jsx";
import EditUpdates from "./Manage Updates/EditUpdates.jsx";

const TripUpdates = ({ trip_id, announcement, setAnnouncement }) => {
  const { width } = Dimensions.get("screen");
  const [loader, setLoader] = useState(true);
  const { permission } = useSelector((state) => state.auth);
  const [refreshAPI, setRefreshAPI] = useState(false);
  const [announcementModalVisible, setAnnouncementModalVisible] =
    useState(false);
  const [activeAnnouncement, setActiveAnnouncement] = useState(null);

  const getAnnouncement = () => {
    setLoader(true);
    GetAnnouncementApi(trip_id)
      .then((res) => {
        setLoader(false);
        setAnnouncement(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        showToast("error", "Something went wrong");
      });
  };
  const deleteAnnouncement = (id) => {
    DeleteAnnouncementApi(id)
      .then((res) => {
        showToast("success", "Announcements deleted");
        getAnnouncement();
      })
      .catch((error) => {
        showToast("error", "Something went wrong");
      });
  };

  useEffect(() => {
    getAnnouncement();
  }, [refreshAPI]);

  return (
    <View style={{
      flexDirection: "column",
      flexDirection: "column",
      gap: 20,
      backgroundColor: whiteColor,
      padding: 16,
      marginHorizontal: 10,
      marginTop: 4,
      borderRadius: 10,
    }} id="updates">
      {/* header */}
      <View>
        {permission &&
          permission.includes(PERMISSION.CAN_CREATE_ANNOUNCEMENT) ? (
          <>
            <View style={styles.rowContainer}>
              <Text style={styles.headerKeyText}>Updates</Text>
              <View style={{ flexDirection: "row", gap: 10 }}>
                {width >= 576 ? (
                  <Button
                    icon={"plus"}
                    buttonColor={primaryColor}
                    textColor={whiteColor}
                    onPress={() => setAnnouncementModalVisible(true)}
                  >
                    Add Updates
                  </Button>
                ) : (
                  <IconButton
                    size={20}
                    icon={"plus"}
                    mode="contained"
                    style={{ backgroundColor: primaryColor }}
                    iconColor={whiteColor}
                    onPress={() => setAnnouncementModalVisible(true)}
                  />
                )}
              </View>
            </View>
            <Divider style={{ maxWidth: 500, padding: 0, marginBottom: 16 }} />
          </>
        ) : (
          <>
            {announcement?.length > 0 && (
              <>
                <Text style={styles.headerKeyText}>Updates</Text>
                <Divider
                  style={{ maxWidth: 500, padding: 0, marginBottom: 16 }}
                />
              </>
            )}
          </>
        )}
      </View>

      {/* loader */}
      {loader && <PlaneLoader />}
      {/* updates */}
      {announcement?.length > 0 ? (
        <>
          {announcement?.map((announcement, index) => (
            <View key={index}>
              <Surface elevation={0} style={styles.listStyle}>
                <List.Item
                  elevation={0}
                  title={announcement.description}
                  titleNumberOfLines={1500}
                  description={moment(announcement.created_at).format(
                    "MMM DD YYYY"
                  )}
                  style={{ cursor: "default", backgroundColor: whiteColor }}
                  left={(props) => (
                    <Octicons {...props} name="arrow-right" size={20} />
                  )}
                  right={(props) => (
                    <>
                      {permission &&
                        permission.includes(
                          PERMISSION.CAN_EDIT_ANNOUNCEMENT
                        ) && (
                          <IconButton
                            {...props}
                            iconColor={WarningBoldColor}
                            icon="pencil"
                            onPress={() => {
                              setActiveAnnouncement(announcement.id);
                            }}
                          />
                        )}
                      {permission &&
                        permission.includes(
                          PERMISSION.CAN_DELETE_ANNOUNCEMENT
                        ) && (
                          <IconButton
                            {...props}
                            iconColor={ErrorBoldColor}
                            icon="delete"
                            onPress={() => deleteAnnouncement(announcement.id)}
                          />
                        )}
                    </>
                  )}
                />
                {activeAnnouncement === announcement.id && (
                  <EditUpdates
                    announcement={announcement}
                    trip_id={trip_id}
                    handleClose={() => setActiveAnnouncement(null)}
                    setRefreshAPI={setRefreshAPI}
                  />
                )}
              </Surface>
            </View>
          ))}
        </>
      ) : permission &&
        permission.includes(PERMISSION.CAN_CREATE_ANNOUNCEMENT) ? (
        <View style={styles.noUpdateContainer}>
          <Image style={{ width: 150, height: 150 }} source={noUpdates} />
          <Text style={styles.noupdatesText}>There are no Updates</Text>
        </View>
      ) : null}

      {announcementModalVisible ? (
        <AnnouncementModal
          visible={announcementModalVisible}
          setAnnouncementModalVisible={setAnnouncementModalVisible}
          tripId={trip_id}
          setRefreshAPI={setRefreshAPI}
        />
      ) : null}
    </View>
  );
};

export default TripUpdates;
