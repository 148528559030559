import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const OTP_ENDPOINT = {
    GET_OTP : BASE_URL+"otp/",
}

export const GetOtpApi = async(type, data) =>{
    return axios.post(OTP_ENDPOINT.GET_OTP+type, data
    )
} 

export const VerifyOtpApi = async(type, data) =>{
    return axios.post(OTP_ENDPOINT.GET_OTP+type, data
    )
} 

    
    