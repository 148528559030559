import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const COMPANY_REVIEW_ENDPOINT = {
  COMPANY_REVIEW_LIST_CREATE_URL: BASE_URL + "company/review/",
  COMPANY_REVIEW_VALIDATE_URL: BASE_URL + "company/review/validate/",

  // super admin only
  LIST_ALL_COMPANY_REVIEW_URL: BASE_URL + "company/review/list/all/",
};

export const GetCompanyReview = async () => {
  return axios.get(COMPANY_REVIEW_ENDPOINT.COMPANY_REVIEW_LIST_CREATE_URL);
};

export const GetAllCompanyReview = async () => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.get(
      COMPANY_REVIEW_ENDPOINT.LIST_ALL_COMPANY_REVIEW_URL,
      headers
    );
  }
};

export const CreateCompanyReview = async (payload) => {
  return axios.post(
    COMPANY_REVIEW_ENDPOINT.COMPANY_REVIEW_LIST_CREATE_URL,
    payload
  );
};

export const ValidateCompanyReview = async (payload, reviewId) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.patch(
      `${COMPANY_REVIEW_ENDPOINT.COMPANY_REVIEW_VALIDATE_URL}${reviewId}/`,
      payload,
      headers
    );
  }
};
