import React, { useEffect, useState } from "react";
import { View, Text, ScrollView, Modal } from "react-native";
import { ids, styles } from "./modalCss";
import { Button, IconButton, TextInput } from "react-native-paper";
import {
    blackColor,
    inputTextColor,
    primaryColor,
} from "../../../../../constant/constantCss";
import { styles as ManageSectionStyles } from "../../../../TripDashboard/ManageSectionModal/ManageSectionModalCss";
import { CreateSectionTrips, DeleteSectionTrips, GetSections, GetSelectedSections } from "../../../../../service/api/trip/api";
import { showToast } from "../../../../../constant/toastMessage";

const AddSectionModal = ({
    visible,
    handleClose,
    trip_id,
}) => {
    const [sections, setSections] = useState([]);
    const [filteredSections, setFilteredSections] = useState([]);
    const [selectedSections, setSelectedSections] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loader, setLoader] = useState(false);

    const fetchData = () => {
        setLoader(true);
        GetSections().then((res) => {
            setSections(res.data.data);
            setFilteredSections(res.data.data);
            setLoader(false);
        }).catch(() => {
            showToast("error", "Something went wrong fetching sections!");
            handleClose();
            setLoader(false);
        });
    };

    const fetchSelected = (trip_id) => {
        setLoader(true);
        GetSelectedSections(trip_id).then((res) => {
            setSelectedSections(res.data.data);
            setLoader(false);
        }).catch(() => {
            showToast("error", "Something went wrong fetching selected sections!");
            handleClose();
            setLoader(false);
        });
    };

    useEffect(() => {
        if (trip_id) {
            fetchData();
            fetchSelected(trip_id);
        }
    }, [trip_id]);

    useEffect(() => {
        const filtered = sections.filter(section =>
            section.section_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredSections(filtered);
    }, [searchQuery, sections]);

    const handleAdd = (data) => {
        const formdata = new FormData();
        formdata.append("section_id", data.id);
        formdata.append("trip_id", trip_id);
        setLoader(true);
        CreateSectionTrips(formdata).then(() => {
            fetchData();
            fetchSelected(trip_id);
            setLoader(false);
        }).catch(() => {
            showToast("error", "Something went wrong!");
            handleClose();
            setLoader(false);
        });
    };

    const handleRemove = (data) => {
        const formdata = new FormData();
        formdata.append("section_id", data.id);
        formdata.append("trip_id", trip_id);
        DeleteSectionTrips(formdata).then(() => {
            fetchData();
            fetchSelected(trip_id);
        }).catch(() => {
            showToast("error", "Something went wrong!");
            handleClose();
        });
    };

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={handleClose}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
                    <View style={styles.headerContainer}>
                        <Text style={styles.headerText} dataSet={{ media: ids.headerText }}>
                            Add Trip to section/s
                        </Text>
                        <IconButton
                            icon={"close"}
                            iconColor={blackColor}
                            onPress={handleClose}
                        />
                    </View>
                    <TextInput
                        placeholder="Search Sections"
                        style={[ManageSectionStyles.textInputSearch, { marginTop: 0, marginBottom: 0 }]}
                        outlineColor={blackColor}
                        activeOutlineColor={inputTextColor}
                        mode="outlined"
                        value={searchQuery}
                        onChangeText={setSearchQuery}
                    />
                    <ScrollView style={{ width: "100%", marginVertical: 5 }}>
                        {(!filteredSections || filteredSections.length===0) && <Text style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>No section/s found!</Text>}
                        {filteredSections && filteredSections.map((data, index) => {
                            return (
                                <View style={{ width: "100%", marginVertical: 5 }} key={index}>
                                    <View
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginVertical: 5, paddingHorizontal: 5 }} key={index}>
                                        <View>
                                            <Text style={{ fontSize: 18, fontWeight: 500 }}>{data.section_name}</Text>
                                        </View>
                                        {selectedSections.find((section) => section.id === data.id) ?
                                            <Button buttonColor={primaryColor} textColor="white" icon={"minus"} onPress={() => handleRemove(data)} loading={loader} disabled={loader}>Remove</Button> :
                                            <Button buttonColor={primaryColor} textColor="white" icon={"plus"} onPress={() => handleAdd(data)} loading={loader} disabled={loader}>Add</Button>}
                                    </View>
                                    <View style={{ borderBottomWidth: 2, borderColor: "grey", marginVertical: 5 }} />
                                </View>
                            )
                        })}
                    </ScrollView>
                </View>
            </View>
        </Modal>
    );
};

export default AddSectionModal;
