import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  Platform,
  RefreshControl,
  Text,
  TouchableOpacity,
} from "react-native";
import { Divider, Button, SegmentedButtons } from "react-native-paper";
import { ids, styles } from "./TripInfoCss.js";
import TripInfo from "./ShowDetails/component/TripInfo.jsx";
import TripCard from "./TripCard/TripCard.jsx";
import TripUpdates from "./ShowDetails/component/TripUpdates.jsx";
import TripAlbums from "./ShowDetails/component/TripAlbums.jsx";
import TripRating from "./ShowDetails/component/TripRating.jsx";
import TripParticipant from "./ShowDetails/component/TripParticipant.jsx";
import { GetTripApi } from "../../service/api/trip/getTripApi.js";
import ContactUs from "../LnadingPage/components/ContactUs.jsx";
import PlaneLoader from "../../components/Loader.jsx";
import {
  primaryColor,
  whiteColor,
} from "../../constant/constantCss.js";
import { PERMISSION } from "../../constant/getAllPermission.js";
import { useSelector } from "react-redux";
import TripAdditionalInfo from "./ShowDetails/component/TripAdditionalInfo.jsx";
import AppBar from "../Layout/AppBar.jsx";
import PublicAppBar from "../Layout/PublicAppBar.jsx";
import TripItenaryRedirector from "./ShowDetails/component/TripItenaryRedirector.jsx";
import BookNowModal from "./Book Now/BookNowModal.jsx";
import MaterialIcons from "react-native-vector-icons/MaterialIcons"
import TripInfoRedirector from "./ShowDetails/component/TripInfoRedirector.jsx";
import { GetAlbumsApi } from "../../service/api/trip/albums.js";
import { GetAnnouncementApi } from "../../service/api/trip/AnnouncementApi.js";


const ShowTripInfo = ({ route }) => {
  const slug_url = route?.params?.data;
  const [tabValue, setTabValue] = useState("overview");
  const [loader, setLoader] = useState(true);
  const [tripDetails, setTripDetails] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [refreshAPI, setRefreshAPI] = useState(false);
  const { permission, isLogin } = useSelector((state) => state.auth);
  const [id, setId] = useState();

  const [announcement, setAnnouncement] = useState([]);
  const [albumList, setAlbumList] = useState([]);

  const [isVisibleBookNowModal, setIsVisibleBookNowModal] = useState(false);


  const currentButtonStyle = (val) => ({
    borderWidth: 0,
    borderBottomWidth: 2,
    borderRadius: 2,
    backgroundColor: whiteColor,
    borderColor: tabValue === val ? primaryColor : whiteColor,
  });

  const [tabButton, setTabButton] = useState([
    {
      value: "overview",
      label: "Overview",
      style: currentButtonStyle("overview"),
    },
    {
      value: "itinerary",
      label: "Itinerary",
      style: currentButtonStyle("itinerary"),
    },
    ...(announcement.length > 0 ||
      (permission && permission.includes(PERMISSION.CAN_CREATE_ANNOUNCEMENT))
      ? [
        {
          value: "updates",
          label: "Updates",
          style: currentButtonStyle("updates"),
        },
      ]
      : []),
    ...(albumList.length > 0 ||
      (permission && permission.includes(PERMISSION.CAN_UPLOAD_ALBUMS))
      ? [
        {
          value: "attractions",
          label: "Attractions",
          style: currentButtonStyle("attractions"),
        },
      ]
      : []),
    {
      value: "review",
      label: "Reviews",
      style: currentButtonStyle("review"),
    },
    ...(permission && permission.includes(PERMISSION.CAN_ADD_CASH_PAYMENT)
      ? [
        {
          value: "participants",
          label: "Participants",
          style: currentButtonStyle("participants"),
        },
      ]
      : []),
  ]);
  const removeButton = (buttonsTobePushed, buttonValue) => {
    const index = buttonsTobePushed.findIndex(
      (button) => button.value === buttonValue
    );
    if (index !== -1) {
      buttonsTobePushed.splice(index, 1);
    }
  };

  const isButtonAlreadyPresent = (buttonsTobePushed, buttonValue) =>
    buttonsTobePushed.some((button) => button.value === buttonValue);

  const initalCallingApi = () => {
    GetTripApi(slug_url)
      .then((res) => {
        setTripDetails(res?.data?.data);
        setId(res?.data?.data.id);
        const buttonsTobePushed = [...tabButton]; // Copying the current state

        // Push buttons based on data availability
        if (res?.data?.data?.inclusion) {
          if (!isButtonAlreadyPresent(buttonsTobePushed, "Inclusion")) {
            buttonsTobePushed.push({
              value: "Inclusion",
              label: "Inclusion",
              style: currentButtonStyle("itinerary"),
            });
          }
        } else {
          // Remove button if not found
          removeButton(buttonsTobePushed, "Inclusion");
        }

        if (res?.data?.data?.exclusion) {
          if (!isButtonAlreadyPresent(buttonsTobePushed, "Exclusion")) {
            buttonsTobePushed.push({
              value: "Exclusion",
              label: "Exclusion",
              style: currentButtonStyle("itinerary"),
            });
          }
        } else {
          // Remove button if not found
          removeButton(buttonsTobePushed, "Exclusion");
        }

        if (res?.data?.data?.remarks) {
          if (!isButtonAlreadyPresent(buttonsTobePushed, "Remarks")) {
            buttonsTobePushed.push({
              value: "Remarks",
              label: "Remarks",
              style: currentButtonStyle("itinerary"),
            });
          }
        } else {
          // Remove button if not found
          removeButton(buttonsTobePushed, "Remarks");
        }

        // Set tab buttons
        setTabButton(buttonsTobePushed);

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (id) {
      GetAlbumsApi(id)
        .then((res) => {
          if (albumList) {
            setAlbumList(res.data.data);
          }
          setLoader(false);
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.detail);
          setLoader(false);
        });
      GetAnnouncementApi(id)
        .then((res) => {
          setLoader(false);
          setAnnouncement(res.data.data);
        })
        .catch((err) => {
          setLoader(false);
          showToast("error", "Something went wrong");
        });
    }
  }, [id])

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initalCallingApi();
      setRefreshing(false);
    }, 1000);
  }, []);

  const handleEnrollTrip = () => {
    setIsVisibleBookNowModal(true);
  };

  useEffect(() => {
    initalCallingApi();
  }, [slug_url, refreshAPI]);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <View style={{ backgroundColor: whiteColor }}>
      {isLogin ? <AppBar /> : <PublicAppBar />}
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={primaryColor}
            colors={[primaryColor]}
          />
        }
        style={{ marginBottom: Platform.OS === "ios" ? 110 : Platform.OS !== "web" && 60 }}
      >
        <View style={styles.profileContainer}>
          {loader ? (
            <View style={{ width: "100%", height: "100%", backgroundColor: "white" }}>
              <PlaneLoader />
            </View>
          ) : (
            <View style={[styles.userProfile]} dataSet={{ media: ids.userProfile }}>
              <View style={[Platform.OS === "web" && {shadowColor: "black",
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.5,
                shadowRadius: 8, padding: 16,},{
                marginTop: 8,
                borderRadius: 10
              }]}>
                <TripCard
                  tripDetails={tripDetails}
                  setRefreshAPI={setRefreshAPI}
                />
                {Platform.OS === "web" && <View style={styles.tabView}>
                  <ScrollView horizontal={true}>
                    <SegmentedButtons
                      style={{ width: Platform.OS === "web" ? "150%" : "100%" }}
                      value={tabValue}
                      onValueChange={(text) => {
                        setTabValue(text);
                        scrollToSection(text); // Scroll to the selected section
                      }}
                      buttons={tabButton}
                    />
                  </ScrollView>
                </View>}
              </View>
              {/* info section */}
              <View
                style={[Platform.OS === "web" && {shadowColor: "black",
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.5,
                  shadowRadius: 8},{
                  flexDirection: "column",
                  gap: 20,
                  marginTop: 12,
                  borderRadius: 10,
                  padding: 16
                }]}
              >
                <TripInfoRedirector
                  tripDetails={tripDetails}
                  trip_id={id}
                  setRefreshAPI={setRefreshAPI}
                // setComponentHeight={onTripInfoLayout}
                />
                <TripItenaryRedirector trip_id={id} />
                {isVisibleBookNowModal ? (
                  <BookNowModal
                    visible={isVisibleBookNowModal}
                    handleClose={() => setIsVisibleBookNowModal(false)}
                    ageRating={tripDetails?.min_age_limit}
                    trip_id={tripDetails?.id}
                    tripTitle={tripDetails?.title}
                    tripDepartuureDate={tripDetails?.departure_date}
                    noOfDays={tripDetails?.no_of_days}
                  />
                ) : null}
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", width: "100%" }}>
                  <Button
                    style={{
                      backgroundColor: primaryColor,
                      borderColor: primaryColor,
                      maxWidth: 480,
                      width: "100%",
                    }}
                    textColor={whiteColor}
                    onPress={handleEnrollTrip}
                  >
                    Book Now
                  </Button>
                </View>
                {permission &&
                  permission.includes(PERMISSION.CAN_ADD_CASH_PAYMENT) && (
                    <>
                      <TripParticipant tripId={id} tripDetails={tripDetails} />
                    </>
                  )}
                {tripDetails?.inclusion ? (
                  <TripAdditionalInfo
                    title={"Inclusion"}
                    data={tripDetails.inclusion}
                  />
                ) : (
                  <></>
                )}
                {tripDetails?.exclusion ? (
                  <TripAdditionalInfo
                    title={"Exclusion"}
                    data={tripDetails.exclusion}
                  />
                ) : (
                  <></>
                )}
                {tripDetails?.remarks ? (
                  <TripAdditionalInfo
                    title={"Remarks"}
                    data={tripDetails.remarks}
                  />
                ) : (
                  <></>
                )}
              </View>
              {((announcement.length > 0) || (permission &&
                permission.includes(PERMISSION.CAN_DELETE_ALBUMS))) && 
                <View style={[
                  Platform.OS === "web" && {
                  shadowColor: "black",
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.5,
                  shadowRadius: 8, padding: 16}
                  ,{
                  marginTop: 8,
                  borderRadius: 10
                }]}>
                  {<TripUpdates trip_id={id} announcement={announcement} setAnnouncement={setAnnouncement} loader={loader} />}
                </View>}
              {((albumList.length > 0) || (permission &&
                permission.includes(PERMISSION.CAN_DELETE_ALBUMS))) && 
                <View style={[Platform.OS === "web" && {
                  shadowColor: "black",
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.5,
                  shadowRadius: 8, padding: 16,
                },{
                  marginTop: 8,
                  borderRadius: 10
                }]}>
                  {<TripAlbums tripId={id} albumList={albumList} setAlbumList={setAlbumList} />}
                </View>}
              <View style={[Platform.OS === "web" && {
                shadowColor: "black",
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.5,
                shadowRadius: 8, padding: 16,
              },{
                marginTop: 8,
                borderRadius: 10
              }]}>
                <TripRating tripId={id} />
              </View>
            </View>
          )}
        </View>
        {Platform.OS !== "web" && <ContactUs />}
      </ScrollView>
      {Platform.OS === "web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',
            bottom: 20,
            right: 20,
            marginTop: -44,
            alignSelf: 'flex-end',
            borderRadius: 50,
            borderRadius: 66,
            backgroundColor: "#ffb4ac",
          }}
          onPress={handleScrollToTop}
        >
          <MaterialIcons name="keyboard-arrow-up" style={{ fontSize: 42 }} />
        </TouchableOpacity>
        </>
      }
    </View>
  );
};

export default ShowTripInfo;