import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Modal,
  Dimensions,
  ScrollView,
  Platform,
} from "react-native";
import { Button, IconButton, TextInput } from "react-native-paper";
import { useForm, Controller } from "react-hook-form";
import { ids, styles } from "./CashPaymentModalCss";
import {
  inputTextColor,
  primaryColor,
  subTextColor,
} from "../../../../../constant/constantCss";
import { useSelector } from "react-redux";
import { Picker } from "@react-native-picker/picker";
import { GetAllserAPI } from "../../../../../service/api/user/getUserApi";
import { showToast } from "../../../../../constant/toastMessage";
import { CreateCashPayment } from "../../../../../service/api/payment/api";
import { GetTripParticipants } from "../../../../../service/api/trip/api";

const CashPaymentModal = ({
  visible,
  handleClose,
  setParticipantList,
  amount,
  title,
  trip_id,
}) => {
  const screenWidth = Dimensions.get("window").width;
  const roundness = screenWidth > 768 ? 20 : 8;
  const [loader, setloader] = useState(false);
  const { phone_number } = useSelector((state) => state.auth);
  const [userList, setUserList] = useState([]);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      entity: "payment",
      method: "cash",
      amount: amount,
      currency: "INR",
      description: title,
      contact: phone_number,
      user_id: null,
      notes: {},
      status: "captured",
      captured: true,
    },
  });
  const onSubmitData = (data) => {
    setloader(true);
    data.amount = data.amount * 100;
    data.notes = { trip_id: trip_id, user_id: data.user_id };
    CreateCashPayment(data)
      .then((res) => {
        showToast("success", res?.data?.message);
        GetTripParticipants(trip_id)
          .then((res) => {
            setloader(false);
            setParticipantList(res?.data);
            handleClose();
          })
          .catch((err) => {
            setloader(false);
            showToast("error", err?.response?.data?.detail);
          });
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        setloader(false);
      });
  };
  useEffect(() => {
    GetAllserAPI({ isPagination: "false" })
      .then((res) => {
        setUserList(res.data);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        setloader(false);
      });
  }, []);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Cash payment</Text>
            <Text style={styles.closeButton} onPress={handleClose}>
              X
            </Text>
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Method</Text>
                <TextInput
                  disabled
                  style={styles.textInput}
                  dataSet={{ media: ids.textInput }}
                  defaultValue={"cash"}
                  theme={{
                    roundness: roundness,
                  }}
                  mode="outlined"
                  outlineColor={inputTextColor}
                  activeOutlineColor={inputTextColor}
                />
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Amount</Text>

                <TextInput
                  disabled
                  left={
                    <TextInput.Icon
                      size={18}
                      icon={"currency-inr"}
                      color={subTextColor}
                    />
                  }
                  style={styles.textInput}
                  dataSet={{ media: ids.textInput }}
                  defaultValue={String(getValues("amount"))}
                  theme={{
                    roundness: roundness,
                  }}
                  mode="outlined"
                  outlineColor={inputTextColor}
                  activeOutlineColor={inputTextColor}
                />
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Trip</Text>
                <TextInput
                  disabled
                  style={styles.textInput}
                  dataSet={{ media: ids.textInput }}
                  defaultValue={getValues("description")}
                  theme={{
                    roundness: roundness,
                  }}
                  mode="outlined"
                  outlineColor={inputTextColor}
                  activeOutlineColor={inputTextColor}
                />
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Phone number</Text>
                <TextInput
                  disabled
                  style={styles.textInput}
                  dataSet={{ media: ids.textInput }}
                  defaultValue={getValues("contact")}
                  theme={{
                    roundness: roundness,
                  }}
                  mode="outlined"
                  outlineColor={inputTextColor}
                  activeOutlineColor={inputTextColor}
                />
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>User*</Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Picker
                      selectedValue={value}
                      onValueChange={(itemValue) =>
                        setValue("user_id", itemValue)
                      }
                      style={styles.picker}
                    >
                      {userList?.map((user, index) => (
                        <Picker.Item
                          key={index}
                          label={user.first_name}
                          value={user.id}
                          contentDescription={user?.is_active ? "true" : "false"}
                        />
                      ))}
                    </Picker>
                  )}
                  name="user_id"
                />
                {errors.user_id && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    Please select any user
                  </Text>
                )}
              </View>

              <View style={styles.inputView}>
                <Button
                  loading={loader}
                  mode="contained"
                  buttonColor={primaryColor}
                  onPress={handleSubmit(onSubmitData)}
                >
                  Save
                </Button>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default CashPaymentModal;
