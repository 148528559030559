import React, { useState } from "react";
import { ids, styles } from "./modal.js";
import {
  View,
  Text,
  Modal,
  ScrollView,
  Linking,
  Image,
  Platform,
  Dimensions,
  TouchableWithoutFeedback,
} from "react-native";
import personalizedTripHeader from "../../../assets/landingPage/personalizedTripHeader.png";
import personalizedTripHeaderMobile from "../../../assets/landingPage/personalizedTripHeaderMobile.png";
import {
  blackColor,
  iconColor,
  inputBackColor,
  inputTextColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../constant/constantCss";
import {
  Button,
  Divider,
  IconButton,
  Surface,
  TextInput,
} from "react-native-paper";
import { useForm, Controller } from "react-hook-form";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { Dropdown } from "react-native-element-dropdown";
import moment from "moment";
import { CreateTripInquiryFormAPI } from "../../service/api/trip inquiry/api.js";
import { showToast } from "../../constant/toastMessage.js";
import AppBar from "../Layout/AppBar.jsx";
import ContactUs from "../LnadingPage/components/ContactUs.jsx";
import Navbar from "../LnadingPage/components/Navbar.jsx";
import { useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import PublicAppBar from "../Layout/PublicAppBar.jsx";

const PersonalizedTripModal = () => {
  const { height, width } = Dimensions.get("screen");
  const authState = useSelector((state) => state.auth);
  const navigation = useNavigation();
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      adult: "0 Adult",
      child: "0 Child",
      infant: "0 Infant",
    },
  });
  const referenceList = [
    { label: "Instagram", value: "instagram" },
    { label: "Facebook", value: "facebook" },
    { label: "Travel Triangle", value: "travel triangle" },
    { label: "Whats App", value: "whatsapp" },
    { label: "Others", value: "other" },
  ];
  const HotelTypeList = [
    { label: "5 Star", value: "5 star" },
    { label: "4 Star", value: "4 star" },
    { label: "3 Star", value: "3 star" },
    { label: "Budget-Friendly", value: "Budget friendly" },
  ];
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const addNumber = (number, key, key_name) => {
    let val = number?.split(" ")[0];
    val = parseInt(val, 10) + 1; // Convert val to a number and increment by 1
    if (val > 1) {
      setValue(key, `${val} ${key_name}s`);
    } else {
      setValue(key, `${val} ${key_name}`);
    }
  };
  const minusNumber = (number, key, key_name) => {
    let val = number?.split(" ")[0];
    val = parseInt(val, 10) - 1; // Convert val to a number and increment by 1
    if (val >= 0) {
      if (val > 1) {
        setValue(key, `${val} ${key_name}s`);
      } else {
        setValue(key, `${val} ${key_name}`);
      }
    }
  };
  const submitForm = (data) => {
    data.adult = data.adult?.split(" ")[0];
    data.child = data.child?.split(" ")[0];
    data.infant = data.infant?.split(" ")[0];
    CreateTripInquiryFormAPI(data)
      .then((res) => {
        showToast(
          "success",
          "Thank you for sending Inquiry form",
          "Will get you back via mobile or email very soon"
        );
        navigation.navigate("dashboard");
      })
      .catch((err) => {
        showToast("error", "");
      });
  };
  const handleConfirm = (date) => {
    const formatDate = moment(date).format("DD/MM/YYYY");
    setValue("arrival_date", formatDate);
    setIsDatePickerVisible(false);
  };

  return (
    <View
      style={{ backgroundColor: "white", minHeight: height, width: "auto" }}
    >
      <Navbar hideComponents={true}/>
      <ScrollView>
        <View
          style={{
            backgroundColor: whiteColor,
            marginBottom:
              Platform.OS === "ios" ? 50 : Platform.OS !== "web" && 60,
          }}
        >
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <View style={styles.centeredView}>
              {/* header */}
              <View style={styles.headerContainer}>
                <Image
                  resizeMode={width >= 576 ? "center" : "contain"}
                  source={
                    width >= 576
                      ? personalizedTripHeader
                      : personalizedTripHeaderMobile
                  }
                  style={{ height: 100, flex: 1 }}
                />
              </View>

              {/* content */}
              <ScrollView style={{ width: "100%" }}>
                <View style={styles.bodyMainContainer}>
                  <Text style={{ fontSize: 18, color: primaryColor }}>
                    <MaterialCommunityIcons name="account-outline" size={20} />{" "}
                    Share your information to understand your needs better
                  </Text>
                  <Divider style={{ marginTop: 6 }} />
                  <View
                    style={styles.bodyContainer}
                    dataSet={{ media: ids.bodyContainer }}
                  >
                    {/* left container */}
                    <View style={styles.leftBodyContainer}>
                      <View
                        style={styles.inputView}
                        dataSet={{ media: ids.inputView }}
                      >
                        <Text style={styles.inputText}>How Many People</Text>
                        {/* adult */}
                        <Controller
                          control={control}
                          name="adult"
                          render={({ field: { onChange, value } }) => (
                            <TextInput
                              value={value}
                              onChangeText={onChange}
                              style={styles.textInput}
                              disabled={true}
                              dataSet={{ media: ids.textInput }}
                              mode="outlined"
                              outlineColor={inputTextColor}
                              activeOutlineColor={inputTextColor}
                              contentStyle={{ color: blackColor }}
                              right={
                                <TextInput.Icon
                                  size={10}
                                  icon={() => (
                                    <MaterialCommunityIcons
                                      name="plus"
                                      color={iconColor}
                                    />
                                  )}
                                  onPress={() => {
                                    addNumber(
                                      getValues("adult"),
                                      "adult",
                                      "Adult"
                                    );
                                  }}
                                  style={{
                                    borderWidth: 1,
                                    borderRadius: 20,
                                    borderColor: iconColor,
                                  }}
                                />
                              }
                              left={
                                <TextInput.Icon
                                  size={10}
                                  icon={() => (
                                    <MaterialCommunityIcons
                                      name="minus"
                                      color={iconColor}
                                    />
                                  )}
                                  style={{
                                    borderWidth: 1,
                                    borderRadius: 20,
                                    borderColor: iconColor,
                                  }}
                                  onPress={() => {
                                    minusNumber(
                                      getValues("adult"),
                                      "adult",
                                      "Adult"
                                    );
                                  }}
                                />
                              }
                            />
                          )}
                        />
                        {errors.adult && (
                          <Text
                            style={styles.inputError}
                            dataSet={{ media: ids.inputError }}
                          >
                            {errors.adult.message}
                          </Text>
                        )}
                        {/* child */}
                        <Controller
                          control={control}
                          name="child"
                          render={({ field: { onChange, value } }) => (
                            <TextInput
                              value={value}
                              onChangeText={onChange}
                              disabled={true}
                              style={[styles.textInput, { marginTop: 10 }]}
                              dataSet={{ media: ids.textInput }}
                              mode="outlined"
                              outlineColor={inputTextColor}
                              activeOutlineColor={inputTextColor}
                              contentStyle={{ color: blackColor }}
                              right={
                                <TextInput.Icon
                                  size={10}
                                  icon={() => (
                                    <MaterialCommunityIcons
                                      name="plus"
                                      color={iconColor}
                                    />
                                  )}
                                  style={{
                                    borderWidth: 1,
                                    borderRadius: 20,
                                    borderColor: iconColor,
                                  }}
                                  onPress={() =>
                                    addNumber(
                                      getValues("child"),
                                      "child",
                                      "Child"
                                    )
                                  }
                                />
                              }
                              left={
                                <TextInput.Icon
                                  size={10}
                                  icon={() => (
                                    <MaterialCommunityIcons
                                      name="minus"
                                      color={iconColor}
                                    />
                                  )}
                                  style={{
                                    borderWidth: 1,
                                    borderRadius: 20,
                                    borderColor: iconColor,
                                  }}
                                  onPress={() => {
                                    minusNumber(
                                      getValues("child"),
                                      "child",
                                      "Child"
                                    );
                                  }}
                                />
                              }
                            />
                          )}
                        />
                        {errors.child && (
                          <Text
                            style={styles.inputError}
                            dataSet={{ media: ids.inputError }}
                          >
                            {errors.child.message}
                          </Text>
                        )}
                        {/* infants */}
                        <Controller
                          control={control}
                          name="infant"
                          render={({ field: { onChange, value } }) => (
                            <TextInput
                              value={value}
                              onChangeText={onChange}
                              disabled={true}
                              style={[styles.textInput, { marginTop: 10 }]}
                              dataSet={{ media: ids.textInput }}
                              mode="outlined"
                              outlineColor={inputTextColor}
                              activeOutlineColor={inputTextColor}
                              contentStyle={{ color: blackColor }}
                              right={
                                <TextInput.Icon
                                  size={10}
                                  icon={() => (
                                    <MaterialCommunityIcons
                                      name="plus"
                                      color={iconColor}
                                    />
                                  )}
                                  style={{
                                    borderWidth: 1,
                                    borderRadius: 20,
                                    borderColor: iconColor,
                                  }}
                                  onPress={() =>
                                    addNumber(
                                      getValues("infant"),
                                      "infant",
                                      "Infant"
                                    )
                                  }
                                />
                              }
                              left={
                                <TextInput.Icon
                                  size={10}
                                  icon={() => (
                                    <MaterialCommunityIcons
                                      name="minus"
                                      color={iconColor}
                                    />
                                  )}
                                  style={{
                                    borderWidth: 1,
                                    borderRadius: 20,
                                    borderColor: iconColor,
                                  }}
                                  onPress={() => {
                                    minusNumber(
                                      getValues("infant"),
                                      "infant",
                                      "Infant"
                                    );
                                  }}
                                />
                              }
                            />
                          )}
                        />
                        {errors.infant && (
                          <Text
                            style={styles.inputError}
                            dataSet={{ media: ids.inputError }}
                          >
                            {errors.infant.message}
                          </Text>
                        )}
                      </View>
                      <View
                        style={styles.flexRowContainer}
                        dataSet={{ media: ids.flexRowContainer }}
                      >
                        {/* arrival date */}
                        <View
                          style={[styles.inputView, { flex: 1 }]}
                          dataSet={{ media: ids.inputView }}
                        >
                          <Text style={styles.inputText}>
                            Your Arrival Date
                          </Text>
                          <Controller
                            control={control}
                            rules={{
                              required: "Arrival Date field is required",
                            }}
                            name="arrival_date"
                            render={({ field: { onChange, value } }) => (
                              <>
                                {Platform.OS === "web" ? (
                                  <input
                                    type="date"
                                    style={styles.dateTimeInput}
                                    onChange={(event) =>
                                      setValue(
                                        "arrival_date",
                                        event.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <>
                                    <TextInput
                                      value={value}
                                      disabled={true}
                                      placeholder="dd-mm-yyyy"
                                      placeholderTextColor="rgba(0, 0, 0, 0.5)"
                                      style={styles.textInput}
                                      dataSet={{ media: ids.textInput }}
                                      mode="outlined"
                                      outlineColor={inputTextColor}
                                      activeOutlineColor={inputTextColor}
                                      contentStyle={{ color: blackColor }}
                                      right={
                                        <TextInput.Icon
                                          size={20}
                                          icon={"calendar"}
                                          color={iconColor}
                                          onPress={() =>
                                            setIsDatePickerVisible(true)
                                          }
                                        />
                                      }
                                    />
                                    <DateTimePickerModal
                                      isVisible={isDatePickerVisible}
                                      mode={"date"}
                                      onConfirm={handleConfirm}
                                      onCancel={() => {
                                        setIsDatePickerVisible(false);
                                      }}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          />

                          {errors.arrival_date && (
                            <Text
                              style={styles.inputError}
                              dataSet={{ media: ids.inputError }}
                            >
                              {errors.arrival_date.message}
                            </Text>
                          )}
                        </View>
                        {/* no of daya */}
                        <View
                          style={[styles.inputView, { flex: 1 }]}
                          dataSet={{ media: ids.inputView }}
                        >
                          <Text style={styles.inputText}>No Of Days</Text>
                          <Controller
                            control={control}
                            rules={{
                              required: "No Of Days field is required",
                              pattern: {
                                value: /^[0-9]{1,}$/,
                                message: "Only number is allowed",
                              },
                            }}
                            name="no_of_days"
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                value={value}
                                maxLength={3}
                                placeholder="0 days"
                                placeholderTextColor="rgba(0, 0, 0, 0.5)"
                                onChangeText={onChange}
                                style={styles.textInput}
                                dataSet={{ media: ids.textInput }}
                                mode="outlined"
                                outlineColor={inputTextColor}
                                activeOutlineColor={inputTextColor}
                                contentStyle={{ color: blackColor }}
                              />
                            )}
                          />
                          {errors.no_of_days && (
                            <Text
                              style={styles.inputError}
                              dataSet={{ media: ids.inputError }}
                            >
                              {errors.no_of_days.message}
                            </Text>
                          )}
                        </View>
                      </View>
                      {/* hotel type */}
                      <View
                        style={styles.inputView}
                        dataSet={{ media: ids.inputView }}
                      >
                        <Text style={styles.inputText}>Hotel Type</Text>
                        <Controller
                          control={control}
                          rules={{
                            required: "Hotel Type field is required",
                          }}
                          name="hotel_type"
                          render={({ field: { onChange, value } }) => (
                            <Dropdown
                              data={HotelTypeList}
                              labelField="label"
                              valueField="value"
                              mode={"auto"}
                              value={watch("hotel_type")}
                              style={{
                                paddingHorizontal: 10,
                                borderWidth: 1,
                                borderColor: inputTextColor,
                                borderRadius: 5,
                                backgroundColor: whiteColor,
                              }}
                              placeholderStyle={{
                                color: "rgba(0, 0, 0, 0.5)",
                                fontWeight: 600,
                              }}
                              placeholder="Hotel Type"
                              placeholderTextColor="rgba(0, 0, 0, 0.5)"
                              onChange={(item) => {
                                setValue("hotel_type", item.value);
                              }}
                            />
                          )}
                        />
                        {errors.hotel_type && (
                          <Text
                            style={styles.inputError}
                            dataSet={{ media: ids.inputError }}
                          >
                            {errors.hotel_type.message}
                          </Text>
                        )}
                      </View>
                      {/* What Would be Your Ideal Trip? */}
                      <View
                        style={styles.inputView}
                        dataSet={{ media: ids.inputView }}
                      >
                        <Text style={styles.inputText}>
                          What Would Be Your Ideal Trip?
                        </Text>
                        <Controller
                          control={control}
                          rules={{
                            required: "Hotel Type field is required",
                          }}
                          name="description"
                          render={({ field: { onChange, value } }) => (
                            <TextInput
                              value={value}
                              numberOfLines={4}
                              multiline
                              placeholder="want to go Goa, Udaipur etc."
                              placeholderTextColor="rgba(0, 0, 0, 0.5)"
                              onChangeText={onChange}
                              style={styles.textAreaInput}
                              mode="outlined"
                              outlineColor={inputTextColor}
                              activeOutlineColor={inputTextColor}
                              contentStyle={{ color: blackColor }}
                            />
                          )}
                        />
                        {errors.description && (
                          <Text
                            style={styles.inputError}
                            dataSet={{ media: ids.inputError }}
                          >
                            {errors.description.message}
                          </Text>
                        )}
                      </View>
                    </View>
                    {/* right container */}
                    <View
                      style={styles.rightBodyContainer}
                      dataSet={{ media: ids.rightBodyContainer }}
                    >
                      {/* name */}
                      <View
                        style={styles.inputView}
                        dataSet={{ media: ids.inputView }}
                      >
                        <Text style={styles.inputText}>Name</Text>
                        <Controller
                          control={control}
                          rules={{
                            required: "Name field is required",
                          }}
                          name="name"
                          render={({ field: { onChange, value } }) => (
                            <TextInput
                              value={value}
                              placeholder="Enter your name"
                              placeholderTextColor="rgba(0, 0, 0, 0.5)"
                              onChangeText={onChange}
                              style={styles.textInput}
                              dataSet={{ media: ids.textInput }}
                              mode="outlined"
                              outlineColor={inputTextColor}
                              activeOutlineColor={inputTextColor}
                              contentStyle={{ color: blackColor }}
                            />
                          )}
                        />
                        {errors.name && (
                          <Text
                            style={styles.inputError}
                            dataSet={{ media: ids.inputError }}
                          >
                            {errors.name.message}
                          </Text>
                        )}
                      </View>
                      <View
                        style={styles.inputView}
                        dataSet={{ media: ids.inputView }}
                      >
                        <Text style={styles.inputText}>Email</Text>
                        <Controller
                          control={control}
                          rules={{
                            required: "Email field is required",
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Invalid email id",
                            },
                          }}
                          name="email"
                          render={({ field: { onChange, value } }) => (
                            <TextInput
                              value={value}
                              placeholder="Enter your email"
                              placeholderTextColor="rgba(0, 0, 0, 0.5)"
                              onChangeText={onChange}
                              style={styles.textInput}
                              dataSet={{ media: ids.textInput }}
                              mode="outlined"
                              outlineColor={inputTextColor}
                              activeOutlineColor={inputTextColor}
                              contentStyle={{ color: blackColor }}
                            />
                          )}
                        />
                        {errors.email && (
                          <Text
                            style={styles.inputError}
                            dataSet={{ media: ids.inputError }}
                          >
                            {errors.email.message}
                          </Text>
                        )}
                      </View>
                      {/* country */}
                      <View
                        style={styles.inputView}
                        dataSet={{ media: ids.inputView }}
                      >
                        <Text style={styles.inputText}>Country</Text>
                        <Controller
                          control={control}
                          rules={{
                            required: "Country field is required",
                          }}
                          name="country"
                          render={({ field: { onChange, value } }) => (
                            <TextInput
                              value={value}
                              placeholder="e.g. india"
                              placeholderTextColor="rgba(0, 0, 0, 0.5)"
                              onChangeText={onChange}
                              style={styles.textInput}
                              dataSet={{ media: ids.textInput }}
                              mode="outlined"
                              outlineColor={inputTextColor}
                              activeOutlineColor={inputTextColor}
                              contentStyle={{ color: blackColor }}
                            />
                          )}
                        />
                        {errors.country && (
                          <Text
                            style={styles.inputError}
                            dataSet={{ media: ids.inputError }}
                          >
                            {errors.country.message}
                          </Text>
                        )}
                      </View>
                      <View
                        style={{ flexDirection: "row", gap: 16, width: "100%" }}
                      >
                        <View
                          style={[styles.inputView, { flex: 1, maxWidth: 60 }]}
                          dataSet={{ media: ids.inputView }}
                        >
                          <Text style={styles.inputText}>Code</Text>
                          <Controller
                            control={control}
                            rules={{
                              required: "Country Code field is required",
                              pattern: {
                                value: /^\+[1-9]\d{0,3}$/,
                                message: "Invalid country code +91",
                              },
                            }}
                            name="country_code"
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                value={value}
                                placeholder="+91"
                                placeholderTextColor="rgba(0, 0, 0, 0.5)"
                                onChangeText={onChange}
                                style={styles.textInput}
                                dataSet={{ media: ids.textInput }}
                                mode="outlined"
                                outlineColor={inputTextColor}
                                activeOutlineColor={inputTextColor}
                                contentStyle={{ color: blackColor }}
                              />
                            )}
                          />
                          {errors.country_code && (
                            <Text
                              style={styles.inputError}
                              dataSet={{ media: ids.inputError }}
                            >
                              {errors.country_code.message}
                            </Text>
                          )}
                        </View>
                        {/* contact number */}
                        <View
                          style={[styles.inputView, { flex: 1 }]}
                          dataSet={{ media: ids.inputView }}
                        >
                          <Text style={styles.inputText}>Contact No</Text>
                          <Controller
                            control={control}
                            rules={{
                              required: "Contact No field is required",
                              pattern: {
                                value: /^[0-9]{1,}$/,
                                message: "Invalid contact number",
                              },
                            }}
                            name="phone_number"
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                value={value}
                                placeholder="0123456789"
                                placeholderTextColor="rgba(0, 0, 0, 0.5)"
                                onChangeText={onChange}
                                style={styles.textInput}
                                dataSet={{ media: ids.textInput }}
                                mode="outlined"
                                outlineColor={inputTextColor}
                                activeOutlineColor={inputTextColor}
                                contentStyle={{ color: blackColor }}
                              />
                            )}
                          />
                          {errors.phone_number && (
                            <Text
                              style={styles.inputError}
                              dataSet={{ media: ids.inputError }}
                            >
                              {errors.phone_number.message}
                            </Text>
                          )}
                        </View>
                      </View>
                      {/* How Did you Find Maroon Migrates? */}
                      <View
                        style={styles.inputView}
                        dataSet={{ media: ids.inputView }}
                      >
                        <Text style={styles.inputText}>
                          How Did You Find Maroon Migrates?
                        </Text>
                        <Controller
                          control={control}
                          rules={{
                            required: "Reference field is required",
                          }}
                          name="reference"
                          render={({ field: { onChange, value } }) => (
                            <Dropdown
                              data={referenceList}
                              labelField="label"
                              valueField="value"
                              mode={"auto"}
                              value={watch("reference")}
                              style={{
                                paddingHorizontal: 10,
                                borderWidth: 1,
                                borderColor: inputTextColor,
                                borderRadius: 5,
                                backgroundColor: whiteColor,
                              }}
                              placeholderStyle={{
                                color: "rgba(0, 0, 0, 0.5)",
                                fontWeight: 600,
                              }}
                              placeholder="How Did You Find Maroon Migrates?"
                              searchPlaceholder="Search..."
                              onChange={(item) => {
                                setValue("reference", item.value);
                              }}
                            />
                          )}
                        />
                        {errors.reference && (
                          <Text
                            style={styles.inputError}
                            dataSet={{ media: ids.inputError }}
                          >
                            {errors.reference.message}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </ScrollView>
              <Button
                buttonColor={primaryColor}
                textColor={whiteColor}
                style={{ width: 250, marginVertical: 24 }}
                onPress={handleSubmit(submitForm)}
              >
                Submit
              </Button>
            </View>
          </View>
          <ContactUs />
        </View>
      </ScrollView>
    </View>
  );
};

export default PersonalizedTripModal;
