import StyleSheet from "react-native-media-query";
import { whiteColor } from "../../constant/constantCss";
import { Dimensions } from "react-native";

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: whiteColor,
  },
   subContainer: {
    padding: 20,
    display:"flex",
    justifyContent:"center",
    minHeight: Dimensions.get("screen").height,
    "@media (min-width: 960px)": {
      maxWidth: 960,
      alignSelf:"center",
      width:"100%"
    },
    "@media (min-width: 1400px)": {
      maxWidth: 1282,
    },
    "@media (min-width: 1900px)": {
      maxWidth: 1586,
    },
    "@media (max-width: 480px)": {
      padding: 12,
      marginHorizontal: 96
    },
    "@media (min-width: 768px)": {
      padding: 24,
      marginHorizontal: 96
    }
  },
  headingText: {
    marginTop: 16,
    fontSize: 20,
    fontWeight: "800",
  },
  tabView: {
    width: "100%",
    backgroundColor: whiteColor,
    marginBottom: 10,
  },
  cardView: {
    display: "flex",
    flexDirection:"row",
    flexWrap: "wrap",
    gap:20,
    width: "100%",
    "@media (max-width: 768px)": {
      marginBottom: 100,
      justifyContent:"center",
    },
  },
});
