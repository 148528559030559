import React, { useEffect, useState, useCallback } from "react";
import {
  View,
  Image,
  Dimensions,
  Modal,
  ScrollView,
  RefreshControl,
  TouchableOpacity
} from "react-native";
import {
  TextInput,
  Button,
  Text,
  PaperProvider,
  Portal,
  Avatar,
  IconButton,
} from "react-native-paper";
import { ids, styles } from "./UserProfileCss.js";
import { useNavigation } from "@react-navigation/native";
import Octicons from "react-native-vector-icons/Octicons";
import AntDesign from "react-native-vector-icons/AntDesign";
import Ionicons from "react-native-vector-icons/Ionicons";
import AppBar from "../Layout/AppBar.jsx";
import EditInfomation from "./EditInformation/EditInformation.jsx";
import {
  blackColor,
  blueColor,
  inputBackColor,
  primaryColor,
  whiteColor,
} from "../../constant/constantCss.js";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { resetState, setUserData } from "../../store/slices/authSlice";
import { GetUserAPI } from "../../service/api/user/getUserApi.js";
import ImagePickerComponent from "../../components/ImagePicker/ImagePicker.jsx";
import { Platform } from "react-native";
import { EditUserApi } from "../../service/api/user/editUserApi.js";
import { showToast } from "../../constant/toastMessage.js";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import * as ImagePicker from "expo-image-picker";
import ChangePasswordModal from "./ChangePasswordModal.jsx";
import DeleteAccountModal from "./DeleteAccountModal.jsx";
import LogoutModal from "../Logout/LogoutModal.jsx";
import PlaneLoader from "../../components/Loader.jsx";
import ContactUs from "../LnadingPage/components/ContactUs.jsx";

const UserProfileScreen = () => {
  const screenWidth = Dimensions.get("window").width;
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isVisibleChangePassword, setIsVisibleChangePassword] = useState(false);
  const [isVisibleDeleteAccount, setIsVisibleDeleteAccount] = useState(false);
  const [isVisibleLogoutModal, setIsVisibleLogoutModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const showModal = () => setVisible(true);
  const [refreshing, setRefreshing] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const {
    first_name,
    profile_url,
    dialing_code,
    phone_number,
    school_name,
    location,
    enrollment_no,
    gender,
    date_of_birth,
    last_login,
    groups,
  } = useSelector((state) => state.auth);
  const iconSize = screenWidth > 768 ? 15 : 12;
  const editButton = screenWidth > 480 ? whiteColor : blackColor;

  const Input = [
    {
      value: first_name ?? "-",
      label: "Full Name",
      icon: "people",
    },
    {
      value: school_name ?? "-",
      label: "School Name",
      icon: "school-outline",
    },
    {
      value: enrollment_no ?? "-",
      label: "Enrollment No",
      icon: "people",
    },
    {
      value: `+${dialing_code} ${phone_number}` ?? "-",
      label: "Phone Number",
      icon: "call-outline",
    },
    {
      value: gender ?? "-",
      label: "Gender",
      icon: "people",
    },
    {
      value: date_of_birth ? moment(date_of_birth).format("D MMM Y") : "-",
      label: "Date of Birth",
      icon: "calendar",
    },
  ];
  const handleUploadButtonClick = (image) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("profile_url", image);
    EditUserApi(formData)
      .then((res) => {
        showToast("success", "Profile Picture Updated Successfully");
        GetUserAPI().then((res) => {
          dispatch(setUserData(res.data));
        });
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        showToast("error", error?.response?.data?.profile_url?.[0]);
      });
  };
  const pickImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      console.error("Permission to access photo library denied");
      return;
    }
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [2, 2],
        quality: 1,
      });

      if (!result?.canceled) {
        const asset = result.assets[0];
        setImageUrl(asset.uri);
        // random name generate
        const currentDate = moment();
        const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
        const imageName = `profile_image_${formattedDate}.jpeg`;

        const formData = new FormData();
        formData.append("profile_url", {
          uri: asset.uri,
          type: "image/jpeg",
          name: imageName,
        });
        setLoader(true);
        EditUserApi(formData)
          .then((res) => {
            showToast("success", "Profile Picture Updated Successfully");
            GetUserAPI().then((res) => {
              dispatch(setUserData(res.data));
            });
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            showToast("error", error?.response?.data?.profile_url?.[0]);
          });
      }
    } catch (error) {
      console.error("Error picking an image:", error);
    }
  };
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      GetUserAPI().then((res) => {
        dispatch(setUserData(res.data));
      });
      setRefreshing(false);
    }, 1000);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (visible === false) {
      GetUserAPI().then((res) => {
        dispatch(setUserData(res.data));
      });
    }
  }, [visible]);

  return (
    <View>
      <AppBar />
      <ScrollView
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={primaryColor}
            colors={[primaryColor]}
          />
        }
        style={{marginBottom: Platform.OS==="ios" ? 110: Platform.OS!=="web" && 60}}
      >
        <PaperProvider>
          <View
            style={{
              backgroundColor: whiteColor,
            }}
          >
            <View style={styles.profileContainer}>
              <View style={styles.userProfile}>
                <View style={styles.formBackground}>
                  <View style={{ position: "relative" }}>
                    <View
                      style={[styles.profilePictureContainer, {top: Platform.OS!=="web" ? 0: 85}]}
                      dataSet={{ media: ids.profilePictureContainer }}
                    >
                      <View
                        style={styles.lastLoginFlex}
                        dataSet={{ media: ids.lastLoginFlex }}
                      >
                        <View
                          style={[styles.imageContainer]}
                          dataSet={{ media: ids.imageContainer }}
                        >
                          {profile_url ? (
                            <View style={styles.profilePicture}>
                              {loader ? (
                                <PlaneLoader />
                              ) : (
                                <View>
                                  <Image
                                    style={styles.profilePicture}
                                    source={{ uri: profile_url }}
                                  />
                                  <View style={styles.iconContainer}>
                                    {Platform.OS === "web" ? (
                                      <>
                                        <input
                                          type="file"
                                          id="fileInputWeb"
                                          accept={".jpg,.png,.webp,.jpeg"}
                                          style={{ display: "none" }}
                                          onChange={(event) =>
                                            handleUploadButtonClick(
                                              event.target.files[0]
                                            )
                                          }
                                        />
                                        <label htmlFor="fileInputWeb">
                                          <MaterialIcons
                                            style={{ cursor: "pointer" }}
                                            name="add-box"
                                            size={40}
                                            color={blueColor}
                                          />
                                        </label>
                                      </>
                                    ) : (
                                      <IconButton
                                        icon={"plus"}
                                        size={18}
                                        iconColor={whiteColor}
                                        style={{
                                          backgroundColor: primaryColor,
                                        }}
                                        onPress={pickImage}
                                      ></IconButton>
                                    )}
                                  </View>
                                </View>
                              )}
                            </View>
                          ) : (
                            <>
                              <View style={styles.profilePicture}>
                                {loader ? (
                                  <PlaneLoader />
                                ) : (
                                  <>
                                    <Avatar.Text
                                      size={150}
                                      label={first_name
                                        ?.split(" ")[0][0]
                                        ?.toUpperCase()}
                                      style={{ backgroundColor: whiteColor }}
                                      color={primaryColor}
                                    />
                                    <View style={styles.iconContainer}>
                                      {Platform.OS === "web" ? (
                                        <>
                                          <input
                                            id="fileInputAndroid"
                                            type="file"
                                            accept={".jpg,.png,.webp,.jpeg"}
                                            style={{ display: "none" }}
                                            onChange={(event) =>
                                              handleUploadButtonClick(
                                                event.target.files[0]
                                              )
                                            }
                                          />
                                          <label htmlFor="fileInputAndroid">
                                            <MaterialIcons
                                              name="add-box"
                                              size={40}
                                              color={blueColor}
                                            />
                                          </label>
                                        </>
                                      ) : (
                                        <ImagePickerComponent
                                          type="profile"
                                          imageurl={imageUrl}
                                          setImageUrl={setImageUrl}
                                        />
                                      )}
                                    </View>
                                  </>
                                )}
                              </View>
                            </>
                          )}
                        </View>
                        <View style={{display:"flex",flexDirection:"column",gap:8}}>
                          <Text style={{fontWeight:800,fontSize:22,paddingTop:12,color:blackColor}}>
                            {first_name}
                          </Text>
                          <Text style={{
                          color: blackColor,
                          fontSize: 16,}}>
                          {groups?.group?.replace("_", " ")}
                          </Text>
                          <Text style={[styles.loginInfo]}>
                            Last Logged at
                            {moment(last_login).format(
                              " MMM D, YYYY [at] hh:mm A"
                            )}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.userForm}>
                  <View
                    style={styles.belowProfile}
                    dataSet={{ media: ids.belowProfile }}
                  >
                    <View
                      style={[styles.editContainer, {alignItems: Platform.OS!=="web" ? "flex-start": "flex-end"}]}
                      dataSet={{ media: ids.editContainer }}
                    >
                      <Button
                        icon="pencil"
                        width={150}
                        mode="contained"
                        onPress={showModal}
                        buttonColor={primaryColor}
                        textColor={whiteColor}
                      >
                        Edit
                      </Button>
                    </View>
                    <Portal>
                      <Modal
                        visible={visible}
                        animationType="slide"
                        transparent={true}
                        style={styles.modal}
                        dataSet={{ media: ids.modal }}
                      >
                        <EditInfomation
                          visible={visible}
                          setVisible={setVisible}
                          first_name={first_name}
                          enrollment_no={enrollment_no}
                          gender={gender}
                          school_name={school_name}
                          location={location}
                          phone_number={phone_number}
                          date_of_birth={date_of_birth}
                          profile_url={profile_url}
                          role={groups.group}
                        />
                      </Modal>
                    </Portal>
                  </View>
                  <Text style={[styles.formHeader]}>Personal Information</Text>
                  <View style={{display: "flex", flexDirection: "row", gap:22,flexWrap:"wrap", justifyContent: "space-between"}}>
                    {Input.map((item, index) => (
                      <View key={index} style={{display:"flex",flexDirection:"column",width:screenWidth <= 768 ? "100%" : "48%",gap:10}}>
                        <View style={styles.formInput}>
                          <Text
                            style={styles.inputText}
                            dataSet={{ media: ids.inputText }}
                          >
                            {item.label}
                          </Text>
                        </View>
                        <View
                          style={[styles.textInput, {paddingBottom: Platform.OS!=="web" ? 30: 10}]}
                          dataSet={{ media: ids.textInput }}
                        >
                          <TextInput
                            value={item.value}
                            style={{ height: 17, backgroundColor:whiteColor }}
                            dataSet={{ media: ids.textInput }}
                            borderColor="transparent"
                            mode="outlined"
                            outlineColor={whiteColor}
                            activeOutlineColor={whiteColor}
                            contentStyle={{
                              color: blackColor,
                            }}
                          />
                        </View>
                      </View>
                    ))}
                  </View>
                  <Text style={[styles.formHeader]}>Utilities</Text>
                  <View style={{display:"flex",flexDirection:screenWidth < 768 ? "column" : "row", gap:10,width:"100%"}}>
                      <Button
                        icon="lock"
                        width="auto"
                        mode="contained"
                        onPress={() => setIsVisibleChangePassword(true)}
                        buttonColor={primaryColor}
                        textColor={whiteColor}
                      >
                        Change Password
                      </Button>
                      <Button
                        icon="delete"
                        width="auto"
                        mode="contained"
                        onPress={() => setIsVisibleDeleteAccount(true)}
                        buttonColor={primaryColor}
                        textColor={whiteColor}
                      >
                        Delete Account
                      </Button>
                    <Button
                        icon="logout"
                        width="auto"
                        mode="contained"
                        onPress={() => setIsVisibleLogoutModal(true)}
                        buttonColor={primaryColor}
                        textColor={whiteColor}
                      >
                        Logout
                      </Button>
                  </View>
                </View>
              </View>
            </View>
            {isVisibleChangePassword && (
              <ChangePasswordModal
                visible={isVisibleChangePassword}
                handleClose={() => setIsVisibleChangePassword(false)}
              />
            )}
            {isVisibleDeleteAccount && (
              <DeleteAccountModal
                visible={isVisibleDeleteAccount}
                handleClose={() => setIsVisibleDeleteAccount(false)}
              />
            )}
            {isVisibleLogoutModal && (
              <LogoutModal
                visible={isVisibleLogoutModal}
                handleClose={() => setIsVisibleLogoutModal(false)}
              />
            )}
          </View>
        </PaperProvider>
        {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius:50,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
    </View>
  );
};

export default UserProfileScreen;
