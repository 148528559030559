import StyleSheet from "react-native-media-query";
import {
  TripDetailBackColor,
  inputBackColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../constant/constantCss";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: TripDetailBackColor,
  },
  subContainer: {
    padding: 24,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  graphFlexRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
    "@media (max-width: 992px)": {
      flexDirection: "column",
    },
  },
  headingText: {
    marginTop: 10,
    fontSize: 22,
    fontWeight: "800",
    color: primaryColor,
  },
  cardViewContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: 20,
    flexWrap: "wrap",
  },
  keyText: {
    fontSize: 18,
    fontWeight: "400",
  },
  valText: {
    fontSize: 16,
    fontWeight: "300",
    color: subTextColor,
  },
  overViewContainer: {
    flex: 1,
    flexDirection: "row",
    gap: 20,
    marginTop: 30,
    maxHeight: 50,
    "@media (max-width: 992px)": {
      flexDirection: "column",
      maxHeight: 150,
      marginBottom: Platform.OS != "web" ? 30 : 0,
    },
  },
  leftContainer: {
    minHeight: 60,
    borderRadius: 10,
    backgroundColor: whiteColor,
    flex: 0.8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },
  rightContainer: {
    minHeight: 60,
    borderRadius: 10,
    backgroundColor: whiteColor,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },
  //   Graph
  graphContainer: {
    flex: 1,
    flexDirection: "row",
    gap: 20,
    marginTop: 30,
    "@media (max-width: 992px)": {
      flexDirection: "column",
    },
  },
  graphLeftContainer: {
    borderRadius: 10,
    backgroundColor: whiteColor,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    gap: 20,
    "@media (max-width: 992px)": {
      flex: 1,
    },
  },
  graphRightContainer: {
    borderRadius: 10,
    backgroundColor: whiteColor,
    flex: 0.8,
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    flexRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    padding: 20,
    "@media (max-width: 992px)": {
      flex: 0.8,
    },
  },

  participantContainer: {
    marginTop: 30,
    backgroundColor: whiteColor,
    borderRadius: 10,
    marginBottom: 100,
  },
  textInput: {
    fontSize: 16,
    height: 28,
    width: "100%",
    borderRadius: 20,
    backgroundColor: inputBackColor,
    paddingLeft: 10,
    paddingRight: 10,
  },
  statusContainer: {
    height: 20,
    width: 20,
    borderRadius: 20,
  },
});
