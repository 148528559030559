import React from "react";
import { View, Text, ScrollView,Platform,TouchableOpacity,Dimensions } from "react-native";
import { blackColor, subTextColor, whiteColor } from "../../../../constant/constantCss";
import Navbar from "../Navbar";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import ContactUs from "../ContactUs";

const PrivacyPolicy = () => {
  const termsList = [
    {
      title: "Information Collection : ",
      description:
        "This section explains what types of information the company collects from customers, such as personal details (name, address, email), payment information, and browsing behavior on the website.",
    },
    {
      title: "Use of Information : ",
      description:
        "It describes how the company uses the collected information, such as processing bookings, personalizing recommendations, and improving services.",
    },
    {
      title: "Information Sharing : ",
      description:
        "This section explains whether and how the company shares customer information with third parties, such as travel partners, service providers, or marketing partners.",
    },
    {
      title: "Data Security : ",
      description:
        "It outlines the measures the company takes to protect customer information from unauthorized access, misuse, or loss.",
    },
    {
      title: "Cookies and Tracking : ",
      description:
        "This section explains how the company uses cookies and other tracking technologies to collect information about customers' browsing behavior and preferences.",
    },
    {
      title: "User Choices : ",
      description:
        "It explains the options customers have regarding the collection, use, and sharing of their information, such as opting out of certain data collection practices",
    },
    {
      title: "Access and Correction : ",
      description:
        "It explains how customers can access and update their personal information stored by the company.",
    },
    {
      title: "Policy Changes : ",
      description:
        "It states how the company will notify customers of any changes to the privacy policy.",
    },
    {
      title: "Legal Requirements : ",
      description:
        "It explains how the company may disclose customer information in response to legal requests or to comply with applicable laws.",
    },
    {
      title: "Contact Information : ",
      description:
        "It provides contact details for customers to reach out with questions or concerns about the privacy policy or their personal information.",
    },
  ];

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const screenHeight = Dimensions.get("window").height;

  return (
    <View style={{backgroundColor:whiteColor,minHeight: screenHeight}}>
      <Navbar hideComponents={true}/>
      <ScrollView
        style={{marginBottom: Platform.OS==="ios" ? 120: Platform.OS!=="web" && 60}}
      >
      <View style={{margin:50,maxWidth:1000,justifyContent:"center",alignSelf:"center"}}>
      <Text style={{ fontSize: 22,
          color: blackColor,
          fontWeight: "900",
          marginTop: 16,
          marginBottom:16}}>
            Our Privacy Policy
        </Text>
      <Text style={{ fontSize: 16, color: subTextColor }}>
        In the travel industry are important documents that outline how a
        company collects, uses, stores, and protects customer information. Here
        are some key elements that are often included in privacy policies for
        travel companies :
      </Text>
      {termsList?.map((term, index) => (
        <Text
          key={index}
          style={{
            fontSize: 18,
            fontWeight: "800",
            color: blackColor,
            marginTop: 16,
          }}
        >
          {term.title}
          <Text style={{ fontSize:16,fontWeight: "500", color: subTextColor }}>
            {term.description}
          </Text>
        </Text>
      ))}
      <Text style={{ fontSize: 16, color: subTextColor, marginTop: 16 }}>
        Overall, privacy policies in the travel industry are designed to inform
        customers about how their information is handled and to ensure
        transparency and trust between the company and its customers.
      </Text>
      </View>
      {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius:50,
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
    </View>
  );
};

export default PrivacyPolicy;
