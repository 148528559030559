import React, { useState, useEffect, useCallback, Fragment } from "react";
import { ids, styles } from "./AllTripCss";
import AppBar from "../Layout/AppBar";
import {
  View,
  ScrollView,
  Dimensions,
  RefreshControl,
  Text,
  Platform,
  TouchableOpacity
} from "react-native";
import TripCard from "../TripDashboard/TripCard/TripCard";
import { GetBannerImages, GetFilterTripAPI, GetSectionTrips } from "../../service/api/trip/api";
import NotFoundTripCard from "../TripDashboard/TripCard/NotFoundTripCard";
import SearchTrip from "../TripDashboard/SearchTrip/SearchTrip";
import { primaryColor, subTextColor } from "../../constant/constantCss";
import PublicAppBar from "../Layout/PublicAppBar";
import { showToast } from "../../constant/toastMessage";
import PlaneLoader from "../../components/Loader";
import ContactUs from "../LnadingPage/components/ContactUs";
import TripFilterModal from "../TripDashboard/TripFilterModal/TripFilterModal";
import { Button } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import HomePageBanner from "./HomePageBanner";
import SectionTrips from "./SectionTrips";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { get_user_permission } from "../../constant/getAllPermission";
import { resetState, setPermission, setUserData } from "../../store/slices/authSlice";
import {IconButton} from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { GetUserAPI } from "../../service/api/user/getUserApi";
import AsyncStorage from "@react-native-async-storage/async-storage";

const DashboardPage = () => {
  const [loader, setloader] = useState(false);
  const [isVisibleFilterModal, setIsVisibleFilterModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [tripDetails, settripDetails] = useState([]);
  const screenHeight = Dimensions.get("window").height;
  const screenWidth = Dimensions.get("window").width;
  const [isPublicURL, setPublicURL] = useState(!authState?.isLogin || true);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [sectons, setSections] = useState([]);

  const generalState = useSelector((state) => state.general);

  const initialCallingAPI = () => {
    setloader(true);
    GetFilterTripAPI("all")
      .then((res) => {
        settripDetails(res.data?.data);
        setloader(false);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        setloader(false);
      });
    GetSectionTrips().then((res) => {
      setSections(res.data.data);
    }).catch(() => {
      showToast("error", "Something went wrong");
      setloader(false);
    });
  };
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initialCallingAPI();
      setRefreshing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    // call API for get all permission for user
    get_user_permission().then((val) => {
      dispatch(setPermission(JSON.parse(val)));
    });
    // call API for user details
    GetUserAPI()
      .then((res) => {
        dispatch(setUserData(res.data));

      })
      .catch(async (err) => {
        if (err?.response?.data?.detail === "Given token not valid for any token type") {
          await AsyncStorage.clear();
          await dispatch(resetState({}));
          navigation.navigate('dashboard');
        }
      });
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Call Upcoming Trip API
  useEffect(() => {
    initialCallingAPI();
    setPublicURL(!authState.isLogin);
  }, [authState.isLogin]);

  const [images, setImages] = useState([]);

  useEffect(() => {
    GetBannerImages().then((res) => {
      setImages(res.data.data);
    })
      .catch((err) => showToast("error", "Something went wrong banner image!"))
  }, [generalState.bannerImagesRefreshState])
    
  return (
    <View style={[styles.container, { minHeight: screenHeight }]}>
      {!isPublicURL ? <AppBar /> : <PublicAppBar />}
      <ScrollView
        refreshControl={
          <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          tintColor={primaryColor}
          colors={[primaryColor]}
          />
        }
        style={{marginBottom: Platform.OS==="ios" ? 120: Platform.OS!=="web" && 60}}
        >
        {<HomePageBanner images={images}  screenWidth={screenWidth}/>}
        <View style={[styles.subContainer, Platform.OS!=="web" && {width: "100%", justifyContent: "center", display: "flex", marginLeft: 0}]} dataSet={{ media: ids.subContainer }}>
          {sectons.map((data, index) => {
            return(
              <Fragment key={index}>
                <SectionTrips data={data.trip_data} section_name={data.section_name}/>
              </Fragment>
            )
          })}
          <Text style={styles.headingText}>All Trips</Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              gap: 20,
              flex: 1,
              marginBottom:10,
              marginTop:10
            }}
          >
            <SearchTrip setTripDetails={settripDetails} filterValue={"all"} />
            {Dimensions.get("screen").width >= 480 ? 
            <Button
              mode="outlined"
              icon={"filter"}
              textColor={subTextColor}
              style={{
                borderRadius: 10,
                border: "1px solid",
                borderRadius: 5,
                height: 37,
                paddingVertical: 0,
                marginRight: 9,
              }}
              onPress={() => {
                setIsVisibleFilterModal(true);
              }}
            >
              Filter
            </Button> : 
            <IconButton
              icon="filter"
              textColor={subTextColor}
              mode="outlined"
              style={{
                borderRadius: 10,
                border: "1px solid",
                borderRadius: 5,
                height: 37,
                paddingVertical: 0,
                marginRight: 9,
              }}
              size={20}
              onPress={() => {
                setIsVisibleFilterModal(true);
              }}
            />}
          </View>

          <View
            style={[
              styles.cardView,
            ]}
            dataSet={{ media: ids.cardView }}
          >
            {loader ? (
              <PlaneLoader />
            ) : tripDetails?.length > 0 ? (
              tripDetails?.map((item, index) => (
                <TripCard
                  key={index}
                  id={item.id}
                  title={item.title}
                  address={item.address}
                  is_public={item.is_public}
                  amount={item.amount}
                  image={item.image_url}
                  departure_date={item.departure_date}
                  no_of_days={item.no_of_days}
                  slug_url={item.slug}
                />
              ))
            ) : (
              <NotFoundTripCard />
            )}
          </View>
        </View>
        {isVisibleFilterModal && (
          <TripFilterModal
          visible={isVisibleFilterModal}
          setTripDetails={settripDetails}
          filterValue={"all"}
          handleClose={() => setIsVisibleFilterModal(false)}
          />
        )}
      {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius:50,
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
    </View>
  );
};

export default DashboardPage;