import React, { useRef, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  Image,
  Animated,
  useWindowDimensions,
  Platform,
} from "react-native";
import {
  primaryColor,
  whiteColor,
} from "../../constant/constantCss";
import { Button, IconButton } from "react-native-paper";
import AddBannerImageModal from "./AddBannerImageModal";
import DeleteBannerImageModal from "./DeleteBannerImageModal";
import { useSelector } from "react-redux";
import { ManageSectionModal } from "../TripDashboard/ManageSectionModal/ManageSectionModal";

const HomePageBanner = ({ images, interval = 3000, screenWidth }) => {
  const { width } = useWindowDimensions();
  const scrollViewRef = useRef();
  const scrollX = useRef(new Animated.Value(0)).current;
  const imageCount = images.length;
  const [isVisibleAddBannerModal, setIsVisibleBannerModal] = useState(false);
  const [isVisibleDeleteBannerModal, setIsVisibleDeleteBannerModal] = useState(false);
  const [selectedImageData, setSelectedImageData] = useState();
  const { permission } = useSelector((state) => state.auth);
  const [isPaused, setIsPaused] = useState(false);
  const pauseTimeoutRef = useRef();
  const autoScrollTimeoutRef = useRef();
  const [isVisibleManageSection, setIsVisibleManageSection] = useState(false);

  useEffect(() => {
    const autoScroll = () => {
      if (!isPaused) {
        let currentIndex = Math.round(scrollX._value / width);
        currentIndex = (currentIndex + 1) % imageCount;
        const offset = currentIndex * width;

        scrollViewRef.current?.scrollTo({ x: offset, animated: true });
      }
      autoScrollTimeoutRef.current = setTimeout(autoScroll, interval);
    };

    autoScrollTimeoutRef.current = setTimeout(autoScroll, interval);

    return () => {
      clearTimeout(autoScrollTimeoutRef.current);
    };
  }, [imageCount, interval, scrollX, width, isPaused]);

  const handleClose = () => {
    setIsVisibleBannerModal(false);
  };

  const handleCloseDelete = () => {
    setIsVisibleDeleteBannerModal(false);
  };

  const handleDelete = (image_url, image_id) => {
    setSelectedImageData({ image_url: image_url, image_id: image_id });
    setIsVisibleDeleteBannerModal(true);
  };

  const handleInvisibleModalSection = () => {
    setIsVisibleManageSection(false);
  }

  const styles = StyleSheet.create({
    container: {
      position: "relative",
      width: "100%",
    },
    scrollView: {
      flexGrow: 0,
    },
    image: {
      width: width,
      height: width / 3.5,
      resizeMode: "cover",
      display: "block",
    },
    buttonStyle: {
      width: "auto",
      padding: 2,
    },
    arrowButton: {
      position: "absolute",
      top: "50%",
      zIndex: 10,
      transform: [{ translateY: -20 }],
      // cursor: "pointer",
    },
    leftArrow: {
      left: 10,
    },
    rightArrow: {
      right: 10,
    },
    dotsContainer: {
      position: "absolute",
      bottom: 10,
      left: 0,
      right: 0,
      flexDirection: "row",
      justifyContent: "center",
    },
    dot: {
      width: 10,
      height: 10,
      borderRadius: 5,
      marginHorizontal: 5,
      backgroundColor: "white",
    },
    activeDot: {
      backgroundColor: "white",
    },
  });

  const renderDots = () => {
    const position = Animated.divide(scrollX, width);
    return (
      <View style={styles.dotsContainer}>
        {images.map((_, i) => {
          const opacity = position.interpolate({
            inputRange: [i - 1, i, i + 1],
            outputRange: [0.5, 1, 0.5],
            extrapolate: "clamp",
          });
          return (
            <Animated.View
              key={i}
              style={[styles.dot, { opacity }]}
            />
          );
        })}
      </View>
    );
  };

  const handleArrowPress = (direction) => {
    if (pauseTimeoutRef.current) {
      clearTimeout(pauseTimeoutRef.current);
    }

    setIsPaused(true);

    let currentIndex = Math.round(scrollX._value / width);
    if (direction === "left") {
      currentIndex = currentIndex === 0 ? imageCount - 1 : currentIndex - 1;
    } else if (direction === "right") {
      currentIndex = (currentIndex + 1) % imageCount;
    }

    const offset = currentIndex * width;
    scrollViewRef.current?.scrollTo({ x: offset, animated: true });

    pauseTimeoutRef.current = setTimeout(() => {
      setIsPaused(false);
    }, 60000);
  };

  return (
    <>
      {images && images.length > 0 && Platform.OS === "web" && screenWidth > 992 && (
        <View style={styles.container}>
          <ScrollView
            ref={scrollViewRef}
            horizontal
            pagingEnabled
            showsHorizontalScrollIndicator={false}
            decelerationRate="fast"
            scrollEventThrottle={16}
            onScroll={Animated.event(
              [{ nativeEvent: { contentOffset: { x: scrollX } } }],
              { useNativeDriver: false }
            )}
            style={styles.scrollView}
          >
            {images?.map((image, index) => (
              <View style={{ width }} key={index}>
                <Image
                  source={{ uri: image.banner_image_url }}
                  style={styles.image}
                />
                {permission && permission.length > 0 && (
                  <View
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      zIndex: 10,
                      // cursor: "pointer",
                    }}
                  >
                    <IconButton
                      icon={"delete"}
                      iconColor="red"
                      size={40}
                      onPress={() =>
                        handleDelete(image.banner_image_url, image.id)
                      }
                    />
                  </View>
                )}
              </View>
            ))}
          </ScrollView>
          {renderDots()}
          {images.length > 1 && (
            <>
              <IconButton
                icon="chevron-left"
                size={40}
                style={[styles.arrowButton, styles.leftArrow]}
                onPress={() => handleArrowPress("left")}
                containerColor="white"
              />
              <IconButton
                icon="chevron-right"
                size={40}
                style={[styles.arrowButton, styles.rightArrow]}
                onPress={() => handleArrowPress("right")}
                containerColor="white"
              />
            </>
          )}
        </View>
      )}
      {permission && permission.length > 0 && (
        <View
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "2%",
            flexDirection: "row",
            gap: 20,
            justifyContent: "center"
          }}
        >
          {Platform.OS === "web" && screenWidth > 992 && <Button
            icon={"pencil"}
            buttonColor={primaryColor}
            textColor={whiteColor}
            onPress={() => setIsVisibleBannerModal(true)}
            style={styles.buttonStyle}
          >
            Add Banner Image
          </Button>}
          <Button
            icon={"pencil"}
            buttonColor={primaryColor}
            textColor={whiteColor}
            onPress={() => setIsVisibleManageSection(true)}
            style={styles.buttonStyle}
          >
            Manage Section
          </Button>
        </View>
      )}
      <AddBannerImageModal
        visible={isVisibleAddBannerModal}
        handleClose={handleClose}
      />
      <DeleteBannerImageModal
        visible={isVisibleDeleteBannerModal}
        handleClose={handleCloseDelete}
        imageData={selectedImageData}
      />
      <ManageSectionModal
        visible={isVisibleManageSection}
        handleClose={handleInvisibleModalSection}
      />
    </>
  );
};

export default HomePageBanner;
