import React, { useEffect, useMemo, useState } from "react";
import { Text, Dimensions, View, Platform,  } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import linking from "./app-navigator";
import LoginScreen from "../screens/AuthScreens/LoginScreen";
import RegisterScreen from "../screens/AuthScreens/RegisterScreen";
import VerifyOtp from "../screens/AuthScreens/VerifyOtp/VerifyOtp";
import Verification from "../screens/AuthScreens/VerifyOtp/Verification/Verification";
import UserProfileScreen from "../screens/UserProfileScreen/UserProfileScreen";
import TripPage from "../screens/TripDashboard/TripPage";
import ShowTripInfo from "../screens/TripDetails/ShowTripInfo";
import ManageSchool from "../screens/ManageSchoolDetails/ManageSchool";
import { useDispatch, useSelector } from "react-redux";
import {
  ActiveItem,
  randomBackgoundImage,
} from "../store/slices/generalSlice";
import DashboardPage from "../screens/Trip/DashboardPage";
import ManageRole from "../screens/ManageRole/ManageRole";
import AllUsers from "../screens/All Users/AllUsers";
import NotificationScreen from "../screens/Notification/NotificationScreen";
import StudentRegister from "../screens/AuthScreens/StudentRegister";
import OrganizationProfile from "../screens/ManageSchoolDetails/Organization Profile/OrganizationProfile";
import PaymentHistory from "../screens/Payment History/PaymentHistory";
import RevenuePage from "../screens/AnalyticsRevenue/RevenuePage";
import TripsAnalysis from "../screens/TripAnalysis/TripsAnalysis";
import LandingPage from "../screens/LnadingPage/LandingPage";
import ForgotPassword from "../screens/AuthScreens/ForgotPassword";
import ResetPassword from "../screens/AuthScreens/ResetPassword";
import DesktopBackground from "../../assets/landingPage/landingBackground.webp";
import DesktopBackground1 from "../../assets/landingPage/landingBackground1.webp";
import DesktopBackground2 from "../../assets/landingPage/landingBackground2.webp";
import DesktopBackground3 from "../../assets/landingPage/landingBackground3.webp";
import DesktopBackground4 from "../../assets/landingPage/landingBackground4.webp";
import DesktopBackground5 from "../../assets/landingPage/landingBackground5.webp";
import DesktopBackground6 from "../../assets/landingPage/landingBackground6.webp";
import DesktopBackground7 from "../../assets/landingPage/landingBackground7.webp";
import DesktopBackground8 from "../../assets/landingPage/landingBackground8.webp";
import CompanyReview from "../screens/CompanyReview/CompanyReview";
import { get_token } from "../constant/apiUrl";
import { setLoginData } from "../store/slices/authSlice";
import linkingUser from "./app-navigation-user";
import PersonalizedTripModal from "../screens/Personalized Trip/PersonalizedTripModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PlaneLoader from "../components/Loader";
import PrivacyPolicy from "../screens/LnadingPage/components/QuickLinkComponent/PrivacyPolicy";
import TermsCondition from "../screens/LnadingPage/components/QuickLinkComponent/TermsCondition";
import PricingPolicy from "../screens/LnadingPage/components/QuickLinkComponent/PricingPolicy";
import CancellationPolicy from "../screens/LnadingPage/components/QuickLinkComponent/CancellationPolicy";
import BusinessLaw from "../screens/LnadingPage/components/QuickLinkComponent/BusinessLaw";

const PERSISTENCE_KEY = 'NAVIGATION_STATE_V1';
const SESSION_KEY = 'SESSION_KEY_V1';

const AuthStack = () => {
  const Stack = createNativeStackNavigator();
  const LoggedInUser = useSelector((state) => state.auth.isLogin);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(Platform.OS==="web"?true:false);
  const [initialState, setInitialState] = useState();
  const generateRandomImage = () => {
    const elements = [
      DesktopBackground,
      DesktopBackground1,
      DesktopBackground2,
      DesktopBackground3,
      DesktopBackground4,
      DesktopBackground5,
      DesktopBackground6,
      DesktopBackground7,
      DesktopBackground8,
    ];
    const randomNum = Math.floor(Math.random() * 9);
    // return 0 to 6  any random number
    return elements[randomNum];
  };
  useEffect(() => {
    dispatch(randomBackgoundImage(generateRandomImage()));
    // set the token in store
    get_token().then((res) => {
      if (res) {
        const payload = {
          access: res,
          refresh: null,
        };
        dispatch(setLoginData(payload));
      }
    });
  }, []);

  useEffect(() => {
    const loadState = async () => {
      try {
        const sessionState = sessionStorage.getItem(SESSION_KEY);
        if (sessionState) {
          const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
          const state = savedStateString ? JSON.parse(savedStateString) : undefined;
          setInitialState(state);
        } else {
          sessionStorage.setItem(SESSION_KEY, 'active');
          await AsyncStorage.removeItem(PERSISTENCE_KEY);
          dispatch(ActiveItem("dashboard"));

        }
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading && Platform.OS ==="web") {
      loadState();
    }
  }, [isLoading]);

  if (isLoading) {
    return <PlaneLoader />;
  }
  return (
    <>
      <SafeAreaProvider>
        <NavigationContainer
          initialState={initialState}
          onStateChange={async (state) => {
            await AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state));
          }}
          linking={LoggedInUser? linkingUser: linking}
          fallback={<Text>Loading...</Text>}
        >
          <Stack.Navigator screenOptions={{ headerShown: false }}>
            {LoggedInUser ? (
               <>
                  <Stack.Screen
                    name="dashboard"
                    options={{ headerShown: false }}
                    component={DashboardPage}
                  />
                  <Stack.Screen
                    name="ShowTripInfo"
                    options={{ headerShown: false }}
                    component={ShowTripInfo}
                  />
                  <Stack.Screen
                    name="profile"
                    options={{ headerShown: false }}
                    component={UserProfileScreen}
                  />
                  <Stack.Screen
                    name="Trip"
                    options={{ headerShown: false }}
                    component={TripPage}
                  />
                  <Stack.Screen
                    name="Manage_school_detail"
                    options={{ headerShown: false }}
                    component={ManageSchool}
                  />
                  <Stack.Screen
                    name="Role_Management"
                    options={{ headerShown: false }}
                    component={ManageRole}
                  />
                  <Stack.Screen
                    name="All_User"
                    options={{ headerShown: false }}
                    component={AllUsers}
                  />
                  <Stack.Screen
                    name="Notification"
                    options={{ headerShown: false }}
                    component={NotificationScreen}
                  />
                  <Stack.Screen
                    name="Organization_Profile"
                    options={{ headerShown: false }}
                    component={OrganizationProfile}
                  />
                  <Stack.Screen
                    name="Payment_History"
                    options={{ headerShown: false }}
                    component={PaymentHistory}
                  />
                  <Stack.Screen
                    name="analytics"
                    options={{ headerShown: false }}
                    component={RevenuePage}
                  />
                  <Stack.Screen
                    name="Analytics_Trip"
                    options={{ headerShown: false }}
                    component={TripsAnalysis}
                  />
                  <Stack.Screen
                    name="Company_Review"
                    options={{ headerShown: false }}
                    component={CompanyReview}
                  />
                </>
            ) : (
              <React.Fragment>
                <Stack.Screen
                  name="dashboard"
                  options={{ headerShown: false, title: "Maroon Migrates - Tourism with Difference, Purpose", }}
                  component={DashboardPage}
                />
                <Stack.Screen name="Register" component={RegisterScreen} />
                <Stack.Screen name="Login" component={LoginScreen} />
                <Stack.Screen name="Reset_Password" component={ResetPassword} />
                <Stack.Screen
                  name="ForgotPassword"
                  component={ForgotPassword}
                />
                <Stack.Screen
                  name="StudentRegister"
                  component={StudentRegister}
                />
                <Stack.Screen name="VerifyOtp" component={VerifyOtp} />
                <Stack.Screen name="Verification" component={Verification} />
                <Stack.Screen
                  name="ShowTripInfo"
                  options={{ headerShown: false }}
                  component={ShowTripInfo}
                />
                
              </React.Fragment>
            )}
            <Stack.Screen name="AboutUs" component={LandingPage} />
            <Stack.Screen name="personalized" component={PersonalizedTripModal} />
            <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy}/>
            <Stack.Screen name="TermsConditions" component={TermsCondition}/>
            <Stack.Screen name="PricingPolicy" component={PricingPolicy}/>
            <Stack.Screen name="CancellationAndReturnPolicy" component={CancellationPolicy}/>
            <Stack.Screen name="BussinessLaw" component={BusinessLaw}/>
          </Stack.Navigator>
        </NavigationContainer>
      </SafeAreaProvider>
    </>
  );
};

export default AuthStack;