import React, { useState } from "react";
import { ids, styles } from "../modal.js";
import { View, Text, Modal, ScrollView } from "react-native";
import {
  Button,
  Divider,
  IconButton,
  Surface,
  TextInput,
} from "react-native-paper";
import {
  blackColor,
  errorTextColor,
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../constant/constantCss.js";
import { useForm, Controller } from "react-hook-form";
import { CreateCompanyReview } from "../../../service/api/company_review/api.js";
import { showToast } from "../../../constant/toastMessage.js";

const CompanyReivewModal = ({ visible, handleClose }) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      rating_value: 4,
    },
  });
  const [rateValue, setRateValue] = useState(3);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState(null);
  const submitForm = (data) => {
    setLoader(true);
    CreateCompanyReview(data)
      .then((res) => {
        setLoader(false);
        showToast(
          "success",
          "Thank you for giving feedback",
          "We will verify your feedback and visible here once approved"
        );
        setMessage("Success : Thank you for giving feedback");
        handleClose();
      })
      .catch((err) => {
        setLoader(false);
        setMessage(
          err?.response?.data?.name ??
            err?.response?.data?.email ??
            err?.response?.data?.rating_value ??
            err?.response?.data?.message
        );
      });
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <Surface
          elevation={3}
          style={styles.modalView}
          dataSet={{ media: ids.modalView }}
        >
          <View style={styles.headerContainer}>
            <View style={{ flex: 1 }}>
              <Text style={styles.headerText}>Write Review</Text>
              <Text>Please fill the below details</Text>
            </View>
            <IconButton
              iconColor={blackColor}
              icon={"close"}
              onPress={handleClose}
            />
          </View>
          {/* content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Name</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Name field is required",
                    pattern: {
                      value: /^[a-zA-Z ]{1,}$/,
                      message:
                        "Name should not contains numbers and special character",
                    },
                  }}
                  name="name"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      placeholder="Enter your full name"
                      onChangeText={onChange}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      maxLength={20}
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                    />
                  )}
                />
                {errors.name && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.name.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Email</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Email field is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email address",
                    },
                  }}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      placeholder="Enter your email address"
                      onChangeText={onChange}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      maxLength={50}
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                    />
                  )}
                />
                {errors.email && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.email.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Feedback</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Review field is required",
                  }}
                  name="message"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      numberOfLines={3}
                      multiline={true}
                      placeholder="Tell us about your experience "
                      onChangeText={onChange}
                      style={{ backgroundColor: whiteColor }}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      maxLength={250}
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                    />
                  )}
                />
                {errors.message && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.message.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Rating</Text>
                <View style={styles.flexRow}>
                  {Array.from({ length: 5 }, (_, index) => (
                    <IconButton
                      key={index + 1}
                      size={30}
                      onPress={() => {
                        setRateValue(index);
                        setValue("rating_value", index + 1);
                      }}
                      icon={rateValue >= index ? "star" : "star-outline"}
                      iconColor={rateValue >= index ? "#ffe234" : "#7e7863"}
                    />
                  ))}
                </View>
              </View>
              <View style={styles.inputView}>
                <Button
                  loading={loader}
                  textColor={whiteColor}
                  buttonColor={primaryColor}
                  onPress={handleSubmit(submitForm)}
                >
                  Submit
                </Button>
              </View>
              {message ? (
                <>
                  <View style={styles.inputView}>
                    <Divider />
                  </View>
                  <View style={styles.inputView}>
                    <Text style={{ fontSize: 18, color: blackColor }}>
                      {message}
                    </Text>
                  </View>
                </>
              ) : null}
            </View>
          </ScrollView>
        </Surface>
      </View>
    </Modal>
  );
};

export default CompanyReivewModal;
