import StyleSheet from "react-native-media-query";
import {
  blackColor,
  greyBackColor,
  inputBackColor,
  inputTextColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../../../constant/constantCss";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    width: 500,
    height: 600,
    backgroundColor: whiteColor,
    borderWidth: 1,
    borderColor: inputTextColor,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    "@media (max-width: 480px)": {
      width: "90%",
    },
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 22,
    fontWeight: "600",
    "@media (max-width: 768px)": {
      fontSize: 18,
    },
  },
  subText: {
    fontSize: 14,
    fontWeight: "normal",
    color: subTextColor,
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "800",
  },
  // Body
  bodyContainer: {
    flexDirection: "column",
    gap: 0,
    width: "100%",
    minWidth: "100%",
    overflow: "auto",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: 0,
    },
  },
  inputView: {
    marginTop: 16,
  },
  inputText: {
    fontSize: 16,
  },
  textInput: {
    fontSize: 16,
    height: Platform.OS === "android" ? null : 38,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: inputTextColor,
    backgroundColor: greyBackColor,
  },
  textAreaInput: {
    fontSize: 16,
    height: 100,
    backgroundColor: greyBackColor,
  },
  inputError: {
    color: primaryColor,
    fontWeight: "600",
    paddingBottom: 0,
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropdownStyle: {
    minWidth: 150,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: inputTextColor,
    borderRadius: 10,
    backgroundColor: inputBackColor,
  },
});
