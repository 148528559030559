import StyleSheet from "react-native-media-query";
import {
  blackColor,
  inputBackColor,
  primaryColor,
  whiteColor,
} from "../../../constant/constantCss";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    width: 500,
    maxHeight:700,
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: blackColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    position: "fixed",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 768px)": {
      width: "85%",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  uploadInput: {
    display: "none",
  },
  uploadButton: {
    maxWidth: 350,
    color: whiteColor,
  },
  headerText: {
    fontSize: 22,
    fontWeight: "800",
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "800",
  },

  // Body
  bodyContainer: {
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: "100%",
    overflow: "auto",
    "@media (max-width: 992px)": {
      flexDirection: "column",
      gap: 0,
    },
  },
  inputView: {
    marginTop: 26,
  },
  inputText: {
    fontSize: 16,
  },
  textInput: {
    fontSize: 16,
    height: Platform.OS === "android" ? null : 38,
    width: "100%",
    borderRadius: 10,
    backgroundColor: inputBackColor,
    color: blackColor,
    "@media (min-width: 768px)": {
      width: 400,
    },
  },
  inputError: {
    color: primaryColor,
    fontWeight: 600,
    paddingBottom: 0,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  titleText: {
    fontSize: 16,
    width: 100,
    fontWeight: 800,
  },
  img: {
    position: "absolute",
    bottom: "-25%",
    left: "43%",
  },
  detailsHeaderContainer: {
    backgroundColor: primaryColor,
    height: 100,
    width: "100%",
    position: "relative",
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
  footerContainer: {
    marginTop: 20,
  },
});
