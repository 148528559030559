import React, { useEffect, useState, useCallback } from "react";
import { Dimensions, Text, View, ScrollView,TouchableOpacity,Platform,RefreshControl } from "react-native";
import AppBar from "../Layout/AppBar";
import { ids, styles } from "./TripsAnalysisCss";
import TripRecordsTable from "./TripRecordsTable/TripRecordsTable";
import { FlatList } from "react-native";
import AntDesign from "react-native-vector-icons/AntDesign";
import { Button } from "react-native-paper";
import { TripDetailBackColor, primaryColor } from "../../constant/constantCss";
import { GetAllTripAnalytics } from "../../service/api/analytics/api";
import AnalyticFilterModal from "../AnalyticsRevenue/FilterModal/AnalyticFilterModal";
import { showToast } from "../../constant/toastMessage";
import moment from "moment";
import ContactUs from "../LnadingPage/components/ContactUs";
import MaterialIcons from "react-native-vector-icons/MaterialIcons"

const TripsAnalysis = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tripData, setTripData] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [isVisibleNote, setIsVisibleNote] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const getStatusIcon = {
    Completed: (
      <AntDesign
        name="checkcircleo"
        size={40}
        style={[styles.statusIcon, { color: "#90F410" }]}
      />
    ),
    Recent: (
      <AntDesign
        name="closecircleo"
        size={40}
        style={[styles.statusIcon, { color: "#FF9B9B" }]}
      />
    ),
    Upcoming: (
      <AntDesign
        name="wifi"
        size={40}
        style={[styles.statusIcon, { color: "#FEE500" }]}
      />
    ),
    Inprogress: (
      <AntDesign
        name="dashboard"
        size={40}
        style={[styles.statusIcon, { color: "#54FFE0" }]}
      />
    ),
  };
  const getTripStatusColor = {
    Completed: "#90F410",
    Recent: "#FF9B9B",
    Upcoming: "#FEE500",
    Inprogress: "#54FFE0",
  };

  const DATA = [
    {
      title: "In Progress",
      value: tripData?.trip_count?.ongoing,
      icon: getStatusIcon["Inprogress"],
      color: getTripStatusColor["Inprogress"],
    },
    {
      title: "Completed",
      value: tripData?.trip_count?.completed,
      icon: getStatusIcon["Completed"],
      color: getTripStatusColor["Completed"],
    },
    {
      title: "Upcoming",
      value: tripData?.trip_count?.upcoming,
      icon: getStatusIcon["Upcoming"],
      color: getTripStatusColor["Upcoming"],
    },
    {
      title: "Recent",
      value: tripData?.trip_count?.recent,
      icon: getStatusIcon["Recent"],
      color: getTripStatusColor["Recent"],
    },
  ];
  const screenHeight = Dimensions.get("window").height;
  const screenWidth = Dimensions.get("window").width;
  const statusCardsWidth = screenWidth >= 992 ? "23%" : "50%";
  const numColumns = screenWidth >= 992 ? 4 : 2;
  const renderItem = ({ item }) => {
    return (
      <View
        style={[styles.statusCards, { width: statusCardsWidth }]}
        dataSet={{ media: ids.statusCards }}
      >
        <Text style={styles.cardTitle}>{item.title}</Text>
        <View style={styles.flexRow}>
          <View style={styles.flexCenter}>{item.icon}</View>
          <Text style={[styles.cardValue, { color: item.color }]}>
            {item.value}
          </Text>
        </View>
      </View>
    );
  };
  const initialCallingAPI = () => {
    GetAllTripAnalytics({})
      .then((res) => {
        setTripData(res?.data);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  const filterMessage = () => {
    if (startDate && endDate) {
      return `Note : The records are now filtered to display only within the specified date range (Start Date: ${moment(
        startDate
      ).format("D-M-YYYY")} End Date: ${moment(endDate).format("D-M-YYYY")})`;
    } else if (startDate) {
      return `Note : The records are now filtered to display only within the specified date range (Start Date: ${moment(
        startDate
      ).format("D-M-YY")})`;
    }
  };

  useEffect(() => {
    initialCallingAPI();
  }, []);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initialCallingAPI();
      setRefreshing(false);
    }, 1000);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <View
      style={{ minHeight: screenHeight, backgroundColor: TripDetailBackColor }}
    >
      <AppBar />
      <ScrollView 
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={primaryColor}
            colors={[primaryColor]} 
          />
          }
          style={{marginBottom: Platform.OS==="ios" ? 110: Platform.OS!=="web" && 60}}
        >
        <View style={[styles.container, {height: Platform.OS!=="web" && "auto"}]}>
          <View
            style={styles.flexRowHeader}
            dataSet={{ media: ids.flexRowHeader }}
          >
            <Text style={styles.pageHeader}>Total Trips</Text>
            <Button
              mode="contained"
              buttonColor={primaryColor}
              icon={"filter"}
              onPress={() => setFilterModalVisible(true)}
            >
              Filter
            </Button>
          </View>
          {isVisibleNote && (
            <View style={styles.boxContainer}>
              <Text style={styles.cardTitle}>{filterMessage()}</Text>
            </View>
          )}
          <FlatList
            data={DATA}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            columnWrapperStyle={styles.contentContainer}
            numColumns={numColumns}
            ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
          />
          {tripData && <TripRecordsTable tripData={tripData?.trip} />}
        </View>
      {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius:50,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
      {filterModalVisible ? (
        <AnalyticFilterModal
          visible={filterModalVisible}
          handleClose={() => setFilterModalVisible(false)}
          type="analytic_trip"
          setTripAnalyticsData={setTripData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setIsVisibleNote={setIsVisibleNote}
        />
      ) : null}
    </View>
  );
};

export default TripsAnalysis;
