import React, { useState } from "react";
import { Dimensions, Linking, Text, View } from "react-native";
import {
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../../constant/constantCss.js";
import { Avatar, Button, Divider, IconButton } from "react-native-paper";
import { ids, styles } from "../../TripInfoCss.js";
import { useSelector } from "react-redux";
import { PERMISSION } from "../../../../constant/getAllPermission.js";
import EditTripModal from "./manageTripModal/EditTripModal.jsx";
import DeleteTripModal from "./manageTripModal/DeleteTripModal.jsx";
import { Platform } from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons"
import AddSectionModal from "./manageTripModal/AddSectionModal.jsx";

const TripInfoM = ({
  tripDetails,
  trip_id,
  setRefreshAPI,
  // setComponentHeight,
}) => {
  const { width } = Dimensions.get("screen");
  const { permission } = useSelector((state) => state.auth);
  const [isVisibleEditTripModal, setIsVisibleEditTripModal] = useState(false);
  const [isVisibleAddSectionModal, setIsVisibleAddSectionModal] = useState(false);
  const [isVisibleDeleteTripModal, setIsVisibleDeleteTripModal] =
    useState(false);
  const openPDF = async () => {
    const pdfLink = tripDetails?.itinerary_url;
    // Open the PDF link in a new tab or window
    if (Platform.OS === "web") {
      window.open(pdfLink, "_blank");
    } else {
      try {
        await Linking.openURL(pdfLink);
      } catch (error) {
        alert(error.message);
      }
    }
  };
  return (
    <View
      style={{ flexDirection: "column", gap: 10 }}
      id="overview"
    // onLayout={setComponentHeight}
    >
      {/* header text */}
      <View style={{ marginBottom: 16 }}>
        <View style={styles.rowContainer}>
          <Text style={styles.headerKeyText}>Overview</Text>
          <View style={{ flexDirection: "row", gap: 10 }}>
            {permission && permission.includes(PERMISSION.CAN_EDIT_TRIP) && tripDetails?.is_public && (
              <>
                {width >= 1000 ? (
                  <Button
                    icon={"plus"}
                    buttonColor={primaryColor}
                    textColor={whiteColor}
                    onPress={() => setIsVisibleAddSectionModal(true)}
                  >
                    Add to Section/s
                  </Button>
                ) : (
                  <IconButton
                    size={20}
                    icon={"plus"}
                    mode="contained"
                    style={{ backgroundColor: primaryColor }}
                    iconColor={whiteColor}
                    onPress={() => setIsVisibleAddSectionModal(true)}
                  />
                )}
              </>
            )}
            {permission && permission.includes(PERMISSION.CAN_EDIT_TRIP) && (
              <>
                {width >= 1000 ? (
                  <Button
                    icon={"pencil"}
                    buttonColor={primaryColor}
                    textColor={whiteColor}
                    onPress={() => setIsVisibleEditTripModal(true)}
                  >
                    Edit Trip
                  </Button>
                ) : (
                  <IconButton
                    size={20}
                    icon={"pencil"}
                    mode="contained"
                    style={{ backgroundColor: primaryColor }}
                    iconColor={whiteColor}
                    onPress={() => setIsVisibleEditTripModal(true)}
                  />
                )}
              </>
            )}
            {permission && permission.includes(PERMISSION.CAN_DELETE_TRIP) && (
              <>
                {width >= 1000 ? (
                  <Button
                    icon={"delete"}
                    buttonColor={primaryColor}
                    textColor={whiteColor}
                    onPress={() => setIsVisibleDeleteTripModal(true)}
                  >
                    {width >= 1000 ? "Delete Trip" : ""}
                  </Button>
                ) : (
                  <IconButton
                    size={20}
                    icon={"delete"}
                    mode="contained"
                    style={{ backgroundColor: primaryColor }}
                    iconColor={whiteColor}
                    onPress={() => setIsVisibleDeleteTripModal(true)}
                  />
                )}
              </>
            )}
          </View>
        </View>
        <Divider style={{ maxWidth: 500, padding: 0, marginBottom: 16 }} />
        <Text style={{ fontSize: 16, color: subTextColor }}>
          {tripDetails?.description}
        </Text>
      </View>
      {tripDetails?.itinerary_url && (
        <Button
          icon="download"
          style={{
            maxWidth: 120,
            marginBottom: 16,
            borderWidth: 1,
            borderColor: primaryColor,
          }}
          buttonColor={whiteColor}
          textColor={primaryColor}
          onPress={openPDF}
        >
          Brochure
        </Button>
      )}
      {/* trip co-ordinator and emergecny no details */}
      <View style={styles.infoSection} dataSet={{ media: ids.infoSection }}>
        <View style={{ flexDirection: "row", gap: 16 }}>
          <Avatar.Text
            size={48}
            label={tripDetails?.trip_coordinator?.name
              ?.charAt(0)
              ?.toUpperCase()}
            style={{ backgroundColor: primaryColor }}
          />
          <View style={{ flexDirection: "column", gap: 8 }}>
            <Text style={styles.infoKeyText}>
              {tripDetails?.trip_coordinator?.name}
            </Text>
            <Text style={styles.infoValText}>
              <Ionicons name="call-sharp" size={15}
                style={{ marginRight: 5 }} />
              {tripDetails?.trip_coordinator?.phone_number}
            </Text>
            <View style={styles.tripCoorinatorChip}>
              <Text>Trip Coordinator</Text>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: "row", gap: 16 }}>
          <Avatar.Text
            size={48}
            label={tripDetails?.emergency_name?.charAt(0)?.toUpperCase()}
            style={{ backgroundColor: primaryColor }}
          />
          <View style={{ flexDirection: "column", gap: 8 }}>
            <Text style={styles.infoKeyText}>
              {tripDetails?.emergency_name}
            </Text>
            <Text style={styles.infoValText}>
              <Ionicons name="call-sharp" size={15}
                style={{ marginRight: 5 }} />
              {tripDetails?.emergency_phone_no}
            </Text>
            <View style={styles.emergencyChip}>
              <Text>Emergency</Text>
            </View>
          </View>
        </View>
      </View>
      {isVisibleAddSectionModal && (
        <AddSectionModal
          visible={isVisibleAddSectionModal}
          handleClose={() => setIsVisibleAddSectionModal(false)}
          trip_id={trip_id}
        />
      )}
      {isVisibleEditTripModal && (
        <EditTripModal
          visible={isVisibleEditTripModal}
          handleClose={() => setIsVisibleEditTripModal(false)}
          trip_object={tripDetails}
          setRefreshAPI={setRefreshAPI}
        />
      )}
      {isVisibleDeleteTripModal && (
        <DeleteTripModal
          visible={isVisibleDeleteTripModal}
          handleClose={() => setIsVisibleDeleteTripModal(false)}
          trip_id={trip_id}
        />
      )}
    </View>
  );
};

export default TripInfoM;
