import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { ids, styles } from "./ImagePickerCss";
import { IconButton, Text } from 'react-native-paper';
import { blueColor, primaryColor } from '../../constant/constantCss';
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

const ImagePickerComponent = ({type, imageurl, setImageUrl}) => {

  useEffect(() => {
    (async () => {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== 'granted') {
        console.error('Permission to access photo library denied');
      }
    })();
  }, []);

  const pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      if (!result.canceled) {
        setImageUrl(result?.assets[0].uri);
      }
    } catch (error) {
      console.error('Error picking an image:', error);
    }
  };

  return (
    <>
    <View>
      <View style={styles.container} dataSet={{ media: ids.container }} >
        {type==="profile"? (
          <MaterialIcons
            style={styles.profileIcon}
            name="add-box"
            size={40}
            color={blueColor}
            onPress={pickImage}
          />
        ) :  <IconButton
            icon="file-upload"
            iconColor={primaryColor}
            mode={"contained"}
            style={styles.uploadButton}
            onPress={pickImage}
          ></IconButton>}
       
      </View>
      { type!="profile" && 
      <>
        {imageurl && <Text style={{textAlign:"left"}}>Image Selected</Text>}
      </>
      }
    </View>

    </>
  );
};

export default ImagePickerComponent;
