import StyleSheet from "react-native-media-query";
import {
  blackColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../constant/constantCss";
import { Dimensions } from "react-native";

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: whiteColor,
  },
  subContainer: {
    padding: 24,
    minHeight: "100%",
    marginTop: 20,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },
  headingText: {
    marginTop: 10,
    fontSize: 22,
    fontWeight: "800",
  },
  subText: {
    marginTop: 10,
    fontSize: 18,
    color: subTextColor,
  },
  cardViewContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: 20,
    flexWrap: "wrap",
  },
  detailsHeaderContainer: {
    backgroundColor: primaryColor,
    height: 200,
    width: "100%",
    position: "relative",
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
  img: {
    position: "absolute",
    bottom: "-25%",
    left: "43%",
    "@media (max-width: 480px)": {
      left: "10%",
    },
  },
  divider: {
    marginTop: 30,
  },
  tableStyle: {
    width: Dimensions.get("window").width,
    "@media (max-width: 768px)": {
      width: 1000,
    },
  },
  CardFlexContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 32,
    alignItems: "center",
    justifyContent: "left",
    "@media (max-width: 1030px)": {
      display: "block",
    },
  },
  textInput: {
    fontSize: 16,
    height: 38,
    borderRadius: 20,
    color: blackColor,
    backgroundColor: whiteColor,
    maxWidth: 350,
    marginBottom: 20,
    marginTop: 20,
  },
});
