import React from "react";
import { View, Text,Platform,ScrollView,TouchableOpacity,Dimensions } from "react-native";
import { blackColor, subTextColor, whiteColor } from "../../../../constant/constantCss";
import Navbar from "../Navbar";
import ContactUs from "../ContactUs";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

const BusinessLaw = () => {
  const termsList = [
    {
      title: "Personalized Recommendations : ",
      description:
        "Travel companies can use data analytics to understand customer preferences and behavior. This data can be used to offer personalized recommendations for destinations, accommodations, and activities, enhancing the customer experience and increasing customer loyalty.",
    },
    {
      title: "Dynamic Pricing : ",
      description:
        "Airlines, hotels, and other travel companies can use data analytics to implement dynamic pricing strategies. By analyzing factors such as demand, competitor pricing, and historical data, companies can optimize pricing to maximize revenue.",
    },
    {
      title: "Fraud Detection : ",
      description:
        "Travel companies can use machine learning algorithms to detect and prevent fraud. By analyzing patterns in booking and transaction data, companies can identify suspicious activity and take preventive measures.",
    },
    {
      title: "Operational Efficiency : ",
      description:
        "Technology can help improve operational efficiency in various ways, such as automating routine tasks, optimizing routes and schedules, and streamlining communication between different departments.",
    },
    {
      title: "Customer Service : ",
      description:
        "Chatbots and virtual assistants can be used to provide 24/7 customer service, handling common queries and issues. This not only improves customer satisfaction but also reduces the workload on human agents.",
    },
    {
      title: "Marketing Campaigns : ",
      description:
        "Data analytics can help travel companies target their marketing campaigns more effectively. By analyzing customer data, companies can identify their target audience and tailor their marketing messages accordingly.",
    },
  ];

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const screenHeight = Dimensions.get("window").height;

  return (
    <View style={{backgroundColor:whiteColor,minHeight: screenHeight}}>
      <Navbar hideComponents={true}/>  
      <ScrollView 
        style={{marginBottom: Platform.OS==="ios" ? 120: Platform.OS!=="web" && 60}}
      >
      <View style={{margin:50,maxWidth:1000,justifyContent:"center",alignSelf:"center"}}>
        <Text style={{ fontSize: 22,
          color: blackColor,
          fontWeight: "900",
          marginTop: 16,
          marginBottom:16}}>
        Our Business Description & Use Case Policy
        </Text>
      <Text style={{ fontSize: 16, color: subTextColor }}>
        Maroon Migrate is a premier travel company dedicated to providing
        unforgettable travel experiences to discerning travelers. With a focus
        on personalized service and attention to detail, we offer a wide range
        of travel packages, including luxury vacations, adventure tours, and
        cultural excursions. Our team of experienced travel experts is committed
        to creating bespoke itineraries that cater to the individual preferences
        and interests of our clients. Whether you're seeking a relaxing beach
        getaway, a thrilling safari adventure, or a cultural immersion in a
        vibrant city, Maroon Migrates is your trusted partner in creating
        memories that last a lifetime. With a passion for travel and a
        commitment to excellence, we strive to exceed our customers'
        expectations at every turn.
      </Text>
      <Text
        style={{
          fontSize: 18,
          color: blackColor,
          fontWeight: "800",
          marginTop: 16,
        }}
      >
        Business Use Case :
      </Text>
      <Text style={{ fontSize: 16, color: subTextColor, marginTop: 16 }}>
        There are several business use cases in the travel industry where
        technology and data play crucial roles. Here are a few examples :
      </Text>
      {termsList?.map((term, index) => (
        <Text
          key={index}
          style={{
            fontSize: 16,
            fontWeight: "800",
            color: blackColor,
            marginTop: 16,
          }}
        >
          {term.title}
          <Text style={{ fontWeight: "500", color: subTextColor }}>
            {term.description}
          </Text>
        </Text>
      ))}
      <Text style={{ fontSize: 16, color: subTextColor, marginTop: 16 }}>
        Overall, technology and data analytics play a crucial role in the travel
        industry, helping companies improve customer experience, optimize
        operations, and increase revenue.
      </Text>
      </View>
      {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
      <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius:50,
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
    </View>
  );
};

export default BusinessLaw;
