import React, { useRef, useState } from "react";
import { View, ScrollView, Platform, TouchableOpacity } from "react-native";
import { ids, styles } from "./landingPageCss";
import Navbar from "./components/Navbar";
import ExploreSection from "./components/ExploreSection";
import WhyChooseSection from "./components/WhyChooseSection";
import ContactUs from "./components/ContactUs";
import WelcomeSection from "./components/WelcomeSection";
import Career from "./components/Career";
import CompanyReview from "./components/CompanyReview";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";


const LandingPage = () => {
  const scrollViewRef = useRef(null);
  const [isCareerModalVisible, setIsCareerModalVisible] = useState(false);
  const [isVisibleAddReview, setIsVisibleAddReview] = useState(false);
  const [isVisiblePersonalizedTripModal, setIsVisiblePersonalizedTripModal] =
    useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <View
      style={[
        styles.container,
        {
          opacity:
            isCareerModalVisible ||
              isVisibleAddReview ||
              isVisiblePersonalizedTripModal
              ? 0.9
              : 1,
          filter:
            isCareerModalVisible ||
              isVisibleAddReview ||
              isVisiblePersonalizedTripModal
              ? "blur(5dp)"
              : "blur(0dp)",
        },
      ]}
      id="about_us"
    >
      {/* Nab bar */}
      <Navbar
        scrollViewRef={scrollViewRef}
        isVisiblePersonalizedTripModal={isVisiblePersonalizedTripModal}
        setIsVisiblePersonalizedTripModal={setIsVisiblePersonalizedTripModal}
      />
      {/* Welcome to Maroon Migrates container */}
      <ScrollView ref={scrollViewRef} style={{ marginBottom: Platform.OS === "ios" ? 110 : Platform.OS !== "web" && 60 }}>
        <View
          style={styles.mainContainer}
          dataSet={{ media: ids.mainContainer }}
        >
          {/* Welcome section */}
          <WelcomeSection
            isVisiblePersonalizedTripModal={isVisiblePersonalizedTripModal}
            setIsVisiblePersonalizedTripModal={
              setIsVisiblePersonalizedTripModal
            }
          />
          {/* Explore container */}
          <ExploreSection />
          {/* Why choose maroon migrates container */}
          <WhyChooseSection />
          {/* Company review */}
          <CompanyReview
            isVisibleAddReview={isVisibleAddReview}
            setIsVisibleAddReview={setIsVisibleAddReview}
          />
          {/* Career section */}
          <Career
            isCareerModalVisible={isCareerModalVisible}
            setIsCareerModalVisible={setIsCareerModalVisible}
          />
        </View>
        {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS === "web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',
            bottom: 20,
            right: 20,
            marginTop: -44,
            alignSelf: 'flex-end',
            borderRadius: 50,
            borderRadius: 66,
            backgroundColor: "#ffb4ac",
          }}
          onPress={handleScrollToTop}
        >
          <MaterialIcons name="keyboard-arrow-up" style={{ fontSize: 42 }} />
        </TouchableOpacity>
        </>
      }
    </View>
  );
};

export default LandingPage;
