import React from "react";
import { Dimensions, View } from "react-native";
import { Chip, DataTable, Text } from "react-native-paper";
import { ids, styles } from "./TripRecordsTableCss";
import { ScrollView } from "react-native";
import { useSelector } from "react-redux";
import {
  primaryColor,
  privateTripColor,
  publicTripColor,
  subTextColor,
} from "../../../constant/constantCss";
import { Link } from "@react-navigation/native";
import moment from "moment";

const TripRecordsTable = ({ tripData }) => {
  const screenWidth = Dimensions.get("window").width;
  const { isDrawerVisible } = useSelector((state) => state.general);
  const tableStyle = {
    width: isDrawerVisible ? screenWidth - 300 : screenWidth * 0.95,
  };
  if (screenWidth <= 768) {
    tableStyle.width = 1000;
  }
  return (
    <ScrollView horizontal={true}>
      <View style={styles.tableStyle} dataSet={{ media: ids.tableStyle }}>
        <Text style={styles.headingText}>Trip Records</Text>
        {tripData?.length <= 0 ? (
          <Text
            style={[
              styles.headingText,
              { marginTop: 10, color: subTextColor, fontWeight: 500 },
            ]}
          >
            There is no trip found !!
          </Text>
        ) : (
          <DataTable collapsable={true}>
            <DataTable.Header>
              <DataTable.Title>Trip</DataTable.Title>
              <DataTable.Title>Amount(₹)</DataTable.Title>
              <DataTable.Title>Days</DataTable.Title>
              <DataTable.Title>Type</DataTable.Title>
              {/* <DataTable.Title>Status</DataTable.Title> */}
            </DataTable.Header>
            {tripData?.map((trip, index) => (
              <DataTable.Row key={index}>
                <DataTable.Cell>
                  <Link
                    style={{ color: primaryColor }}
                    to={{
                      screen: "ShowTripInfo",
                      params: { data: trip?.id },
                    }}
                  >
                    {trip?.title ?? "N/A"}
                  </Link>
                </DataTable.Cell>
                <DataTable.Cell>{trip?.discount ? `${parseFloat(trip?.amount) - (parseFloat(trip?.amount) * parseFloat(trip?.discount)/100).toFixed(2)} ₹` : `${trip?.amount} ₹` ?? "N/A"}</DataTable.Cell>
                <DataTable.Cell>
                  {`${moment(trip?.end_time)?.diff(
                    moment(trip?.start_time),
                    "days"
                  )} Days`}
                </DataTable.Cell>
                <DataTable.Cell>
                  {trip?.is_public ? (
                    <Chip style={{ backgroundColor: publicTripColor }}>
                      Public
                    </Chip>
                  ) : (
                    <Chip style={{ backgroundColor: privateTripColor }}>
                      Private
                    </Chip>
                  )}
                </DataTable.Cell>
                {/* <DataTable.Cell>
                  {trip?.is_public ? (
                    <Chip style={{ backgroundColor: publicTripColor }}>
                      Public
                    </Chip>
                  ) : (
                    <Chip style={{ backgroundColor: privateTripColor }}>
                      Private
                    </Chip>
                  )}
                </DataTable.Cell> */}
              </DataTable.Row>
            ))}
          </DataTable>
        )}
      </View>
    </ScrollView>
  );
};

export default TripRecordsTable;
