import React from "react";
import { View, Text, Platform, ScrollView, Modal } from "react-native";
import { ids, styles } from "./EditItenariesModalCss";
import { useForm, Controller } from "react-hook-form";
import { TextInput, Button, IconButton } from "react-native-paper";
import {
  blackColor,
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../../../constant/constantCss";
import DatePicker from "../../../../../components/DatePicker/DatePicker";
import { EditStopAPI } from "../../../../../service/api/itinerary/api";
import { showToast } from "../../../../../constant/toastMessage";

const EditStop = ({
  visible,
  handleClose,
  stop_id,
  stop_time,
  stop_desc,
  setRefreshAPI,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      stop_time: stop_time,
      description: stop_desc,
    },
  });
  const onSubmitStopData = (data) => {
    EditStopAPI(data, stop_id)
      .then((res) => {
        showToast("success", res?.data?.message);
        setRefreshAPI((prev) => !prev);
        handleClose();
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        handleClose();
      });
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText} dataSet={{ media: ids.headerText }}>
              Edit Itinerary Stop
            </Text>
            <IconButton
              icon={"close"}
              iconColor={blackColor}
              onPress={handleClose}
            />
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Stop time</Text>
                <Controller
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      {Platform.OS === "web" ? (
                        <input
                          type="time"
                          style={styles.textInput}
                          dataSet={{ media: ids.textInput }}
                          onChange={onChange}
                          value={value}
                          placeholder="Enter Stop time in HH:MM"
                        />
                      ) : (
                        <DatePicker
                          inputString="---Select Stop Time---"
                          mode="time"
                          setDateTime={(date) => {
                            setValue("stop_time", date);
                          }}
                        />
                      )}
                    </>
                  )}
                  name={"stop_time"}
                />
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Stop description</Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textAreaInput}
                      dataSet={{ media: ids.textAreaInput }}
                      onChangeText={onChange}
                      value={value}
                      multiline={true}
                      numberOfLines={4}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter stop description"
                    />
                  )}
                  name="description"
                />
                {errors.description && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors?.description?.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Button
                  mode="contained"
                  buttonColor={primaryColor}
                  textColor={whiteColor}
                  onPress={handleSubmit(onSubmitStopData)}
                >
                  Save
                </Button>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default EditStop;
