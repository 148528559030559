import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  ImageBackground,
  ScrollView,
  Platform,
} from "react-native";
import { TextInput, Button } from "react-native-paper";
import { ids, styles } from "./registerCss.js";
import { useForm, Controller } from "react-hook-form";
import { useNavigation } from "@react-navigation/native";
import { UserAuthAPI } from "../../service/api/user/UserAuthApi.js";
import { GetOtpApi } from "../../service/api/user/OtpApi.js";
import { Picker } from "@react-native-picker/picker";
import logo from "../../../assets/splashLogo.png";
import {
  blackColor,
  inputBackColor,
  inputTextColor,
  subTextColor,
  whiteColor,
} from "../../constant/constantCss.js";
import { showToast } from "../../constant/toastMessage.js";
import { Link } from "@react-navigation/native";
import { GetPublicRole } from "../../service/api/role/api.js";
import useKeyboardHeight from "../../hooks/KeyboardHeight.jsx";
import { Dropdown } from "react-native-element-dropdown";
import { useSelector } from "react-redux";

const RegisterScreen = () => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] =
    useState(false);
  const { backgroundImage } = useSelector((state) => state.general);
  const navigation = useNavigation();
  const screenWidth = Dimensions.get("window").width;
  const roundness = screenWidth > 768 ? 40 : 8;
  const [loader, setLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState("individual");
  const [userRoles, setUserRoles] = useState([]);
  const keyboardHeight = useKeyboardHeight();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: "",
      dialingCode: "+91",
      phone: "",
      password: "",
      confirmPasword: "",
    },
  });
  const onSubmit = (data) => {
    const registerData = {
      first_name: data.fullName,
      phone_number: data.phone,
      password: data.password,
      user_types: selectedValue,
      dialing_code: data.dialingCode.replace(/\+/g, ""),
    };
    const otpData = {
      dialing_code: data.dialingCode.replace(/\+/g, ""),
      phone_number: data.phone,
    };
    UserAuthAPI("register/", registerData)
      .then((res) => {
        showToast("success", "Successfully registered");
        setLoader(false);
        // navigation.navigate("Verification");
        // Note : This code is for send and verify OTP
        GetOtpApi("send/", otpData)
          .then((res) => {
            showToast("success", res.data.data?.message);
            otpData.otp = res.data.data.otp;
            navigation.navigate("VerifyOtp", {
              data: otpData,
              type: "user_register",
            });
          })
          .catch((err) => {
            showToast("error", err?.response?.data?.detail);
          });
      })
      .catch((error) => {
        showToast(
          "error",
          error?.response?.data?.error ?? error?.response?.data?.first_name
        );
        setLoader(false);
      });
  };

  useEffect(() => {
    GetPublicRole()
      .then((res) => {
        const modifiedObject = res.data.filter(
          (item) => item.name !== "parent"
        );
        setUserRoles(modifiedObject);
      })
      .catch((error) => {
        showToast("error", "Something went wrong!");
      });
  }, []);
  const handleSecurityPassword = () => {
    setIsVisiblePassword((prev) => !prev);
  };
  return (
    <React.Fragment>
      <View style={[styles.imageContainer, { marginBottom: keyboardHeight }]}>
        <ImageBackground
          source={backgroundImage}
          style={styles.imageBackground}
        >
          <ScrollView centerContent={true}>
            <View style={styles.container} dataSet={{ media: ids.container }}>
              <View style={styles.form} dataSet={{ media: ids.form }}>
                <View style={[styles.flexRow, { justifyContent: "center" }]}>
                  <Image
                    style={styles.image}
                    dataSet={{ media: ids.image }}
                    source={logo}
                  />
                </View>
                <View
                  style={styles.formContainer}
                  dataSet={{ media: ids.formContainer }}
                >
                  <Text style={styles.header} dataSet={{ media: ids.header }}>
                    Register
                  </Text>
                  <Text style={styles.details} dataSet={{ media: ids.details }}>
                    Fill in the details to Register
                  </Text>
                  <View style={styles.inputView}>
                    <Text style={styles.text} dataSet={{ media: ids.text }}>
                      Name
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          style={styles.textInput}
                          onChangeText={onChange}
                          value={value}
                          mode="outlined"
                          outlineColor={inputTextColor}
                          activeOutlineColor={inputTextColor}
                          theme={{
                            roundness: roundness,
                          }}
                          contentStyle={{ color: blackColor }}
                        />
                      )}
                      name="fullName"
                    />
                    {errors.fullName && (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        Name is required
                      </Text>
                    )}
                  </View>
                  <View style={styles.inputView}>
                    <Text style={styles.text} dataSet={{ media: ids.text }}>
                      Mobile Number
                    </Text>
                    <View style={[styles.phoneContainer]}>
                      <Controller
                        control={control}
                        rules={{
                          required: "coutry code is required",
                          pattern: {
                            value: /^\+[1-9]\d{0,3}$/,
                            message: "Please enter a valid country code",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            style={[styles.textInput, styles.pin]}
                            onChangeText={onChange}
                            value={value}
                            mode="outlined"
                            outlineColor={inputTextColor}
                            activeOutlineColor={inputTextColor}
                            maxLength={4}
                            theme={{
                              roundness: roundness,
                            }}
                            contentStyle={{ color: blackColor }}
                          />
                        )}
                        name="dialingCode"
                      />
                      <Controller
                        control={control}
                        rules={{
                          required: "Mobile No. is required",
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message:
                              "Please enter a valid 10-digit phone number",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            style={[styles.textInput, styles.phoneField]}
                            onChangeText={onChange}
                            value={value}
                            mode="outlined"
                            outlineColor={inputTextColor}
                            activeOutlineColor={inputTextColor}
                            maxLength={10}
                            theme={{
                              roundness: roundness,
                            }}
                            contentStyle={{ color: blackColor }}
                          />
                        )}
                        name="phone"
                      />
                    </View>
                    {errors.dialingCode && errors.phone ? (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        Mobile Number and Dialing Code is required
                      </Text>
                    ) : errors.dialingCode ? (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        {errors?.dialingCode?.message}
                      </Text>
                    ) : errors.phone ? (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        {errors.phone.message}
                      </Text>
                    ) : null}
                  </View>
                  <View style={styles.inputView}>
                    <Text style={styles.text} dataSet={{ media: ids.text }}>
                      Select Role
                    </Text>
                    {Platform.OS === "android" || Platform.OS === "ios" ? (
                      <Dropdown
                        data={userRoles}
                        labelField="name"
                        valueField="name"
                        search
                        mode={"default"}
                        style={{
                          minWidth: 150,
                          paddingHorizontal: 10,
                          borderWidth: 1,
                          borderColor: inputTextColor,
                          borderRadius: roundness,
                          backgroundColor: inputBackColor,
                        }}
                        placeholderStyle={{ color: subTextColor }}
                        maxHeight={300}
                        inputSearchStyle={{
                          minWidth: 100,
                          maxHeight: 30,
                        }}
                        placeholder="select role"
                        searchPlaceholder="Search..."
                        onChange={(item) => {
                          setSelectedValue(item.name);
                        }}
                      />
                    ) : (
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <Picker
                            selectedValue={selectedValue}
                            onValueChange={(itemValue) =>
                              setSelectedValue(itemValue)
                            }
                            style={styles.picker}
                          >
                            {userRoles?.map((item, index) => {
                              const label = item.name
                                .replace(/^is_/, "")
                                .split("_")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ");
                              return (
                                <Picker.Item
                                  key={index}
                                  label={label}
                                  value={item.name}
                                />
                              );
                            })}
                          </Picker>
                        )}
                        name="selectedValue"
                      />
                    )}
                    {errors.selectedValue && (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        This is required.
                      </Text>
                    )}
                  </View>

                  <View style={styles.inputView}>
                    <Text style={styles.text} dataSet={{ media: ids.text }}>
                      Password
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/,
                          message:
                            "Password must include at least one uppercase letter, one lowercase letter, and one number",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          right={
                            <TextInput.Icon
                              size={18}
                              icon={!isVisiblePassword ? "eye" : "eye-off"}
                              color={subTextColor}
                              onPress={handleSecurityPassword}
                            />
                          }
                          style={styles.textInput}
                          onChangeText={onChange}
                          value={value}
                          theme={{
                            roundness: roundness,
                          }}
                          mode="outlined"
                          outlineColor={inputTextColor}
                          activeOutlineColor={inputTextColor}
                          secureTextEntry={!isVisiblePassword}
                          contentStyle={{ color: blackColor }}
                        />
                      )}
                      name="password"
                    />
                    {errors.password && (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        {errors.password.message}
                      </Text>
                    )}
                  </View>
                  <View style={styles.inputView}>
                    <Text style={styles.text} dataSet={{ media: ids.text }}>
                      Confirm Password
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: "Confirm Password is required",
                        validate: (value) =>
                          value === getValues("password") ||
                          "Passwords don't match",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          right={
                            <TextInput.Icon
                              size={18}
                              icon={
                                !isVisibleConfirmPassword ? "eye" : "eye-off"
                              }
                              color={subTextColor}
                              onPress={() => {
                                setIsVisibleConfirmPassword((prev) => !prev);
                              }}
                            />
                          }
                          style={styles.textInput}
                          onChangeText={onChange}
                          value={value}
                          mode="outlined"
                          outlineColor={inputTextColor}
                          activeOutlineColor={inputTextColor}
                          theme={{
                            fontSize: 10,
                            roundness: roundness,
                          }}
                          secureTextEntry={!isVisibleConfirmPassword}
                          contentStyle={{ color: blackColor }}
                          // i add here enter hit work register
                          onSubmitEditing={Platform.OS==="web" ? handleSubmit(onsubmit): ()=>{}}
                        />
                      )}
                      name="confirmPasword"
                    />
                    {errors.confirmPasword && (
                      <Text style={styles.inputError}>
                        {errors.confirmPasword.message}
                      </Text>
                    )}
                  </View>
                  <View
                    style={{
                      flexDirection:
                        Dimensions.get("screen").width >= 768
                          ? "row"
                          : "column",
                      marginTop: 20,
                      justifyContent: "space-between",
                      columnGap: 20,
                    }}
                  >
                    <View style={styles.bottomContainer}>
                      <Text
                        style={styles.bottomText}
                        dataSet={{ media: ids.bottomText }}
                      >
                        Already have an account?
                      </Text>
                      <View style={styles.linkContainer}>
                        <Link
                          to={{ screen: "Login" }}
                          style={styles.bottomLinks}
                          dataSet={{ media: ids.bottomLinks }}
                        >
                          Login
                        </Link>
                      </View>
                    </View>
                    <Link
                      to={{ screen: "StudentRegister" }}
                      style={styles.bottomLinks}
                      dataSet={{ media: ids.bottomLinks }}
                    >
                      Register as a Student
                    </Link>
                  </View>
                </View>
                <View
                  style={styles.buttonContainer}
                  dataSet={{ media: ids.buttonContainer }}
                >
                  <Button
                    style={styles.button}
                    dataSet={{ media: ids.button }}
                    mode="outlined"
                    textColor={whiteColor}
                    onPress={handleSubmit(onSubmit)}
                    loading={loader}
                  >
                    Register
                  </Button>
                </View>
              </View>
            </View>
          </ScrollView>
        </ImageBackground>
      </View>
    </React.Fragment>
  );
};

export default RegisterScreen;
