import React, { useState, useRef, useEffect } from "react";
import {
  View,
  Text,
  Modal,
  Platform,
  Dimensions,
  ScrollView,
} from "react-native";
import { ids, styles } from "./CreateTripModalCss";
import {
  TextInput,
  Switch,
  Button,
  IconButton,
  Chip,
} from "react-native-paper";
import { useForm, Controller } from "react-hook-form";
import ItenariesModal from "../CreateItenariesModal/ItenariesModal";
import SchoolSelectionModal from "../SchoolSelectionModal/SchoolSelectionModal";
import {
  blackColor,
  inputBackColor,
  inputTextColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../constant/constantCss";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { showToast } from "../../../constant/toastMessage";
import { Picker } from "@react-native-picker/picker";
import { GetTripCoordinator } from "../../../service/api/trip/getTripCoordinatorApi";
import * as ImagePickerExpo from "expo-image-picker";
import moment from "moment";
import { Dropdown } from "react-native-element-dropdown";
import { TripTitleCheck } from "../../../service/api/trip/api";

export const CreateTripModal = ({ visible, handleClose }) => {
  const screenWidth = Dimensions.get("window").width;
  const roundness = screenWidth > 768 ? 20 : 8;
  const [itenariesModalVisible, setItenariesModalVisible] = useState(false);
  const [schoolModalVisible, setSchoolModalVisible] = useState(false);
  const fileInputRef = useRef(null);
  const [departureDate, setDepartureDate] = useState("");
  const [departureDateList, setdepartureDateList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [tripCoordinator, setTripCoordinator] = useState([]);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      departure_date: "",
      amount: "",
      is_public: false,
      trip_coordinator: "",
      emergency_name: "",
      emergency_phone_no: "",
      inclusion: "",
      exclusion: "",
      remarks: "",
    },
  });
  const onSubmitData = async (data) => {
    if (!data.title) {
      setError("title", { message: "Title is required." });
    } else {
      const trip_title_promise = await TripTitleCheck(data.title);
      if (trip_title_promise?.data?.message) {
        setError("title", {
          message: "Please choose a different title, this one's taken!",
        });
        return;
      }
    }
    if (tagList.length <= 0) {
      setError("tag", "please press + icon to add the tag");
    } else {
      data.departure_date = JSON.stringify(departureDateList);
      data.tag = JSON.stringify(tagList);
      const slug_string = data.title;
      setValue("departure_date", JSON.stringify(departureDateList));
      setValue("tag", JSON.stringify(tagList));
      setValue("slug", slug_string);
      if (data.is_public) {
        setItenariesModalVisible(true);
      } else {
        setSchoolModalVisible(true);
      }
    }
  };
  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDeleteDipartureDate = (dateToDelete) => {
    setdepartureDateList((prevDates) =>
      prevDates.filter((date) => date !== dateToDelete)
    );
    setValue("departure_date", "");
  };
  const handleDeleteTag = (tagToDelete) => {
    setTagList((prevTag) => prevTag.filter((date) => date !== tagToDelete));
    setValue("departure_date", "");
  };
  const pickImage = async () => {
    const { status } =
      await ImagePickerExpo.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      console.error("Permission to access photo library denied");
      return;
    }
    try {
      let result = await ImagePickerExpo.launchImageLibraryAsync({
        mediaTypes: ImagePickerExpo.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result?.canceled) {
        // random name generate
        const currentDate = moment();
        const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
        const imageName = `trip_image_${formattedDate}.jpeg`;

        const fileObj = {
          uri: result?.assets?.[0]?.uri,
          type: "image/jpeg",
          name: imageName,
        };
        setValue("image_url", fileObj);
      }
    } catch (error) {
      console.error("Error picking an image:", error);
    }
  };
  useEffect(() => {
    GetTripCoordinator()
      .then((res) => {
        setTripCoordinator(res.data);
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
      });
  }, []);

  const [imageError, setImageError] = useState();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        if (
          aspectRatio >= 1.6 &&
          aspectRatio <= 2 &&
          img.width >= 700 &&
          img.height >= 400 &&
          img.width <= 4000 &&
          img.height <= 2200
        ) {
          setValue("image_url", e.target.files[0]);
          setError("image_url", null);
        } else {
          setError("image_url",
            {message: "Image does not meet the required aspect ratio (between 1.7 to 2) and size (between 700x400 to 4000x2200)."}
          );
        }
        URL.revokeObjectURL(img.src);
      };
    }
  };

  return (
    <Modal
      style={styles.modalView}
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Create Trip</Text>
            <IconButton
              icon={"close"}
              iconColor={blackColor}
              onPress={handleClose}
            />
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View
                style={styles.inlineContainer}
                dataSet={{ media: ids.inlineContainer }}
              >
                <View style={styles.inputView}>
                  <View style={styles.flexRow}>
                    <Text style={styles.inputText}>Trip Title*</Text>
                    <Text style={styles.inputText}>
                      {watch("title")?.length}/40
                    </Text>
                  </View>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        maxLength={40}
                        style={styles.textInput}
                        dataSet={{ media: ids.textInput }}
                        onChangeText={onChange}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter title of Trip"
                      />
                    )}
                    name="title"
                  />
                  {errors.title && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      {errors.title.message || "This is required."}
                    </Text>
                  )}
                </View>

                <View style={styles.inputView}>
                  <View style={styles.flexRow}>
                    <Text style={styles.inputText}>Trip Description*</Text>
                    <Text style={styles.inputText}>
                      {watch("description")?.length}/500
                    </Text>
                  </View>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textAreaInput}
                        maxLength={500}
                        onChangeText={onChange}
                        multiline={true}
                        numberOfLines={4}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter Description of Trip"
                      />
                    )}
                    name="description"
                  />
                  {errors.description && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Tag*</Text>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        onChangeText={onChange}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter tagline of trip"
                        right={
                          <TextInput.Icon
                            size={18}
                            icon={"plus"}
                            color={subTextColor}
                            onPress={() => {
                              if (
                                watch("tag") !== "" &&
                                watch("tag") !== undefined
                              ) {
                                setTagList((prev) => [...prev, watch("tag")]);
                                setValue("tag", "");
                              }
                            }}
                          />
                        }
                      />
                    )}
                    name="tag"
                  />
                  {errors.tag && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      please press + icon to add the tag
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    marginTop: 10,
                    flexDirection: "row",
                    gap: 8,
                    flexWrap: "wrap",
                  }}
                >
                  {tagList?.map((tag, index) => (
                    <Chip
                      key={index}
                      icon="information"
                      closeIcon={"close"}
                      style={{
                        maxWidth: 150,
                        backgroundColor: inputBackColor,
                        color: whiteColor,
                        marginBottom: 10,
                      }}
                      onClose={() => handleDeleteTag(tag)}
                    >
                      {tag}
                    </Chip>
                  ))}
                </View>
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Amount*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: "This field is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Only integers are allowed",
                      },
                      validate: (value) =>
                        parseFloat(value) > 0 ||
                        "Amount must be greater than 0",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        keyboardType="numeric"
                        style={styles.textInput}
                        onChangeText={onChange}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter amount of trip"
                      />
                    )}
                    name="amount"
                  />
                  {errors.amount && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      {errors.amount.message}
                    </Text>
                  )}
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Departure Date</Text>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <React.Fragment>
                        {Platform.OS === "web" ? (
                          <input
                            type="date"
                            style={styles.textInput}
                            onChange={(e) => {
                              setValue("departure_date", "");
                              setdepartureDateList((prev) => [
                                ...prev,
                                e.target.value,
                              ]);
                            }}
                            value={value}
                          />
                        ) : (
                          <DatePicker
                            inputString="dd-mm-yyyy"
                            mode="date"
                            dateTime={departureDate}
                            setDateTime={(date) => {
                              setDepartureDate(date);
                              setValue("departure_date", date);
                              setdepartureDateList((prev) => [...prev, date]);
                            }}
                          />
                        )}
                      </React.Fragment>
                    )}
                    name="departure_date"
                  />
                </View>
                <View
                  style={{
                    marginTop: 10,
                    flexDirection: "row",
                    gap: 8,
                    flexWrap: "wrap",
                  }}
                >
                  {departureDateList?.map((departure_date, index) => (
                    <Chip
                      key={index}
                      icon="information"
                      closeIcon={"close"}
                      style={{
                        maxWidth: 150,
                        backgroundColor: inputBackColor,
                        color: whiteColor,
                        marginBottom: 10,
                      }}
                      onClose={() => handleDeleteDipartureDate(departure_date)}
                    >
                      {departure_date}
                    </Chip>
                  ))}
                </View>

                <View style={styles.rowContainer}>
                  <Text style={styles.inputText}>Upload cover photo*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        {Platform.OS === "web" ? (
                          <>
                            <input
                              type="file"
                              accept={".jpg,.png,.webp,.jpeg"}
                              ref={fileInputRef}
                              style={styles.uploadInput}
                              onChange={(e) => handleFileChange(e)}
                            />
                            <IconButton
                              icon="file-upload"
                              iconColor={primaryColor}
                              mode={"contained"}
                              style={styles.uploadButton}
                              onPress={handleUploadButtonClick}
                            ></IconButton>
                            <Text>{getValues("image_url")?.name}</Text>
                          </>
                        ) : (
                          <IconButton
                            icon="file-upload"
                            iconColor={primaryColor}
                            mode={"contained"}
                            style={styles.uploadButton}
                            onPress={pickImage}
                          ></IconButton>
                        )}
                      </>
                    )}
                    name="image_url"
                  />
                </View>
                {errors.image_url && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.image_url.message}
                  </Text>
                )}

                <View style={styles.inputView}>
                  <View style={styles.flexRow}>
                    <Text style={styles.inputText}>Inclusion</Text>
                  </View>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textAreaInput}
                        onChangeText={onChange}
                        multiline={true}
                        numberOfLines={4}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter Inclusion of Trip"
                      />
                    )}
                    name="inclusion"
                  />
                </View>

                <View style={styles.inputView}>
                  <View style={styles.flexRow}>
                    <Text style={styles.inputText}>Remarks</Text>
                  </View>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textAreaInput}
                        onChangeText={onChange}
                        multiline={true}
                        numberOfLines={4}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter remarks of Trip"
                      />
                    )}
                    name="remarks"
                  />
                </View>
              </View>

              <View
                style={styles.inlineContainer}
                dataSet={{ media: ids.inlineContainer }}
              >
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Minimum Age Limit*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: "This field is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Only integers are allowed",
                      },
                      validate: (value) =>
                        parseFloat(value) > 0 || "Age must be greater than 0",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        keyboardType="numeric"
                        style={styles.textInput}
                        onChangeText={onChange}
                        maxLength={3}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter minimum age limit"
                      />
                    )}
                    name="min_age_limit"
                  />
                  {errors.min_age_limit && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      {errors.min_age_limit.message}
                    </Text>
                  )}
                </View>
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>No of Days*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: "This field is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Only integers are allowed",
                      },
                      validate: (value) =>
                        parseFloat(value) > 0 ||
                        "No of days must be greater than 0",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        keyboardType="numeric"
                        style={styles.textInput}
                        onChangeText={onChange}
                        value={value}
                        maxLength={3}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter No of days"
                      />
                    )}
                    name="no_of_days"
                  />
                  {errors.no_of_days && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      {errors.no_of_days.message}
                    </Text>
                  )}
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Trip Coordinator*</Text>
                  {Platform.OS === "ios" ? (
                    <Dropdown
                      data={tripCoordinator}
                      labelField="first_name"
                      valueField="id"
                      search
                      mode={"auto"}
                      style={{
                        minWidth: 150,
                        paddingHorizontal: 10,
                        borderWidth: 1,
                        borderColor: inputTextColor,
                        borderRadius: 10,
                        backgroundColor: inputBackColor,
                      }}
                      placeholderStyle={{ color: subTextColor }}
                      maxHeight={300}
                      inputSearchStyle={{
                        minWidth: 100,
                        maxHeight: 30,
                      }}
                      placeholder="Select Type"
                      searchPlaceholder="Search..."
                      onChange={(item) => {
                        setValue("trip_coordinator", item.id);
                      }}
                    />
                  ) : (
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <Picker
                          selectedValue={field.value}
                          onValueChange={field.onChange}
                          style={[
                            styles.textInput,
                            { borderWidth: 1, borderColor: inputTextColor },
                          ]}
                        >
                          <Picker.Item
                            label="Select a Trip Coordinator"
                            value=""
                          />
                          {tripCoordinator?.map((item, index) => {
                            return (
                              <Picker.Item
                                key={index}
                                label={item.first_name}
                                value={item.id}
                              />
                            );
                          })}
                        </Picker>
                      )}
                      name="trip_coordinator"
                    />
                  )}

                  {errors.trip_coordinator && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Emegency Name*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        onChangeText={onChange}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter supervisor name"
                      />
                    )}
                    name="emergency_name"
                  />
                  {errors.emergency_name && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Emergency Phone Number*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: "This is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit phone number",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        keyboardType="numeric"
                        onChangeText={onChange}
                        value={value}
                        maxLength={10}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter supervisor phone number"
                      />
                    )}
                    name="emergency_phone_no"
                  />
                </View>
                {errors.emergency_phone_no && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.emergency_phone_no.message}
                  </Text>
                )}

                <View style={styles.inputView}>
                  <View style={styles.flexRow}>
                    <Text style={styles.inputText}>Badge Text</Text>
                    <Text style={styles.inputText}>
                      {watch("badge_text")?.length || 0}/15
                    </Text>
                  </View>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        onChangeText={onChange}
                        maxLength={15}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter badge text"
                      />
                    )}
                    name="badge_text"
                  />
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Discount %</Text>
                  <Controller
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value) return true;
                        const number = parseFloat(value);
                        if (isNaN(number)) {
                          return "Please enter a valid number.";
                        } else if (number < 0 || number > 100) {
                          return "Number must be between 0 and 100.";
                        }
                        return true;
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        onChangeText={onChange}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        keyboardType="numeric"
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter discount %"
                      />
                    )}
                    name="discount"
                  />
                </View>
                {errors.discount && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.discount.message}
                  </Text>
                )}

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Trip Category*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <View style={styles.rowContainer}>
                        <Text
                          style={[
                            styles.inputText,
                            styles.inputSwitchContainer,
                          ]}
                        >
                          Public trip
                        </Text>
                        <Switch
                          value={value}
                          color={primaryColor}
                          onValueChange={(val) => {
                            onChange(val);
                          }}
                        />
                      </View>
                    )}
                    name="is_public"
                  />
                </View>

                <View style={styles.inputView}>
                  <View style={styles.flexRow}>
                    <Text style={styles.inputText}>Exclusion</Text>
                  </View>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textAreaInput}
                        onChangeText={onChange}
                        multiline={true}
                        numberOfLines={4}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter exclusion of Trip"
                      />
                    )}
                    name="exclusion"
                  />
                </View>
              </View>
            </View>
          </ScrollView>
          {/* Footer Container */}
          <View style={styles.footerContainer}>
            <Button
              style={{ minWidth: 250 }}
              mode="contained"
              buttonColor={primaryColor}
              onPress={handleSubmit(onSubmitData)}
            >
              Next
            </Button>
          </View>
        </View>
      </View>

      <ItenariesModal
        ItenariesVisible={itenariesModalVisible}
        handleCloseCreateModal={handleClose}
        handleCloseModal={() => setItenariesModalVisible(false)}
        control={control}
        handleSubmit={handleSubmit}
        errors={errors}
        unregister={unregister}
        setValue={setValue}
        watch={watch}
      />

      <SchoolSelectionModal
        modalVisible={schoolModalVisible}
        handleCloseCreateModal={handleClose}
        handleCloseModal={() => setSchoolModalVisible(false)}
        ItenariesVisible={() => setItenariesModalVisible(true)}
        setValue={setValue}
      />
    </Modal>
  );
};