import AsyncStorage from "@react-native-async-storage/async-storage";

import { REACT_APP_DEV_URL, REACT_APP_ENV, REACT_APP_PROD_URL } from "@env";

export const getApiUrl =
  REACT_APP_ENV == "development" ? REACT_APP_DEV_URL : REACT_APP_PROD_URL;

export const get_token = async () => {
  try {
    const value = await AsyncStorage.getItem("AuthToken");
    if (value != null) {
      return value;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
