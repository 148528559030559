import React, { useRef, useState } from "react";
import {
  View,
  Text,
  Modal,
  Platform,
  Dimensions,
  ScrollView,
} from "react-native";
import { ids, styles } from "./IternariesModalCss";
import { Controller } from "react-hook-form";
import {
  TextInput,
  Button,
  Switch,
  TouchableRipple,
  IconButton,
} from "react-native-paper";
import { CreateTripAPI } from "../../../service/api/trip/api";
import { inputTextColor, primaryColor } from "../../../constant/constantCss";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { showToast } from "../../../constant/toastMessage";
import { useSelector } from "react-redux";
import moment from "moment";
import AntDesign from "react-native-vector-icons/AntDesign";
import * as DocumentPicker from "expo-document-picker";

const ItenariesModal = ({
  ItenariesVisible,
  handleCloseModal,
  handleCloseCreateModal,
  control,
  handleSubmit,
  errors,
  unregister,
  setValue,
  watch,
}) => {
  const { id } = useSelector((state) => state.auth);
  const [loader, setloader] = useState(false);
  const screenWidth = Dimensions.get("window").width;
  const roundness = screenWidth > 768 ? 20 : 8;
  const [isUploadIternaries, setIsUploadIternaries] = useState(true);
  const [locationItems, setLocationItems] = useState([{ stopItems: [0] }]);
  const [stopTime, setStopTime] = useState([{ stopItems: [0] }]);
  const fileInputRef = useRef(null);
  const onSubmitData = (data) => {
    const lastLocation = `location_${locationItems.length - 1}`;
    if (data[lastLocation] === "") {
      showToast("error", "Location can't null");
    } else {
      setloader(true);
      const newArray = [];
      // Iterate through the input object keys and group them into a new structure
      for (const key in data) {
        const [prefix, index, subIndex] = key.split("_");

        // Check if the key is a location
        if (prefix === "location") {
          const locationIndex = parseInt(index);
          const newObject = {
            location_name: data[key],
            stop: [],
          };
          delete data[key]; // delete from data key location
          // Find associated stop descriptions and stop times
          for (let stopIndex = 0; ; stopIndex++) {
            const stopTimeKey = `stopTime_${locationIndex}_${stopIndex}`;
            const stopDescKey = `stopDescription_${locationIndex}_${stopIndex}`;

            if (data[stopDescKey] && data[stopTimeKey]) {
              newObject.stop.push({
                stop_time: data[stopTimeKey],
                description: data[stopDescKey],
              });
              delete data[stopTimeKey];
              delete data[stopDescKey];
            } else if (data[stopDescKey]) {
              newObject.stop.push({
                description: data[stopDescKey],
              });
              delete data[stopTimeKey];
              delete data[stopDescKey];
            } else {
              break; // Stop when there are no more associated stops
            }
          }
          newArray.push(newObject);
        }
      }
      Object.assign(data, { created_by: id });

      if (isUploadIternaries === false) {
        Object.assign(data, { itinerary: JSON.stringify(newArray) });
      }

      // create form data
      const formData = new FormData();

      // Append data to the formData object
      Object.keys(data).forEach((key) => {
        if (data[key] !== undefined) {
          if (key === "school") {
            formData.append("school", JSON.stringify(data.school));
          } else {
            formData.append(key, data[key]);
          }
        }
      });
      //call API for creating Trip
      CreateTripAPI(formData)
        .then((res) => {
          showToast("success", "Trip created Success!");
          setloader(false);
          handleCloseModal();
          handleCloseCreateModal();
        })
        .catch((e) => {
          setloader(false);
        });
    }
  };

  const handleAddLocation = () => {
    setLocationItems([...locationItems, { stopItems: [0] }]);
  };
  const handleAddStop = (index) => {
    const location = locationItems[index];
    location.stopItems.push(location.stopItems.length + 1);

    setLocationItems([...locationItems]);
  };
  const handleDeleteLocation = (index) => {
    if (index > 0) {
      locationItems.splice(index, 1);
      setLocationItems([...locationItems]);
      unregister(`location_${index}`);
    } else {
      if (Platform.OS == "web") {
        alert("error : you can't delete");
      } else {
        showToast("error", "you can't delete");
      }
    }
  };
  function handleDeleteStop(index, stopIndex) {
    if (stopIndex > 0) {
      locationItems[index].stopItems.splice(stopIndex, 1);
      setLocationItems([...locationItems]);
      unregister(`stopTime_${index}_${stopIndex}`);
      unregister(`stopDescription_${index}_${stopIndex}`);
    } else {
      if (Platform.OS == "web") {
        alert("error : you can't delete");
      } else {
        showToast("error", "you can't delete");
      }
    }
  }
  const pickImage = async (index) => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: ["image/*"],
      });

      if (!result?.canceled) {
        if (Platform.OS === "web") {
          setValue(`image_location_${index}`, result?.assets[0]?.file);
        } else {
          // random name generate
          const currentDate = moment();
          const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
          const imageName = `location_image_${formattedDate}.jpeg`;

          const fileObj = {
            uri: result?.assets?.[0]?.uri,
            type: "image/jpeg",
            name: imageName,
          };
          setValue(`image_location_${index}`, fileObj);
        }
      }
    } catch (error) {
      console.error("Error picking an image:", error);
    }
  };

  const pickItinerariesFile = async () => {
    if (Platform.OS === "web") {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      const allowedFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      try {
        let result = await DocumentPicker.getDocumentAsync({
          type: allowedFileTypes,
        });
        if (!result?.canceled) {
          // random name generate
          const currentDate = moment();
          const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
          const imageName = `itinerary_${formattedDate}.pdf`;

          const fileObj = {
            uri: result?.assets?.[0]?.uri,
            type: result?.assets?.[0]?.mimeType,
            name: imageName,
          };
          setValue("itinerary_url", fileObj);
        }
      } catch (error) {
        console.error("Error picking an image:", error);
      }
    }
  };

  return (
    <Modal
      style={styles.modelContainer}
      animationType="slide"
      transparent={true}
      visible={ItenariesVisible}
      onRequestClose={handleCloseModal}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>
              <Button icon="arrow-left" onPress={handleCloseModal}></Button>
              Itinerary Details
              {"\n"}
              <Text style={styles.subText}>
                Add Details regarding your locations and other status updates
              </Text>
            </Text>
            <Text style={styles.closeButton} onPress={handleCloseModal}>
              X
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
              justifyContent: "flex-start",
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: "600",
                paddingTop: 10,
              }}
            >
              Would you want to upload itinerary pdf ??
            </Text>
            <Switch
              color={primaryColor}
              value={isUploadIternaries}
              onValueChange={(val) => {
                setIsUploadIternaries(val);
                if (val === true) {
                  unregister("itinerary");
                } else {
                  unregister("itinerary_url");
                }
              }}
            />
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              {!isUploadIternaries ? (
                <View>
                  {locationItems?.map((locationItem, index) => (
                    <View style={styles.locationContainer} key={index}>
                      <View>
                        <Button
                          mode="text"
                          icon={"minus"}
                          style={styles.addmoreButton}
                          labelStyle={{ fontWeight: "900" }}
                          onPress={() => handleDeleteLocation(index)}
                        >
                          Delete Location
                        </Button>
                        <Text style={styles.inputText}>
                          Location {index + 1}
                        </Text>
                        <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <TextInput
                              style={styles.textInput}
                              dataSet={{ media: ids.textInput }}
                              onChangeText={onChange}
                              value={value}
                              theme={{
                                roundness: roundness,
                              }}
                              mode="outlined"
                              outlineColor={inputTextColor}
                              activeOutlineColor={inputTextColor}
                              placeholder="Enter Location"
                            />
                          )}
                          name={`location_${index}`}
                        />
                        {`errors.location_${index}` && (
                          <Text
                            style={styles.inputError}
                            dataSet={{ media: ids.inputError }}
                          >
                            This is required.
                          </Text>
                        )}
                        <Text style={styles.inputText}>
                          Location Image {index + 1}
                        </Text>
                        <React.Fragment>
                          <IconButton
                            icon="file-upload"
                            iconColor={primaryColor}
                            mode={"contained"}
                            onPress={() => pickImage(index)}
                          ></IconButton>
                          <Text>{watch(`image_location_${index}`)?.name}</Text>
                        </React.Fragment>
                      </View>

                      <View style={styles.stopMainContainer}>
                        <ScrollView horizontal={true}>
                          {locationItem?.stopItems?.map((res, stopIndex) => (
                            <View key={index + stopIndex}>
                              <View style={styles.stopContainer}>
                                <Text style={styles.inputText}>
                                  Stop Time {stopIndex + 1}
                                </Text>
                                <Controller
                                  control={control}
                                  rules={{
                                    required: false,
                                  }}
                                  render={({ field: { onChange, value } }) => (
                                    <>
                                      {Platform.OS === "web" ? (
                                        <input
                                          type="time"
                                          style={styles.textInput}
                                          dataSet={{ media: ids.textInput }}
                                          onChange={onChange}
                                          value={value}
                                          placeholder="Enter Stop time in HH:MM"
                                        />
                                      ) : (
                                        <>
                                          <DatePicker
                                            inputString="---Select Stop Time---"
                                            dateTime={stopTime}
                                            mode="time"
                                            setDateTime={(date) => {
                                              setValue(
                                                `stopTime_${index}_${stopIndex}`,
                                                date
                                              );
                                            }}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                  name={`stopTime_${index}_${stopIndex}`}
                                />
                                <View style={styles.inputView}>
                                  <Text style={styles.inputText}>
                                    Description of stop {stopIndex + 1}*
                                  </Text>
                                  <Controller
                                    control={control}
                                    rules={{
                                      required: true,
                                    }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <TextInput
                                        style={styles.textAreaInput}
                                        onChangeText={onChange}
                                        multiline={true}
                                        numberOfLines={4}
                                        value={value}
                                        theme={{
                                          roundness: roundness,
                                        }}
                                        mode="outlined"
                                        outlineColor={inputTextColor}
                                        activeOutlineColor={inputTextColor}
                                        placeholder="Enter Description of Trip"
                                      />
                                    )}
                                    name={`stopDescription_${index}_${stopIndex}`}
                                  />
                                  {`error.locationDescription_${index}_${stopIndex}` && (
                                    <Text
                                      style={styles.inputError}
                                      dataSet={{ media: ids.inputError }}
                                    >
                                      This is required.
                                    </Text>
                                  )}
                                </View>
                                <Button
                                  mode="text"
                                  icon={"minus"}
                                  style={styles.addmoreButton}
                                  labelStyle={{ fontWeight: "900" }}
                                  onPress={() =>
                                    handleDeleteStop(index, stopIndex)
                                  }
                                >
                                  Delete Stop
                                </Button>
                              </View>
                            </View>
                          ))}
                        </ScrollView>
                      </View>
                      <Button
                        mode="text"
                        icon={"plus"}
                        style={styles.addmoreButton}
                        labelStyle={{ fontWeight: "900" }}
                        onPress={() => handleAddStop(index)}
                      >
                        Add More Stop
                      </Button>
                    </View>
                  ))}

                  <View style={styles.buttonView}>
                    <Button
                      mode="text"
                      icon={"plus"}
                      style={styles.addmoreButton}
                      labelStyle={{ fontWeight: "900" }}
                      onPress={handleAddLocation}
                    >
                      Add More Location
                    </Button>
                  </View>
                </View>
              ) : (
                // upload itineraries flow
                <View>
                  {Platform.OS === "web" ? (
                    <View>
                      <input
                        type="file"
                        accept={".pdf,.docx,.doc"}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          setValue("itinerary_url", e.target.files[0])
                        }
                      />
                      <TouchableRipple
                        style={styles.upload_container}
                        onPress={pickItinerariesFile}
                      >
                        <View style={styles.upload_sub_container}>
                          <AntDesign
                            name="upload"
                            size={20}
                            color={primaryColor}
                          />
                          <Text style={styles.upload_text}>
                            Click here to upload itinerary
                          </Text>
                        </View>
                      </TouchableRipple>
                      <Text>{watch("itinerary_url")?.name}</Text>
                    </View>
                  ) : (
                    <TouchableRipple
                      style={styles.upload_container}
                      onPress={pickItinerariesFile}
                    >
                      <View style={styles.upload_sub_container}>
                        <AntDesign
                          name="upload"
                          size={20}
                          color={primaryColor}
                        />
                        <Text style={styles.upload_text}>
                          Click here to upload itinerary
                        </Text>
                      </View>
                    </TouchableRipple>
                  )}
                </View>
              )}
              <View style={styles.footerContainer}>
                <Button
                  loading={loader}
                  mode="contained"
                  style={{ maxWidth: 250 }}
                  buttonColor={primaryColor}
                  onPress={handleSubmit(onSubmitData)}
                >
                  Save
                </Button>
                <Text style={{ marginTop: 10 }}>
                  Note : You can edit after the event creation done!
                </Text>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default ItenariesModal;
