import React, { useState } from "react";
import { ids, styles } from "./BookNowCss";
import {
  Button,
  Divider,
  IconButton,
  Surface,
  TextInput,
  TouchableRipple,
  Checkbox,
} from "react-native-paper";
import { View, Text, Modal, ScrollView, Platform,Dimensions } from "react-native";
import {
  blackColor,
  iconColor,
  inputTextColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../constant/constantCss";
import { useForm, Controller } from "react-hook-form";
import TermsCondition from "../../LnadingPage/components/QuickLinkComponent/TermsCondition";
import QuickLinkModal from "../../LnadingPage/components/QuickLinkModal";
import { Dropdown } from "react-native-element-dropdown";
import { useSelector } from "react-redux";
import {
  EmailIdValidation,
  MobileNumberValidation,
  OnlyNumberValidation,
  PasswordValidation,
} from "../../../constant/validationRegex";
import {
  EnrollTrip,
  EnrollTripWithoutLogin,
} from "../../../service/api/trip/api";
import { showToast } from "../../../constant/toastMessage";
import moment from "moment";
import { RazorpayApiKey, RazorpayConfig } from "../../Razorpay/config";
import logo from "../../../../assets/appIcon/logo512.png";
import { GetOtpApi, VerifyOtpApi } from "../../../service/api/user/OtpApi";
import { useNavigation } from "@react-navigation/native"
import {RazorpayAndroidAndIos} from "../../../../src/screens/Razorpay/RazorpayAndroid"


const BookNowModal = ({
  visible,
  handleClose,
  ageRating,
  trip_id,
  tripTitle,
  tripDepartuureDate,
  noOfDays,
}) => {
  const { first_name, phone_number, id, isLogin } = useSelector(
    (state) => state.auth
  );
  const navigation = useNavigation();
  const [sendOTPLoader, setSendOTPLoader] = useState(false);
  const [isVisibleOTPField, setIsVisibleOTPField] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [isUserExists, setIsUserExists] = useState(false);
  const [isVisibleTermModal, setIsVisibleTermModal] = useState(false);
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      no_of_people: 1?.toString(),
      term_and_condition: false,
      mobile_no: phone_number?.toString(),
      person_name_1: first_name,
    },
  });
  const BatchTypeList = tripDepartuureDate.map((date) => ({
    label: `${moment(date).format("Do MMM YYYY")} To ${moment(date)
      .clone()
      .add(noOfDays, "days")
      .format("Do MMM YYYY")}`,
    value: date,
  }));
  const addNumber = (number, key) => {
    const val = parseInt(number, 10) + 1; // Convert val to a number and increment by 1
    setValue(key, `${val}`);
    unregister(`person_name_${number}`, `person_age_${number}`);
  };
  const minusNumber = (number, key) => {
    const val = parseInt(number, 10) - 1; // Convert val to a number and increment by 1
    if (val >= 1) {
      setValue(key, `${val}`);
    }
    unregister([`person_name_${number}`, `person_age_${number}`]);
  };
  const submitUserDetails = (data) => {
    // Extracting person details dynamically
    const personDetails = [];
    for (let i = 1; i <= parseInt(data.no_of_people); i++) {
      const person = {
        person_name: data[`person_name_${i}`],
        person_age: data[`person_age_${i}`],
      };
      personDetails.push(person);
    }
    const payload = {
      batch_date: moment(data.batch_date).format("YYYY-MM-DD"),
      no_of_people: data.no_of_people,
      email: data.email,
      phone_number: data.mobile_no,
      billing_address: data.billing_address,
      trip: trip_id,
    };

    if (isLogin === true) {
      EnrollTrip(trip_id, payload)
        .then(async (res) => {
          const response = res?.data;
          const options = {
            config: RazorpayConfig,
            key: RazorpayApiKey,
            order_id: response?.order_id,
            name: "Maroon Migrates",
            description: tripTitle,
            currency: response?.currency,
            amount: response?.amount,
            image: logo,
            prefill: {
              name: first_name,
              contact: phone_number,
            },
            notes: {
              trip_id: trip_id,
              user_id: id,
              // other fields
              batch_date: moment(data.batch_date).format("YYYY-MM-DD"),
              no_of_people: data.no_of_people,
              people_details: JSON.stringify(personDetails),
              email: data.email,
              phone_number: data.mobile_no,
              billing_address: data.billing_address,
            },
            theme: { color: primaryColor },
          };
          // web and mac Os support
          if (res?.data?.Enroll && Platform.OS === "web") {
            var rzp1 = new Razorpay(options);
            rzp1.open();
            handleClose();
          }
          // android support
          else if (
            res?.data?.Enroll &&
            (Platform.OS === "android" || Platform.OS === "ios")
          ) {
            await RazorpayAndroidAndIos(options);
            handleClose();
          }
        })
        .catch((err) => {
          showToast(
            "error",
            err.response?.data?.message ?? err.response?.data?.detail,
            err.response?.data?.description
          );
        });
    } else {
      if (isUserExists) {
        showToast(
          "error",
          "Please log in to your existing account to book this trip again."
        );
        navigation.navigate("Login");
      } else {
        const userObj = {
          email: data.email,
          name: data.person_name_1,
          dialing_code: "91",
          phone_number: data.mobile_no,
          user_types: "individual",
          password: data.password,
          payload: { ...payload },
        };
        EnrollTripWithoutLogin(trip_id, userObj)
          .then((res) => {
            const response = res?.data;
            const options = {
              config: RazorpayConfig,
              key: RazorpayApiKey,
              order_id: response?.order_id,
              name: "Maroon Migrates",
              description: tripTitle,
              currency: response?.currency,
              amount: response?.amount,
              image: logo,
              prefill: {
                name: userObj.name,
                contact: userObj.phone_number,
              },
              notes: {
                trip_id: trip_id,
                user_id: response?.user,
                // other fields
                batch_date: moment(data.batch_date).format("YYYY-MM-DD"),
                no_of_people: data.no_of_people,
                people_details: JSON.stringify(personDetails),
                email: data.email,
                phone_number: data.mobile_no,
                billing_address: data.billing_address,
              },
              theme: { color: primaryColor },
            };
            // web and mac Os support
            if (res?.data?.Enroll && Platform.OS === "web") {
              var rzp1 = new Razorpay(options);
              rzp1.open();
              handleClose();
            }
            // android support
            else if (
              res?.data?.Enroll &&
              (Platform.OS === "android" || Platform.OS === "ios")
            ) {
              RazorpayAndroidAndIos(options);
              handleClose();
            }
          })
          .catch((err) => {
            showToast(
              "error",
              err.response?.data?.message ?? err.response?.data?.detail,
              err.response?.data?.description
            );
          });
      }
    }
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <Surface
          elevation={3}
          style={styles.modalView}
          dataSet={{ media: ids.modalView }}
        >
          {/* header */}
          <View style={styles.headerContainer}>
            <View style={{ flex: 1 }}>
              <Text style={styles.headerText}>Book your slot</Text>
              <Text style={styles.subText}>
                Please fill the below details to join trip
              </Text>
            </View>
            <IconButton
              iconColor={blackColor}
              icon={"close"}
              onPress={handleClose}
            />
          </View>
          {/* content */}
          <ScrollView style={{ width: "100%" }}>
            <Divider />
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              {/* select batch */}
              <View style={styles.inputView}>
                <Text style={styles.inputText}>
                  Select Batch<Text style={styles.inputError}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: "please select the batch",
                  }}
                  name="batch_date"
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      data={BatchTypeList}
                      labelField="label"
                      valueField="value"
                      mode={"auto"}
                      search
                      value={watch("batch_date")}
                      style={{
                        paddingHorizontal: 10,
                        borderWidth: 1,
                        borderColor: inputTextColor,
                        borderRadius: 5,
                        backgroundColor: whiteColor,
                        height:40
                      }}
                      inputSearchStyle={{
                        minWidth: 100,
                        maxHeight: 30,
                      }}
                      searchPlaceholder="Search..."
                      placeholderStyle={{ color: subTextColor }}
                      placeholder="Batch Type"
                      onChange={(item) => {
                        setValue("batch_date", item.value);
                      }}
                    />
                  )}
                />
                {errors.batch_date && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.batch_date.message}
                  </Text>
                )}
              </View>
              {/* How many people */}
              <View style={styles.inputView}>
                <Text style={styles.inputText}>
                  How Many People<Text style={styles.inputError}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Name field is required",
                  }}
                  name="no_of_people"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      disabled
                      placeholder="Enter your name"
                      onChangeText={onChange}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                      right={
                        <TextInput.Icon
                          size={10}
                          icon={"plus"}
                          color={iconColor}
                          style={{
                            borderWidth: 1,
                            borderRadius: 20,
                            borderColor: iconColor,
                          }}
                          onPress={() =>
                            addNumber(getValues("no_of_people"), "no_of_people")
                          }
                        />
                      }
                      left={
                        <TextInput.Icon
                          size={10}
                          icon={"minus"}
                          color={iconColor}
                          style={{
                            borderWidth: 1,
                            borderRadius: 20,
                            borderColor: iconColor,
                          }}
                          onPress={() => {
                            minusNumber(
                              getValues("no_of_people"),
                              "no_of_people"
                            );
                          }}
                        />
                      }
                    />
                  )}
                />
                {errors.no_of_people && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.no_of_people.message}
                  </Text>
                )}
              </View>
              {/* no of people */}
              {Array.from({ length: watch("no_of_people") }, (_, index) => (
                <View style={styles.inputView} key={index}>
                  <View style={styles.flexRow}>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.inputText}>
                        Person {index + 1}
                        <Text style={styles.inputError}>*</Text>
                      </Text>
                      <Controller
                        control={control}
                        rules={{
                          required: "Name field is required",
                          pattern: {
                            value: /^[a-zA-Z ]{1,}$/,
                            message: "Invalid name",
                          },
                          maxLength: 30,
                        }}
                        name={`person_name_${index + 1}`}
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            value={value}
                            placeholder="ex. John Doe"
                            onChangeText={onChange}
                            style={styles.textInput}
                            dataSet={{ media: ids.textInput }}
                            mode="outlined"
                            outlineColor={inputTextColor}
                            activeOutlineColor={inputTextColor}
                            contentStyle={{ color: blackColor }}
                          />
                        )}
                      />
                      {errors[`person_name_${index + 1}`] && (
                        <Text
                          style={styles.inputError}
                          dataSet={{ media: ids.inputError }}
                        >
                          {errors[`person_name_${index + 1}`]?.message}
                        </Text>
                      )}
                    </View>
                    <View style={{ maxWidth: 100 }}>
                      <Text style={styles.inputText}>
                        Age<Text style={styles.inputError}>*</Text>
                      </Text>
                      <Controller
                        control={control}
                        rules={{
                          required: "Age field is required",
                          pattern: {
                            value: OnlyNumberValidation,
                            message: "Invalid Age",
                          },
                          validate: (value) => {
                            const age_rating = ageRating ?? 1;
                            if (parseInt(value) < age_rating) {
                              return `Age must be ${age_rating} or more`;
                            }
                            return null;
                          },
                        }}
                        name={`person_age_${index + 1}`}
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            value={value}
                            placeholder="ex. 25"
                            onChangeText={onChange}
                            style={styles.textInput}
                            dataSet={{ media: ids.textInput }}
                            mode="outlined"
                            outlineColor={inputTextColor}
                            activeOutlineColor={inputTextColor}
                            contentStyle={{ color: blackColor }}
                          />
                        )}
                      />
                      {errors[`person_age_${index + 1}`] && (
                        <Text
                          style={styles.inputError}
                          dataSet={{ media: ids.inputError }}
                        >
                          {errors[`person_age_${index + 1}`]?.message}
                        </Text>
                      )}
                    </View>
                  </View>
                </View>
              ))}

              {/* Email */}
              <View style={styles.inputView}>
                <Text style={styles.inputText}>
                  Email<Text style={styles.inputError}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Email field is required",
                    pattern: {
                      value: EmailIdValidation,
                      message: "Invalid Email id",
                    },
                  }}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      placeholder="ex. john.doe@gmail.com"
                      onChangeText={onChange}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                    />
                  )}
                />
                {errors.email && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.email.message}
                  </Text>
                )}
              </View>
              {/* Mobile number*/}
              <View style={styles.inputView}>
                <Text style={styles.inputText}>
                  Mobile No<Text style={styles.inputError}>*</Text>
                </Text>
                <View style={styles.flexRow}>
                  <View style={{ flex: 1 }}>
                    <Controller
                      control={control}
                      name="mobile_no"
                      rules={{
                        required: "Mobile no field is required",
                        pattern: {
                          value: MobileNumberValidation,
                          message: "Invalid Mobile no",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          value={value}
                          placeholder="ex. 0123456789"
                          onChangeText={onChange}
                          maxLength={10}
                          disabled={
                            phone_number || isOTPVerified ? true : false
                          }
                          style={styles.textInput}
                          dataSet={{ media: ids.textInput }}
                          mode="outlined"
                          outlineColor={inputTextColor}
                          activeOutlineColor={inputTextColor}
                          contentStyle={{ color: blackColor }}
                        />
                      )}
                    />
                    {errors.mobile_no && (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        {errors.mobile_no.message}
                      </Text>
                    )}
                  </View>
                  {!phone_number && (
                    <Button
                      buttonColor={primaryColor}
                      textColor={whiteColor}
                      loading={sendOTPLoader}
                      disabled={isOTPVerified ? true : false}
                      style={{ maxWidth: 180, borderRadius: 5 }}
                      onPress={() => {
                        if (
                          getValues("mobile_no") !== undefined &&
                          getValues("mobile_no")?.length >= 10
                        ) {
                          setSendOTPLoader(true);
                          const otpData = {
                            dialing_code: "91",
                            phone_number: getValues("mobile_no"),
                          };
                          GetOtpApi("send/", otpData)
                            .then((res) => {
                              setIsVisibleOTPField(true);
                              setSendOTPLoader(false);
                              showToast("success", res.data.data.message);
                              setIsUserExists(res.data.data.is_exist_user);
                            })
                            .catch((err) => {
                              setSendOTPLoader(false);
                              showToast("error", err?.response?.data?.detail);
                            });
                        }
                      }}
                    >
                      {isOTPVerified ? "Verified" : "Send OTP"}
                    </Button>
                  )}
                </View>
              </View>
              {/* otp */}
              {isVisibleOTPField && !isOTPVerified && (
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>
                    OTP<Text style={styles.inputError}>*</Text>
                  </Text>
                  <View style={styles.flexRow}>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={control}
                        name="otp"
                        render={({ field: { onChange, value } }) => (
                          <TextInput
                            value={value}
                            placeholder="XXXXXX"
                            onChangeText={onChange}
                            maxLength={6}
                            disabled={phone_number ? true : false}
                            style={styles.textInput}
                            dataSet={{ media: ids.textInput }}
                            mode="outlined"
                            outlineColor={inputTextColor}
                            activeOutlineColor={inputTextColor}
                            contentStyle={{ color: blackColor }}
                          />
                        )}
                      />
                      {errors.otp && (
                        <Text
                          style={styles.inputError}
                          dataSet={{ media: ids.inputError }}
                        >
                          {errors.otp.message}
                        </Text>
                      )}
                    </View>
                    {!phone_number && (
                      <Button
                        buttonColor={primaryColor}
                        textColor={whiteColor}
                        style={{ maxWidth: 180, borderRadius: 5 }}
                        onPress={() => {
                          const dataWithOTP = {
                            dialing_code: "91",
                            phone_number: getValues("mobile_no"),
                            otp: getValues("otp"),
                          };
                          VerifyOtpApi("verify/", dataWithOTP)
                            .then((res) => {
                              showToast("success", res.data?.message);
                              setIsOTPVerified(true);
                            })
                            .catch((err) => {
                              showToast("error", err.response?.data?.message);
                            });
                        }}
                      >
                        Verify
                      </Button>
                    )}
                  </View>
                </View>
              )}
              {/* Password */}
              {isOTPVerified && !isUserExists && (
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>
                    Create Your Password<Text style={styles.inputError}>*</Text>
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: "Password field is required",
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/,
                        message:
                          "Password must include at least one uppercase letter, one lowercase letter, and one number",
                      },
                    }}
                    name="password"
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        value={value}
                        onChangeText={onChange}
                        style={styles.textInput}
                        dataSet={{ media: ids.textInput }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        contentStyle={{ color: blackColor }}
                        secureTextEntry
                      />
                    )}
                  />
                  {errors.password && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      {errors.password.message}
                    </Text>
                  )}
                </View>
              )}
              {/* Already exist user so please login flow */}
              {isUserExists && isOTPVerified && (
                <View style={styles.inputView}>
                  <View style={styles.flexRow}>
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      Note : Please log in to your existing account to book this
                      trip again.
                    </Text>
                    <Button
                      buttonColor={primaryColor}
                      textColor={whiteColor}
                      style={{ maxWidth: 180, borderRadius: 5 }}
                      onPress={() => {
                        handleClose();
                        navigation.navigate("Login");
                      }}
                    >
                      Login
                    </Button>
                  </View>
                </View>
              )}
              {/* Billing address*/}
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Billing Address</Text>
                <Controller
                  control={control}
                  name="billing_address"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      placeholder="ex. 123, Location, City"
                      onChangeText={onChange}
                      maxLength={150}
                      multiline={true}
                      style={styles.textAreaInput}
                      dataSet={{ media: ids.textAreaInput }}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                    />
                  )}
                />
                {errors.billing_address && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.billing_address.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Controller
                    control={control}
                    name="term_and_condition"
                    rules={{
                      required: "Please select the T&C checkbox",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox.Android
                        color={primaryColor}
                        status={
                          getValues("term_and_condition") === true
                            ? "checked"
                            : "unchecked"
                        }
                        onPress={() =>
                          setValue(
                            "term_and_condition",
                            !getValues("term_and_condition")
                          )
                        }
                      />
                    )}
                  />

                  <Text style={{ fontSize: 18, color: blackColor }}>
                    I accept the{" "}
                    <TouchableRipple
                      onPress={() => setIsVisibleTermModal(true)}
                    >
                      <Text style={{ color: primaryColor }}>
                        Terms and condition
                      </Text>
                    </TouchableRipple>
                  </Text>
                </View>
                {errors.term_and_condition && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.term_and_condition.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                {isUserExists ? (
                  <Button
                    buttonColor={primaryColor}
                    textColor={whiteColor}
                    onPress={() => {
                      handleClose();
                      navigation.navigate("Login");
                    }}
                  >
                    Login
                  </Button>
                ) : (
                  <Button
                    buttonColor={primaryColor}
                    textColor={whiteColor}
                    disabled={phone_number || isOTPVerified ? false : true}
                    onPress={handleSubmit(submitUserDetails)}
                  >
                    Pay Now
                  </Button>
                )}
              </View>
            </View>
          </ScrollView>
        </Surface>
      </View>
    </Modal>
  );
};

export default BookNowModal;
