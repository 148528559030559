import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const USER_ENDPOINT = {
  USER_FILTER_URL: BASE_URL + "auth/user/",
};

export const EditUserApi = async (data) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    const mergedHeaders = {
      ...headers.headers,
      'Content-Type': 'multipart/form-data',
    };
    return axios.patch(USER_ENDPOINT.USER_FILTER_URL, data, {headers:mergedHeaders});
  }
};
