import AsyncStorage from "@react-native-async-storage/async-storage";
import { getApiUrl, get_token } from "../../constant/apiUrl";
import { REACT_APP_DEV_URL, REACT_APP_ENV, REACT_APP_PROD_URL } from "@env";

// Define an asynchronous function to set the headers
export async function getAuthorizationHeader() {
  try {
    const authToken = await get_token();

    if (authToken) {
      const headers = await {
        headers: {
          Authorization: "Bearer " + authToken,
        },
      };
      return headers;
    } else {
      return false;
    }
  } catch (error) {
    // Handle errors related to AsyncStorage
    console.error("Error getting AuthToken:", error);
    throw error; // You might want to rethrow the error to handle it further up the call stack
  }
}

// The variable from Env not getting this is going in else condition
export const BACK_END_URL =
  REACT_APP_ENV == "development"
    ? REACT_APP_DEV_URL?.replace("/api/", "")
    : REACT_APP_PROD_URL?.replace("/api/", "");
export const BASE_URL = getApiUrl ?? `${BACK_END_URL}/api/`;
