import React, { useState } from "react";
import { ids, styles } from "./UserModelCss";
import { View, Text, Modal, ScrollView } from "react-native";
import { Button } from "react-native-paper";
import { primaryColor } from "../../../constant/constantCss";
import { VerifyUserAPI } from "../../../service/api/user/verifyUser";
import { showToast } from "../../../constant/toastMessage";
import { GetAllserAPI } from "../../../service/api/user/getUserApi";

const UserDetailModal = ({
  visible,
  handleClose,
  userData,
  setUsers,
  isActivateButtonVisible,
}) => {
  const [loader, setloader] = useState(false);
  const RowContainer = ({ userKey, userVal }) => {
    return (
      <View style={styles.flexRow} key={userKey}>
        <Text style={styles.titleText}>{userKey}</Text>
        <Text style={styles.subTitleText}>
          {userVal !== null ? userVal : "N/A"}
        </Text>
      </View>
    );
  };
  const handleVerifyButton = () => {
    setloader(true);
    const payload = {
      is_active: !userData.is_active,
    };
    VerifyUserAPI(userData.id, payload)
      .then((res) => {
        GetAllserAPI({}).then((res) => {
          if (!userData.is_active) {
            showToast(
              "success",
              "Account activated success!",
              "Now This User can login."
            );
          } else {
            showToast(
              "error",
              "Account de-activated success!",
              "Now This User can't login any more."
            );
          }
          setUsers(res?.data?.results);
          setloader(false);
          handleClose();
        });
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        setloader(false);
      });
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>User Details</Text>
            <Text style={styles.closeButton} onPress={handleClose}>
              X
            </Text>
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <RowContainer userKey="Name: " userVal={userData.first_name} />
              <RowContainer userKey="Gender: " userVal={userData.gender} />
              <RowContainer
                userKey="Date of birth: "
                userVal={userData.date_of_birth}
              />
              <RowContainer
                userKey="Phone number: "
                userVal={`+${userData.dialing_code} ${userData.phone_number}`}
              />
              <RowContainer userKey="Role: " userVal={userData.groups?.group} />
              <RowContainer
                userKey="School name: "
                userVal={userData.school_name}
              />
              <RowContainer
                userKey="Enrollment no: "
                userVal={userData.enrollment_no}
              />
              <RowContainer
                userKey="Verified by: "
                userVal={userData?.activated_by?.first_name ?? "N/A"}
              />
            </View>
          </ScrollView>
          {isActivateButtonVisible && (
            <Button
              loading={loader}
              icon={userData?.is_active ? "close" : "check"}
              mode="contained"
              buttonColor={primaryColor}
              style={{ maxWidth: 250 }}
              onPress={handleVerifyButton}
            >
              {userData?.is_active ? "De-Activate" : "Activate"}
            </Button>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default UserDetailModal;
