import React from "react";
import { View, Text, Modal } from "react-native";
import { Button } from "react-native-paper";
import { ids, styles } from "./ConfirmModalCss";
import { primaryColor, whiteColor } from "../../constant/constantCss";

const VerifyReviewModal = ({
  visible,
  handleClose,
  title,
  description,
  handleFooterButton,
  review_id,
}) => {
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>{title}</Text>
            <Text style={styles.closeButton} onPress={handleClose}>
              X
            </Text>
          </View>
          {/* body */}
          <View
            style={styles.bodyContainer}
            dataSet={{ media: ids.bodyContainer }}
          >
            <Text style={styles.descriptionText}>{description}</Text>
          </View>
          {/* footer */}
          <View style={styles.footerContainer}>
            <Button
              mode="text"
              style={{ maxWidth: 200 }}
              textColor={primaryColor}
              onPress={() => handleFooterButton("cancel", review_id)}
            >
              Cancel
            </Button>
            <Button
              mode="contained"
              style={{ maxWidth: 200 }}
              buttonColor={primaryColor}
              textColor={whiteColor}
              onPress={() => handleFooterButton("ok", review_id)}
            >
              Ok
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default VerifyReviewModal;
