import StyleSheet from "react-native-media-query";
import {
  primaryColor,
  whiteColor,
  errorTextColor,
  inputBackColor,
  inputTextColor,
  blackColor,
} from "../../../constant/constantCss";
import { Dimensions } from "react-native";

const { height, width } = Dimensions.get("window");
const inputWidth = width * 2;

export const { ids, styles } = StyleSheet.create({
  gridInput: {
    "@media (min-width: 768px)": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
    },
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    width: 400,
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 480px)": {
      width: "85%",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  closeIcon: {
    display: "flex",
    textAlign: "right",
    justifyContent: "flex-end",
  },

  container: {
    borderRadius: 20,
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
    color: blackColor,
    marginBottom: 10,
    height: 400,
  },
  inputColumns: {
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 1200px)": {
      flexDirection: "column",
    },
  },
  rowContainer: {
    flexDirection: "row",
  },
  input: {
    marginTop: 20,
    "@media (min-width: 769px)": {
      width: "100%",
    },
  },
  inputError: {
    color: errorTextColor,
    fontWeight: 600,
    padding: 10,
    paddingBottom: 0,
  },
  inputText: {
    fontSize: 16,
    height: 35,
    borderRadius: 5,
    backgroundColor: inputBackColor,
    borderColor: inputTextColor,
    paddingLeft: 10,
    paddingRight: 10,
    color: blackColor
  },
  textInput: {
    fontSize: 13,
    height: 35,
    backgroundColor: inputBackColor,
    width: "100%",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  genderInput: {
    fontSize: 13,
    height: 35,
    width: inputWidth,
    flexDirection: "row",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },

  inputLabel: {
    fontWeight: 700,
    color: blackColor,
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
  },
  uploadInput: {
    display: "None",
  },
  gender: {
    color: blackColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    marginTop: 40,
    borderWidth: 1,
    borderColor: primaryColor,
    backgroundColor: primaryColor,
    width: "100%",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
});
