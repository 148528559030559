import StyleSheet from "react-native-media-query";
import {
  whiteColor,
  blackColor,
  inputBackColor,
  subTextColor,
} from "../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    width: 500,
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: blackColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    position: "fixed",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 768px)": {
      width: "90%",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  uploadInput: {
    display: "none",
  },
  uploadButton: {
    maxWidth: 350,
    color: whiteColor,
  },
  headerText: {
    fontSize: 22,
    fontWeight: "800",
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "800",
  },

  // Body
  bodyContainer: {
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: "100%",
    overflow: "auto",
    "@media (max-width: 992px)": {
      flexDirection: "column",
      gap: 0,
    },
  },
  scrollList: {
    height: 500,
  },
  inputView: {
    marginTop: 26,
  },
  inputText: {
    fontSize: 16,
  },
  textInput: {
    fontSize: 16,
    height: 38,
    borderRadius: 10,
    backgroundColor: inputBackColor,
    color: blackColor,
    flex: 1,
  },
  inputError: {
    color: "#c72810",
    fontWeight: 600,
    paddingBottom: 0,
  },
  listContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    gap: 20,
  },
  listTitle: {
    fontSize: 16,
  },
  listDesc: {
    fontSize: 14,
    color: subTextColor,
  },
});
