import React, { useState, useEffect } from "react";
import { View, Text, Modal, ScrollView } from "react-native";
import { Button, List, Switch } from "react-native-paper";
import { ids, styles } from "./PermissionCss";
import { GetAllPermission } from "../../../service/api/permission/api";
import { CreateRole, GetAllRole } from "../../../service/api/role/api";
import { useDispatch } from "react-redux";
import { setRoleDetails } from "../../../store/slices/rolePermissionSlice";
import { showToast } from "../../../constant/toastMessage";
import { primaryColor } from "../../../constant/constantCss";
import SearchPermission from "./Search Permission/SearchPermission";
import Octicons from "react-native-vector-icons/Octicons";
import DescriptionDialog from "../DescriptionDialog";

const SelectPermissionModal = ({
  formData,
  setFormData,
  visible,
  handleClose,
  closeCreateRoleModal,
}) => {
  useEffect(() => {
    GetAllPermission()
      .then((res) => {
        setPermissions(res?.data);
        setAllPermissions(res?.data);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  }, []);
  const [visibleDialog, setVisibleDialog] = useState({
    visible: false,
    permission: "",
    description: "",
  });

  const [allPermissions, setAllPermissions] = useState([]); // for searching permission
  const [permissions, setPermissions] = useState([]);
  const [loader, setloader] = useState(false);
  const dispatch = useDispatch();
  const togglePermission = (permissionId, isSet) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      permission: {
        ...prevFormData.permission,
        [permissionId]: isSet,
      },
    }));
  };
  const handleSubmit = () => {
    setloader(true);
    CreateRole(formData)
      .then((res) => {
        setloader(false);
        showToast("success", formData?.name + " role is created!");

        GetAllRole()
          .then((res) => {
            dispatch(setRoleDetails(res?.data?.results));
            handleClose();
            closeCreateRoleModal();
          })
          .catch((err) => {
            showToast(
              "error",
              err?.response?.data?.detail || "Something went wrong!"
            );
          });
      })
      .catch((err) => {
        setloader(false);
        showToast(
          "error",
          err?.response?.data?.name?.[0] ??
            err?.response?.data?.detail ??
            "Something went wrong"
        );
      });
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>
              <Button icon="arrow-left" onPress={handleClose}></Button>
              Select Permissions
            </Text>
            <Text style={styles.closeButton} onPress={handleClose}>
              X
            </Text>
          </View>
          <View style={{ width: "100%" }}>
            <SearchPermission
              permissionList={permissions}
              setpermissionList={setPermissions}
              allPermission={allPermissions}
            />
            <View
              style={[styles.bodyContainer, styles.scrollList]}
              dataSet={{ media: ids.bodyContainer }}
            >
              <ScrollView>
                {permissions?.map((permission, index) => (
                  <List.Item
                    key={index}
                    left={(props) => (
                      <Octicons
                        name="info"
                        size={20}
                        onPress={() =>
                          setVisibleDialog({
                            visible: true,
                            description: permission.name,
                            permission: permission.codename,
                          })
                        }
                      />
                    )}
                    title={permission.codename?.replaceAll("_", " ")}
                    titleNumberOfLines={3}
                    description={permission.name}
                    right={() => (
                      <Switch
                        value={formData.permission[permission.id] || false}
                        color={"#B01D32"}
                        onValueChange={(val) => {
                          togglePermission(permission.id, val);
                        }}
                      ></Switch>
                    )}
                  />
                ))}
              </ScrollView>
            </View>
          </View>
          <Button
            mode="contained"
            buttonColor={primaryColor}
            style={{ width: 250 }}
            onPress={handleSubmit}
            loading={loader}
          >
            {loader ? "Saving" : "Save"}
          </Button>
        </View>
      </View>
      {visibleDialog.visible && (
        <DescriptionDialog
          visibleDialog={visibleDialog}
          setVisibleDialog={setVisibleDialog}
        />
      )}
    </Modal>
  );
};

export default SelectPermissionModal;
