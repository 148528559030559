import StyleSheet from "react-native-media-query";
import {
  whiteColor,
  greyBackColor,
  primaryColor,
} from "../../../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  container: {
    marginBottom: 30,
    height: "100%",
    minHeight: 0,
    padding: 16,
  },
  innerContainer: {
    height: "100%",
  },
  whiteContainer: {
    width: "100%",
    backgroundColor: whiteColor,
    padding: 20,
    borderRadius: 20,
    "@media (max-width: 480px)": {
      padding: 10,
    },
  },
  centeredContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  noupdatesText: {
    fontSize: 18,
    fontWeight: 600,
    margin: 20,
  },

  //Modal
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    width: 500,
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 480px)": {
      width: "85%",
    },
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  closeIcon: {
    display: "flex",
    textAlign: "right",
    justifyContent: "flex-end",
  },
  updateText: {
    fontSize: 16,
    marginBottom: 5,
  },
  textInput: {
    backgroundColor: greyBackColor,
  },
  editButton: {
    backgroundColor: primaryColor,
    borderColor: primaryColor,
    borderWidth: 1,
    borderRadius: 10,
    "@media (max-width: 480px)": {
      right: 10,
    },
  },
  listStyle: {
    backgroundColor: whiteColor,
    borderRadius: 10,
    width: "100%",
    marginTop: 10,
  },
});
