import React from "react";
import { View, Text,Platform,TouchableOpacity,ScrollView,Dimensions } from "react-native";
import { blackColor, subTextColor, whiteColor } from "../../../../constant/constantCss";
import ContactUs from "../ContactUs";
import Navbar from "../Navbar";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

const TermsCondition = () => {
  const termsList = [
    {
      title: "Booking and Reservation Policies : ",
      description:
        "This includes information about how to book a service (online, through an agent, etc.), the deadlines for booking, and the requirements for making a reservation (e.g., providing a deposit).",
    },
    {
      title: "Payment Terms : ",
      description:
        "This section outlines the payment methods accepted by the travel company, the currency in which payments must be made, and any payment deadlines or installment options.",
    },
    {
      title: "Cancellation and Refund Policies : ",
      description:
        "This covers the conditions under which a customer can cancel a booking and receive a refund, as well as any penalties or fees that may apply for cancellations.",
    },
    {
      title: "Changes to Bookings : ",
      description:
        "This section explains the process for making changes to a booking (such as changing dates or adding passengers) and any associated fees or restrictions.",
    },
    {
      title: "Travel Insurance : ",
      description:
        "Some terms and conditions may recommend or require customers to purchase travel insurance to protect against unforeseen events such as trip cancellations or medical emergencies.",
    },
    {
      title: "Travel Documents and Identification : ",
      description:
        "This includes information about the documents required for travel (e.g., passports, visas) and the responsibility of the customer to ensure they have the correct documentation.",
    },
    {
      title: "Health and Safety : ",
      description:
        "This section may include information about health and safety requirements (e.g., vaccinations) and any liability limitations related to health issues during travel.",
    },
    {
      title: "Liability and Disclaimers : ",
      description:
        "This covers the limits of the travel company's liability for losses or damages incurred by the customer, as well as any disclaimers of warranties or guarantees.",
    },
    {
      title: "Force Majeure : ",
      description:
        "This clause typically outlines the travel company's responsibilities and liabilities in the event of unforeseeable circumstances such as natural disasters, political unrest, or other force majeure events.",
    },
    {
      title: "Governing Law : ",
      description:
        "This specifies the jurisdiction whose laws will govern the terms and conditions and any disputes that may arise between the travel company and the customer.",
    },
  ];

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const screenHeight = Dimensions.get("window").height;

  return (
    <View style={{backgroundColor:whiteColor,minHeight: screenHeight}}>
      <Navbar hideComponents={true}/>
      <ScrollView
        style={{marginBottom: Platform.OS==="ios" ? 120: Platform.OS!=="web" && 60}}
      >
      <View style={{margin:50,maxWidth:1000,justifyContent:"center",alignSelf:"center"}}>
      <Text style={{ fontSize: 22,
          color: blackColor,
          fontWeight: "900",
          marginTop: 16,
          marginBottom:16}}>
            Our Terms And Conditions Policy
        </Text>
      <Text style={{ fontSize: 16, color: subTextColor }}>
        Terms and conditions in the travel industry are the rules and
        regulations that govern the relationship between a travel company (such
        as an airline, hotel, or tour operator) and its customers. These terms
        and conditions typically cover a wide range of topics, including booking
        procedures, payment terms, cancellation policies, and liability
        limitations. Here are some common terms and conditions you might
        encounter in the travel industry :
      </Text>
      {termsList?.map((term, index) => (
        <Text
          key={index}
          style={{
            fontSize: 18,
            fontWeight: "800",
            color: blackColor,
            marginTop: 16,
          }}
        >
          {term.title}
          <Text style={{ fontSize:16, fontWeight: "500", color: subTextColor }}>
            {term.description}
          </Text>
        </Text>
      ))}
      </View>
      {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius:50,
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
    </View>
  );
};

export default TermsCondition;
