import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDrawerVisible: false,
  backgroundImage: null,
  ActiveItem: "dashboard",
  selected_trip_id: null,
  fromBackButton: false,
  currentTripId: null,
  bannerImagesRefreshState: false
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setIsDrawerVisible: (state, isVisible) => {
      state.isDrawerVisible = isVisible.payload;
    },
    setBannerImageRefreshState: (state, isVisible) => {
      state.bannerImagesRefreshState = isVisible.payload;
    },
    setSelectedTripId: (state, id) => {
      state.selected_trip_id = id.payload;
    },
    setFromBackButton: (state, data) => {
      state.fromBackButton = data.payload;
    },
    setCurrentTripId: (state, data) => {
      state.currentTripId = data.payload;
    },
    randomBackgoundImage: (state, number) => {
      state.backgroundImage = number.payload;
    },
    ActiveItem: (state, screen) => {
      state.ActiveItem = screen.payload;
    },
  },
  extraReducers: (builder) => {},
});
export const { setIsDrawerVisible } = generalSlice.actions;
export const { randomBackgoundImage } = generalSlice.actions;
export const { ActiveItem } = generalSlice.actions;
export const { setSelectedTripId } = generalSlice.actions;
export const { setFromBackButton } = generalSlice.actions;
export const { setCurrentTripId } = generalSlice.actions;
export const { setBannerImageRefreshState } = generalSlice.actions;
export default generalSlice.reducer;
