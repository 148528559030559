import React, { useEffect, useRef } from "react";
import { Animated, Image, View, StyleSheet, Easing } from "react-native";
import flyingMan from "../../assets/01.png";

const PlaneLoader = () => {
  const animatedValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const startAnimation = () => {
      // animatedValue.setValue(0);
      Animated.timing(animatedValue, {
        toValue: 1,
        duration: 800,
        easing: Easing.linear,
        useNativeDriver: true,
      })
        .start(() => {
          animatedValue.setValue(0); // Reset the animation value
          startAnimation();
        });
    };

    startAnimation();
  }, []);

  const translateX = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [-100, 250], // Adjusted to ensure the image moves from right to left continuously
  });

  return (
    <View style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
      <View style={styles.container}>
        <Animated.View style={{ transform: [{ translateX }], }}>
          <Image
            resizeMode="contain"
            source={flyingMan}
            style={styles.image}
          />
        </Animated.View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 200,
    height: 150,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: 100,
    width: 150,
  },
});

export default PlaneLoader;
