import React, { useEffect, useState } from "react";
import { ids, styles } from "./ParticipantTableCss";
import { ScrollView, View, Text } from "react-native";
import { Chip, DataTable } from "react-native-paper";
import {
  primaryColor,
  privateTripColor,
  publicTripColor,
} from "../../../constant/constantCss";
import { Link } from "@react-navigation/native";
import { GetAllParticipantCount } from "../../../service/api/analytics/api";
import { showToast } from "../../../constant/toastMessage";
import PlaneLoader from "../../../components/Loader";

const ParticipantTable = () => {
  const [participantList, setParticipantList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    GetAllParticipantCount()
      .then((res) => {
        setLoader(false);
        setParticipantList(res.data);
      })
      .catch((err) => {
        setLoader(false);
        showToast("error", err?.response?.data?.detail);
      });
  }, []);

  return (
    <ScrollView horizontal={true}>
      <View style={styles.tableStyle} dataSet={{ media: ids.tableStyle }}>
        {loader ? (
          <PlaneLoader />
        ) : participantList?.length <= 0 ? (
          <Text style={styles.notFoundText}>
            There is no participants found !!
          </Text>
        ) : (
          <DataTable collapsable={true}>
            <DataTable.Header>
              <DataTable.Title>Trip</DataTable.Title>
              <DataTable.Title>Participants</DataTable.Title>
              <DataTable.Title>Amount(₹)</DataTable.Title>
              <DataTable.Title>Type</DataTable.Title>
            </DataTable.Header>
            {participantList?.map((participant, index) => (
              <DataTable.Row key={index}>
                <DataTable.Cell>
                  <Link
                    style={{ color: primaryColor }}
                    to={{
                      screen: "ShowTripInfo",
                      params: { data: participant?.trip },
                    }}
                  >
                    {participant?.details?.title ?? "N/A"}
                  </Link>
                </DataTable.Cell>
                <DataTable.Cell>
                  {participant?.participant_count ?? 0}
                </DataTable.Cell>
                <DataTable.Cell>
                  {participant?.details?.discount ? `${parseFloat(participant?.details?.amount) - (parseFloat(participant?.details?.amount) * parseFloat(participant?.details?.discount) / 100).toFixed(2)} ₹` : `${participant?.details?.amount} ₹` ?? "N/A"}
                </DataTable.Cell>
                <DataTable.Cell>
                  {participant?.details?.is_public ? (
                    <Chip style={{ backgroundColor: publicTripColor }}>
                      Public
                    </Chip>
                  ) : (
                    <Chip style={{ backgroundColor: privateTripColor }}>
                      Private
                    </Chip>
                  )}
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
        )}
      </View>
    </ScrollView>
  );
};

export default ParticipantTable;
