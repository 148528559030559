import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  first_name: null,
  last_name: null,
  gender: null,
  profile_url: null,
  date_of_birth: null,
  dialing_code: null,
  phone_number: null,
  last_login: null,
  groups: [],
  school_name: null,
  enrollment_no: null,

  access_token: null,
  refresh_token: null,
  isLogin: false,
  permission: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, user) => {
      state.id = user.payload.id;
      state.first_name = user.payload.first_name;
      state.last_name = user.payload.last_name;
      state.gender = user.payload.gender;
      state.profile_url = user.payload.profile_url;
      state.date_of_birth = user.payload.date_of_birth;
      state.dialing_code = user.payload.dialing_code;
      state.phone_number = user.payload.phone_number;
      state.last_login = user.payload.last_login;
      state.groups = user.payload.groups;
      state.school_name = user.payload.school_name;
      state.enrollment_no = user.payload.enrollment_no;
      state.permission = user.payload.groups.permissions;
      state.isLogin = true;
    },
    resetState: (state, user) => {
      state.id = null;
      state.first_name = null;
      state.last_name = null;
      state.gender = null;
      state.profile_url = null;
      state.date_of_birth = null;
      state.dialing_code = null;
      state.phone_number = null;
      state.last_login = null;
      state.groups = null;
      state.school_name = null;
      state.enrollment_no = null;
      state.permission = [];
      state.isLogin = false;
      state.access_token = null;
    },
    setPermission: (state, permission_list) => {
      state.permission = permission_list.payload;
    },
    setLoginData: (state, loginUser) => {
      state.access_token = loginUser.payload.access;
      state.refresh_token = loginUser.payload?.refresh;
      state.isLogin = true;
    },
  },
  extraReducers: (builder) => {},
});
export const { setUserData } = authSlice.actions;
export const { setPermission } = authSlice.actions;
export const { resetState } = authSlice.actions;
export const { setLoginData } = authSlice.actions;
export const setUserStore = (state) => state.setUserData;
export default authSlice.reducer;
