import { useEffect, useState, Fragment } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  Platform,
  StatusBar,
  Text,
  TouchableOpacity
} from "react-native";
import AuthStack from "./src/navigation/auth-stack";
import { Provider } from "react-redux";
import { store,persistor } from "./src/store/store";
import Toast from "react-native-toast-message";
import { toastConfig } from "./src/constant/toastMessage";
import { primaryColor, whiteColor } from "./src/constant/constantCss";
import { LogLevel, OneSignal } from "react-native-onesignal";
import Constants from "expo-constants";
import NetInfo from "@react-native-community/netinfo";
import NoInternetScreen from "./src/components/NoInternet/NoInternetScreen";
import { SafeAreaView } from "react-native";
import MaterialIcons from "react-native-vector-icons/MaterialIcons"
import { PersistGate } from "redux-persist/integration/react";

export default function App() {
  const currentHeight = Dimensions.get("window").height;
  const [isConnected, setIsConnected] = useState(false);
  const checkInternetConnectivity = async () => {
    const state = await NetInfo.fetch();
    return state.isConnected;
  };
  const checkConnectivity = async () => {
    const isConnected = await checkInternetConnectivity();
    setIsConnected(isConnected);
  };
  useEffect(() => {
    // check connectivity of internet
    checkConnectivity();

    if (Platform.OS === "web") {
      // Rezor pay script load for web only ..
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);

      // one signal script for web only
      const one_signal_script = document.createElement("script");
      one_signal_script.src =
        "https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js";
      one_signal_script.async = true;
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      OneSignalDeferred.push(function (OneSignal) {
        OneSignal.init({
          appId: "a66ebaba-14cf-49d4-bfe6-2798bf67530c",
          safari_web_id:
            "web.onesignal.auto.261dc44c-6b5c-4882-ba5f-51ef0736d918",
          notifyButton: {
            enable: true,
          },
          allowLocalhostAsSecureOrigin: true,
        });
      });
      document.body.appendChild(one_signal_script);
    } else if (Platform.OS === "android" || Platform.OS === "ios") {
      OneSignal.Debug.setLogLevel(LogLevel.Verbose);
      OneSignal.initialize(Constants.expoConfig.extra.oneSignalAppId);
      // Also need enable notifications to complete OneSignal setup
      OneSignal.Notifications.requestPermission(true);
    }
    return () => {
      if (Platform.OS === "web") {
        document.body.removeChild(script);
        document.body.removeChild(one_signal_script);
      }
    };
  }, []);
  return (
    <Fragment>
      {Platform.OS === "ios" ? (
        <SafeAreaView>
          <Provider store={store}>
            <View style={[styles.container, { minHeight: currentHeight }]}>
              <StatusBar
                style="auto"
                animated={true}
                backgroundColor={primaryColor}
              />
              {isConnected ? (
                <AuthStack />
              ) : (
                <NoInternetScreen checkConnectivity={checkConnectivity} />
              )}
              <Toast config={toastConfig} />
            </View>
          </Provider>
        </SafeAreaView>
      ) : (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <View style={[styles.container, { minHeight: currentHeight }]}>
              <StatusBar
                style="auto"
                animated={true}
                backgroundColor={primaryColor}
              />
              {isConnected ? (
                <AuthStack />
              ) : (
                <NoInternetScreen checkConnectivity={checkConnectivity} />
              )}
              
              <Toast config={toastConfig} />
            </View>
          </PersistGate>
        </Provider>
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    position: "relative",
    flex: 1,
    backgroundColor: whiteColor,
  },
});
