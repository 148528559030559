import React from "react";
import { Text, View } from "react-native";
import { ids, styles } from "../../TripInfoCss.js";
import { Divider } from "react-native-paper";

const TripAdditionalInfo = ({ title, data }) => {
    const renderBulletedText = (text) => {
        return text.split('\n').map((line, index) => (
            <View key={index} style={styles.bulletText}>
                <Text style={styles.bullet}>• </Text>
                <Text>{line}</Text>
            </View>
        ));
    };

    return (
        <View style={{ flexDirection: "column" }} id={title}>
            {/* header */}
            <View>
                <View style={styles.rowContainer}>
                    <Text style={styles.headerKeyText}>{title}</Text>
                </View>
                <Divider style={{ maxWidth: 500, padding: 0, marginBottom: 16 }} />
            </View>
            <View>{renderBulletedText(data)}</View>
        </View>
    );
};

export default TripAdditionalInfo;
