import React from "react";
import { TextInput } from "react-native-paper";
import { StyleSheet } from "react-native";
import { GetAllRole } from "../../../service/api/role/api";
import { useDispatch } from "react-redux";
import { setRoleDetails } from "../../../store/slices/rolePermissionSlice";
import { showToast } from "../../../constant/toastMessage";
import {
  blackColor,
  inputTextColor,
  whiteColor,
} from "../../../constant/constantCss";

const SearchRole = () => {
  const dispatch = useDispatch();
  const handleSearchSchool = (searchVal) => {
    GetAllRole(null, searchVal)
      .then((res) => {
        dispatch(setRoleDetails(res?.data?.results));
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  };
  const styles = StyleSheet.create({
    textInput: {
      fontSize: 16,
      height: 38,
      borderRadius: 20,
      color: blackColor,
      backgroundColor: whiteColor,
      maxWidth: 350,
      marginBottom: 20,
      marginTop: 20,
    },
  });
  return (
    <TextInput
      style={styles.textInput}
      mode="outlined"
      outlineColor={blackColor}
      activeOutlineColor={inputTextColor}
      placeholder="search role "
      onChangeText={(text) => handleSearchSchool(text)}
    />
  );
};

export default SearchRole;
