import StyleSheet from "react-native-media-query";
import {
  blackColor,
  inputBackColor,
  inputTextColor,
  primaryColor,
  subTextColor,
  whiteColor,
  errorTextColor,
  appBarColor,
} from "../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  navBarContainer: {
    height: 60,
    paddingHorizontal: 40,
    backgroundColor: appBarColor,
    "@media (max-width: 768px)": {
      height: 60,
      paddingHorizontal: 20,
    },
  },
  navbarFlexRow: {
    height: "100%",
    flexDirection: "row",
    gap: 20,
    justifyContent: "flex-end",
    alignItems: "center",
  },

  imageContainer: {
    flex: 1,
    flexDirection: "column",
  },
  imageBackground: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    rowGap: 30,
    margin: 20,
    marginVertical: 60,
    "@media (max-width: 480px)": {
      margin: "0",
    },
  },
  image: {
    resizeMode: "contain",
    width: 300,
    height: 150,
    marginBottom: 20,
    "@media (max-width: 480px)": {
      width: 220,
      height: 110,
    },
  },
  form: {
    width: "100%",
    margin: "auto",
    backgroundColor: whiteColor,
    width: "35%",
    borderWidth: 1,
    borderColor: inputTextColor,
    borderRadius: 20,
    padding: 25,
    "@media (max-width: 1200px)": {
      width: "50%",
    },
    "@media (max-width: 768px)": {
      borderWidth: 0,
      marginLeft: 20,
      marginRight: 20,
      borderRadius: 16,
      width: "85%",
      padding: 20,
    },
    "@media (max-width: 480px)": {
      borderWidth: 0,
      marginLeft: 20,
      marginRight: 20,
      // backgroundColor: "transparent",
      borderRadius: 16,
      width: "85%",
      padding: 16,
    },
  },
  formContainer: {
    borderBottomStartRadius: 40,
    borderBottomEndRadius: 40,
    "@media (max-width: 480px) ": {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 30,
    },
  },
  header: {
    fontSize: 26,
    color: blackColor,
    fontWeight: "bold",
    textAlign: "left",
  },
  details: {
    marginTop: 10,
    fontWeight: 500,
    fontSize: 14,
    color: subTextColor,
  },
  inputView: {
    marginTop: 25,
  },
  text: {
    marginBottom: 4,
    fontWeight: 600,
  },
  phoneContainer: {
    flexDirection: "row",
    columnGap: 5,
  },
  pin: {
    flex: 2,
    "@media (max-width: 768px)": {
      flex: 4,
    },
  },
  phoneField: {
    flex: 10,
    "@media (max-width: 576px)": {
      flex: 12,
    },
  },
  textInput: {
    padding: 0,
    fontSize: 13,
    height: 35,
    backgroundColor: inputBackColor,
  },
  inputError: {
    color: errorTextColor,
    fontWeight: 600,
    padding: 10,
    paddingBottom: 0,
  },

  picker: {
    borderColor: "#D3D3D3",
    borderWidth: 1,
    borderRadius: 20,
    height: 38,
    fontSize: 16,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: inputBackColor,
  },
  bottomContainer: {
    color: "#446FEA",
  },
  bottomText: {
    color: blackColor,
  },
  linkContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bottomLinks: {
    color: blackColor,
    textDecorationLine: "underline",
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginTop: 40,
    borderColor: primaryColor,
    backgroundColor: whiteColor,
    width: "100%",
    borderWidth: 1,
    backgroundColor: primaryColor,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
  },
});
