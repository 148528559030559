import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const TRIP_ENDPOINT = {
  GET_ANNOUNCEMNET: BASE_URL + "announcement/",
};

export const GetAnnouncementApi = async (id) => {
  return axios.get(TRIP_ENDPOINT.GET_ANNOUNCEMNET + id + "/");
};

export const CreateAnnouncementApi = async (id, data) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(TRIP_ENDPOINT.GET_ANNOUNCEMNET, data, headers);
  }
};

export const DeleteAnnouncementApi = async (id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.delete(TRIP_ENDPOINT.GET_ANNOUNCEMNET + id + "/", headers);
  }
};

export const EditAnnouncementApi = async (id, data) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.patch(
      TRIP_ENDPOINT.GET_ANNOUNCEMNET + id + "/",
      data,
      headers
    );
  }
};
