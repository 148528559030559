import React, { useCallback } from "react";
import { TextInput } from "react-native-paper";
import { GetFilterTripAPI } from "../../../service/api/trip/api";
import { StyleSheet } from "react-native";
import {
  blackColor,
  inputTextColor,
  subTextColor,
  whiteColor,
} from "../../../constant/constantCss";

const SearchTrip = ({ setTripDetails, filterValue }) => {
  const handleSearchTrip = useCallback(
    (searchVal) => {
      GetFilterTripAPI(filterValue, searchVal)
        .then((res) => {
          setTripDetails(res.data?.data || []);
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.detail);
        });
    },
    [filterValue]
  );
  const styles = StyleSheet.create({
    textInput: {
      fontSize: 16,
      height: 38,
      borderRadius: 20,
      color: blackColor,
      backgroundColor: whiteColor,
      flexGrow: 1,
      maxHeight: 38,
      maxWidth: 350,
    },
  });
  return (
    <TextInput
      style={styles.textInput}
      mode="outlined"
      outlineColor={blackColor}
      activeOutlineColor={inputTextColor}
      placeholder="Search Your Interest"
      onChangeText={(text) => handleSearchTrip(text)}
    />
  );
};

export default SearchTrip;
