import React, { useState } from "react";
import { Button, Checkbox, TextInput } from "react-native-paper";
import { ids, styles } from "./CreateNotifyModelCss";
import {
  View,
  Text,
  Modal,
  Dimensions,
  ScrollView,
  Platform,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import {
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../constant/constantCss";
import DatePicker from "../../../components/DatePicker/DatePicker";
import SelectUsersModal from "./SelectUsersModal";

const CreateNotificationModel = ({
  visible,
  handleClose,
  setNotificationList,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      users: [],
    },
  });
  const screenWidth = Dimensions.get("window").width;
  const roundness = screenWidth > 768 ? 20 : 8;
  const [isChecked, setIsChecked] = useState(false);
  const [isVisibleUserModal, setIsVisibleUserModal] = useState(false);
  const [scheduledDateTime, setScheduledDateTime] = useState("");

  const handleChecked = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    unregister("scheduled_at");
  };
  const onSubmitData = (data) => {
    setIsVisibleUserModal(true);
  };
  return (
    <>
      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={handleClose}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
            {/* Header */}
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>Add Notification</Text>
              <Text style={styles.closeButton} onPress={handleClose}>
                X
              </Text>
            </View>
            {/* Content */}
            <ScrollView style={{ width: "100%" }}>
              <View
                style={styles.bodyContainer}
                dataSet={{ media: ids.bodyContainer }}
              >
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Type*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        dataSet={{ media: ids.textInput }}
                        onChangeText={onChange}
                        value={value}
                        maxLength={40}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter type eq. general, trip etc."
                      />
                    )}
                    name="type"
                  />
                  {errors.type && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Message*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textAreaInput}
                        dataSet={{ media: ids.textAreaInput }}
                        multiline={true}
                        onChangeText={onChange}
                        value={value}
                        maxLength={250}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter message"
                      />
                    )}
                    name="message"
                  />
                  {errors.message && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>
                <View style={styles.inputView}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox.Android
                          color={primaryColor}
                          status={isChecked ? "checked" : "unchecked"}
                          onPress={handleChecked}
                        />
                        <Text onPress={handleChecked}>Schedule now</Text>
                      </View>
                    )}
                    name="is_schedule_now"
                  />
                </View>
                {!isChecked && (
                  <View style={styles.inputView}>
                    <Text style={styles.inputText}>Scheduled Date-Time*</Text>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value } }) =>
                        Platform.OS === "web" ? (
                          <input
                            type="datetime-local"
                            style={styles.textInput}
                            onChange={onChange}
                            value={value}
                          />
                        ) : (
                          <DatePicker
                            inputString="dd-mm-yyyy"
                            mode="datetime"
                            dateTime={scheduledDateTime}
                            setDateTime={(date) => {
                              setScheduledDateTime(date);
                              setValue("scheduled_at", date);
                            }}
                          />
                        )
                      }
                      name="scheduled_at"
                    />
                    {errors.scheduled_at && (
                      <Text
                        style={styles.inputError}
                        dataSet={{ media: ids.inputError }}
                      >
                        This is required.
                      </Text>
                    )}
                  </View>
                )}

                <View style={styles.inputView}>
                  <Button
                    buttonColor={primaryColor}
                    textColor={whiteColor}
                    onPress={handleSubmit(onSubmitData)}
                  >
                    Next
                  </Button>
                </View>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
      {isVisibleUserModal && (
        <SelectUsersModal
          visible={isVisibleUserModal}
          handleClose={() => setIsVisibleUserModal(false)}
          closeNotificationModel={handleClose}
          handleSubmit={handleSubmit}
          setValue={setValue}
          setNotificationList={setNotificationList}
        />
      )}
    </>
  );
};

export default CreateNotificationModel;
