import { useRef, useState } from "react";
import { Modal, Text, View, StyleSheet } from "react-native";
import { IconButton, Button } from "react-native-paper";
import {
  styles,
  ids,
} from "../TripDashboard/CreateTripModal/CreateTripModalCss";
import {
  blackColor,
  whiteColor,
  primaryColor,
} from "../../constant/constantCss";
import { UploadBannerImage } from "../../service/api/trip/api";
import { showToast } from "../../constant/toastMessage";
import { useSelector, useDispatch } from "react-redux";
import { setBannerImageRefreshState } from "../../store/slices/generalSlice";

const AddBannerImageModal = ({ visible, handleClose }) => {
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState();
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loader, setloader] = useState(false);
  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const dispatch = useDispatch();
  const generalState = useSelector((state) => state.general);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      const fileReader = new FileReader();
      const image = new Image();
      image.onload = () => {
        const { width, height } = image;
        const aspect_ratio = width / height;
        if (width > 2800 || height > 800 || width < 1300 || height < 400 || aspect_ratio < 3.2 || aspect_ratio > 3.5) {
          setError(`Image dimensions should be between 1300x400 and 2800x800 and aspect ratio between 3.2 and 3.5.${"\n"}Uploaded image dimensions: ${width}x${height}, Aspect Ratio: ${aspect_ratio}`);
          setFile(null);
        } else {
          setError("");
          fileReader.onload = (event) => {
            setImageUrl(event.target.result);
          };
          fileReader.readAsDataURL(e.target.files[0]);
        }
      };
      image.src = URL.createObjectURL(e.target.files[0]);
    }
  };
  const addBannerStyles = StyleSheet.create({
    uploadInput: {
      display: "none",
    },
    uploadButton: {
      maxWidth: 350,
      color: whiteColor,
    },
    buttonStyle: {
      width: "auto",
      padding: 2,
    },
  });

  const clearAllStates = () => {
    setFile();
    setImageUrl();
  }

  const handleSave = () => {
    const formData = new FormData();
    formData.append("banner_image_url", file);
    setloader(true);
    UploadBannerImage(formData)
      .then(() => {
        handleClose(); setloader(false);
        showToast("success", "Uploaded Banner Image Successfully!");
        dispatch(setBannerImageRefreshState(!generalState.bannerImagesRefreshState));
      })
      .catch((err) => {
        setloader(false);
        showToast("error", err?.response?.data?.detail);
      });
  };
  return (
    <>
      <Modal
        style={styles.modalView}
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={handleClose}
      >
        <View style={styles.centeredView}>
          <View
            style={[styles.modalView, { height: "55%" }]}
            dataSet={{ media: ids.modalView }}
          >
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>Add Banner Image</Text>
              <IconButton
                icon={"close"}
                iconColor={blackColor}
                onPress={() => { handleClose(); clearAllStates(); }}
              />
            </View>
            <View
              style={[
                styles.bodyContainer,
                {
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flex: 0,
                  marginTop: 30,
                  gap: 20,
                },
              ]}
              dataSet={{ media: ids.bodyContainer }}
            >
              <Text style={{ fontWeight: "bold", fontSize: 14 }}>
                Upload Banner Image
              </Text>
              <input
                type="file"
                accept={".jpg,.png,.webp,.jpeg"}
                ref={fileInputRef}
                style={addBannerStyles.uploadInput}
                onChange={(e) => handleFileChange(e)}
              />
              <IconButton
                icon="file-upload"
                iconColor={primaryColor}
                mode={"contained"}
                style={[addBannerStyles.uploadButton, { margin: 0 }]}
                onPress={handleUploadButtonClick}
              ></IconButton>
              {file && file.name && <Text>{file.name}</Text>}
            </View>
            {error && <View
              style={[
                styles.bodyContainer,
                {
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flex: 0,
                  marginTop: 40,
                  gap: 20,
                },
              ]}
              dataSet={{ media: ids.bodyContainer }}
            >
              <Text style={{ color: "red", fontWeight: "bold" }}>{error}</Text>
            </View>}
            <View
              style={[
                styles.bodyContainer,
                { justifyContent: "flex-start", marginTop: 40, height: "60%" },
              ]}
            >
              {(imageUrl && (!error || error.length === 0)) && (
                <>
                  <img
                    src={imageUrl}
                    style={{ height: "100%", width: "100%" }}
                  />
                </>
              )}
            </View>
            {(file && file.name && !error) && (
              <View
                style={[
                  styles.bodyContainer,
                  { justifyContent: "center", height: "auto", marginTop: 10 },
                ]}
              >
                <Button
                  icon={"cloud-upload"}
                  buttonColor={primaryColor}
                  textColor={whiteColor}
                  onPress={() => {
                    handleSave();
                  }}
                  style={addBannerStyles.buttonStyle}
                  loading={loader}
                >
                  Add Image
                </Button>
              </View>
            )}
          </View>
        </View>
      </Modal>
    </>
  );
};

export default AddBannerImageModal;
