import StyleSheet from "react-native-media-query";
import { whiteColor } from "../../../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    width: "60%",
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 480px)": {
      width: "80%",
    },
  },
  headerContainer: {
    flexDirection: "row-reverse",
  },
  flexButton: {
    flexDirection: "row",
    justifyContent: "right",
    columnGap: 20,
    marginTop: 40,
  },
});
