import React, { useState } from "react";
import { View, Text, Modal, ScrollView, Dimensions } from "react-native";
import { Button, TextInput } from "react-native-paper";
import { ids, styles } from "../Permission Modal/PermissionCss";
import EditPermissionModal from "./EditPermissionModal";
import { showToast } from "../../../constant/toastMessage";
import { primaryColor } from "../../../constant/constantCss";

const EditRoleModal = ({ visible, handleClose, roleDetails }) => {
  const screenWidth = Dimensions.get("window").width;
  const [isVisibleEditPermission, setisVisibleEditPermission] = useState(false);
  const roundness = screenWidth > 768 ? 20 : 8;
  const [formData, setFormData] = useState({
    id: roleDetails?.id,
    name: roleDetails?.name,
    permission: roleDetails?.permissions,
  });
  const handleSubmit = () => {
    if (formData.name !== "") {
      setisVisibleEditPermission(true);
    } else {
      showToast("error", "Role name is required field!");
    }
  };
  return (
    <React.Fragment>
      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={handleClose}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
            {/* Header */}
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>
                <Button icon="arrow-left" onPress={handleClose}></Button>
                Edit Role
              </Text>
              <Text style={styles.closeButton} onPress={handleClose}>
                X
              </Text>
            </View>
            {/* content */}
            <ScrollView style={{ width: "100%" }}>
              <View
                style={styles.bodyContainer}
                dataSet={{ media: ids.bodyContainer }}
              >
                <View style={styles.inputView}>
                  <Text style={styles.inputText}> Role Name*</Text>
                  <TextInput
                    style={styles.textInput}
                    defaultValue={formData.name}
                    dataSet={{ media: ids.textInput }}
                    onChangeText={(text) => {
                      setFormData({ ...formData, name: text });
                    }}
                    theme={{
                      roundness: roundness,
                    }}
                    mode="outlined"
                    outlineColor="#D3D3D3"
                    activeOutlineColor="#D3D3D3"
                    placeholder="Enter role name"
                  />
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Role Description</Text>
                  <TextInput
                    style={styles.textInput}
                    defaultValue={formData.description}
                    dataSet={{ media: ids.textInput }}
                    onChangeText={(text) => {
                      setFormData({ ...formData, description: text });
                    }}
                    theme={{
                      roundness: roundness,
                    }}
                    mode="outlined"
                    outlineColor="#D3D3D3"
                    activeOutlineColor="#D3D3D3"
                    placeholder="Enter description of role"
                  />
                  <View style={styles.inputView}></View>
                  <Button
                    mode="contained"
                    buttonColor={primaryColor}
                    style={{ maxWidth: 250 }}
                    onPress={handleSubmit}
                  >
                    Proceed
                  </Button>
                </View>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
      {isVisibleEditPermission && (
        <EditPermissionModal
          visible={isVisibleEditPermission}
          handleClose={() => setisVisibleEditPermission(false)}
          closeEditRoleModal={handleClose}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </React.Fragment>
  );
};

export default EditRoleModal;
