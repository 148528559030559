import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const TRIP_ENDPOINT = {
  GET_SCHOOL_ADMIN: BASE_URL + "auth/user/all/school-admin",
};

export const GetAllSchoolAdmin = async (id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.get(TRIP_ENDPOINT.GET_SCHOOL_ADMIN, headers);
  }
};
