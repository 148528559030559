import React, { useState, useRef, useEffect } from "react";
import { Button, IconButton, TextInput } from "react-native-paper";
import { ids, styles } from "./AddSchoolModalCss";
import {
  View,
  Text,
  Modal,
  Dimensions,
  ScrollView,
  Platform,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { CreateSchool, GetAllSchool } from "../../../service/api/school/api";
import { inputBackColor, inputTextColor, primaryColor, subTextColor } from "../../../constant/constantCss";
import { showToast } from "../../../constant/toastMessage";
import { Picker } from "@react-native-picker/picker";
import { GetAllSchoolAdmin } from "../../../service/api/trip/getSchoolAdminApi";
import * as ImagePicker from "expo-image-picker";
import moment from "moment";
import { Dropdown } from "react-native-element-dropdown";

const AddSchoolModal = ({ visible, handleClose, setschoolList }) => {
  const screenWidth = Dimensions.get("window").width;
  const roundness = screenWidth > 768 ? 20 : 8;
  const [loader, setloader] = useState(false);
  const [schoolAdmin, setSchoolAdmin] = useState([]);
  const fileInputRef = useRef(null);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({});

  const onSubmitData = (data) => {
    setloader(true);
    //convet to formData
    const formData = new FormData();
    // Append data to the formData object
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) {
        formData.append(key, data[key]);
      }
    });
    // call API for creating school
    CreateSchool(formData)
      .then((res) => {
        setloader(false);
        showToast("success", "School Created!");
        // call the API for fetching school after creating new school
        GetAllSchool().then((res) => {
          setschoolList(res.data);
        });
        handleClose();
      })
      .catch((err) => {
        setloader(false);
        showToast("error", err?.response?.data?.detail);
      });
  };
  const handleUploadButtonClick = () => {
    // Trigger a click event on the file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const pickImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      console.error("Permission to access photo library denied");
      return;
    }
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result?.canceled) {
        // random name generate
        const currentDate = moment();
        const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
        const imageName = `organization_image_${formattedDate}.jpeg`;

        const fileObj = {
          uri: result?.assets?.[0]?.uri,
          type: "image/jpeg",
          name: imageName,
        };
        setValue("image", fileObj);
      }
    } catch (error) {
      console.error("Error picking an image:", error);
    }
  };
  useEffect(() => {
    GetAllSchoolAdmin()
      .then((res) => {
        setSchoolAdmin(res.data);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
      });
  }, []);
  return (
    <React.Fragment>
      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={handleClose}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
            {/* Header */}
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>
                <Button icon="arrow-left" onPress={handleClose}></Button>
                Add Organization Details
              </Text>
              <Text style={styles.closeButton} onPress={handleClose}>
                X
              </Text>
            </View>
            {/* Content */}
            <ScrollView style={{ width: "100%" }}>
              <View
                style={styles.bodyContainer}
                dataSet={{ media: ids.bodyContainer }}
              >
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Organization Logo</Text>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <React.Fragment>
                        {Platform.OS === "web" ? (
                          <React.Fragment>
                            <input
                              type="file"
                              accept={".jpg,.png,.webp,.jpeg"}
                              ref={fileInputRef}
                              style={styles.uploadInput}
                              onChange={(e) =>
                                setValue("image", e.target.files[0])
                              }
                            />
                            <IconButton
                              icon="file-upload"
                              iconColor={primaryColor}
                              mode={"contained"}
                              style={styles.uploadButton}
                              onPress={handleUploadButtonClick}
                            ></IconButton>
                            <Text>{getValues("image")?.name}</Text>
                          </React.Fragment>
                        ) : (
                          <IconButton
                            icon="file-upload"
                            iconColor={primaryColor}
                            mode={"contained"}
                            style={styles.uploadButton}
                            onPress={pickImage}
                          ></IconButton>
                        )}
                      </React.Fragment>
                    )}
                    name="image"
                  />
                </View>
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Organization Name*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        dataSet={{ media: ids.textInput }}
                        onChangeText={onChange}
                        maxLength={50}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter name of organization"
                      />
                    )}
                    name="name"
                  />
                  {errors.name && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Address*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        dataSet={{ media: ids.textInput }}
                        onChangeText={onChange}
                        value={value}
                        maxLength={50}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter address"
                      />
                    )}
                    name="address"
                  />
                  {errors.address && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Area*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        dataSet={{ media: ids.textInput }}
                        onChangeText={onChange}
                        maxLength={50}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter area"
                      />
                    )}
                    name="area"
                  />
                  {errors.area && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>City*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        dataSet={{ media: ids.textInput }}
                        onChangeText={onChange}
                        maxLength={50}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter city"
                      />
                    )}
                    name="city"
                  />
                  {errors.city && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>

                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Phone Number*</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        style={styles.textInput}
                        keyboardType="numeric"
                        dataSet={{ media: ids.textInput }}
                        onChangeText={onChange}
                        maxLength={14}
                        value={value}
                        theme={{
                          roundness: roundness,
                        }}
                        mode="outlined"
                        outlineColor={inputTextColor}
                        activeOutlineColor={inputTextColor}
                        placeholder="Enter phone number"
                      />
                    )}
                    name="phone_number"
                  />
                  {errors.phone_number && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>
                <View style={styles.inputView}>
                  <Text style={styles.inputText}>Organization Admin*</Text>
                  {Platform.OS === "ios" ? (
                    <Dropdown
                      data={schoolAdmin}
                      labelField="first_name"
                      valueField="id"
                      search
                      mode={"auto"}
                      style={{
                        minWidth: 150,
                        paddingHorizontal: 10,
                        borderWidth: 1,
                        borderColor: inputTextColor,
                        borderRadius: 10,
                        backgroundColor: inputBackColor,
                      }}
                      placeholderStyle={{ color: subTextColor }}
                      maxHeight={300}
                      inputSearchStyle={{
                        minWidth: 100,
                        maxHeight: 30,
                      }}
                      placeholder="Select a Organization Coordinator"
                      searchPlaceholder="Search..."
                      onChange={(item) => {
                        setValue("setSchoolAdmin", item.id);
                      }}
                    /> ) : (
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Picker
                        selectedValue={field.value}
                        onValueChange={field.onChange}
                        style={[
                          styles.textInput,
                          { borderWidth: 1, borderColor: inputTextColor },
                        ]}
                      >
                        <Picker.Item
                          label="Select a Organization Coordinator"
                          value=""
                        />
                        {schoolAdmin?.map((item, index) => (
                          <Picker.Item
                            key={index}
                            label={item.first_name}
                            value={item.id}
                          />
                        ))}
                      </Picker>
                    )}
                    name="school_admin"
                  />)}
                  {errors.school_admin && (
                    <Text
                      style={styles.inputError}
                      dataSet={{ media: ids.inputError }}
                    >
                      This is required.
                    </Text>
                  )}
                </View>
                <View style={styles.inputView}>
                  <Button
                    loading={loader}
                    mode="contained"
                    buttonColor={primaryColor}
                    onPress={handleSubmit(onSubmitData)}
                  >
                    Save
                  </Button>
                </View>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    </React.Fragment>
  );
};

export default AddSchoolModal;
