import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const PERMISSION_ENDPOINT = {
  GET_ALL_PERMISSION: BASE_URL + "permission/",
};

export const GetAllPermission = async () => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.get(PERMISSION_ENDPOINT.GET_ALL_PERMISSION, headers);
  }
};
