import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const TRIP_ENDPOINT = {
  GET_TRIP_COORDINATOR: BASE_URL + "auth/user/all/trip-coordinator",
};

export const GetTripCoordinator = async (id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.get(TRIP_ENDPOINT.GET_TRIP_COORDINATOR, headers);
  }
};
