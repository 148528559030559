import React, { useState } from "react";
import { ScrollView, View, Text } from "react-native";
import { Button, Chip, DataTable } from "react-native-paper";
import UserDetailModal from "../../../All Users/User Modal/UserDetailModal";
import { ids, styles } from "../ProfileCss";
import { primaryColor, sidebarColor } from "../../../../constant/constantCss";

const UserListTable = ({ userList }) => {
  const [isVisibleUserModal, setIsVisibleUserModal] = useState(false);
  const [number, setNumber] = useState(0);
  return (
    <ScrollView horizontal={true} style={{ width: "100%" }}>
      {userList?.length > 0 ? (
        <View style={styles.tableStyle} dataSet={{ media: ids.tableStyle }}>
          <DataTable collapsable={true}>
            <DataTable.Header>
              <DataTable.Title>Name</DataTable.Title>
              <DataTable.Title>Mobile number</DataTable.Title>
              <DataTable.Title>Role</DataTable.Title>
              <DataTable.Title>Action</DataTable.Title>
            </DataTable.Header>
          </DataTable>
          {userList?.map((userData, index) => (
            <DataTable.Row key={index}>
              <DataTable.Cell>{userData.first_name}</DataTable.Cell>
              <DataTable.Cell>
                {`+${userData.dialing_code} ${userData.phone_number}`}
              </DataTable.Cell>
              <DataTable.Cell>
                <Chip style={{ backgroundColor: sidebarColor }}>
                  {userData.groups?.group}
                </Chip>
              </DataTable.Cell>
              <DataTable.Cell>
                <Button
                  mode="text"
                  textColor={primaryColor}
                  style={styles.buttonStyle}
                  onPress={() => {
                    setIsVisibleUserModal(true);
                    setNumber(userData.id);
                  }}
                >
                  {userData.is_active ? "Verified" : "Verify"}
                </Button>
              </DataTable.Cell>
              {userData.id === number && isVisibleUserModal && (
                <UserDetailModal
                  visible={isVisibleUserModal}
                  handleClose={() => setIsVisibleUserModal(false)}
                  userData={userData}
                  isActivateButtonVisible={false}
                />
              )}
            </DataTable.Row>
          ))}
        </View>
      ) : (
        <Text style={{ fontSize: 20 }}>No User Found!</Text>
      )}
    </ScrollView>
  );
};

export default UserListTable;
