import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const PAYMENT_ENDPOINT = {
  GET_USER_PAYMENT_HISTORY: BASE_URL + "payment_history/user/",
  CREATE_CASH_PAYMENT: BASE_URL + "payment_history/cash/",
};

export const GetUserPaymentHistory = async ({
  page,
  search,
  status,
  price,
}) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    let url = PAYMENT_ENDPOINT.GET_USER_PAYMENT_HISTORY;
    if (page) {
      url += `?page= ${page}`;
    }
    if (search) {
      url += `?search=${search}`;
    }
    if (status) {
      url += `?status=${status}`;
    }
    if (price) {
      url += `?price=${price}`;
    }
    return axios.get(url, headers);
  }
};

export const CreateCashPayment = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(PAYMENT_ENDPOINT.CREATE_CASH_PAYMENT, payload, headers);
  }
};
