import AsyncStorage from "@react-native-async-storage/async-storage";

export const PERMISSION = {
  // role
  CAN_CREATE_ROLE: "can_create_role",
  CAN_EDIT_ROLE: "can_edit_role",
  CAN_DELETE_ROLE: "can_delete_role",
  CAN_SHOW_ROLE_MANAGEMENT_TAB: "can_show_role_management_tab",
  // school/organization
  CAN_CREATE_ORGANIZATION: "can_create_organization",
  CAN_SHOW_ORGANIZATION_DETAILS_TAB: "can_show_organization_details_tab",
  CAN_CREATE_STUDENT_LIST: "can_create_student_list",
  // trip
  CAN_CREATE_TRIP: "can_create_trip",
  CAN_EDIT_TRIP: "can_edit_trip",
  CAN_DELETE_TRIP: "can_delete_trip",
  CAN_SHOW_ALL_TRIP: "can_show_all_trip",
  CAN_SHOW_PRIVATE_TRIP: "can_show_private_trip",
  CAN_SHOW_PUBLIC_TRIP: "can_show_public_trip",
  // user
  CAN_SHOW_ALL_USERS_TAB: "can_show_all_users_tab",
  // notification
  CAN_CREATE_NOTIFICATION: "can_create_notification",
  CAN_DELETE_NOTIFICATION: "can_delete_notification",
  // info
  CAN_CHECK_STUDENT_LIST: "can_check_student_list",
  // iternaries
  CAN_EDIT_ITERNARIES: "can_edit_iternaries",
  // announcements / updates
  CAN_CREATE_ANNOUNCEMENT: "can_create_announcements",
  CAN_EDIT_ANNOUNCEMENT: "can_edit_announcements",
  CAN_DELETE_ANNOUNCEMENT: "can_delete_announcements",
  // albums
  CAN_UPLOAD_ALBUMS: "can_upload_albums",
  CAN_DELETE_ALBUMS: "can_delete_albums",
  // payment
  CAN_ADD_CASH_PAYMENT: "can_add_cash_payment",
  // analytics
  CAN_SHOW_ANALYTICS_OVERVIEW_TAB: "can_show_analysis_overview_tab",
  CAN_SHOW_ANALYTICS_TRIP_TAB: "can_show_analysis_trip_tab",
  CAN_SHOW_COMPANY_REVIEW_TAB: "can_show_company_review_tab",
  CAN_MAKE_FEATURE_TRIP: "can_make_feature_trip",
};

export const get_user_permission = async () => {
  try {
    const value = await AsyncStorage.getItem("permission");
    if (value != null) {
      return value;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
