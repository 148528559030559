import StyleSheet from "react-native-media-query";
import { whiteColor } from "../../constant/constantCss";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: whiteColor,
  },
  subContainer: {
    padding: 24,
    ...Platform.select({
      android: {
        marginBottom: 150,
      },
    }),
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  headingText: {
    marginTop: 10,
    fontSize: 22,
    fontWeight: "800",
    marginBottom: 20,
  },
  buttonStyle: {
    color: "green",
  },
});
