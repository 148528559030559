import React, { useCallback, useState } from "react";
import { View, Text } from "react-native";
import { Switch } from "react-native-paper";
import { primaryColor } from "../../../constant/constantCss";
import { GetAllserAPI } from "../../../service/api/user/getUserApi";
import { showToast } from "../../../constant/toastMessage";

const FilterUser = ({ setUsers }) => {
  const [isChecked, setIsChecked] = useState(false);
  const onChangeSwitch = useCallback(
    (val) => {
      setIsChecked(val);
      GetAllserAPI({ isVerified: val == false ? "True" : "False" })
        .then((res) => {
          setUsers(res?.data?.results);
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.detail);
        });
    },
    [isChecked]
  );
  return (
    <View
      style={{ flex: 1, flexDirection: "row", gap: 10, alignItems: "center" }}
    >
      <Text style={{ fontSize: 16 }}>Filter by Pending Verification :</Text>
      <Switch
        color={primaryColor}
        value={isChecked}
        onValueChange={(val) => onChangeSwitch(val)}
      />
    </View>
  );
};

export default FilterUser;
