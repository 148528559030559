import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const AUTH_ENDPOINT = {
  CHANGE_PASSWORD: BASE_URL + "auth/chnge-password/",
  FORGOT_PASSWORD: BASE_URL + "auth/forgot-password/",
  RESET_PASSWORD: BASE_URL + "auth/reset-password/",
  DELETE_ACCOUNT: BASE_URL + "auth/delete-account/",
};

export const UserChangePassword = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.patch(AUTH_ENDPOINT.CHANGE_PASSWORD, payload, headers);
  }
};

export const UserForgotPassword = async (payload) => {
  return axios.post(AUTH_ENDPOINT.FORGOT_PASSWORD, payload);
};

export const UserResetPassword = async (payload) => {
  return axios.patch(AUTH_ENDPOINT.RESET_PASSWORD, payload);
};

export const DeleteUserAccount = async (payload) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.post(AUTH_ENDPOINT.DELETE_ACCOUNT, payload, headers);
  }
};
