import StyleSheet from "react-native-media-query";
import { inputTextColor, whiteColor } from "../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  imageContainer: {
    flex: 1,
    flexDirection: "column",
  },
  imageBackground: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  gridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    rowGap: 30,
  },
  image: {
    resizeMode: "contain",
    width: 180,
    height: 100,
  },
  form: {
    borderWidth: 1,
    borderColor: inputTextColor,
    margin: "auto",
    padding: 25,
    borderRadius: 40,
    backgroundColor: whiteColor,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    marginBottom: 20,
  },
});
