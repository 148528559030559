import React, { useRef, useState } from "react";
import { ids, styles } from "../modal.js";
import { View, Text, Modal, ScrollView, Linking, Platform } from "react-native";
import * as DocumentPicker from "expo-document-picker";
import {
  ErrorColor,
  blackColor,
  inputTextColor,
  primaryColor,
  whiteColor,
} from "../../../constant/constantCss.js";
import {
  Button,
  Divider,
  IconButton,
  Surface,
  TextInput,
  TouchableRipple,
} from "react-native-paper";
import { useForm, Controller } from "react-hook-form";
import AntDesign from "react-native-vector-icons/AntDesign";
import moment from "moment";
import { UserCareer } from "../../../service/api/user/career.js";
import { showToast } from "../../../constant/toastMessage.js";

const CareerModal = ({ visible, handleClose }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loader, setLoader] = useState(false);
  const fileInputRef = useRef(null);
  const pickImage = async () => {
    if (Platform.OS === "web") {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      const allowedFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      try {
        let result = await DocumentPicker.getDocumentAsync({
          type: allowedFileTypes,
        });
        if (!result?.canceled) {
          // random name generate
          const currentDate = moment();
          const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
          const imageName = `resume_${formattedDate}.pdf`;

          const fileObj = {
            uri: result?.assets?.[0]?.uri,
            type: result?.assets?.[0]?.mimeType,
            name: imageName,
          };
          setValue("resume_url", fileObj);
        }
      } catch (error) {
        console.error("Error picking an image:", error);
      }
    }
  };
  const submitCareer = (data) => {
    setLoader(true);
    // create form data
    const formData = new FormData();
    // Append data to the formData object
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    UserCareer(formData)
      .then((res) => {
        showToast("success", "Your Information successfully sent to Admin");
        showToast("success", "Will get back you soon via mail/phone_number");
        setLoader(false);
        handleClose();
      })
      .catch((err) => {
        setErrorMessage(
          err?.response?.data?.phone_number ??
            err?.response?.data?.email ??
            err?.response?.data?.resume_url ??
            err?.response?.data?.detail
        );
        setLoader(false);
      });
  };
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({});
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <Surface
          elevation={3}
          style={styles.modalView}
          dataSet={{ media: ids.modalView }}
        >
          <View style={styles.headerContainer}>
            <View style={{ flex: 1 }}>
              <Text style={styles.headerText}>Join Us</Text>
              <Text>Please fill the below details</Text>
            </View>
            <IconButton
              iconColor={blackColor}
              icon={"close"}
              onPress={handleClose}
            />
          </View>
          {/* content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Name</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Name field is required",
                    pattern: {
                      value: /^[a-zA-Z ]{1,}$/,
                      message: "Name should not contains space",
                    },
                    maxLength: 30,
                  }}
                  name="name"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      placeholder="Enter your name"
                      onChangeText={onChange}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                    />
                  )}
                />
                {errors.name && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.name.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Email</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Email field is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email address",
                    },
                    maxLength: 50,
                  }}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      placeholder="Enter your email address"
                      onChangeText={onChange}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                    />
                  )}
                />
                {errors.email && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.email.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Phone Number</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Phone No. is required",
                    pattern: {
                      value: /^[0-9]{7,14}$/,
                      message: "Please enter valid phone number",
                    },
                  }}
                  name="phone_number"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      placeholder="Enter your phone number"
                      onChangeText={onChange}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                    />
                  )}
                />
                {errors.phone_number && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.phone_number.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>What Are You Looking For</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Role field is required",
                    maxLength: 150,
                  }}
                  name="looking_for"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      numberOfLines={3}
                      multiline={true}
                      placeholder="Describe here what are you looking for "
                      onChangeText={onChange}
                      style={{ backgroundColor: whiteColor }}
                      dataSet={{ media: ids.textInput }}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      contentStyle={{ color: blackColor }}
                    />
                  )}
                />
                {errors.looking_for && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.looking_for.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Upload Resume(Pdf only)</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "Please upload your resume.",
                  }}
                  name="resume_url"
                  render={({ field: { onChange, value } }) => (
                    <View>
                      {Platform.OS === "web" ? (
                        <>
                          <input
                            type="file"
                            accept={".pdf,.docx,.doc"}
                            ref={fileInputRef}
                            style={styles.uploadInput}
                            onChange={(e) =>
                              setValue("resume_url", e.target.files[0])
                            }
                          />
                          <TouchableRipple onPress={pickImage}>
                            <View style={styles.uploadContainer}>
                              <AntDesign name="upload" size={20} />
                              <Text style={{ fontSize: 16 }}>
                                Click here or Just drag and Drop
                              </Text>
                            </View>
                          </TouchableRipple>
                        </>
                      ) : (
                        <TouchableRipple onPress={pickImage}>
                          <View style={styles.uploadContainer}>
                            <AntDesign name="upload" size={20} />
                            <Text style={{ fontSize: 16 }}>
                              Click here or Just drag and Drop
                            </Text>
                          </View>
                        </TouchableRipple>
                      )}

                      <Text>{getValues("resume_url")?.name}</Text>
                    </View>
                  )}
                />
                {errors.resume_url && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.resume_url.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Button
                  buttonColor={primaryColor}
                  textColor={whiteColor}
                  loading={loader}
                  onPress={handleSubmit(submitCareer)}
                >
                  Submit
                </Button>
              </View>
              <View style={styles.inputView}>
                <Divider horizontalInset />
              </View>
              <View style={styles.inputView}>
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <Text style={{ fontSize: 18 }}>Email us at</Text>
                  <TouchableRipple
                    onPress={() => {
                      Linking.openURL(
                        "mailto:contact@maroonmigrates.com"
                      );
                    }}
                  >
                    <Text style={styles.link_text}>
                      contact@maroonmigrates.com
                    </Text>
                  </TouchableRipple>
                </View>
              </View>
              {errorMessage && (
                <View style={styles.inputView}>
                  <View
                    style={{
                      backgroundColor: ErrorColor,
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 14,
                    }}
                  >
                    <Text style={{ fontSize: 16, fontWeight: "600" }}>
                      Error : {errorMessage}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </ScrollView>
        </Surface>
      </View>
    </Modal>
  );
};

export default CareerModal;
