import React, { useState, useEffect, useCallback } from "react";
import { styles } from "./PaymentHistoryCss";
import {
  Dimensions,
  ScrollView,
  View,
  Image,
  RefreshControl,
  TouchableOpacity,
  Platform
} from "react-native";
import AppBar from "../Layout/AppBar";
import SearchPayment from "./Search Payment/SearchPayment";
import { Text } from "react-native";
import { Button,IconButton } from "react-native-paper";
import { primaryColor, subTextColor } from "../../constant/constantCss";
import PaymentHistoryTable from "./Payment Table/PaymentHistoryTable";
import { GetUserPaymentHistory } from "../../service/api/payment/api";
import { showToast } from "../../constant/toastMessage";
import noPayment from "../../../assets/icons/no_payment.png";
import PaymentFilterModal from "./Filter Modal/PaymentFilterModal";
import PlaneLoader from "../../components/Loader";
import ContactUs from "../LnadingPage/components/ContactUs";
import MaterialIcons from "react-native-vector-icons/MaterialIcons"

const PaymentHistory = () => {
  const PAGE_SIZE = 10;
  const { height, width } = Dimensions.get("window");
  const [loader, setLoader] = useState(false);
  const [isVisibleFilterModal, setIsVisibleFilterModal] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [page, setPage] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const initalCallingApi = () => {
    setLoader(true);
    GetUserPaymentHistory({ page: page + 1 })
      .then((res) => {
        setLoader(false);
        setPaymentList(res.data?.results);
        setNoOfPage(Math.ceil(res?.data?.count / PAGE_SIZE));
      })
      .catch((err) => {
        setLoader(false);
        showToast("error", err?.response?.data?.detail);
      });
  };
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initalCallingApi();
      setRefreshing(false);
    }, 1000);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    initalCallingApi();
  }, [page]);

  return (
    <View style={[styles.container, { minHeight: height }]}>
      <AppBar />
      <ScrollView
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={primaryColor}
            colors={[primaryColor]}
          />
        }
        style={{marginBottom:
          Platform.OS === "ios" ? 110 : Platform.OS !== "web" && 60,}}
      >
        <View style={styles.subContainer}>
          <View style={styles.flexRow}>
            <Text style={styles.headingText}>Payment History</Text>
          </View>
          {loader ? (
            <PlaneLoader />
          ) : (
            <React.Fragment>
              <View style={styles.flexRow}>
                <SearchPayment setPaymentList={setPaymentList} />
                {Dimensions.get("screen").width >= 480 ? 
            <Button
              mode="outlined"
              icon={"filter"}
              textColor={subTextColor}
              style={{
                borderRadius: 4,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                height: 37,
                paddingVertical: 0,
                marginRight: 9,
              }}
              onPress={() => {
                setIsVisibleFilterModal(true);
              }}
            >
              Filter
            </Button> : 
            <IconButton
              icon="filter"
              textColor={subTextColor}
              mode="outlined"
              style={{
                borderRadius: 4,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                height: 37,
                paddingVertical: 0,
                marginRight: 9,
              }}
              size={20}
              onPress={() => {
                setIsVisibleFilterModal(true);
              }}
            />}
              </View>
              {paymentList?.length > 0 ? (
                <PaymentHistoryTable
                  paymentList={paymentList}
                  page={page}
                  setPage={setPage}
                  noOfPage={noOfPage}
                />
              ) : (
                // payment not found view
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={noPayment}
                    resizeMode={"contain"}
                    style={{ height: 100, width: 100 }}
                  ></Image>
                  <Text style={{ fontSize: 18, fontWeight: 600 }}>
                    Payment Not Found!
                  </Text>
                </View>
              )}
            </React.Fragment>
          )}
        </View>
        {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
      {isVisibleFilterModal && (
        <PaymentFilterModal
          visible={isVisibleFilterModal}
          handleClose={() => setIsVisibleFilterModal(false)}
          setPaymentList={setPaymentList}
        />
      )}
    </View>
  );
};

export default PaymentHistory;
