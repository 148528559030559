import React from "react";
import { Image, Text, View } from "react-native";
import { ids, styles } from "../landingPageCss";
// why choose maroon migrates img
import whyChooseIcon from "../../../../assets/landingPage/why_marron_migrate_section/icon.png";
import purposeFulImg from "../../../../assets/landingPage/why_marron_migrate_section/1.webp";
import tailoredImg from "../../../../assets/landingPage/why_marron_migrate_section/2.webp";
import eduExcellenceImg from "../../../../assets/landingPage/why_marron_migrate_section/3.webp";
import {
  containerBlackColor,
  primaryOrangeColor,
} from "../../../constant/constantCss";

const WhyChooseSection = () => {
  const whyChooseDescription = [
    {
      title1: "Purposeful ",
      title2: "Difference",
      description:
        "We pioneer responsible tourism, ensuring that your journey contributes positively to both the local communities and the environment.",
      img: purposeFulImg,
    },
    {
      title1: "Tailored ",
      title2: "Experiences",
      description:
        "Each trip is meticulously crafted with a purpose, offering you a personalized adventure that aligns with your values and interests.",
      img: tailoredImg,
    },
    {
      title1: "Educational ",
      title2: "Excellence",
      description:
        "Our educational trips are designed not just to broaden your horizons but also to actively contribute to the betterment of the destinations you explore.",
      img: eduExcellenceImg,
    },
  ];
  return (
    <View
      style={styles.why_choose_container}
      dataSet={{ media: ids.explore_col_reverse_flex }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={whyChooseIcon}
          style={styles.icon}
          dataSet={{ media: ids.icon }}
        />
        <Text
          style={[
            styles.preHeaderText,
            {
              textAlign: "center",
            },
          ]}
          dataSet={{ media: ids.preHeaderText }}
        >
          <Text style={{ color: containerBlackColor }}>Why Choose </Text>
          Maroon
          <Text style={{ color: primaryOrangeColor }}> Migrates</Text>
        </Text>
      </View>

      {whyChooseDescription?.map((why_choose_section, index) => (
        <View
          style={styles.explore_col_reverse_flex}
          dataSet={{ media: ids.explore_col_reverse_flex }}
          key={index}
        >
          <Image
            style={styles.explore_img}
            source={why_choose_section?.img}
            dataSet={{ media: ids.explore_img }}
          />
          <View
            style={styles.flexTextContainer}
            dataSet={{ media: ids.flexTextContainer }}
          >
            <View style={styles.subContainer}>
              <View
                style={styles.paddingRightStyle}
                dataSet={{ media: ids.paddingRightStyle }}
              >
                <Text style={styles.explore_headerText}>
                  {why_choose_section?.title1}
                  <Text style={{ color: primaryOrangeColor }}>
                    {why_choose_section?.title2}
                  </Text>
                </Text>
                <Text style={styles.explore_subText}>
                  {why_choose_section?.description}
                </Text>
              </View>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
};

export default WhyChooseSection;
