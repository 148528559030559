import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage"; 
import authSlice from "./slices/authSlice";
import generalSlice from "./slices/generalSlice";
import rolePermissionSlice from "./slices/rolePermissionSlice";

const persistConfig = {
  key: 'root',
  storage: AsyncStorage, 
};

const persistedGeneralReducer = persistReducer(persistConfig, generalSlice);

export const store = configureStore({
  reducer: {
    auth: authSlice,
    general: persistedGeneralReducer,
    rolePermission: rolePermissionSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: true,
});

export const persistor = persistStore(store);

