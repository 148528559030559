import React, { useEffect, useState } from "react";
import { Dimensions, Image, Text, View } from "react-native";
import { Avatar, Button, Chip, Divider, List } from "react-native-paper";
import {
  GetAllUserRating,
  GetUserRating,
  VerifyReview,
} from "../../../../service/api/rating/api";
import { useSelector } from "react-redux";
import { primaryColor, whiteColor } from "../../../../constant/constantCss";
import PlaneLoader from "../../../../components/Loader";
import noReview from "../../../../../assets/icons/no_review.png";
import { ids, styles } from "../../TripInfoCss.js";
import ReviewModal from "./CreateReviewModal.jsx/ReviewModal.jsx";
import Ionicons from "react-native-vector-icons/Ionicons";
import { showToast } from "../../../../constant/toastMessage.js";
import VerifyReviewModal from "../../../../components/LogoutConfirmModal/VerifyReviewModal.jsx";
import { PERMISSION } from "../../../../constant/getAllPermission.js";

const TripRating = ({ tripId }) => {
  const { width } = Dimensions.get("screen");
  const [loader, setLoader] = useState(true);
  const [ratingList, setRatingList] = useState([]);
  const [refreshAPI, setRefreshAPI] = useState(false);
  const [isVisibleAddRatingModal, setIsVisibleAddRatingModal] = useState(false);
  const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState(false);

  const authState = useSelector((state) => state.auth);

  const handleFooterButton = (str, review_id) => {
    if (str == "ok") {
      const payload = { is_public: true };
      VerifyReview(review_id, payload)
        .then((res) => {
          showToast(
            "success",
            "Verification Successful",
            "The rating and review is now accessible to the public"
          );
          setRefreshAPI((prev) => !prev);
          setIsVisibleConfirmModal(false);
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.detail);
        });
    } else {
      setIsVisibleConfirmModal(false);
    }
  };

  useEffect(()=>{
    initialCallingApi();
  }, [refreshAPI])

  const [hasReviewed, setHasReviewed] = useState(false);

  useEffect(() => {
    const temp = ratingList?.filter((data) =>
      parseInt(data.user.id) == parseInt(authState.id)
    )
    if(temp?.length>0){
      setHasReviewed(true);
    }
  }, [ratingList, authState])
  const initialCallingApi = () => {
    GetAllUserRating({ trip_id: tripId }).then((res) => {
      setLoader(false);
      setRatingList(res?.data);
    }).catch(() => setLoader(false));
  }
  useEffect(() => {
    initialCallingApi();
  }, []);

  useEffect(() => {
    GetUserRating({ trip_id: tripId }).then((res) => {
      setLoader(false);
      setRatingList(res?.data);
    }).catch(() => setLoader(false));
  }, [])

  return (
    <View style={{
      flexDirection: "column",
      flexDirection: "column",
      gap: 20,
      backgroundColor: whiteColor,
      padding: 16,
      marginHorizontal: 10,
      marginTop: 4,
      borderRadius: 10,
    }} id="review">
      {/* header */}
      <View>
        <View style={styles.rowContainer}>
          <Text style={styles.headerKeyText}>Rating & Reviews </Text>
          {ratingList?.length > 0 && authState?.isLogin && !hasReviewed &&
            <View style={{ flexDirection: "row", gap: 10 }}>
              <Button
                icon={"plus"}
                buttonColor={primaryColor}
                textColor={whiteColor}
                onPress={() => setIsVisibleAddRatingModal(true)}
              >
                Add Rating
              </Button>
            </View>}
        </View>
        <Divider style={{ maxWidth: 500, padding: 0, marginBottom: 16 }} />
      </View>
      {/* loader */}
      {loader && <PlaneLoader />}
      {/* review and rating */}
      {ratingList?.length > 0 ? (
        ratingList?.map((rating, index) => (
          <View key={index}>
            <List.Item
              style={{ cursor: "default" }}
              title={rating.title}
              description={
                <View style={{ flex: 1, flexDirection: "column", gap: 5 }}>
                  <Text>{rating.message}</Text>
                  {rating.is_public == false && (authState?.permission.includes(PERMISSION.CAN_SHOW_COMPANY_REVIEW_TAB)?(
                    <Chip
                      style={{
                        maxWidth: 70,
                        backgroundColor: primaryColor,
                      }}
                      textStyle={{ color: whiteColor }}
                      onPress={() => setIsVisibleConfirmModal(true)}
                    >
                      Verify
                    </Chip>
                  ):(
                    <Chip
                      style={{
                        backgroundColor: primaryColor,
                      }}
                      textStyle={{ color: whiteColor }}
                    >
                      Sent for verification
                    </Chip>
                  ))}
                </View>
              }
              descriptionNumberOfLines={10}
              left={(props) => (
                <Avatar.Text
                  size={35}
                  style={{ backgroundColor: primaryColor }}
                  label={rating?.user?.first_name?.charAt(0).toUpperCase()}
                />
              )}
              right={
                width > 768
                  ? (props) =>
                    Array.from({ length: 5 }, (_, index) => (
                      <Ionicons
                        color={
                          rating.rating_value > index ? "#ffe234" : "#7e7863"
                        }
                        name={
                          rating.rating_value > index
                            ? "star"
                            : "star-outline"
                        }
                        size={22}
                      />
                    ))
                  : null
              }
            />
            {width <= 768 && (
              <View style={{ flexDirection: "row", marginLeft: 50 }}>
                {Array.from({ length: 5 }, (_, index) => (
                  <Ionicons
                    color={rating.rating_value > index ? "yellow" : "grey"}
                    name={rating.rating_value > index ? "star" : "star-outline"}
                    size={22}
                  />
                ))}
              </View>
            )}
            {isVisibleConfirmModal && (
              <VerifyReviewModal
                visible={isVisibleConfirmModal}
                handleClose={() => setIsVisibleConfirmModal(false)}
                title={"Verify Review"}
                description={
                  "Are you certain you wish to confirm the validity of this rating and review? \n\nNote : Once it is verified, it will become accessible to the general public"
                }
                handleFooterButton={handleFooterButton}
                review_id={rating.id}
              />
            )}
          </View>
        ))
      ) : (
        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image style={{ width: 150, height: 150 }} source={noReview} />
          <Text style={{ fontSize: 16, fontWeight: 500 }}>
            No Ratings found
          </Text>
          {ratingList?.length === 0 && authState?.isLogin && !hasReviewed && <View style={{ display: "flex", justifyContent: "center", marginVertical: 10, alignItems: "center", width: "100%", flexDirection: "row" }}>
            <Button
              icon={"plus"}
              buttonColor={primaryColor}
              textColor={whiteColor}
              onPress={() => setIsVisibleAddRatingModal(true)}
              style={{
                backgroundColor: primaryColor,
                borderColor: primaryColor,
                maxWidth: 480,
                width: "100%",
              }}
            >
              Add Rating
            </Button>
          </View>}
        </View>
      )}

      {isVisibleAddRatingModal && (
        <ReviewModal
          trip_id={tripId}
          visible={isVisibleAddRatingModal}
          handleClose={() => setIsVisibleAddRatingModal(false)}
          setRatingList={setRatingList}
          initialCallingApi={initialCallingApi}
        />
      )}
    </View>
  );
};

export default TripRating;
