import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const ANALYTICS_ENDPOINT = {
  GET_ALL_PARTICIPANT_COUNT: BASE_URL + "analytics/trip_participants/",
  GET_ALL_TRIP_COUNT: BASE_URL + "analytics/trip/count/",
  GET_ALL_PAYMENT_COUNT: BASE_URL + "analytics/payment/",
};

export const GetAllParticipantCount = async () => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.get(ANALYTICS_ENDPOINT.GET_ALL_PARTICIPANT_COUNT, headers);
  }
};

export const GetAllTripAnalytics = async ({ startDate, endDate }) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    let url = ANALYTICS_ENDPOINT.GET_ALL_TRIP_COUNT;
    if (startDate && endDate) {
      url += `?start_date=${startDate}&end_date=${endDate}`;
    } else if (startDate) {
      url += `?start_date=${startDate}`;
    }
    return axios.get(url, headers);
  }
};

export const GetAllPaymentAnalytics = async ({ startDate, endDate }) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    let url = ANALYTICS_ENDPOINT.GET_ALL_PAYMENT_COUNT;
    if (startDate && endDate) {
      url += `?start_date=${startDate}&end_date=${endDate}`;
    } else if (startDate) {
      url += `?start_date=${startDate}`;
    }
    return axios.get(url, headers);
  }
};
