import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  Platform,
  ScrollView,
  Text,
  View,
} from "react-native";
import {
  Button,
  Divider,
  IconButton,
  List,
  Surface,
  TouchableRipple,
} from "react-native-paper";
import { PERMISSION } from "../../../../constant/getAllPermission";
import { ids, styles } from "../../TripInfoCss.js";
import { useSelector } from "react-redux";
import {
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../../constant/constantCss";
import PlaneLoader from "../../../../components/Loader";
import { GetAlbumsApi } from "../../../../service/api/trip/albums.js";
import noPhotoesImg from "../../../../../assets/icons/no_photos.png";
import AddAlbum from "../component/Add Albums/AddAlbums.jsx";
import VerifyAlbumsModal from "./VerifyAlbumsModal/VerifyAlbumsModal.jsx";
import { showToast } from "../../../../constant/toastMessage.js";

const TripAlbums = ({ tripId, albumList, setAlbumList }) => {
  const { width } = Dimensions.get("screen");
  const [loader, setLoader] = useState(true);
  const { permission } = useSelector((state) => state.auth);
  const [refreshAPI, setRefreshAPI] = useState(false);
  const [isVisibleAddAlbumModal, setIsVisibleAddAlbumModal] = useState(false);
  const [activeAlbum, setActiveAlbum] = useState(null);
  useEffect(() => {
    GetAlbumsApi(tripId)
      .then((res) => {
        if(albumList){
          setAlbumList(res.data.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        showToast("error", err?.response?.data?.detail);
        setLoader(false);
      });
  }, [refreshAPI]);

  return (
    <View style={{
      flexDirection: "column",
      flexDirection: "column",
      gap: 20,
      backgroundColor: whiteColor,
      padding: 16,
      marginHorizontal: 10,
      marginTop: 4,
      borderRadius: 10,
    }} id="attractions">
      {/* header */}
      <View>
        {permission && permission.includes(PERMISSION.CAN_UPLOAD_ALBUMS) ? (
          <>
            <View style={styles.rowContainer}>
              <Text style={styles.headerKeyText}>Attractions</Text>
              <View style={{ flexDirection: "row", gap: 10 }}>
                {width >= 576 ? (
                  <Button
                    icon={"plus"}
                    buttonColor={primaryColor}
                    textColor={whiteColor}
                    onPress={() => setIsVisibleAddAlbumModal(true)}
                  >
                    Add Attractions
                  </Button>
                ) : (
                  <IconButton
                    size={20}
                    icon={"plus"}
                    mode="contained"
                    style={{ backgroundColor: primaryColor }}
                    iconColor={whiteColor}
                    onPress={() => setIsVisibleAddAlbumModal(true)}
                  />
                )}
              </View>
            </View>
            <Divider style={{ maxWidth: 500, padding: 0, marginBottom: 16 }} />
          </>
        ) : (
          <>
            {albumList?.length > 0 && (
              <>
                <Text style={styles.headerKeyText}>Attractions</Text>
                <Divider
                  style={{ maxWidth: 500, padding: 0, marginBottom: 16 }}
                />
              </>
            )}
          </>
        )}
      </View>
      {/* loader */}
      {loader && <PlaneLoader />}
      {/* albums section */}
      {albumList?.length > 0 ? (
        <ScrollView horizontal={true} style={{ width: "100%", height: 250 }}>
          {albumList?.map((album, index) => (
            <TouchableRipple
              key={index}
              onPress={() => setActiveAlbum(album?.id)}
            >
              <Surface
                elevation={0}
                key={index}
                style={styles.surfaceContainer}
                dataSet={{ media: ids.surfaceContainer }}
              >
                <Image
                  resizeMode="cover"
                  source={{ uri: album?.album_url }}
                  style={styles.imageContainer}
                  dataSet={{ media: ids.imageContainer }}
                />
                <Text style={{ fontSize: 18, color: subTextColor }}>
                  {album?.location}
                </Text>
                {album?.id === activeAlbum && (
                  <VerifyAlbumsModal
                    imageId={album?.id}
                    imageUrl={album?.album_url}
                    is_public={album?.is_public}
                    trip_id={tripId}
                    setAlbum={setAlbumList}
                    handleClose={() => setActiveAlbum(null)}
                  />
                )}
              </Surface>
            </TouchableRipple>
          ))}
        </ScrollView>
      ) : permission && permission.includes(PERMISSION.CAN_UPLOAD_ALBUMS) ? (
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 16,
            gap: 16,
          }}
        >
          <Image source={noPhotoesImg} style={{ width: 150, height: 150 }} />
          <Text style={styles.noupdatesText}>There are no albums</Text>
        </View>
      ) : null}
      {isVisibleAddAlbumModal && (
        <AddAlbum
          visible={isVisibleAddAlbumModal}
          setVisible={setIsVisibleAddAlbumModal}
          setRefreshAPI={setRefreshAPI}
          tripId={tripId}
        />
      )}
    </View>
  );
};

export default TripAlbums;
