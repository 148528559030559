import React, { useState } from "react";
import { View, Text, Modal, ScrollView } from "react-native";
import { Button, List } from "react-native-paper";
import { ids, styles } from "./PermissionCss";
import SearchPermission from "./Search Permission/SearchPermission";
import Octicons from "react-native-vector-icons/Octicons";
import DescriptionDialog from "../DescriptionDialog";

const ListPermissionModal = ({ visible, handleClose, permissions }) => {
  const [permissionList, setpermissionList] = useState(
    permissions?.permissions
  );
  const [visibleDialog, setVisibleDialog] = useState({
    visible: false,
    permission: "",
    description: "",
  });
  const allPermission = permissions?.permissions; // for searching permission
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Permission List</Text>
            <Text style={styles.closeButton} onPress={handleClose}>
              X
            </Text>
          </View>
          {/* content */}
          <View style={{ width: "100%" }}>
            <SearchPermission
              permissionList={permissionList}
              setpermissionList={setpermissionList}
              allPermission={allPermission}
            />
            <View
              style={[styles.bodyContainer, styles.scrollList]}
              dataSet={{ media: ids.bodyContainer }}
            >
              <ScrollView>
                {permissionList?.map((permission, index) => (
                  <List.Item
                    key={index}
                    left={(props) => (
                      <Octicons
                        name="info"
                        size={20}
                        onPress={() =>
                          setVisibleDialog({
                            visible: true,
                            description: permission.name,
                            permission: permission.codename,
                          })
                        }
                      />
                    )}
                    title={permission.codename?.replaceAll("_", " ")}
                    titleNumberOfLines={3}
                    description={permission.name}
                  />
                ))}
              </ScrollView>
            </View>
          </View>
        </View>
      </View>
      {visibleDialog.visible && (
        <DescriptionDialog
          visibleDialog={visibleDialog}
          setVisibleDialog={setVisibleDialog}
        />
      )}
    </Modal>
  );
};

export default ListPermissionModal;
