import StyleSheet from "react-native-media-query";
import { whiteColor, blackColor } from "../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    width: 500,
    backgroundColor: whiteColor,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: blackColor,
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    "@media (max-width: 768px)": {
      width: "85%",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 22,
    fontWeight: "800",
  },
  closeButton: {
    fontSize: 22,
    fontWeight: "800",
  },

  // Body
  bodyContainer: {
    padding: 20,
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minWidth: "100%",
    overflow: "auto",
  },
});
