import React, { useEffect, useState } from "react";
import { View, Text, Platform, ScrollView, Modal } from "react-native";
import { ids, styles } from "./modalCss";
import { Button, Chip, IconButton, TextInput } from "react-native-paper";
import { Controller, useForm } from "react-hook-form";
import {
  blackColor,
  inputBackColor,
  inputTextColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../../../constant/constantCss";
import DatePicker from "../../../../../components/DatePicker/DatePicker";
import { Dropdown } from "react-native-element-dropdown";
import { GetTripCoordinator } from "../../../../../service/api/trip/getTripCoordinatorApi";
import { UpdateTripAPI } from "../../../../../service/api/trip/api";
import { showToast } from "../../../../../constant/toastMessage";
import { Picker } from "@react-native-picker/picker";
import { useNavigation } from "@react-navigation/native";
import { TripTitleCheck } from "../../../../../service/api/trip/api";

const EditTripModal = ({
  visible,
  handleClose,
  trip_object,
  setRefreshAPI,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: trip_object?.title,
      description: trip_object?.description,
      min_age_limit: trip_object?.min_age_limit?.toString(),
      amount: trip_object?.amount.toString(),
      emergency_name: trip_object?.emergency_name,
      no_of_days: trip_object?.no_of_days.toString(),
      emergency_phone_no: trip_object?.emergency_phone_no.toString(),
      trip_coordinator: trip_object?.trip_coordinator?.id,
      inclusion: trip_object?.inclusion,
      exclusion: trip_object?.exclusion,
      remarks: trip_object?.remarks,
      badge_text: trip_object?.badge_text,
      discount: trip_object?.discount
    },
  });
  const currentTripTitle = trip_object?.title;
  const [tripCoordinator, setTripCoordinator] = useState([]);
  const [tagList, setTagList] = useState(JSON.parse(trip_object?.tag) ?? []);
  const [departureDateList, setdepartureDateList] = useState(
    trip_object?.departure_date
  );
  const navigation = useNavigation();
  const handleDeleteTag = (tagToDelete) => {
    setTagList((prevTag) => prevTag.filter((date) => date !== tagToDelete));
    setValue("departure_date", "");
  };
  const handleEditSubmit = async (data) => {
    let { slug, departure_date, tag, inclusion, exclusion, remarks, ...restOfData } = data;
    if (!restOfData.title) {
      setError("title", { message: "Title is required." });
    }
    else {
      if (currentTripTitle !== restOfData.title) {
        const trip_title_promise = await TripTitleCheck(restOfData.title);
        if (trip_title_promise?.data?.message) {
          setError("title", { message: "Please choose a different title, this one's taken!" })
          return;
        }
      }
    }
    if (tagList.length <= 0) {
      setError("tag", "please press + icon to add the tag");
    } else {
      departure_date = JSON.stringify(departureDateList);
      tag = JSON.stringify(tagList);
      if (!inclusion) {
        inclusion = null;
      }
      if (!exclusion) {
        exclusion = null;
      }
      if (!remarks) {
        remarks = null;
      }
      UpdateTripAPI(trip_object?.id, { ...restOfData, departure_date: departure_date, slug: data.title, tag: tag, inclusion: inclusion, exclusion: exclusion, remarks: remarks })
        .then((res) => {
          showToast("success", res?.data?.message);
          handleClose();
          navigation.navigate("ShowTripInfo", { data: res?.data?.slug })
          setRefreshAPI((prev) => !prev);
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.detail);
        });
    }
  };
  const handleDeleteDipartureDate = (dateToDelete) => {
    setdepartureDateList((prevDates) =>
      prevDates.filter((date) => date !== dateToDelete)
    );
    setValue("departure_date", "");
  };
  useEffect(() => {
    GetTripCoordinator()
      .then((res) => {
        setTripCoordinator(res.data);
      })
      .catch((err) => {
        showToast("error", "Something went wrong");
      });
  }, []);

  const formatTextAsBullets = (text) => {
    return text
      .split('\n')
      .map((line, index) => `\u2022 ${line}`)
      .join('\n');
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText} dataSet={{ media: ids.headerText }}>
              Edit trip details
            </Text>
            <IconButton
              icon={"close"}
              iconColor={blackColor}
              onPress={handleClose}
            />
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <View style={styles.inputView}>
                <View style={styles.flexRow}>
                  <Text style={styles.inputText}>Trip title*</Text>
                  <Text style={styles.inputText}>
                    {watch("title")?.length ?? 0}/40
                  </Text>
                </View>
                <Controller
                  control={control}
                  rules={{
                    required: "This is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      maxLength={40}
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      onChangeText={onChange}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter title of trip"
                    />
                  )}
                  name="title"
                />
                {errors.title && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors?.title?.message || "This is required."}
                  </Text>
                )}
              </View>

              <View style={styles.inputView}>
                <View style={styles.flexRow}>
                  <Text style={styles.inputText}>Trip Description*</Text>
                  <Text style={styles.inputText}>
                    {watch("description")?.length ?? 0}/500
                  </Text>
                </View>
                <Controller
                  control={control}
                  rules={{
                    required: "This is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textAreaInput}
                      maxLength={500}
                      onChangeText={onChange}
                      multiline={true}
                      numberOfLines={4}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter Description of Trip"
                    />
                  )}
                  name="description"
                />
                {errors.description && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors?.description?.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <View style={styles.flexRow}>
                  <Text style={styles.inputText}>Inclusion</Text>
                </View>
                <Controller
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textAreaInput}
                      onChangeText={onChange}
                      multiline={true}
                      numberOfLines={4}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter Inclusion of Trip"
                    />
                  )}
                  name="inclusion"
                />
              </View>
              <View style={styles.inputView}>
                <View style={styles.flexRow}>
                  <Text style={styles.inputText}>Exclusion</Text>
                </View>
                <Controller
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textAreaInput}
                      onChangeText={onChange}
                      multiline={true}
                      numberOfLines={4}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter Exclusion of Trip"
                    />
                  )}
                  name="exclusion"
                />
              </View>
              <View style={styles.inputView}>
                <View style={styles.flexRow}>
                  <Text style={styles.inputText}>Remarks</Text>
                </View>
                <Controller
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textAreaInput}
                      onChangeText={onChange}
                      multiline={true}
                      numberOfLines={4}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter Remarks of Trip"
                    />
                  )}
                  name="remarks"
                />
              </View>
              <View style={styles.inputView}>
                <View style={styles.flexRow}>
                  <Text style={styles.inputText}>Badge Text</Text>
                  <Text style={styles.inputText}>
                    {watch("badge_text")?.length ?? 0}/15
                  </Text>
                </View>
                <Controller
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textInput}
                      maxLength={15}
                      dataSet={{ media: ids.textInput }}
                      onChangeText={onChange}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter Badge Text of trip"
                    />
                  )}
                  name="badge_text"
                />
              </View>
              <View style={styles.inputView}>
                <View style={styles.flexRow}>
                  <Text style={styles.inputText}>Discount %</Text>
                </View>
                <Controller
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textInput}
                      dataSet={{ media: ids.textInput }}
                      onChangeText={onChange}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter discount %"
                    />
                  )}
                  name="discount"
                />
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Tag*</Text>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textInput}
                      onChangeText={onChange}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter tagline of trip"
                      right={
                        <TextInput.Icon
                          size={18}
                          icon={"plus"}
                          color={subTextColor}
                          onPress={() => {
                            if (
                              watch("tag") !== "" &&
                              watch("tag") !== undefined
                            ) {
                              setTagList((prev) => [...prev, watch("tag")]);
                              setValue("tag", "");
                            }
                          }}
                        />
                      }
                    />
                  )}
                  name="tag"
                />
                {errors.tag && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    please press + icon to add the tag
                  </Text>
                )}
              </View>
              <View
                style={{
                  marginTop: 10,
                  flexDirection: "row",
                  gap: 8,
                  flexWrap: "wrap",
                }}
              >
                {tagList?.map((tag, index) => (
                  <Chip
                    key={index}
                    icon="information"
                    closeIcon={"close"}
                    style={{
                      maxWidth: 150,
                      backgroundColor: inputBackColor,
                      color: whiteColor,
                      marginBottom: 10,
                    }}
                    onClose={() => handleDeleteTag(tag)}
                  >
                    {tag}
                  </Chip>
                ))}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Departure Date</Text>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <React.Fragment>
                      {Platform.OS === "web" ? (
                        <input
                          type="date"
                          style={styles.textInput}
                          onChange={(e) => {
                            setValue("departure_date", "");
                            setdepartureDateList((prev) => [
                              ...prev,
                              e.target.value,
                            ]);
                          }}
                          value={value}
                        />
                      ) : (
                        <DatePicker
                          inputString="dd-mm-yyyy"
                          mode="date"
                          setDateTime={(date) => {
                            setValue("departure_date", date);
                            setdepartureDateList((prev) => [...prev, date]);
                          }}
                        />
                      )}
                    </React.Fragment>
                  )}
                  name="departure_date"
                />
              </View>
              <View
                style={{
                  marginTop: 10,
                  flexDirection: "row",
                  gap: 8,
                  flexWrap: "wrap",
                }}
              >
                {departureDateList?.map((departure_date, index) => (
                  <Chip
                    key={index}
                    icon="information"
                    closeIcon={"close"}
                    style={{
                      maxWidth: 150,
                      backgroundColor: inputBackColor,
                      color: whiteColor,
                      marginBottom: 10,
                    }}
                    onClose={() => handleDeleteDipartureDate(departure_date)}
                  >
                    {departure_date}
                  </Chip>
                ))}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Minimum Age Limit*</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "This field is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only integers are allowed",
                    },
                    validate: (value) =>
                      parseFloat(value) > 0 || "Age must be greater than 0",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      keyboardType="numeric"
                      style={styles.textInput}
                      onChangeText={onChange}
                      maxLength={3}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter minimum age limit"
                    />
                  )}
                  name="min_age_limit"
                />
                {errors.min_age_limit && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.min_age_limit.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>No of Days*</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "This field is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only integers are allowed",
                    },
                    validate: (value) =>
                      parseFloat(value) > 0 ||
                      "No of days must be greater than 0",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      keyboardType="numeric"
                      style={styles.textInput}
                      onChangeText={onChange}
                      value={value}
                      maxLength={3}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter No of days"
                    />
                  )}
                  name="no_of_days"
                />
                {errors.no_of_days && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.no_of_days.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Amount*</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "This is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only integers are allowed",
                    },
                    validate: (value) =>
                      parseFloat(value) > 0 || "Amount must be greater than 0",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      keyboardType="numeric"
                      style={styles.textInput}
                      onChangeText={onChange}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter amount of trip"
                    />
                  )}
                  name="amount"
                />
                {errors.amount && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.amount?.message}
                  </Text>
                )}
              </View>

              <View style={styles.inputView}>
                <Text style={styles.inputText}>Trip Coordinator*</Text>
                {Platform.OS === "ios" ? (
                  <Dropdown
                    data={tripCoordinator}
                    labelField="first_name"
                    valueField="id"
                    search
                    mode={"auto"}
                    style={styles.dropdownStyle}
                    placeholderStyle={{ color: subTextColor }}
                    maxHeight={300}
                    inputSearchStyle={{
                      minWidth: 100,
                      maxHeight: 30,
                    }}
                    placeholder="Select Type"
                    searchPlaceholder="Search..."
                    onChange={(item) => {
                      setValue("trip_coordinator", item.id);
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    rules={{
                      required: "This is required",
                    }}
                    render={({ field }) => (
                      <Picker
                        selectedValue={field.value}
                        onValueChange={field.onChange}
                        style={[
                          styles.textInput,
                          { borderWidth: 1, borderColor: inputTextColor },
                        ]}
                      >
                        <Picker.Item
                          label="Select a Trip Coordinator"
                          value=""
                        />
                        {tripCoordinator?.map((item, index) => {
                          return (
                            <Picker.Item
                              key={index}
                              label={item.first_name}
                              value={item.id}
                            />
                          );
                        })}
                      </Picker>
                    )}
                    name="trip_coordinator"
                  />
                )}

                {errors.trip_coordinator && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.trip_coordinator?.message}
                  </Text>
                )}
              </View>

              <View style={styles.inputView}>
                <Text style={styles.inputText}>Emegency Name*</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "This is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textInput}
                      onChangeText={onChange}
                      value={value}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter supervisor name"
                    />
                  )}
                  name="emergency_name"
                />
                {errors.emergency_name && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors?.emergency_name?.message}
                  </Text>
                )}
              </View>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>Emergency Phone Number*</Text>
                <Controller
                  control={control}
                  rules={{
                    required: "This is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      style={styles.textInput}
                      keyboardType="numeric"
                      onChangeText={onChange}
                      value={value}
                      maxLength={10}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="Enter supervisor phone number"
                    />
                  )}
                  name="emergency_phone_no"
                />
                {errors.emergency_phone_no && (
                  <Text
                    style={styles.inputError}
                    dataSet={{ media: ids.inputError }}
                  >
                    {errors.emergency_phone_no.message}
                  </Text>
                )}
              </View>
            </View>
          </ScrollView>
          <View style={styles.inputView}>
            <Button
              mode="contained"
              buttonColor={primaryColor}
              onPress={handleSubmit(handleEditSubmit)}
            >
              Submit
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default EditTripModal;
