import React from "react";
import {
  View,
  Image,
  ImageBackground,
  ScrollView,
} from "react-native";
import { ids, styles } from "./VerifyOtpCss";
import OtpInput from "./OtpInput/OtpInput";
import logo from "../../../../assets/splashLogo.png";
import useKeyboardHeight from "../../../hooks/KeyboardHeight";
import { useSelector } from "react-redux";

const VerifyOtp = ({ route }) => {
  const { backgroundImage } = useSelector((state) => state.general);
  const otpData = route.params.data;
  // OTP Type indicated from where this request comes either : forgot password, register user
  const otpType = route.params.type;
  const keyboardHeight = useKeyboardHeight();
  return (
    <React.Fragment>
      <View style={[styles.imageContainer, { marginBottom: keyboardHeight }]}>
        <ImageBackground
          source={backgroundImage}
          style={styles.imageBackground}
        >
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
          >
            <View
              style={styles.gridContainer}
              dataSet={{ media: ids.gridContainer }}
            >
              <View style={styles.form} dataSet={{ media: ids.form }}>
                <View style={styles.flexRow}>
                  <Image
                    style={styles.image}
                    dataSet={{ media: ids.image }}
                    source={logo}
                  />
                </View>
                <OtpInput otpData={otpData} otpType={otpType} />
              </View>
            </View>
          </ScrollView>
        </ImageBackground>
      </View>
    </React.Fragment>
  );
};

export default VerifyOtp;
