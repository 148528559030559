import StyleSheet from "react-native-media-query";
import {
  ErrorBoldColor,
  TripItineraryColor,
  blackColor,
  errorTextColor,
  greyBackColor,
  lightBlueColor,
  primaryColor,
  sidebarColor,
  subTextColor,
  whiteColor,
} from "../../constant/constantCss";
import { Dimensions } from "react-native";

export const { ids, styles } = StyleSheet.create({
  profileContainer: {
    flexDirection: "row",
    justifyContent:"center",
    minHeight: Dimensions.get("screen").height,
  },
  userProfile: {
    flex: 1,
    marginBottom: 20,
    "@media (min-width: 1024px)": {
      maxWidth: 944,
    },
    "@media (min-width: 1400px)": {
      maxWidth: 1282,
    },
    "@media (min-width: 1900px)": {
      maxWidth: 1586,
    },
  },
  tabView: {
    width: "100%",
    marginBottom: 10,
    marginTop:10,
    borderRadius:10

  },
  // info style
  headerKeyText: {
    fontSize: 22,
    fontStyle: "normal",
    color: primaryColor,
    marginBottom: 6,
    width: "50%",
    "@media (max-width: 576px)": {
      fontSize: 18,
    },
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
  },
  infoSection: {
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: 20,
    "@media (max-width: 576px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: 10,
    },
  },
  tripCoorinatorChip: {
    fontSize: 14,
    color: blackColor,
    paddingHorizontal: 14,
    paddingVertical: 6,
    borderRadius: 10,
    backgroundColor: lightBlueColor,
    maxWidth: 150,
  },
  bulletText: {
    // styles for the entire bullet line
    fontSize: 16,
    color: "black",
    marginBottom: 4,
    alignItems: "center",
    display: "flex",
    flexDirection: "row"
  },
  bullet: {
    // styles for the bullet point
    fontSize: 26,
    color: "black",
  },
  emergencyChip: {
    fontSize: 14,
    paddingHorizontal: 14,
    paddingVertical: 6,
    borderRadius: 10,
    color: blackColor,
    backgroundColor: sidebarColor,
    maxWidth: 120,
  },
  infoKeyText: {
    fontSize: 16,
    color: blackColor,
  },
  infoValText: {
    fontSize: 14,
    color: subTextColor,
  },

  // itinerary
  dayText: {
    paddingHorizontal: 20,
    paddingVertical: 4,
    borderRadius: 20,
    backgroundColor: TripItineraryColor,
    flexWrap: "wrap",
    flexDirection:"row",
    "@media (max-width: 576px)": {
      fontSize: 16,
    },
  },
  locationContainer: {
    marginLeft: 22,
    padding: 16,
    paddingLeft: 24,
    borderLeftWidth: 2,
    borderColor: TripItineraryColor,
    position: "relative",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: 16,
  },
  locationText: { fontSize: 18, color: blackColor, fontWeight: "500", flex: 1 },
  locationImage: {
    width: 450,
    height: 300,
    borderRadius: 16,
    "@media (max-width: 576px)": {
      width: "100%",
      height: 200,
    },
  },
  planeImg: {
    height: 45,
    width: 45,
    position: "absolute",
    left: -24,
    top: 5,
  },
  stopText: {
    fontSize: 16,
    color: subTextColor,
  },
  tinyText: {
    fontSize: 12,
    color: subTextColor,
    marginLeft: 24,
  },
  //  updates
  noUpdateContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
    marginTop: 16,
  },
  noupdatesText: {
    fontSize: 16,
    color: subTextColor,
  },
  listStyle: {
    backgroundColor: whiteColor,
    borderRadius: 10,
  },
  // albums :
  surfaceContainer: {
    width: 266,
    height: "100%",
    backgroundColor: whiteColor,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: 12,
    padding: 16,
  },
  imageContainer: {
    height: 150,
    width: 250,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: "transparent",
  },
  // participants :
  status: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 500,
  },
  listHeader: {
    fontWeight: "bold",
    paddingBottom: 20,
    fontSize: 18,
  },
  tableStyle: {
    width: Dimensions.get("window").width,
    backgroundColor: whiteColor,
    "@media (max-width: 768px)": {
      width: 1000,
    },
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
});
