import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Modal,
  ScrollView,
  Platform,
} from "react-native";
import { ids, styles } from "./ManageSectionModalCss";
import {
  TextInput,
  IconButton,
  Button
} from "react-native-paper";
import {
  blackColor,
  inputTextColor,
  primaryColor,
} from "../../../constant/constantCss";
import { showToast } from "../../../constant/toastMessage";
import { AddSection, DeleteSection, GetSections, UpdateSection } from "../../../service/api/trip/api";

export const ManageSectionModal = ({ visible, handleClose }) => {
  const [sections, setSections] = useState([]);
  const [editing, setEditing] = useState(-1);
  const [newEditedValue, setNewEditedValue] = useState('');
  const [addValue, setAddValue] = useState('');
  const [editingAdd, setEditingAdd] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchData = () => {
    GetSections().then((res) => {
      setSections(res.data.data);
    })
      .catch((e) => {
        showToast("error", "Issue in fetching sections!")
      })
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSavedEdit = (id) => {
    const formdata = new FormData();
    formdata.append("id", id);
    formdata.append("name", newEditedValue);
    UpdateSection(formdata).then(() => {
      fetchData();
    })
      .catch((e) => {
        showToast("error", "Error Updating Name!");
        handleClose();
      })
  }

  const handleDelete = (id) => {
    const formdata = new FormData();
    formdata.append("id", id);
    DeleteSection(formdata).then(() => fetchData()).catch((e) => {
      showToast("error", "Error Deleting!");
      handleClose();
    })
  }

  const handleSaveAdd = () => {
    const formdata = new FormData();
    formdata.append("section_name", addValue);
    AddSection(formdata).then(()=>{
      fetchData();
    })
    .catch(()=>{
      showToast("error", "Error Adding!");
      handleClose();
    })
  }

  const filteredSections = sections.filter(section =>
    section.section_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Modal
      style={styles.modalView}
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Manage Sections</Text>
            <IconButton
              icon={"close"}
              iconColor={blackColor}
              onPress={handleClose}
            />
          </View>
          <View style={{ display: "flex", flexDirection: "col", width: "100%", marginVertical: 15 }}>
            <View style={{ display: "flex", width: "100%" }}>
              <TextInput
                placeholder="Search Sections"
                style={[styles.textInputSearch,Platform.OS !== "web" && {width : 300}, { marginTop: 0, marginBottom: 0 }]}
                outlineColor={blackColor}
                activeOutlineColor={inputTextColor}
                mode="outlined"
                value={searchQuery}
                onChangeText={setSearchQuery}
              />
            </View>
            {!editingAdd ? <View style={{ display: "flex", alignSelf: "flex-start", width: "auto", marginVertical: 5 }}>
              <Button
                mode="contained"
                buttonColor={primaryColor}
                icon={"plus"}
                onPress={() => setEditingAdd(true)}
              >
                Add Section
              </Button>
            </View> : <View style={[styles.addContainer, { width: "45%" }]}>
              <TextInput
                placeholder="Add Sections"
                style={styles.textInputSearch}
                outlineColor={blackColor}
                activeOutlineColor={inputTextColor}
                mode="outlined"
                onChangeText={(e) => setAddValue(e)}
              />
              <IconButton icon="check-circle" iconColor={primaryColor} onPress={() => { setEditingAdd(false); handleSaveAdd() }} />
              <IconButton icon="cancel" iconColor={primaryColor} onPress={() => setEditingAdd(false)} />
            </View>}
          </View>
          <ScrollView style={{ width: "100%" }}>
            {filteredSections.length === 0 && <Text style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>No Section found!</Text>}
            {filteredSections.map((data, index) => {
              return (
                <View key={index} style={styles.sectionStyles}>
                  {editing !== index ? 
                    <Text style={{ fontSize: 16 }}>{data.section_name}</Text> :
                    <TextInput 
                      style={[styles.editTextInput, { height: "90%" }]} 
                      defaultValue={newEditedValue} 
                      autoFocus={true} 
                      outlineColor="white" 
                      activeOutlineColor="white" 
                      underlineColor="white" 
                      activeUnderlineColor="white"
                      placeholder="Enter Section Name" 
                      onChangeText={(e) => setNewEditedValue(e)} 
                    />}
                  <View style={styles.editContainer}>
                    {editing !== -1 && editing === index ?
                      <View style={styles.editContainer}>
                        <IconButton icon="check-circle" iconColor={primaryColor} onPress={() => { setEditing(-1); handleSavedEdit(data.id) }} />
                        <IconButton icon="cancel" iconColor={primaryColor} onPress={() => setEditing(-1)} />
                      </View> :
                      <IconButton icon="pencil" iconColor={primaryColor} onPress={() => { setNewEditedValue(data.section_name); setEditing(index) }} />}
                    <IconButton icon="delete" iconColor={primaryColor} onPress={() => handleDelete(data.id)} />
                  </View>
                </View>
              )
            })}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};
