import StyleSheet from "react-native-media-query";
import {
  blackColor,
  subTextColor,
  whiteColor,
} from "../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  notificationContainer: {
    width: "35%",
    height: 320,
    backgroundColor: whiteColor,
    borderWidth: 1,
    borderColor: "#EEEEEE",
    borderRadius: 10,
    marginTop: 10,
    padding: 14,
    "@media (max-width: 992px)": {
      display: "none",
    },
  },
  headerText: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 16,
    textAlign: "center",
  },
  container: {
    backgroundColor: "white",
  },
  subContainer: {
    padding: 24,
    "@media (max-width: 768px)": {
      padding: 14,
    },
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  headingText: {
    marginTop: 10,
    fontSize: 22,
    fontWeight: "800",
  },
  screenBody: {
    paddingVertical: 20,
  },
  listBody: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: "#D1D1D1",
  },
  listStyle: {
    cursor: "default",
    marginBottom: 5,
    borderColor: subTextColor,
  },
  textInput: {
    fontSize: 16,
    height: 40,
    width: "100%",
    borderRadius: 10,
    backgroundColor: whiteColor,
    color: blackColor,
  },
});
