import React from "react";
import { Dimensions, ScrollView, View } from "react-native";
import StyleSheet from "react-native-media-query";
import { Chip, DataTable } from "react-native-paper";
import { statusBackColor, statusText } from "../../../constant/statusColor";
import moment from "moment";
import { Link } from "@react-navigation/native";
import { primaryColor } from "../../../constant/constantCss";

const PaymentHistoryTable = ({ paymentList, page, setPage, noOfPage }) => {
  const { ids, styles } = StyleSheet.create({
    tableStyle: {
      width: Dimensions.get("window").width,
      "@media (max-width: 768px)": {
        width: 1000,
      },
    },
  });
  return (
    <>
      <ScrollView horizontal={true}>
        <View style={styles.tableStyle} dataSet={{ media: ids.tableStyle }}>
          <DataTable collapsable={true}>
            <DataTable.Header>
              <DataTable.Title>Trip Name</DataTable.Title>
              <DataTable.Title>Order Id</DataTable.Title>
              <DataTable.Title>Mobile Number</DataTable.Title>
              <DataTable.Title>Paid Mode</DataTable.Title>
              <DataTable.Title>Payment Status</DataTable.Title>
              <DataTable.Title>No of People</DataTable.Title>

              <DataTable.Title>Amount Paid(₹)</DataTable.Title>
              <DataTable.Title>Paid on</DataTable.Title>
            </DataTable.Header>
            {paymentList?.map((payment, index) => (
              <DataTable.Row key={index}>
                <DataTable.Cell>
                  <Link
                    style={{ color: primaryColor }}
                    to={{
                      screen: "ShowTripInfo",
                      params: { data: payment?.trip?.id },
                    }}
                  >
                    {payment?.trip?.title}
                  </Link>
                </DataTable.Cell>
                <DataTable.Cell>{payment?.order_id ?? "N/A"}</DataTable.Cell>
                <DataTable.Cell>{payment?.contact}</DataTable.Cell>
                <DataTable.Cell>
                  {payment?.method + "  "}(
                  {payment?.bank ?? payment?.wallet ?? payment?.vpa})
                </DataTable.Cell>
                <DataTable.Cell>
                  <Chip
                    style={{
                      backgroundColor: statusBackColor[payment?.status],
                    }}
                  >
                    {statusText[payment?.status]}
                  </Chip>
                </DataTable.Cell>
                <DataTable.Cell>{payment?.notes?.no_of_people}</DataTable.Cell>
                <DataTable.Cell>{`${payment?.amount / 100} ${
                  payment?.currency
                }`}</DataTable.Cell>
                <DataTable.Cell>
                  {moment(payment?.created_at).format(
                    "MMM D, YYYY [at] hh:mm A"
                  )}
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
        </View>
      </ScrollView>
      <DataTable.Pagination
        page={page}
        numberOfPages={noOfPage}
        onPageChange={(page) => setPage(page)}
        label={`Page ${page + 1} of ${noOfPage}`}
        numberOfItemsPerPage={5}
        showFastPaginationControls
        selectPageDropdownLabel={"Rows per page"}
      />
    </>
  );
};

export default PaymentHistoryTable;
