import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const TRIP_ENDPOINT = {
  GET_ALBUMS: BASE_URL + "album/",
};

export const GetAlbumsApi = async (id) => {
  return axios.get(TRIP_ENDPOINT.GET_ALBUMS + id + "/");
};

export const DeleteAlbumsApi = async (id) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.delete(TRIP_ENDPOINT.GET_ALBUMS + id + "/", headers);
  }
};
export const AddAlbumsApi = async (data) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    const mergedHeaders = {
      ...headers.headers,
      'Content-Type': 'multipart/form-data',
    };
    return axios.post(TRIP_ENDPOINT.GET_ALBUMS, data, {headers:mergedHeaders});
  }
};

export const VerifyAlbumsApi = async (data, imageId) => {
  let headers = await getAuthorizationHeader();
  if (headers) {
    return axios.patch(TRIP_ENDPOINT.GET_ALBUMS + imageId + "/", data, headers);
  }
};
