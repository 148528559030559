import * as React from "react";
import { View, Text, Image, TouchableOpacity, Animated } from "react-native";
import { ids, styles } from "./FeatureTripCardCss.js";
import { Surface } from "react-native-paper";
import tripCardImg from "../../../../assets/DefaultImages/defaultTrip.jpeg";
import { useNavigation } from "@react-navigation/native";
import moment from "moment";
import { blackColor, subTextColor } from "../../../constant/constantCss.js";
import MarqueeText from "react-native-marquee";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { setCurrentTripId, setFromBackButton } from "../../../store/slices/generalSlice.js";

const FeaturedTripCard = ({
  id,
  title,
  address,
  is_public,
  amount,
  image,
  departure_date,
  no_of_days,
  slug_url,
  badge_text,
  discount
}) => {
  const navigation = useNavigation();
  const { selected_trip_id, fromBackButton } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(setFromBackButton(false));
    dispatch(setCurrentTripId(id))
    navigation.navigate("ShowTripInfo", { data: slug_url });
  };
  const fadeAnim = useRef(new Animated.Value(1)).current;

  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 0.2,
      duration: 900,
      useNativeDriver: true,
    }).start();
  };
  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 900,
      useNativeDriver: true,
    }).start();
  };
  useEffect(() => {
    if (id === selected_trip_id && fromBackButton) {
      dispatch(setFromBackButton(false));
      dispatch(setCurrentTripId(null));
      fadeOut();
      setTimeout(() => {
        fadeIn()
      }, 901)
    }
  }, [selected_trip_id, fromBackButton])
  return (
    <TouchableOpacity
      onPress={() => handleButtonClick()}
      style={[styles.upcomingCardContainer]}
      dataSet={{ media: ids.upcomingCardContainer }}
    >
      <Animated.View style={{ opacity: fadeAnim }}>
        <Surface
          elevation={1}
          style={styles.surfaceContainer}
          dataSet={{ media: ids.surfaceContainer }}
        >
          <Image
            resizeMode={"cover"}
            style={styles.cardImage}
            source={image ? { uri: image } : tripCardImg}
          />
          {badge_text && (
            <View style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: 200,
              height: 350,
              overflow: "hidden",
              zIndex: 10,
            }}>
              <View style={{
                position: "absolute",
                top: 27,
                left: -28,
                transform: [{ rotate: '-45deg' }],
                width: 170,
                backgroundColor: "#b01d32",
                paddingVertical: 8,
                marginLeft: "-5%",
                shadowColor: "black",
                shadowOffset: { width: -2, height: 2 },
                shadowOpacity: 0.5,
                shadowRadius: 3,
              }}>
                <Text style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 14,
                  textAlign: "center",
                }}>
                  {badge_text}
                </Text>
              </View>
            </View>
          )}
          <View style={styles.cardFooter}>
            <View style={styles.cardleftFooter}>
              <View style={styles.textFlex}>
                <MarqueeText
                  style={styles.cardPrimaryText}
                  speed={0.2}
                  marqueeOnStart={true}
                  loop={true}
                  delay={1000}
                >
                  {title}
                </MarqueeText>
                <Text style={styles.cardSecondaryText}>
                  {discount && <Text
                    style={{
                      color: subTextColor,
                      textDecorationLine: "line-through",
                      marginRight: "2%"
                    }}
                  >
                    &#8377;{`${amount}`}
                  </Text>}
                  &#8377;{discount? `${parseFloat(amount) - (parseFloat(amount) * parseFloat(discount)/100)}/- ` : `${amount}/- `}
                  <Text
                    style={{
                      fontSize: 14,
                      color: subTextColor,
                      fontWeight: "100",
                    }}
                  >
                    Per Person
                  </Text>
                </Text>

                <Text style={styles.chipText}>
                  {`${no_of_days ?? 0} Day${no_of_days > 1 ? "s" : ""}`}

                  {no_of_days - 1 > 0 &&
                    ` / ${no_of_days - 1 ?? 0} Night${no_of_days - 1 > 1 ? "s" : ""
                    }`}
                  {!is_public && (
                    <Text style={[styles.privateTripText]}> | Private </Text>
                  )}
                </Text>
                <MarqueeText
                  style={{ fontSize: 16, color: blackColor }}
                  speed={0.2}
                  marqueeOnStart={true}
                  loop={true}
                  delay={1000}
                >
                  {departure_date.length !== 0 &&
                    <>
                      <Text style={styles.chipText}>Departure Date : </Text>
                      {departure_date?.map((res, index) => (
                        <Text key={index}>
                          {moment(res).format("Do MMM YYYY")}
                          {index == departure_date.length - 1 ? "" : ", "}
                        </Text>
                      ))}
                    </>}
                </MarqueeText>
              </View>
            </View>
          </View>
        </Surface>
      </Animated.View>
    </TouchableOpacity>
  );
};

export default FeaturedTripCard;
