import React, { useDebugValue } from "react";
import { View, Text, Modal } from "react-native";
import { Button, IconButton } from "react-native-paper";
import { ids, styles } from "./LogoutModalCss";
import {
  ErrorBoldColor,
  blackColor,
  primaryColor,
  whiteColor,
} from "../../constant/constantCss";
import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { resetState } from "../../store/slices/authSlice";
import { setIsDrawerVisible } from "../../store/slices/generalSlice";

const LogoutModal = ({ visible, handleClose }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const handleLogoutButton = async (str) => {
    if (str === "ok") {
      navigation.navigate("Trip");
      await AsyncStorage.clear();
      dispatch(resetState({}));
      dispatch(setIsDrawerVisible(false));
    } else {
      handleClose();
    }
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* header */}
          <View style={styles.headerContainer}>
            <IconButton
              icon={"close"}
              onPress={handleClose}
              iconColor={blackColor}
            />
          </View>
          {/* body */}
          <View
            style={styles.bodyContainer}
            dataSet={{ media: ids.bodyContainer }}
          >
            <IconButton icon={"logout"} size={45} iconColor={primaryColor} />
            <Text style={styles.headerText}>Logout</Text>
            <Text style={styles.descriptionText}>
              Are you sure you want to log out ?
            </Text>
          </View>
          {/* footer */}
          <View style={styles.footerContainer}>
            <Button
              mode="outlined"
              style={{ flex: 1, borderRadius: 10 }}
              labelStyle={{ fontSize: 16 }}
              textColor={blackColor}
              buttonColor={whiteColor}
              onPress={() => handleLogoutButton("cancel")}
            >
              No
            </Button>
            <Button
              mode="contained"
              style={{ flex: 1, borderRadius: 10 }}
              labelStyle={{ fontSize: 16 }}
              buttonColor={primaryColor}
              textColor={whiteColor}
              onPress={() => handleLogoutButton("ok")}
            >
              Yes
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default LogoutModal;
