import React, { useState } from "react";
import { DataTable, Button, Chip } from "react-native-paper";
import ListPermissionModal from "../Permission Modal/ListPermissionModal";
import EditRoleModal from "../Edit Role and Permission/EditRoleModal";
import { useDispatch, useSelector } from "react-redux";
import { DeleteRole, GetAllRole } from "../../../service/api/role/api";
import { setRoleDetails } from "../../../store/slices/rolePermissionSlice";
import { showToast } from "../../../constant/toastMessage";
import { PERMISSION } from "../../../constant/getAllPermission";
import ConfimModal from "../../../components/LogoutConfirmModal/ConfimModal";
import { Dimensions, ScrollView } from "react-native";
import { View } from "react-native";
import StyleSheet from "react-native-media-query";

const RoleTable = ({ page, setPage, noOfPage }) => {
  const formatedRoleTitle = (title) => {
    return title.replaceAll("_", " ");
  };
  const { permission } = useSelector((state) => state.auth);
  const roleList = useSelector((state) => state.rolePermission.roleDetails);
  const [isPermissionVisible, setIsPermissionVisible] = useState(false);
  const [isVisibleEditRole, setisVisibleEditRole] = useState(false);
  const [isVisibleConfim, setIsVisibleConfim] = useState({
    visible: false,
    deleteID: 0,
  });
  const [number, setNumber] = useState(false);
  const dispatch = useDispatch();
  const handleDelete = (role_id) => {
    setIsVisibleConfim({ visible: true, deleteID: role_id });
  };
  const handleFooterButton = (str) => {
    if (str == "ok") {
      DeleteRole(isVisibleConfim.deleteID)
        .then((res) => {
          showToast("success", "Role is deleted!");
          GetAllRole()
            .then((res) => {
              dispatch(setRoleDetails(res?.data?.results));
              setIsVisibleConfim({ ...isVisibleConfim, visible: false });
            })
            .catch((err) => {
              showToast("error", err?.response?.data?.detail);
            });
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.detail);
        });
    } else {
      setIsVisibleConfim({ ...isVisibleConfim, visible: false });
    }
  };
  const { isDrawerVisible } = useSelector((state) => state.general);
  const { ids, styles } = StyleSheet.create({
    tableStyle: {
      width: Dimensions.get("window").width,
      "@media (max-width: 768px)": {
        width: 1000,
      },
    },
  });
  return (
    <React.Fragment>
      <ScrollView horizontal={true}>
        <View style={styles.tableStyle} dataSet={{ media: ids.tableStyle }}>
          <DataTable collapsable={true}>
            <DataTable.Header>
              <DataTable.Title>Number</DataTable.Title>
              <DataTable.Title>Role Type</DataTable.Title>
              <DataTable.Title>Permission</DataTable.Title>
              <DataTable.Title>Action</DataTable.Title>
            </DataTable.Header>
            {roleList?.map((roleRow, index) => (
              <DataTable.Row key={index}>
                <DataTable.Cell>{index + 1}</DataTable.Cell>
                <DataTable.Cell>
                  {formatedRoleTitle(roleRow.name)}
                </DataTable.Cell>
                <DataTable.Cell>
                  <Chip
                    style={{ backgroundColor: "#FFF2F2" }}
                    onPress={() => {
                      if (roleRow?.permissions?.length > 0) {
                        setIsPermissionVisible(true);
                        setNumber(roleRow.id);
                      }
                    }}
                  >
                    {`${roleRow.permissions.length} Permissions`}
                  </Chip>
                </DataTable.Cell>
                <DataTable.Cell>
                  {permission &&
                    permission?.includes(PERMISSION.CAN_EDIT_ROLE) && (
                      <Button
                        mode="text"
                        onPress={() => {
                          setisVisibleEditRole(true);
                          setNumber(roleRow.id);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  {permission &&
                    permission?.includes(PERMISSION.CAN_DELETE_ROLE) && (
                      <Button
                        textColor="#B01D32"
                        mode="text"
                        onPress={() => {
                          handleDelete(roleRow.id);
                        }}
                      >
                        Delete
                      </Button>
                    )}
                </DataTable.Cell>

                {roleRow.id === number && isPermissionVisible && (
                  <ListPermissionModal
                    visible={isPermissionVisible}
                    handleClose={() => setIsPermissionVisible(false)}
                    permissions={roleRow}
                  />
                )}
                {roleRow.id === number && isVisibleEditRole && (
                  <EditRoleModal
                    visible={isVisibleEditRole}
                    handleClose={() => setisVisibleEditRole(false)}
                    roleDetails={roleRow}
                  />
                )}
              </DataTable.Row>
            ))}
          </DataTable>
        </View>
      </ScrollView>
      <DataTable.Pagination
        page={page}
        numberOfPages={noOfPage}
        onPageChange={(page) => setPage(page)}
        label={`Page ${page + 1} of ${noOfPage}`}
        numberOfItemsPerPage={5}
        showFastPaginationControls
        selectPageDropdownLabel={"Rows per page"}
      />
      {isVisibleConfim.visible && (
        <ConfimModal
          visible={isVisibleConfim.visible}
          handleClose={() =>
            setIsVisibleConfim({ ...isVisibleConfim, visible: false })
          }
          title={"Delete Role"}
          description={"Are you sure want to Delete Role ??"}
          handleFooterButton={handleFooterButton}
        />
      )}
    </React.Fragment>
  );
};

export default RoleTable;
