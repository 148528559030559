import StyleSheet from "react-native-media-query";
import {
  SuccessBoldColor,
  blackColor,
  blueColor,
  greyBackColor,
  inputTextColor,
  primaryColor,
  subTextColor,
  whiteColor,
} from "../../../constant/constantCss";

export const { ids, styles } = StyleSheet.create({
  CardContainer: {
    minWidth: "60%",
    marginBottom: 10,
    border: 1,
    backgroundColor: whiteColor,
    borderRadius: 10,
    padding: 15,
    marginBottom: 0,
  },
  flexContainer: {
    flexDirection: "column",
    rowGap: 10,
    "@media (min-width: 992px)": {
      flexDirection: "row",
      columnGap: 40,
    },
  },
  cardImage: {
    height: 350,
    width: "100%",
    marginBottom: 10,
    borderWidth: 1,
    borderColor: "transparent",
    borderRadius: 20,
    position: "relative",
    "@media (max-width: 480px)": {
      height: 240,
      width: "100%",
    },
  },
  infoContainer: {
    "@media (min-width: 992px)": {
      flex: 6,
      justifyContent: "center",
    },
  },
  cardleftFooter: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  timeContainer: {
    height: 90,
    minWidth: 70,
    backgroundColor: primaryColor,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: whiteColor,
    fontSize: 17,
    fontWeight: 700,
  },
  textFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    width: "100%",
  },
  cardPrimaryText: {
    fontSize: 24,
    fontWeight: "700",
    marginBottom: 8,
    "@media (max-width: 768px)": {
      fontSize: 18,
    },
  },
  cardSecondaryText: {
    fontSize: 24,
    fontWeight: "700",
    color: blackColor,
  },
  chipText: {
    fontSize: 14,
    color: subTextColor,
  },
  privateTripText: {
    width: 80,
    color: blueColor,
  },
  cardButton: {
    backgroundColor: primaryColor,
    borderColor: primaryColor,
    maxWidth: 480,
    marginTop: 16,
  },
  BottomContainer: {
    marginBottom: 10,
    flexDirection: "row",
  },
  BottomText: {
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },
  bottomIcon: {
    backgroundColor: greyBackColor,
    padding: 4,
    borderColor: inputTextColor,
    borderRadius: 8,
    borderWidth: 1,
  },
});
