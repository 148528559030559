import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  Image,
  RefreshControl,
  Platform,
  TouchableOpacity,
} from "react-native";
import { ids, styles } from "./NotificationCss";
import {
  Button,
  Chip,
  Divider,
  IconButton,
  List,
  TextInput,
} from "react-native-paper";
import AppBar from "../Layout/AppBar";
import {
  ErrorBoldColor,
  primaryColor,
  WarningColor,
  SuccessColor,
  ErrorColor,
  inputTextColor,
} from "../../constant/constantCss";
import moment from "moment";
import CreateNotificationModel from "./Create Notification/CreateNotificationModel";
import {
  DeleteNotification,
  GetAllNotifications,
} from "../../service/api/notification/api";
import { showToast } from "../../constant/toastMessage";
import noNotificationImg from "../../../assets/icons/no_notification.png";
import { useSelector } from "react-redux";
import { PERMISSION } from "../../constant/getAllPermission";
import PlaneLoader from "../../components/Loader";
import ContactUs from "../LnadingPage/components/ContactUs";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";

const NotificationScreen = () => {
  const { permission } = useSelector((state) => state.auth);
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false);
  const { height, width } = Dimensions.get("window");
  const [notificationList, setNotificationList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchVal, setsearchVal] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const initialCallingAPI = () => {
    setLoader(true);
    GetAllNotifications()
      .then((res) => {
        setLoader(false);
        setNotificationList(res.data);
      })
      .catch((err) => {
        setLoader(false);
        showToast("error",err?.response?.data?.detail);
      });
  };
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      initialCallingAPI();
      setRefreshing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    initialCallingAPI();
  }, []);

  const handleDelete = (id) => {
    DeleteNotification(id)
      .then((res) => {
        showToast("success", res?.data?.message);
        GetAllNotifications()
          .then((res) => {
            setLoader(false);
            setNotificationList(res.data);
          })
          .catch((err) => {
            setLoader(false);
            showToast("error", err?.response?.data?.detail);
          });
      })
      .catch((err) => showToast("error", err?.response?.data?.detail));
  };
  const handleSearch = () => {
    setLoader(true);
    GetAllNotifications(searchVal)
      .then((res) => {
        setLoader(false);
        setNotificationList(res.data);
      })
      .catch((err) => {
        setLoader(false);
        showToast("error", err?.response?.data?.detail);
      });
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <View style={[styles.container, { minHeight: height }]}>
      <AppBar />
      <ScrollView
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            tintColor={primaryColor}
            colors={[primaryColor]}
          />
        }
        style={{marginBottom: Platform.OS==="ios" ? 110: Platform.OS!=="web" && 60}}
      >
        <View style={styles.subContainer} dataSet={{ media: ids.subContainer }}>
          <View style={styles.flexRow}>
            <Text style={styles.headingText}>Notification</Text>
            {permission &&
              permission?.includes(PERMISSION.CAN_CREATE_NOTIFICATION) && (
                <View style={styles.flexRow}>
                  <Button
                    icon={"bell-plus"}
                    mode="contained"
                    buttonColor={primaryColor}
                    onPress={() => setIsVisibleCreateModal(true)}
                  >
                    {width >= 480 && "Add New Notification"}
                  </Button>
                </View>
              )}
          </View>
          <View style={styles.screenBody}>
            {loader ? (
              <PlaneLoader />
            ) : (
              <View style={styles.listBody}>
                {notificationList.length > 0 ? (
                  <>
                    <TextInput
                      left={
                        <TextInput.Icon
                          icon="database-search"
                          color="#000"
                          onPress={handleSearch}
                        />
                      }
                      style={styles.textInput}
                      mode="outlined"
                      outlineColor={inputTextColor}
                      activeOutlineColor={inputTextColor}
                      placeholder="search by type or message"
                      onChangeText={(val) => setsearchVal(val)}
                    />
                    {notificationList?.map((notify, index) => (
                      <React.Fragment key={index}>
                        <List.Item
                          style={styles.listStyle}
                          title={
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 20,
                                  fontWeight: 800,
                                  marginRight: 8,
                                }}
                              >
                                {notify.type}
                              </Text>
                            </View>
                          }
                          descriptionNumberOfLines={10}
                          description={
                            <View
                              style={{
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <Text>{notify.message} </Text>
                              <Text>
                                {moment(notify.scheduled_at).format(
                                  "MMM D, YYYY [at] hh:mm A"
                                )}
                              </Text>
                            </View>
                          }
                          left={(props) => (
                            <List.Icon {...props} icon="bell-ring" />
                          )}
                          right={(props) =>
                            permission &&
                            permission.includes(
                              PERMISSION.CAN_DELETE_NOTIFICATION
                            ) && (
                              <IconButton
                                {...props}
                                iconColor={ErrorBoldColor}
                                icon="delete"
                                onPress={() => handleDelete(notify.id)}
                              />
                            )
                          }
                        />
                        <Divider horizontalInset={true} />
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 50,
                      gap: 20,
                    }}
                  >
                    <Image
                      source={noNotificationImg}
                      style={{ height: 300, width: 300 }}
                    />
                    <Text style={styles.headerText}>
                      There are no notifications
                    </Text>
                  </View>
                )}
              </View>
            )}
          </View>
        </View>
        {Platform.OS!=="web" && <ContactUs />}
      </ScrollView>
      {Platform.OS==="web" && 
        <>
        <ContactUs />
        <TouchableOpacity
          style={{
            position: 'sticky',  
            bottom: 20,
            right: 20,
            marginTop:-44,
            alignSelf: 'flex-end',
            borderRadius: 66,
            backgroundColor:"#ffb4ac",
          }}
          onPress={handleScrollToTop}
        > 
          <MaterialIcons name="keyboard-arrow-up" style={{fontSize:42}}/>
        </TouchableOpacity>
        </>
      }
      {isVisibleCreateModal && (
        <CreateNotificationModel
          visible={isVisibleCreateModal}
          handleClose={() => setIsVisibleCreateModal(false)}
          setNotificationList={setNotificationList}
        />
      )}
    </View>
  );
};

export default NotificationScreen;
