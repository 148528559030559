import axios from "axios";
import { BASE_URL, getAuthorizationHeader } from "../const";

const USER_ENDPOINT = {
    USER_FILTER_URL : BASE_URL+"auth/",
}

    
export const UserAuthAPI = async(type, data) => {
    
    return axios.post(USER_ENDPOINT.USER_FILTER_URL+type, data)
}