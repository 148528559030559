import {
  ErrorBoldColor,
  ErrorColor,
  InfoBoldColor,
  InfoColor,
  SuccessBoldColor,
  SuccessColor,
  WarningBoldColor,
  WarningColor,
} from "./constantCss";

export const statusBackColor = {
  pending: WarningColor,
  created: WarningColor,
  authorized: WarningColor,
  captured: SuccessColor,
  refunded: InfoColor,
  failed: ErrorColor,
};
export const statusTextColor = {
  pending: WarningBoldColor,
  created: WarningBoldColor,
  authorized: WarningBoldColor,
  captured: SuccessBoldColor,
  refunded: InfoBoldColor,
  failed: ErrorBoldColor,
};

export const statusText = {
  pending: "pending",
  created: "pending",
  authorized: "pending",
  captured: "success",
  refunded: "refunded",
  failed: "failed",
};
