import StyleSheet from "react-native-media-query";
import { inputBackColor } from "../../constant/constantCss";

export const {ids, styles} = StyleSheet.create({
    textAreaInput: {
        fontSize: 16,
        height:38,
        flex:0.8,
        borderRadius:20,
        backgroundColor: inputBackColor,
        maxWidth:350,
        paddingLeft:10,
        paddingRight:10
    },
    dateIcon:{
        marginLeft:20,
    }
});
