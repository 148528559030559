import React, { useRef, useState } from "react";
import { ids, styles } from "../../AddSchoolModal/AddSchoolModalCss";
import { Modal, View, Text, ScrollView, Platform, Linking } from "react-native";
import { Button, IconButton } from "react-native-paper";
import { primaryColor, subTextColor } from "../../../../constant/constantCss";
import { showToast } from "../../../../constant/toastMessage";
import { CreateUserList } from "../../../../service/api/school/api";
import * as DocumentPicker from "expo-document-picker";
import moment from "moment";

const CreateStudentListModal = ({ visible, handleClose, schoolName, school_id }) => {
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    // Trigger a click event on the file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDownload = async () => {

    const CSVLink =
      "https://drive.google.com/u/0/uc?id=1iCEwiqaClQsm0bvYhul0GYW_wetvDKN9&export=download";
    // Open the PDF link in a new tab or window
    if (Platform.OS === "web") {
      window.open(CSVLink, "_blank");
    } else {
      try {
        await Linking.openURL(CSVLink);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const handleUploadCSVAndroidIphone = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "text/csv",
    });
    const currentDate = moment();
    const formattedDate = currentDate.format("YYYY-MM-DD_HH-mm-ss");
    const imageName = `csv_${formattedDate}.csv`;

    if (!result?.canceled) {
      const formData = new FormData();
      formData.append("school", schoolName);
      formData.append("file", {
        uri: result?.assets?.[0]?.uri,
        type: result?.assets?.[0]?.mimeType,
        name: imageName,
      });
      CreateUserList(formData)
        .then((res) => {
          setLoader(false);
          showToast(
            "success",
            res?.data?.message,
            "User's Password sent in their email id!"
          );
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          showToast(
            "error",
            err?.response?.data?.message ??
              err?.response?.data?.error?.phone_number?.[0] ??
              err?.response?.data?.detail,
            err?.response?.data?.description ?? err?.response?.data?.row
          );
        });
    }
  };

  const handleSubmit = () => {
    if (selectedFile) {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("school", schoolName);
      formData.append("school_id", school_id);
      CreateUserList(formData)
        .then((res) => {
          setLoader(false);
          showToast(
            "success",
            res?.data?.message,
            "User's Password sent in their email id!"
          );
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          showToast(
            "error",
            err?.response?.data?.message ??
              err?.response?.data?.error?.phone_number?.[0] ??
              err?.response?.data?.detail,
            err?.response?.data?.description ?? err?.response?.data?.row
          );
        });
    } else {
      showToast(
        "error",
        "Please select csv file!",
        "File type must be csv file"
      );
    }
  };
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={handleClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView} dataSet={{ media: ids.modalView }}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Add User List</Text>
            <Text style={styles.closeButton} onPress={handleClose}>
              X
            </Text>
          </View>
          {/* Content */}
          <ScrollView style={{ width: "100%" }}>
            <View
              style={styles.bodyContainer}
              dataSet={{ media: ids.bodyContainer }}
            >
              <Text style={{ color: subTextColor, marginTop: 10 }}>
                Upload CSV File with 7 column format {"\n"}
                (first_name,dialing_code,phone_number,email,gender,date_of_birth,enrollment_no)
              </Text>
              <Button textColor={primaryColor} onPress={handleDownload}>
                Click here to download demo format
              </Button>

              <View
                style={{
                  marginTop: 20,
                  width: "100%",
                  marginBottom: 8,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Text style={styles.inputText}>Upload CSV*</Text>
                {Platform.OS === "web" ? (
                  <>
                    <input
                      type="file"
                      accept={".csv"}
                      ref={fileInputRef}
                      style={styles.uploadInput}
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <IconButton
                      icon="file-upload"
                      iconColor={primaryColor}
                      mode={"contained"}
                      onPress={handleUploadButtonClick}
                    ></IconButton>
                  </>
                ) : (
                  <IconButton
                    icon="file-upload"
                    iconColor={primaryColor}
                    mode={"contained"}
                    onPress={handleUploadCSVAndroidIphone}
                  ></IconButton>
                )}
                <Text>{selectedFile?.name}</Text>
              </View>
              <Button
                icon={"content-save"}
                loading={loader}
                mode="contained"
                buttonColor={primaryColor}
                onPress={handleSubmit}
              >
                {loader ? "Saving" : "Save"}
              </Button>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default CreateStudentListModal;
